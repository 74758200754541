import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select, { components, StylesConfig, ValueContainerProps } from "react-select";
export type Option = {
    value: string;
    label: string;
};



const ValueContainer = ({
    children,
    ...props
}: ValueContainerProps<Option>) => {

    let [values, input] = children as any;

    if (Array.isArray(values)) {
        // console.log(values[0].props?.children);

        // const plural = values.length === 1 ? "" : "s";
        // values = values.length === 1 ? values[0].props?.children : `${values.length} items selected`;
        values = values.length === 1 ? <div className="select-text-overflow">{values[0].props?.children}</div> : <div className="select-text-overflow">{`${values.length} items selected`}</div>;
    }

    return (
        <components.ValueContainer {...props}>
            {values}
            {input}
        </components.ValueContainer>
    );
};

export default function CategorySelect(props: any) {

    const { value, options, onChange, className, isMulti, placeholder, isSearchable } = props;
    // const [selected, setSelected] = React.useState<Option[]>([]);
    const cliendConfig = useSelector((state: any) => state.careers.cliendConfig);
    const [prColor, setPrColor] = useState("");

    useEffect(() => {
        if (cliendConfig.status === 200) {
            const dataress = cliendConfig?.data?.data?.clientSettings;
            setPrColor(dataress?.secondaryColor);
            // document.documentElement.style.setProperty("--theam-secondary-color", dataress?.secondaryColor);
        }


    }, [cliendConfig]);
    const colourStyles: StylesConfig<any> = {
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? prColor
                : isSelected
                    ? prColor
                    : undefined,
            zIndex: 1,
            padding: 4,
            fontSize: 14,
            color: isFocused
                ? "#FBFBFB"
                : isSelected
                    ? "#FBFBFB"
                    : undefined,
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                    ? prColor
                    : prColor
                    : undefined,
            },        

        }),
        // control: (styles) => (console.log(styles),
        // { ...styles, backgroundColor: "white" }),
        // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        //   return {
        // ...styles,
        // backgroundColor: isDisabled
        //   ? undefined
        //   : isSelected
        //   ? data.color
        //   : isFocused
        //   ? color.alpha(0.1).css()
        //   : undefined,
        // color: isDisabled
        //   ? "#ccc"
        //   : isSelected
        //   ? chroma.contrast(color, "white") > 2
        //     ? "white"
        //     : "black"
        //   : data.color,
        // cursor: isDisabled ? "not-allowed" : "default",

        //     ":active": {
        //       ...styles[":active"],
        //       backgroundColor: !isDisabled
        //         ? isSelected
        //           ? "red"
        //           : ""
        //         : undefined,
        //     },
        //   };
        // },
        // input: (styles) => ({ ...styles, ...dot() }),
        // placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
        // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };
    const selectValue = (s: any) => {
        onChange(s);
    };

    return (
        <Select
            className={className}
            value={value ? value : null}
            onChange={(s: any) => selectValue(s)}
            options={options}
            isMulti={isMulti}
            isSearchable={isSearchable}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            placeholder={placeholder}
            components={{
                ValueContainer
            }}
            styles={colourStyles}
        />
    );
}
