import React, { useEffect, useState } from "react";
import NotFount from "../../assets/images/notfount.png";
import Image from "../../component/image/Image";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const NotFountError = () => {
    const location = useLocation();
    const onboardingUrl = "getCandidate";
    const [errorControl, setErrorControl] = useState<any>(null);
    const jobsList = useSelector((state: any) => state.careers.allJobList);

    const notLoadPageError = () => {
        return (
            <div className="text-center mt-5" >
                <Image src={NotFount} clasName="img-fluid" width={500} />
            </div>
        );
    };
    const onboardingPageError = () => {
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <div className="text-center mt-5" >
                            <div className="alert alert-danger" role="alert">
                                Please try with valid URL
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    useEffect(() => {
        const locationUrl = location.pathname?.split("/");
        if (locationUrl?.includes(onboardingUrl)) {
            setErrorControl(onboardingPageError());
        } else if(jobsList?.status === 401) {
            setErrorControl(onboardingPageError());
        } else {
            setErrorControl(notLoadPageError());
        }
    }, [location]);

    return (
        <>
            {errorControl}
        </>
    );
};

export default NotFountError;