import React, { useEffect, useState } from "react";
import "./ChipInput.scss";

/**
 * 
 * @param props label:string - adds a label, with a htmlfor and label content
 * @param chips parent state, passed in, holds all the chips
 * @param setChips update for parent state, updates chips
 * @param regExValidator {rule, errorMessage} regex rule test expr - rule to check validity against, message to show if it fails
 * @param required boolean: required validation (min 1 chip)
 * @param requiredError string, message to show when there is a required validation message to be shown
 * @param formRef any reference to form or formik form
 * @param placeholder : string, input placeholder for chip input
 */
const ChipInput = (props:any) => {
    
    const { label, chips, setChips, regExValidator, required, requiredError, formRef, placeholder } = props;  
    const [text, setText] = useState("");  
    const [validationError, setValidationError] = useState("");    

    const removeChip = (chipToRemove:any) => {
        // filtering out the chip that the user wants to remove
        const updatedChips = chips.filter((chip:any) => chip !== chipToRemove);
        setChips(updatedChips);
    };

    const handlePressEnter = (e:any) => {
        // don't submit the form if the user presses 'Enter'
        if (e.key === "Enter") {
            e.preventDefault();
        }
        // return if the user pressed a key that is not 'Enter', or the user hasn't typed anything
        if (e.key !== "Enter" || !text) {
            return;
        }
        // need to show error if the user tries to add the same input more than once
        if (chips.includes(text)) {
            setValidationError("Cannot add the same input more than once");
            return;
        }
        /**
         * Regular expr validator
         * @param : rule / regex format
         * @param : errMessage / string
         */
        if(!regExValidator.rule.test(e.target.value)) {
            setValidationError(regExValidator.errMessage);
            return;
        }
        // adding the input value to chips array
        setChips([...chips, e.target.value]);

        // clearing the input box
        setText("");
        
        // clearing error message
        setValidationError("");
    };    

    return (
        <div className="chipinput-container">
        {label && <label htmlFor={label}>{label}</label>}
        <div className="input-container">
            <ul className="chips">
                {chips.map((chip:any) => (
                    <li key={chip} className="chip">
                    <span>{chip}</span>
                    <span className="pi pi-times" onClick={() => removeChip(chip)} />
                    </li>
                ))}
            </ul>
            <input
            type="text"
            id="tags"
            placeholder={placeholder ? placeholder : "Press Enter to add tag"}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handlePressEnter}
            />
        </div>
        {validationError && <p className="error-message">{validationError}</p>}
        </div>
    );
};

export default ChipInput;
