import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";

const Loader = (props: any) => {
    const { loading } = props;
    useEffect(() => {
        if (loading) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }

    }, [loading]);
    return (
        <>
            {loading ?
                <div className="loader">
                    <div className="loader-sub">
                        <Spinner animation="border" variant="secondary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <div className="text">Loading...</div>
                    </div>
                </div>
                :
                ""
            }
        </>
    );
};

export default Loader;