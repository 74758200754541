import React, { useCallback, useEffect, useMemo, useState } from "react";
import Image from "../../../../component/image/Image";
import Button from "../../../../component/button/Button";
import { useDispatch, useSelector } from "react-redux";
import userIcon from "../../../../assets/images/user_icon.png";
import DateIcon from "../../../../assets/images/date-icon.svg";
import JobIcon from "../../../../assets/images/jobIcon.png";
import Price from "../../../../assets/images/Price.png";
import Category from "../../../../assets/images/category.png";
import LocationIcon from "../../../../assets/images/locationIcon.png";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import JobFilter from "../JobFilter";
import XIcon from "../../../../assets/images/xicon.png";
import FaceBoox from "../../../../assets/images/Facebookicon.png";
import Linkdin from "../../../../assets/images/LinkedInicon.png";
import Mail from "../../../../assets/images/Gmail.png";
import Pinterest from "../../../../assets/images/Pinterest.png";
import Reddit from "../../../../assets/images/Reddit.png";
import Whatsapp from "../../../../assets/images/Whatsapp.png";
import Telegram from "../../../../assets/images/Telegram.png";
import Pocket from "../../../../assets/images/Pocket.png";
import CopyLink from "../../../../assets/images/CopyLink.png";
import {
  getJobDetails,
  getJobtemplateconfig,
  getQuestionList,
  loderControlFalse,
  updateJobViewConfig,
} from "../../../../redux/slices/careersSlices.reducer";
import { publishingDate } from "../CareersFucConfig";
import Model from "../../../../component/modal/Modal";
import CareersFooter from "../../CareersFooter/CareersFooter";
import { clientIds, clients, customFooterIds } from "../../../../config/clients/clients";
import { NavigateOptions } from "../../../../config/interface/htmlInterFace";
import JobViewSplit from "./JobViewSplit";
import JobViewClient from "./JobViewClient";
import JobFilterClient from "../JobFilterClient";
import CareersCustomFooter from "../../CareersFooter/CareersCustomFooterBase";



const JobView = (props: any) => {
  const { jobDataView, filterListData, tableControl, setTableControl, searchControlSubmit, withinDisabled, withinDisabledLocClass, clientId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const routerLocation = useLocation();



  const jobDetails = useSelector((state: any) => state.careers.jobDetails);

  const jobConfig = useSelector((state: any) => state.careers.jobConfig);
  const questionId = useSelector((stete: any) => { return stete?.careers?.getQuestionId; });
  const [updateTemplete, setUpdateTemplete] = useState([]);
  const [jobModel, setJobModel] = useState(false);
  const [clientDesign, setClientDesign] = useState("");
  const cliendConfig = useSelector((state: any) => state.careers.cliendConfig);

  const checkClientDesign = () => {
    if(clientIds?.hvtc === cliendConfig?.data?.data?.clientSettings?.clientId){
        setClientDesign("hvtc");
    } else {
        setClientDesign("common");
    }
  };

  useEffect(() => {
      checkClientDesign();
  }, [routeParams, cliendConfig]);

  useEffect(() => {
    if (routerLocation.pathname === `/view/${routeParams?.id}`) {
      dispatch<any>(getJobtemplateconfig(cliendConfig?.data?.data?.clientSettings?.clientId));
      if(Object.keys(questionId).length === 0){
        dispatch<any>(getQuestionList());
      }
    }
  }, []);

  useEffect(() => {    
    if (routerLocation.pathname === `/view/${routeParams?.id}/apply-job/from/${routeParams?.from}`) {
      dispatch<any>(getJobDetails(routeParams?.id));
    }
  }, [routeParams?.id]);

  useEffect(() => {

    if (
      jobDetails?.data?.statusCode === 200 &&
      routerLocation?.pathname ===
      `/view/${routeParams?.id}`
    ) {
      // console.log(343);
      if (navigator.userAgent.indexOf("Firefox") !== -1) {
        // alert("Chrome");
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 1300);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [routerLocation]);

  // console.log(jobDetails?.data?.data?.job?.jobType);

  // title
  const titleSection = () => {
    return (
      <>
        <div className="job-heading d-flex">
          <div className="job-heading-title">{jobDetails?.data?.data?.job.title}</div>
          {routerLocation.state !== "jobApply" ? (
            <div className="d-sm-block d-block d-md-none d-none">
              <i className="pi pi-star me-2 header-star"></i>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };
  // description
  const description = () => {
    return (
      <>
        <div
          className="job-view-description"
          dangerouslySetInnerHTML={{
            __html: jobDetails?.data?.data?.job.description,
          }}
        >
          {/* {jobDetails?.data?.data?.job.description} */}
        </div>
      </>
    );
  };
  const videoLink = () => {
    return (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: jobDetails?.data?.data?.job?.videoLink,
          }}
        ></div>
      </>
    );
  };
  const imageLink = () => {
    return (
      <>
        <Image src={jobDetails?.data?.data?.job?.imageLink} />
      </>
    );
  };
  const jobType = () => {
    return <>{jobDetails?.data?.data?.job?.jobType}</>;
  };
  const employmentType = () => {
    return <>{jobDetails?.data?.data?.job?.employmentType}</>;
  };
  const location = () => {
    return (
      <>
        <div className="d-flex align-items-center pe-4">
          <Image src={LocationIcon} width={15} />
          <div className="add-start d-flex">
            {" "}
            <span className="d-none d-sm-none d-md-block d-lg-block mob-ipad-d-none">
                Location :
            </span>
            <span className="location">
               {jobDetails?.data?.data?.job?.location?.location}
            </span>
          </div>
        </div>
      </>
    );
  };
  const jobLogo = () => {
    let clientSettings = cliendConfig.data.data.clientSettings;
    let defaultJobLogo = "https://stag-compliance-careers.s3.ap-southeast-2.amazonaws.com/career-doc-settings/uploads/no_image.png";
    if(clientSettings.clientId === clientIds.hvtc) {
      defaultJobLogo = cliendConfig.data.data.clientSettings.logo;
    }
    return (
      <div className="text-end">
        <Image
          src={
            jobDetails?.data?.data?.job?.logo
              ? jobDetails?.data?.data?.job?.logo
              : defaultJobLogo
          }
          width={100}
          
          alt="logo"
        />
      </div>
    );
  };
  const expiryDate = () => {
    return <>{jobDetails?.data?.data?.job?.expiryDate}</>;
  };
  const category = () => {
    return (
      <>
        <div className="d-flex align-items-center">
          <Image src={Category} width={15} />
          <div className="add-start">
          <span className="mob-ipad-d-none">Category:</span>{" "}
            <span className="add-avalue">
              {" "}
              {jobDetails?.data?.data?.job?.category}
            </span>
          </div>
        </div>
        {/* <div className="d-flex align-items-center pt-2">
          <Image src={Category} width={15} />
          <div className="add-start">
            Sub Category:{" "}
            <span className="add-avalue">
              {" "}
              {jobDetails?.data?.data?.job?.subCategory}
            </span>
          </div>
        </div> */}
      </>
    );
  };
  const subCategory = () => {
    return <>{jobDetails?.data?.data?.job?.subCategory}</>;
  };
  const referenceNo = () => {
    return (
      <>
        <div
          className={`d-flex align-items-center pe-4 ${routerLocation.state === "jobApply" ? "pt-2" : ""
            }`}
        >
          <Image src={JobIcon} width={15} />
          <div className="add-start d-flex">
            {" "}
            <span className="d-none d-sm-none d-md-block d-lg-block mob-ipad-d-none">
              Job Reference :
            </span>
            <span className="add-avalue">
               {jobDetails?.data?.data?.job?.referenceNo}
            </span>
          </div>
        </div>
      </>
    );
  };
  const postedOn = () => {
    return (
      <>
        <div className="d-flex align-items-center pe-2">
          <Image src={DateIcon} />
          <div className="add-start">
          <span className="mob-ipad-d-none">Posted On:</span>{" "}
            <span className="add-avalue">
              {publishingDate(jobDetails?.data?.data?.job?.postedAt)}
            </span>
          </div>
        </div>
      </>
    );
  };
  const addRess = () => {
    return (
      <>
        <div>
          {/* <div className="d-flex align-items-center pt-2">
                        <Image src={JobIcon} width={15} />
                        <div className="add-start">Job Reference: <span className="add-avalue">TT005891</span></div>
                    </div> */}
          {/* {referenceNo()} */}
          {/* {postedOn()} */}
        </div>
      </>
    );
  };
  const salary = () => {
    return (
      <>
        <div className="d-flex align-items-center">
          <Image src={Price} width={15} />
          <div className="add-start">
            {" "}
            Salary:<span className="add-avalue">$80,000 + Super + Bonus</span>
          </div>
        </div>
      </>
    );
  };

  const positionType = () => {
    return (
      <>
        <div className="d-flex align-items-center ">
          <Image src={userIcon} width={15} />
          <div className="add-start">
            <span className="mob-ipad-d-none">Job type:</span>{" "}
            <span className="add-avalue">
              {jobDetails?.data?.data?.job?.jobType}
            </span>
          </div>
        </div>
      </>
    );
  };
  // ======================================
  const imgAndVideo = () => {
    return (
      <>
        {/* {routerLocation.state !== "jobApply" ? */}
        <div className="pt-2">
          {(jobDetails?.data?.data?.job?.videoLink !== "" && jobDetails?.data?.data?.job?.videoLink !== null) ? (
            <>
              <iframe width={ clientDesign === "hvtc" ? "750" : "837" } height='315' src={jobDetails?.data?.data?.job?.videoLink} title='Your Career' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen></iframe>
              {/* <div
                className="job-view-video"
                dangerouslySetInnerHTML={{
                  __html: jobDetails?.data?.data?.job?.videoLink,
                }}
              ></div> */}
            </>
          ) : (
            <>
              {(jobDetails?.data?.data?.job?.imageLink !== "" && jobDetails?.data?.data?.job?.imageLink !== null) ? (
                <>
                  <Image width={ clientDesign === "hvtc" ? "750" : "837" } height='315' src={jobDetails?.data?.data?.job?.imageLink} />
                </>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        {/* : "" */}
        {/* } */}
      </>
    );
  };

  const applyNow = () => {
    return (
      <>
        <div className="d-flex align-items-center my-3 bg-danger">
          <Button label={"Apply Now"} clasName="btn btn-primary" />
        </div>
      </>
    );
  };
  const navicateToApplayJob = () => {
    navigate(`/view/${routeParams?.id}/apply-job/from/direct`);
  };
  const ApplayNowButton = () => {
    return (
      <>
        {routerLocation.state !== "jobApply" ? (
          <>
            <div className="me-3 my-3 d-flex completion-btn-primary-parent">
              <a onClick={() => navicateToApplayJob()} className={ clientDesign === "hvtc" ? "butn completion-btn-primary" : "btn btn-primary completion-btn-primary" }>
                <span>{cliendConfig?.data?.data?.clientSettings?.applyButtonText}</span>
              </a>
            </div>
            {routerLocation.state !== "jobApply" ? (
              <div className="d-sm-block d-block d-md-none ms-auto d-none">
                {/* <i className="pi pi-star me-2 header-star"></i>
                {jobLogo()} */}
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  };
  const starred = () => {
    return (
      <>
        <div className="d-none d-sm-node d-md-flex align-items-center unstarred">
          <i className="pi pi-star me-2"></i>
          <div className="me-2 mt-1">Unstarred</div>
        </div>
      </>
    );
  };
  const ViewAllStarred = () => {
    return (
      <>
        <div className="ms-lg-4 ms-md-4 ms-sm-0 ms-0 mt-1 unstarred d-none d-sm-none d-md-block">
          View All starred
        </div>
      </>
    );
  };
  const socialShare = () => {
    return (
      <>
        {routerLocation.state !== "jobApply" ? (
          <div className="d-flex">
            <Image
              src={FaceBoox}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
            <Image
              src={XIcon}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
            <Image
              src={Linkdin}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
            <Image
              src={Mail}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
            <Image
              src={Pinterest}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
            <Image
              src={Reddit}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
            <Image
              src={Whatsapp}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
            <Image
              src={Telegram}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
            <Image
              src={Pocket}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
            <Image
              src={CopyLink}
              clasName="img-fluid my-3 mx-1"
              width={25}
              height={20}
            />
          </div>
        ) : (
          ""
        )}
      </>
    );
  };
  const listOfPoint = () => {
    return (
      <>
        {jobDetails?.data?.data?.job?.bulletPoint1 ||
          jobDetails?.data?.data?.job?.bulletPoint2 ||
          jobDetails?.data?.data?.job?.bulletPoint3 ? (
          <div>
            <ul className="list-style">
              {jobDetails?.data?.data?.job?.bulletPoint1 ? (
                <li>{jobDetails?.data?.data?.job?.bulletPoint1}</li>
              ) : (
                ""
              )}

              {jobDetails?.data?.data?.job?.bulletPoint2 ? (
                <li>{jobDetails?.data?.data?.job?.bulletPoint2}</li>
              ) : (
                ""
              )}

              {jobDetails?.data?.data?.job?.bulletPoint3 ? (
                <li>{jobDetails?.data?.data?.job?.bulletPoint3}</li>
              ) : (
                ""
              )}
            </ul>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const JobDetails = () => {
    return <></>;
  };

  const subDataConfigList = (params: any) => {
    switch (params.label) {
      case "Apply Now":
        return ApplayNowButton();
      case "Starred":
        return starred();
      case "View All Starred":
        return ViewAllStarred();
      case "Location":
        return location();
      case "Job Reference":
        return referenceNo();
      case "Posted On":
        return postedOn();
      case "Position Type":
        return positionType();
      case "Category":
        return category();
      // case "Salary":
      //   return salary();
      default:
        return "";
    }
  };
  const subDataConfigPosition = (params: any) => {
    return (
      <>
        {params?.subsection.map((ress: any, i: number) => {
          return (
            <div
              key={i}
              className={
                ress.lineType === "horizantal"
                  ? "d-flex align-items-center "
                  : ""
              }
            >
              {ress?.lineItems.map(
                (data: any) => {
                  return subDataConfigList(data);
                }
                // return (
                //     <>
                //         {subDataConfigList(data)}
                //     </>
                // );
              )}
            </div>
          );
        })}
        <div className=""></div>
      </>
    );
  };
  const section = (params: any) => {
    switch (params.cvalue) {
      case "title":
        return titleSection();
      case "location":
        return location();
      case "media":
        return imgAndVideo();
      case "social_share":
        return socialShare();
      case "":
        return subDataConfigPosition(params);
      default:
        break;
    }
    return "";
  };
  useEffect(() => {
    if (jobConfig.status === 200 && jobConfig?.data?.statusCode === 200) {
      // console.log(jobConfig?.data?.statusCode);      
      const updateTheam = jobConfig?.data?.data?.jobDetailView?.map(
        (ress: any) => {
          return {
            ...ress,
            htmlText: section(ress),
          };
        }
      );
      setUpdateTemplete(updateTheam);
    } else {
      console.log(jobConfig);
    }
  }, [jobConfig]);

  useEffect(()=> {
    //console.log("question id ",questionId?.data?.data);
    //console.log("route id ", routeParams?.id);
    if(questionId?.data?.data === routeParams?.id) {
      // navigate(`/view/${jobDetails?.data?.data?.job?.id}/apply-job/from/direct`);
      navigate(`/view/${questionId?.data?.data}/apply-job/register/from/${routeParams?.from}`);
    }
  }, [questionId]);

  const searchControlFilter = () => {
    setJobModel((prs) => (prs ? false : true));
  };
  const JobFilterBody = () => {
    return (
      <>
        <div className="model-close-icon">
          <i onClick={() => searchControlFilter()} className="pi pi-times"></i>
        </div>
        {jobDataView}
      </>
    );
  };

  // const filterViewClient = useMemo(() => {
  //   return (
  //       <>
  //           <JobFilterClient filterListData={filterListData} tableControl={tableControl} setTableControl={setTableControl} searchControlSubmit={searchControlSubmit} />
  //       </>
  //   );
  // }, []);

  const filterViewClient = () => {
    return (
        <>
            <JobFilterClient filterListData={filterListData} tableControl={tableControl} setTableControl={setTableControl} searchControlSubmit={searchControlSubmit} withinDisabled = {withinDisabled} withinDisabledLocClass = {withinDisabledLocClass}/>
        </>
    );
  };

  const jobDetailDownloadLink = () => {
    return (
      <>
        {(jobDetails?.data?.data?.job?.positionFile !== "" && jobDetails?.data?.data?.job?.positionFile !== null) ? (
          <span className="view-job-download-link-parent"><a className={clientDesign === "hvtc" ? "view-job-download-link text-primary" : "view-job-download-link text-primary" } href={jobDetails?.data?.data?.job?.positionFile} rel="noopener noreferrer" download target="_blank"> View Position Description</a></span> 
        ) : "" }
      </>
    );
  };

  //Resolves if the current client has a custom footer using the inbuilt clientids
    const hasCustomFooter = (id:any) => {
      return customFooterIds.includes(id);
  };

  return (
    <>
      {jobDetails.status === 200 && jobDetails?.data?.statusCode === 200 ? (
        (clientDesign === "hvtc") ? 
          <JobViewClient 
            routerLocation = {routerLocation}
            routeParams = {routeParams}
            titleSection = {titleSection}
            jobLogo = {jobLogo}
            ApplayNowButton = {ApplayNowButton}
            listOfPoint = {listOfPoint}
            description = {description}
            imgAndVideo = {imgAndVideo} 
            jobDetails={jobDetails} 
            filterViewClient = {filterViewClient}
            jobDetailDownloadLink = {jobDetailDownloadLink}
          />
        : clientDesign === "common" ? 
          <JobViewSplit 
            jobDataView = {jobDataView}
            routerLocation = {routerLocation}
            routeParams = {routeParams}
            titleSection = {titleSection} 
            jobLogo = {jobLogo}
            ApplayNowButton = {ApplayNowButton}
            listOfPoint = {listOfPoint}
            description = {description}
            imgAndVideo = {imgAndVideo}
            location = {location}
            referenceNo = {referenceNo}
            positionType = {positionType}
            category = {category}
            postedOn = {postedOn}
            searchControlFilter = {searchControlFilter}
            jobDetailDownloadLink = {jobDetailDownloadLink}
          /> : ""
      ) : (
        <>
          {/* {JSON.stringify(jobDetails)} */}
          {jobDetails?.data?.statusCode === 400 ? (
            <div className="card m-5">
              <div className="card-body text-center">
                {jobDetails?.data?.message}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
      {
          clientIds.pifs === cliendConfig?.data?.data?.clientSettings?.clientId ?
              <>
                  <CareersFooter />
              </>
                  : hasCustomFooter(cliendConfig?.data?.data?.clientSettings?.clientId) ?
                      <>
                          <CareersCustomFooter/>
                      </>
                      :
                      ""
      }
      <Model show={jobModel} size="lg" modelBody={JobFilterBody()} />
    </>
  );
};

export default JobView;