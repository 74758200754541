import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faLinkedin,
    faTwitter,
    faXTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { clients } from "../../../config/clients/clients";
import { useParams } from "react-router-dom";
import FooterLogo from "../../../assets/images/pifs_footer_img.png";
import { useSelector } from "react-redux";
const CareersFooter = () => {
    const clientConfig = useSelector((state: any) => state.careers.cliendConfig);

    const navigateToNewApp = (params: string) => {
        const currentClient = clients.filter(
            (ress: any) => ress.clientId === clientConfig?.data?.data?.clientSettings?.clientId
        );
        switch (params) {
            case "faceBook":
                window.open(currentClient[0]["faceBookPage"], "_blank");
                break;
            case "youtube":
                window.open(currentClient[0]["youtubePage"], "_blank");
                break;
            case "linkedin":
                window.open(currentClient[0]["linkedinPage"], "_blank");
                break;
            case "x-twitter":
                window.open(currentClient[0]["xTwitterPage"], "_blank");
                break;
            default:
                break;
        }
    };
    return (
        <>
            <footer>
                <div className="footer-inner">
                    <div className="foo-cont">
                        <div className="fooLogoImg">
                            <img src={FooterLogo} alt="" />
                        </div>
                        <p className="footer-text-font mb-0">
                            The Forum’s Pacific Vision is for a region of peace, harmony,
                            security, social inclusion and prosperity, so that all Pacific
                            people can lead free, healthy, and productive lives
                        </p>
                    </div>

                    <div className="foo-cont">
                        <h2>Contact:</h2>
                        <p className="mb-0">
                            <a href="tel:+679 331 2600" target="_blank" rel="noreferrer">
                                +679 331 2600
                            </a>
                        </p>
                        <p className="footer-text-font">info@forumsec.org</p>
                        <p className="footer-text-font">
                            Pacific Islands Forum Secretariat{" "}
                            <span className="d-block">Ratu Sukuna Road, Suva, Fiji.</span>
                        </p>
                        <div className="d-flex footer-soc-icon">
                            <FontAwesomeIcon
                                role="button"
                                onClick={() => navigateToNewApp("youtube")}
                                icon={faYoutube}
                                className="youtube"
                            />
                            <FontAwesomeIcon
                                role="button"
                                onClick={() => navigateToNewApp("faceBook")}
                                icon={faFacebook}
                                className="facebook"
                            />
                            <FontAwesomeIcon
                                role="button"
                                onClick={() => navigateToNewApp("linkedin")}
                                icon={faLinkedin}
                                className="linkedin"
                            />
                            <FontAwesomeIcon
                                role="button"
                                onClick={() => navigateToNewApp("x-twitter")}
                                icon={faXTwitter}
                                className="x-twitter"
                            />
                        </div>
                    </div>
                    <div className="foo-cont">
                        <h2>Our Work:</h2>
                        <div className="foo-links">
                            <a
                                role="button"
                                target="_blank"
                                rel="noreferrer"
                                href="https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/2050"
                            >
                                2050 Strategy for Blue Pacific Continent
                            </a>
                            <a
                                role="button"
                                target="_blank"
                                rel="noreferrer"
                                href="https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site"
                            >
                                Political Leadership and Regionalism
                            </a>
                            <a
                                role="button"
                                target="_blank"
                                rel="noreferrer"
                                href="https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/economic-and-trade-development"
                            >
                                Economic and Trader Development
                            </a>
                            <a
                                role="button"
                                target="_blank"
                                rel="noreferrer"
                                href="https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/"
                            >
                                Climate Change and Resilience
                            </a>
                            <a
                                role="button"
                                target="_blank"
                                rel="noreferrer"
                                href="https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/"
                            >
                                Peace and Security
                            </a>
                            <a
                                role="button"
                                target="_blank"
                                rel="noreferrer"
                                href="https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/people-centered-development"
                            >
                                People-Centered Development
                            </a>
                        </div>
                    </div>
                </div>

                <div className="d-md-flex d-block  justify-content-md-between footer-bottom">
                    <div className="copy-right">
                        <p className="mb-0">Copyright © 2023 - Pacific Island Forum</p>
                    </div>
                    <div className="policy">
                        <a
                            rel="noreferrer"
                            href="http://example.com"
                            target="_blank"
                            className="mb-0"
                        >
                            {" "}
                            Personal Information Protection Policy
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default CareersFooter;
