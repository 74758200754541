import { firstnameVar, lastnameVar } from "../config/regex/regexPattern";

export async function candidateDataPostProcess(apidata: any, params?: any) {

    let candidateFirstName = apidata?.data?.candidateDetails?.firstName || null;
    let candidateLastName = apidata?.data?.candidateDetails?.lastName || null;
    let mutatedData = apidata;

    const transformValues = (fieldData:string) => {
        let transformedString = fieldData;
        if(transformedString.match(firstnameVar)) {
            transformedString = transformedString.replace(firstnameVar, " " + candidateFirstName);
        }
        if(transformedString.match(lastnameVar)) {
            transformedString = transformedString.replace(lastnameVar, " " + candidateLastName);            
        }
        return transformedString;
    };
    
    mutatedData.data?.data.forEach((dataItem:any) => {                
        dataItem.fields?.forEach((field:any) => {
            (field.pdfLabel) ? field.pdfLabel = transformValues(field.pdfLabel) : null;
            (field.label) ? field.label = transformValues(field.label) : null;
            if(field.values) {
                field.values.forEach((fieldValue:any) => {
                    (fieldValue.label) ? fieldValue.label = transformValues(fieldValue.label) : null;
                });
            }
        });
    });

    return mutatedData;
}