import React, { useEffect, useState } from "react";
import { clientIds, customLinkData, customMenuUISettings, responsiveSettings } from "../../../../config/clients/clients";
import DropDown from "../../../../component/dropdown/dropdown";
import VitalityFragment from "./VitalityFragment";
import Image from "../../../../component/image/Image";
import chevronOrange from "../../../../assets/images/clients/chevron-orange.svg";
import contactPage from "../../../../assets/images/clients/vitalityworks/contact-page.svg";
import generalEnquiries from "../../../../assets/images/clients/vitalityworks/general-enquiries.svg";
import vaccinationSupportHotline from "../../../../assets/images/clients/vitalityworks/vaccination-support-hotline.svg";

const CustomMegaMenu = (props: any) => {

    const { menuSettings, clientId } = props;
    const [navOpen, setNavOpen] = useState<boolean>(false);
    const [dropdownIcon, setDropdownIcon] = useState<boolean>(false);
    const [doesMenuActAsAccordion, setMenuActAsAccordion] = useState(false);
    
    const [linkListData, setLinkListData] = useState<any>({
        id : "",
        linkList : []
    });

    useEffect(() => { 
        if (menuSettings) {
            setLinkListData(customLinkData.find(client => client.id === clientId));
        }
    }, [customLinkData, menuSettings]);

    useEffect(() => {
        if(clientIds?.vitality === clientId){
            setDropdownIcon(true);
        } else {
            setDropdownIcon(false);
        }
    });

    const toggleLinkListData = (currentId:any, toggle:boolean, doesMenuActAsAccordion?: boolean) => {
        setLinkListData((listItems:any) => {
            let modifiedList = listItems.linkList;
            let currentItemIndex = modifiedList.findIndex((item: { id: any; }) => item.id === currentId);
            if(doesMenuActAsAccordion && doesMenuActAsAccordion === true){
                if(modifiedList[currentItemIndex]["open"]){
                    modifiedList[currentItemIndex]["open"] = false;
                } else {
                    modifiedList[currentItemIndex]["open"] = true;
                }
            } 
            else {
                modifiedList[currentItemIndex]["open"] = toggle;
            }
    
            return {
                ...listItems,
                linkList : modifiedList
            };
          });
    };

    useEffect(() => {
        if (responsiveSettings.vitalityMenuBreakpoint && screen.width < responsiveSettings.vitalityMenuBreakpoint) {
            setMenuActAsAccordion(true);
        }
    }, []);

    //Mouse enter to toggle dropdown, acts as touch during mobile responsive
    const onMouseEnter = (currentId:any) => {
        toggleLinkListData(currentId, true, doesMenuActAsAccordion);
    };

    const onMouseLeave = (currentId:any) => {
        toggleLinkListData(currentId, false);
    };

    const toggleNavBar = () => {
        navOpen ? setNavOpen(false) : setNavOpen(true);

        if(navOpen) {
            document.body.classList.remove("menu-open-body");
        } else {
            document.body.classList.add("menu-open-body");
        }
    };

    return (
        <> 
            <header>
                <div className="header">
                    <div className="container-new">
                        <div className="head-top">
                            <div>Introducing the groundbreaking new solution disrupting Australian EAP – Thrive Mental Health is now part of the Vitality Works family </div>
                            <div><a href="https://vitalityworks.health/service/thrive-mental-health/" rel="noreferrer" target="_blank">Find out more</a></div>    
                        </div>
                        <div className="head-parent">
                            <div className="head-left">
                                <div className="logo-new">
                                    <a href={menuSettings.homeLinkHref} rel="noreferrer" target="_blank">
                                        <img src={process.env.PUBLIC_URL + menuSettings.logoimg} className="cust-logo-desktop-img"/>
                                        <img src={process.env.PUBLIC_URL + menuSettings.moblogoimg}  className="cust-logo-mob-img" />
                                    </a>
                                </div>
                            </div>
                            <button className={navOpen ? "responsive-nav-btn menu-open" : "responsive-nav-btn menu-closed"} onClick={toggleNavBar}>
                                <img className="menu-open-icon" src={process.env.PUBLIC_URL + menuSettings.menuToggle}/>
                                <img className="menu-close-icon" src={process.env.PUBLIC_URL + menuSettings.menuToggleClose}/></button>
                            <div className="head-right">
                                
                                <nav className={navOpen ? "nav-open" : "nav-closed"}>
                                    <ul className="submenu-list">
                                        {linkListData.linkList.map((listItem:any) => {
                                            return (
                                                <li 
                                                    key={listItem.href}
                                                    onMouseEnter={() => onMouseEnter(listItem.id)}
                                                    onMouseLeave={() => onMouseLeave(listItem.id)}
                                                    className={listItem.open ? "open" : ""}
                                                >
                                                    <a href={listItem.href} title={listItem.name} rel="noreferrer" target="_blank"> {listItem.name} {listItem.open}</a>  
                                                    <span className={dropdownIcon ? "menu-arrow-new" : "menu-arrow" } onClick={() => onMouseEnter(listItem.id)}>{ dropdownIcon ? <Image src={chevronOrange} /> : "" }</span>
                                                    {
                                                        (listItem.customMenuTemplate && listItem.customMenuTemplate === true) ? 
                                                            <VitalityFragment menuSettings={menuSettings} />
                                                        : 
                                                        <DropDown submenuItems={listItem.submenu} menuType={listItem.subType} menuSettings={menuSettings}/>
                                                    }
                                                    
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <div className="contact-features">
                                        <ul className="contact-details">
                                            <li className="linkedIn">
                                                <a href="https://www.linkedin.com/company/vitality-works-sanitarium" rel="noreferrer" target="_blank"> 
                                                    <svg className="linkedin-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                                </a>  
                                            </li>
                                            <li className="phone">
                                            <svg className="phone-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                                                <div className="contact_details">
                                                    <ul>
                                                        <li>
                                                            <div className="contact_details-left">
                                                                <Image src={generalEnquiries} />
                                                            </div>
                                                            <div className="contact_details-right">
                                                                <div><strong>General Enquiries</strong></div>
                                                                <a href="tel:1300647000">1300 647 000</a>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="contact_details-left">
                                                                <Image src={vaccinationSupportHotline} />
                                                            </div>
                                                            <div className="contact_details-right">
                                                                <div><strong>Vaccination Support Hotline</strong></div>
                                                                <a href="tel:1300662328">1300 662 328</a>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="contact_details-left">
                                                                <Image src={contactPage} />
                                                            </div>
                                                            <div className="contact_details-right">
                                                                <div><strong>Contact Page</strong></div>
                                                                <a href="https://vitalityworks.health/contact/" target="_blank" rel="noreferrer"><strong>Email &amp; Locations</strong></a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};


export default CustomMegaMenu;