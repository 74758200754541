import React from "react";
import Accordion from "../../../../component/accordion/Accordion";
import { responsiveSettings } from "../../../../config/clients/clients";

const CustomPioneerFooter = (props: any) => {

    const { menuSettings } = props;

    const MyControllerElement = ({isExpanded}:any) => {
        return (<button>{isExpanded ? "Hide" : "Show"} Content</button>);
    };
    
    return (
        <>          
            <div className="footer">
                <div className="container-new">
                    <div className="footer-list">
                        <div className="foo1">
                            <a href={menuSettings.homeLinkHref} rel="noreferrer" target="_blank" className="foo-logo"><img src={process.env.PUBLIC_URL + menuSettings.footerLogoImg} /></a>
                        </div>
                        <div className="foo2 desktop-view">
                            <Accordion
                                responsiveBreakPoint={responsiveSettings.upandgoFooterBreakpoint}
                                controllerElement={(isExpanded:any) => (
                                <h2>USEFUL LINKS</h2>
                                )}
                            >
                                <ul>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/" rel="noreferrer" target="_blank">Home</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/our-story" rel="noreferrer" target="_blank">Our Story</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/our-products" rel="noreferrer" target="_blank">Our Products</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/faq" rel="noreferrer" target="_blank">FAQ</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/find-us" rel="noreferrer" target="_blank">Find Us</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/contact-us" rel="noreferrer" target="_blank">Contact Us</a>
                                    </li>
                                </ul>
                            </Accordion>
                        </div>
                        <div className="foo3">
                            <h2>SAY HELLO</h2>    
                            <div className="sm">
                                <div className="insta-icon">
                                    <a href="https://www.instagram.com/upandgoeu/" rel="noreferrer" target="_blank">
                                        <i className="fa fa-2x fa-instagram"></i>
                                    </a>
                                </div>
                                <div className="fb-icon">
                                    <a href="https://www.facebook.com/upandgoeu/" rel="noreferrer" target="_blank">
                                        <i className="fa fa-2x fa-facebook"></i>
                                    </a>
                                </div>
                                <div className="tiktok-icon">
                                    <a href="https://www.tiktok.com/@upandgoeu" rel="noreferrer" target="_blank">
                                        <svg fill="#000000" width="800px" height="800px" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            
                            <ul>
                                <li>
                                    <a href="mailto:hello@upandgo.co.uk">hello@upandgo.co.uk</a>
                                </li>
                                <li>
                                    <a href="tel:+44203 642 3410" rel="noreferrer" target="_blank">+44 (0) 203 642 3410</a>
                                </li>
                            </ul>
                        </div>
                        <div className="foo2 mobile-view">
                            <Accordion
                                responsiveBreakPoint={responsiveSettings.upandgoFooterBreakpoint}
                                controllerElement={(isExpanded:any) => (
                                <h2>USEFUL LINKS</h2>                                
                                )}
                            >
                                <ul>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/" rel="noreferrer" target="_blank">Home</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/our-story" rel="noreferrer" target="_blank">Our Story</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/our-products" rel="noreferrer" target="_blank">Our Products</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/faq" rel="noreferrer" target="_blank">FAQ</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/find-us" rel="noreferrer" target="_blank">Find Us</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/contact-us" rel="noreferrer" target="_blank">Contact Us</a>
                                    </li>
                                </ul>
                            </Accordion>
                        </div>
                        <div className="foo4">
                            <Accordion
                                responsiveBreakPoint={responsiveSettings.upandgoFooterBreakpoint}
                                controllerElement={(isExpanded:any) => (
                                <h2>MORE INFO</h2>                                
                                )}
                            >
                                <ul>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/terms-and-conditions" rel="noreferrer" target="_blank">Terms and Conditions</a>
                                    </li>
                                    <li>
                                        <a href="https://www.upandgo.co.uk/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a>
                                    </li>
                                </ul>
                            </Accordion>
                        </div>
                    </div>
                </div>
                <div className="footer-copy">
                    <div className="container-new">
                        COPYRIGHT © LIFE HEALTH FOODS 2024
                    </div>
                </div>
            </div>
        </>
    );
};


export default CustomPioneerFooter;