export const endPoint = {
    getcandidatedata: "getcandidatedata",
    createCandidateDataDocuments: "createcandidatedatadocuments",
    createcandidatedata: "createcandidatedata",
    download: "downloadFile",
    user: "users",
    login: "login",
    agreement: "agreement",
    getjoblist: "getjoblist",
    getsearchfieldsmaster: "getsearchfieldsmaster",
    viewclientjob: "viewclientjob",
    getjobtemplateconfig: "getjobtemplateconfig",
    candidateToken: "get-token",
    submitJob: "submitjob",
    getclientsettingsconfig:"getclientsettingsconfig",
    getCountryList:"getcountrylist",
    getStateList:"getstatelist",
    getgeneralapid:"getgeneralapid",
    complianceConfig:"getonboardingconfig",
    deleteFile: "deleteFile",
    getfrequencylist: "getfrequencylist",
    storefrequency: "storefrequency",
    verifyEmail: "verifyEmail",
    jobAlertUnsubscribe: "jobAlertUnsubscribe"
};