import React, { useMemo } from "react";
import Button from "../../../../component/button/Button";
import JobApplay from "../jobapplication/JobApplay";

const JobViewSplit = (props: any) => {

    const { jobDataView, routerLocation, routeParams, titleSection, jobLogo, ApplayNowButton, listOfPoint, 
    description, imgAndVideo, location, referenceNo, positionType, category, postedOn, searchControlFilter,
    jobDetailDownloadLink } = props;
    
    const applyPath = useMemo(() => {
        return routerLocation.pathname === `/view/${routeParams?.id}`;
    }, [routerLocation.pathname]);

    const content = useMemo(() => {
        return !applyPath ? <JobApplay /> : "";
    }, [applyPath]);

    return (
        <>
            {routerLocation.pathname === `/view/${routeParams?.id}` ?
                <div className="container-fluid mobile-backgrount p-0">
                <div className="row container-inner mt-3">
                    <div
                    className={`${routerLocation.pathname ===
                        `/view/${routeParams?.id}`
                        ? "col-xxl-8 col-lg-8 col-md-12 col-sm-12 col-12 pl-md-0"
                        : "col-md-12"
                        }`}
                    >
                    <div className="row">
                        <div
                        className={
                            routerLocation.state !==
                            `/view/${routeParams?.id}/apply-job`
                            ? "col-lg-9 col-md-9 col-sm-9 col-9"
                            : "col-md-12"
                        }
                        >
                        {titleSection()}
                            <div className="d-lg-flex d-md-flex  d-flex d-sm-flex align-items-center job-view-desc-sec">
                                {ApplayNowButton()}
                                {jobDetailDownloadLink()}
                            </div>
                        </div>
                        {routerLocation.pathname !==
                        `/view/${routeParams?.id}/apply-job` ? (
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 mob-joblogo">
                            {jobLogo()}
                        </div>
                        ) : (
                        ""
                        )}
                    </div>
                    {/* <div className="d-lg-flex d-md-flex  d-flex d-sm-flex align-items-center">
                        {ApplayNowButton()}
                        {starred()}
                        {ViewAllStarred()}
                    </div> */}
                    {/* <div>{socialShare()}</div> */}
                    <div>{listOfPoint()}</div>
                    <div></div>
                    <div>{description()}</div>
                    <div>{imgAndVideo()}</div>
                    <div
                        className={`${routerLocation.pathname !==
                        `/view/${routeParams?.id}/apply-job`
                        ? "d-flex"
                        : ""
                        } pt-2`}
                    >
                        {location()}
                        {referenceNo()}
                        {/* {postedOn()} */}
                    </div>
                    <div className="py-4">
                        <div className="py-1">{positionType()}</div>
                        <div className="py-1">{category()}</div>
                        {/* <div className="py-1">{salary()}</div> */}
                        {/* <div className="py-1">
                                            {referenceNo()}
                                        </div> */}
                        <div className="py-1">{postedOn()}</div>
                    </div>

                    {/* <div>
                                        <div className="sub-head">
                                            {jobDetails?.data?.data?.job?.category}
                                        </div>
                                        <div className="para-text" dangerouslySetInnerHTML={{ __html: jobDetails?.data?.data?.job?.description }}>
                                        </div>
                                    </div> */}
                    </div>
                    {routerLocation.pathname ===
                    `/view/${routeParams?.id}` ? (
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mb-sm-2 mb-4 mb-md-0 mb-lg-0 d-sm-none d-md-0  d-lg-block d-none  mob-job-search-parent pr-md-0">
                        {jobDataView}
                    </div>
                    ) : (
                    ""
                    )}
                </div>
                {routerLocation.pathname ===
                    `/view/${routeParams?.id}` ? (
                    <div className="d-sm-block d-block d-md-block d-lg-none container-inner mb-4">
                    <div className="d-sm-block d-md-block d-block control-container">
                        <div className="d-flex justify-content-between py-4 px-2">
                        <div className="mobile-search-view">Continue Looking?</div>
                        <div>
                            <Button
                            clasName="btn btn-primary completion-btn-primary w-100"
                            label={"Search Jobs"}
                            position="left"
                            icon="pi pi-search"
                            onClick={() => searchControlFilter()}
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                ) : (
                    ""
                )}
                </div>
            :
                null
            }
            { content }
        </>
    );
};

export default JobViewSplit;