import React from "react";
import { Navigate, useParams } from "react-router-dom";

const PatternNavigator = ({ to, ...rest }:any) => {

    let path = location.pathname;
    let fragArray = location.pathname.split("/");
    let fragIndex = fragArray.indexOf("view");
    let toUrl = "/" + fragArray.slice(fragIndex).join("/");    
    if(fragIndex < 0) {
        toUrl = to;
    }
    console.log(toUrl);
    return <Navigate to={toUrl} {...rest} />;
};

export default PatternNavigator;