import React, { useEffect, useState } from "react";
import "../../../styles/header/onboardingheader.scss";
// import Logo from "../../assets/images/Logo.png";
import Logo from "../../../assets/images/Logo.png";
// import Vector from "../../assets/images/Vector.png";
import { useSelector } from "react-redux";
const Header = () => {
    const teamConfig = useSelector((state: any) => { return state?.newRole?.teamConfig; });
    const activeClient = useSelector((state: any) => { return state?.newRole?.activeClient; });
    const [getLogo, setLogo] = useState<any>("");
    
    useEffect(() => {
        if (activeClient?.length > 0) {
            document.body.className = "";
            document.body.classList.add(activeClient[0]["className"]);            
        }
    }, [activeClient]);
    useEffect(() => {
        let link: any = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = teamConfig?.data?.clientSettings?.favIcon;
        if (teamConfig?.statusCode === 200) {
            document.title = `${teamConfig?.data?.clientSettings?.titleTabText} - Compliance`;
            document.documentElement.style.setProperty("--primary-color", teamConfig?.data?.clientSettings?.brandColor);
            document.documentElement.style.setProperty("--theam-secondary-color", teamConfig?.data?.clientSettings?.secondaryColor);
            setLogo(teamConfig?.data?.clientSettings?.logo);
        }
    }, [teamConfig]);

    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={getLogo} className="logo" alt="" />
                    </a>
                    {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">
                                    <img width={100} src={teamConfig?.clientDetails?.logo} alt="" />
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link disabled" aria-disabled="true">Disabled</a>
                            </li> */}
                        </ul>
                        {/* <form className="d-flex" role="search">
                            Millala
                        </form> */}
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;


