import React from "react";
import Image from "../../component/image/Image";


/**
 * 
 * @param condition - bool, condition that specifices whether to wrap its children or not
 * @param wrap - caller inner function to be used to render children without a wrapper
 * @param children - the HTML child to be wrapped or rendered
 * 
 */
const ConditionalWrap = ({ condition, wrap, children }:any) => (
    condition ? wrap(children) : children
);

const DropDown = (props: any) => {
    const { submenuItems, menuType, menuSettings } = props;
    
    const innerMenu =  (
        (submenuItems?.length > 0) ? 
            <ul className={"submenu" + ((menuType && menuType === "icons") ? " submenu-icon-type" : "")}>
                {submenuItems.map((menuItem:any, index:any) => {
                    return (
                        <li key={index}>
                            { (menuType && menuType === "icons") ? <span className="submenu-icon"><Image src={menuItem.icon} /></span> : "" }
                            <a href={menuItem.href} title={menuItem.name}  rel="noreferrer" target="_blank" className="active"> {menuItem.name} </a>
                        </li>
                    );
                })}
            </ul>
        : ""
    );

    return (
        <>
        {               
            <ConditionalWrap condition={menuSettings.submenuContainer} wrap={(innerMenu:any) => <div className="submenu-icon-type-parent">{innerMenu}</div>}
            >
                {innerMenu}
            </ConditionalWrap>
        }
        </>
    );
};

export default DropDown;