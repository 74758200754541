import "./App.scss";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import React, { useEffect } from "react";
import Header from "./pages/onboarding/header/Header";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import WelcomeScreen from "./pages/onboarding/Welcome";
import OnboardingResult from "./pages/onboarding/OnboardingResult";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFountError from "./pages/notfounterror/NotFountError";
import OnBoarding from "./pages/onboarding/Onboarding";
import "react-datepicker/dist/react-datepicker.css";
import OnBoardingRoot from "./pages/onboarding/Root";
import CompletionRoot from "./pages/careers/CareersRoot";
import JobListings from "./pages/careers/jobListings/JobListings";
// import "./i18n/i18n";
import JobApplay from "./pages/careers/jobListings/jobapplication/JobApplay";
import FileDownload from "./pages/FileDownload/FileDownload";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch } from "react-redux";
import JobAlerts from "./pages/careers/jobalerts/JobAlerts";
import JobAlertUnSubscribe from "./pages/careers/JobAlertSubscriptionAndVerification/JobAlertUnSubscribe";
import VerifyEmail from "./pages/careers/JobAlertSubscriptionAndVerification/VerifyEmail";
import PatternNavigator from "./service/Navigator";


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch<any>(removeCandidateData());
  }, []);

  axios.interceptors.request.use(
    (request: any) => {
      // request.headers["Authorization"] = `Bearer ${sessionStorage.getItem("Token")}`;
      // request.headers["X-CSRF-TOKEN"] = "",
      return request;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (request: any) => {
      return request;
    },
    (error: any) => {

      return Promise.reject(error);
    }
  );
  const router = () => {
    return (
      <>
        {/* <Header /> */}
        <Routes>

          <Route path="getCandidate" element={<OnBoardingRoot />}>
            <Route path=":clientId" element={<WelcomeScreen />} />
            <Route path="onboarding" element={<OnBoarding />} />
            <Route path="onboarding-result" element={<OnboardingResult />} />
          </Route>
          <Route path="" element={<CompletionRoot />}>
            <Route path="apply-job" element={<JobApplay />} />
            <Route path="job-alerts" element={<JobAlerts />}/>
            <Route path="unsubscribe/:token" element={<JobAlertUnSubscribe />}/>
            <Route path="verify-email/:token" element={<VerifyEmail />}/>
            <Route path="" element={<JobListings />} />
            <Route path="view/:id" >
              <Route path="" element={<JobListings />} />
              {/* <Route path="apply-job?form=id" element={<JobApplay  resourceType={"people"}/>} /> */}
              <Route path="apply-job/register/from/:from" element={<JobApplay />} />
              <Route path="apply-job/from/:from" >
                <Route path="" element={<JobListings />} />
              </Route>
            </Route>
          </Route>
          <Route path="download" element={<FileDownload />} />
          {/* patch to redirect user to / router when old url structure is called */}
          {/* <Route path="careers/*" element={<Navigate to="/" replace />} /> */}
          <Route path="careers/*" element={<PatternNavigator to={"/"} replace/>} />
          <Route path="*" element={<NotFountError />} />
        </Routes >
      </>
    );
  };

  return (
    <>

      {process.env.REACT_APP_BUILD_CONFIG === "uat" ?
        <BrowserRouter basename="/turbo_frontend"  >
          {router()}
        </BrowserRouter >
        :
        <BrowserRouter >
          {router()}
        </BrowserRouter >
      }

      <ToastContainer position="bottom-right" theme="colored" autoClose={100} />

    </>
  );
}

export default App;
