
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { apiDelete, apiGet, apiPost, apiPut } from "../../service/apiService";
import { endPoint } from "../../config/endPoint";

interface UsermanageMent {

    loading: boolean;
    error: string | null | undefined;
    user: {}
    userCreateRess: {} | null,
    usersList: {} | null,
    delete: {} | null
}

const initialState: UsermanageMent = {

    loading: false,
    error: null,
    user: {
        userName: "",
        email: "",
        phoneNumber: "",
        location: "",
    },
    userCreateRess: null,
    usersList: null,
    delete: null
};


export const createNewUser = createAsyncThunk("users/createTodo", async (payload: any) => {
    const ress = await apiPost(endPoint.user, payload);
    return ress;
});

export const getUsers = createAsyncThunk("users/list", async (payload: any) => {
    const ress = await apiGet(endPoint.user, payload);
    return ress;
});

export const getDeleteUser = createAsyncThunk("users/delete", async (payload: any) => {
    const ress = await apiDelete(endPoint.user + `/${payload?.id}`, payload);
    return ress;
});
export const updateUser = createAsyncThunk("users/update", async (payload: any) => {
    const ress = await apiPut(endPoint.user + `/${payload?.id}`, payload);
    return ress;
});

const userSlices = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createNewUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createNewUser.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.userCreateRess = action.payload;
            })
            .addCase(createNewUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.userCreateRess = action.payload;
            })

            .addCase(getUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.userCreateRess = null;
            })
            .addCase(getUsers.fulfilled, (state, action: any) => {
                state.loading = false;
                state.usersList = action.payload;
                state.userCreateRess = null;
                state.delete = null;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.userCreateRess = null;
            })

            .addCase(getDeleteUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getDeleteUser.fulfilled, (state, action: any) => {
                state.loading = false;
                state.delete = action.payload;
            })
            .addCase(getDeleteUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default userSlices.reducer;
