import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { apiGet, apiPost } from "../../service/apiService";
import { endPoint } from "../../config/endPoint";
import { clients } from "../../config/clients/clients";
import { domainWithHttp } from "../../config/onboarding/onBoarding";

interface Joblist {
  loading: boolean;
  error: any;
  allJobList: any | null;
  allSearchData: any;
  jobDetails: any;
  jobConfig: any;
  submitJob: any;
  cliendConfig: any;
  countryList: any;
  stateList: any;
  getQuestionId: any;
  frequencyList:any;
  jobAlertFrequencySubmission: any;
  verificationStatus:any;
  subscriptionCancellation:any;
  activeClient: any | null;
  jobListScroll: number;
  resetActiveTap: boolean;
  settingsLoaded: boolean;
}

const initialState: Joblist = {
  loading: false,
  error: null,
  allJobList: [],
  allSearchData: {} as any,
  jobDetails: {} as any,
  jobConfig: {} as any,
  submitJob: {} as any,
  cliendConfig: {} as any,
  countryList: {} as any,
  stateList: {} as any,
  getQuestionId: {} as any,
  frequencyList: {} as any,
  jobAlertFrequencySubmission: {} as any,
  verificationStatus: {} as any,
  subscriptionCancellation: {} as any,
  activeClient: null,
  jobListScroll: 0,
  resetActiveTap: false,
  settingsLoaded: false
};

export type joblist = Readonly<typeof initialState>;

export const getJobList = createAsyncThunk(
  "careers/getJobList",
  async (payload: any, pathParams?: any) => {
    const ress = await apiGet(
      endPoint.getjoblist + `/${payload.url}`,
      payload.params,
      {domainWithHttp: domainWithHttp}
    );
    return ress;
  }
);

export const getSearchData = createAsyncThunk(
  "careers/getSearchData",
  async (payload: any) => {
    const ress = await apiGet(
      endPoint.getsearchfieldsmaster + `/${payload}`,
      "", 
      {domainWithHttp: domainWithHttp}
    );
    return ress;
  }
);

export const getQuestionList = createAsyncThunk(
  "careers/getQuestionList",
  async () => {
    const ress = await apiGet(endPoint.getgeneralapid, "", {domainWithHttp: domainWithHttp});
    return ress;
  }
);

export const getJobDetails = createAsyncThunk(
  "careers/getJobDetails",
  async (payload: any) => {
    
    const ress = await apiGet(endPoint.viewclientjob + `/${payload}`, {domainWithHttp: domainWithHttp});
    return ress;
  }
);

export const getJobtemplateconfig = createAsyncThunk(
  "careers/getjobtemplateconfig",
  async (payload: any) => {
    const ress = await apiGet(
      endPoint.getjobtemplateconfig + `/${payload}`,
      "",
      {domainWithHttp: domainWithHttp}
    );
    return ress;
  }
);

export const submitJob = createAsyncThunk(
  "careers/submitJob",
  async (payload: any) => {
    const ress = await apiPost(endPoint.submitJob, payload, {domainWithHttp: domainWithHttp});
    return ress;
  }
);

export const clientSettingsConfig = createAsyncThunk(
  "careers/clientSettingsConfig",
  async () => {
    const ress = await apiGet(
      endPoint.getclientsettingsconfig,
      "",
      {domainWithHttp: domainWithHttp}
    );
    return ress;
  }
);

export const careersContryList = createAsyncThunk(
  "careers/careersContryList",
  async () => {
    const ress = await apiGet(endPoint.getCountryList, "", {domainWithHttp: domainWithHttp});
    return ress;
  }
);

export const careersStateList = createAsyncThunk(
  "careers/careersStateList",
  async (payload: any) => {
    const ress = await apiGet(endPoint.getStateList + `/${payload}`, "", {domainWithHttp: domainWithHttp});
    return ress;
  }
);

export const getFrequencyList = createAsyncThunk(
  "careers/frequencyList",
  async () => {
    const ress = await apiGet(endPoint.getfrequencylist, "", {domainWithHttp: domainWithHttp});
    return ress;
  }
);

export const submitJobAlertFrequency = createAsyncThunk(
  "careers/jobAlertFrequencySubmission",
  async (payload: any) => {
    const ress = await apiPost(endPoint.storefrequency, payload, {domainWithHttp: domainWithHttp});
    return ress;
  }
);

export const verifyEmail = createAsyncThunk(
  "careers/verifyEmail",
  async (token: string) => {
    const ress = await apiGet(endPoint.verifyEmail, "", {domainWithHttp: domainWithHttp, token: token});
    return ress;
  }
);

export const jobAlertUnsubscribe = createAsyncThunk(
  "careers/jobAlertUnsubscribe",
  async (token: string) => {
    const ress = await apiGet(endPoint.jobAlertUnsubscribe, "", {domainWithHttp: domainWithHttp, token: token});
    return ress;
  }
);

// export const updateJobtemplateconfig = createAsyncThunk("careers/updatejobtemplateconfig", async (payload: any) => {
//     console.log(payload);

//     // const ress = await apiGet(endPoint.getjobtemplateconfig + `/${payload}`, "");
//     // return ress;
// });

const CareersSlices = createSlice({
  name: "careers",
  initialState: initialState as joblist,
  reducers: {
    loderControl: (state) => {
      state.loading = true;
    },
    loderControlFalse: (state) => {
      state.loading = false;
    },
    updateJobViewConfig: (state) => {
      console.log(state);
    },
    removSubmiutJob: (state) => {
      state.submitJob = {};
    },
    searchJobScroll: (state, action) => {
      state.jobListScroll = action.payload;
    },
    jobApplicationScroll: (state, action) => {
      // state.jobListScroll = action.payload;
    },
    closeAllTap: (state, action) => {
        console.log( action.payload);
        
      state.resetActiveTap = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.allJobList = action?.payload;
      })
      .addCase(getSearchData.fulfilled, (state, action: any) => {
        state.loading = false;
        state.allSearchData = action?.payload;
      })
      .addCase(getJobDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.jobDetails = action?.payload;
      })
      .addCase(getJobtemplateconfig.fulfilled, (state, action: any) => {
        state.loading = false;
        state.jobConfig = action?.payload;
      })
      .addCase(submitJob.fulfilled, (state, action: any) => {
        state.loading = false;
        state.submitJob = action?.payload;
      })
      .addCase(clientSettingsConfig.fulfilled, (state, action: any) => {
        state.loading = false;
        state.cliendConfig = action?.payload;
        state.settingsLoaded = true;
        state.activeClient =
          action?.payload?.data?.statusCode === 200 &&
          clients.filter(
            (ress: any) =>
              ress?.clientId ===
              action.payload?.data?.data?.clientSettings?.clientId
          );
      })
      .addCase(careersContryList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.countryList = action?.payload;
      })
      .addCase(careersStateList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.stateList = action?.payload;
      })
      .addCase(getQuestionList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.getQuestionId = action?.payload;
      })
      .addCase(getFrequencyList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.frequencyList = action?.payload;
      })
      .addCase(submitJobAlertFrequency.fulfilled, (state, action: any) => {
        state.loading = false;
        state.jobAlertFrequencySubmission = action?.payload;
      })
      .addCase(verifyEmail.fulfilled, (state, action:any) =>{
        state.loading = false;
        state.verificationStatus = action?.payload;
      })
      .addCase(jobAlertUnsubscribe.fulfilled, (state, action:any) =>{
        state.loading = false;
        state.subscriptionCancellation = action?.payload;
      })
      .addMatcher(
        isPending(
          getQuestionList,
          careersContryList,
          clientSettingsConfig,
          getJobList,
          getSearchData,
          getJobDetails,
          getJobtemplateconfig,
          submitJob,
          submitJobAlertFrequency
        ),
        (state, action: any) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isRejected(
          getQuestionList,
          clientSettingsConfig,
          getJobList,
          getSearchData,
          getJobDetails,
          getJobtemplateconfig,
          submitJob,
          submitJobAlertFrequency
        ),
        (state, action: any) => {
          state.loading = false;
        }
      );
  },
});
export const {
  searchJobScroll,
  loderControlFalse,
  loderControl,
  updateJobViewConfig,
  removSubmiutJob,
  closeAllTap,
} = CareersSlices.actions;
export default CareersSlices.reducer;
