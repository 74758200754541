import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "../../styles/pagination.scss";
const Pagination = (props: any) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange && paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const lastPage = paginationRange && paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={classnames("pagination-container", { [className]: className })}
        >
            <li
                className={classnames("pagination-item", {
                    disabled: currentPage === 1
                })}
                onClick={onPrevious}
            >
                {/* <div className="arrow left" /> */}
                <i className="pi pi-angle-left"></i>
            </li>
            {paginationRange && paginationRange.map((pageNumber: any) => {
                if (pageNumber === DOTS) {
                    return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
                }

                return (
                    <li key={pageNumber}
                        className={classnames("pagination-item", {
                            selected: pageNumber === currentPage
                        })}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={classnames("pagination-item", {
                    disabled: currentPage === lastPage
                })}
                onClick={onNext}
            >
                {/* <div className="arrow right" /> */}
                <i className="pi pi-angle-right"></i>

            </li>
        </ul>
    );
};

export default Pagination;
