import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { apiPost } from "../../service/apiService";
import { endPoint } from "../../config/endPoint";
import Loader from "../../component/loader/Loader";
import { loginData } from "../../config/careers/careers";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const FileDownload = () => {
    const [searchParams] = useSearchParams();
    const doc = new jsPDF();


    const base64StringToBlob = (base64String: any) => {
        const byteCharacters = atob(base64String);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: "application/octet-stream" });
    };
    const fileDownload = (params: any) => {
        const blob = base64StringToBlob(params.data.data);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        const fileName: any = searchParams?.get("file")?.split("/");
        a.download = fileName[fileName.length - 1];
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        // Make sure this code is executed in response to a user click
        window.close();
    };
    useEffect(() => {

        return;
        const dowloadOutOfLocagtion = async () => {
            const ress = await apiPost(endPoint.candidateToken, loginData);
            if (ress.status === 200) {
                sessionStorage.setItem("Token", ress?.data?.data?.token);
                const obj: any = {};
                obj["url"] = searchParams?.get("file");
                const responce = await apiPost(endPoint.download, obj);
                if (responce.status === 200) {
                    console.log(responce);
                    fileDownload(responce);
                }
            }
        };
        dowloadOutOfLocagtion();
    }), [searchParams];
    const handleConvertToPdf = () => {
        console.log(doc);
        //        

        // autoTable(doc, {
        //     // head: [["label", "value"]],
        //     html: "#table"
        // })

        // Create HTML content with hyperlinks
        // const htmlContent = `
        //   <h1>My HTML Document with Hyperlinks</h1>
        //   <p>
        //     <a href="https://www.example.com">Example Link</a>
        //   </p>
        // `;

        // // Define CSS styles for the PDF content (optional)
        // const styles = `
        //   <style>
        //     h1 { color: blue; }
        //     a { text-decoration: none; color: #0073e6; }
        //   </style>
        // `;

        // Add HTML content to the PDF document
        // doc.html(htmlContent + styles, 10, 10);

        // Save the PDF
        doc.save("output.pdf");
    };
    const generatePDF = () => {
        const doc = new jsPDF();

        // Define your data for the table
        const data = [
            ["1", "John Doe", "johndoe@example.com", "https://www.example.com"],
            ["2", "Jane Smith", "janesmith@example.com", "https://www.example2.com"],
        ];

        // Set up the table headers
        const headers = ["#", "Name", "Email", "Website"];

        // Define the width of the columns
        const columnWidths = [10, 50, 50, 50, 40];

        // Loop through the data and add rows to the table
        data.forEach((row, rowIndex) => {
            row.forEach((cell, cellIndex) => {
                // Check if the cell is a URL (assuming the last column contains URLs)
                if (cellIndex === 3) {
                    // Add a clickable link to the cell
                    doc.textWithLink(cell, 10 + cellIndex * columnWidths[cellIndex], 20 + rowIndex * 10, {
                        url: cell,
                    });
                } else {
                    // Add regular text to the cell
                    doc.text(cell, 10 + cellIndex * columnWidths[cellIndex], 20 + rowIndex * 10);
                }
            });
        });

        // Save the PDF
        doc.save("table_with_links.pdf");
    };
    return (
        <>
            <Loader />
            <button onClick={() => handleConvertToPdf()}>asf</button >
            <button onClick={generatePDF}>Generate PDF</button>

            <table className="table" id="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{

                            doc.textWithLink("download", 20, 20, { url: "https://www.google.com/search?q=enforce+meaning+in+tamil&rlz=1C1RXQR_enIN1062IN1062&oq=e&gs_lcrp=EgZjaHJvbWUqCAgBEEUYOBg7MgYIABBFGDkyCAgBEEUYOBg7MgkIAhAjGCcYigUyBggDEEUYPDIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEINjUzM2oxajmoAgCwAgA&sourceid=chrome&ie=UTF-8&bshm=rime/1" })

                        }</th>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td >Larry the Bird</td>
                        <td>@twitter</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default FileDownload;

// import { useSearchParams } from "react-router-dom";

// const PopSearchParams = () => {
//   const [searchParams, setSearchParams] = useSearchParams();

//   const popSearchParams = () => {
//     setSearchParams({});
//   };

//   return (
//     <button onClick={popSearchParams}>Pop Search Params</button>
//   );
// };