import React from "react";
import Accordion from "../../../../component/accordion/Accordion";
import { responsiveSettings } from "../../../../config/clients/clients";
import Image from "../../../../component/image/Image";
import chevronOrange from "../../../../assets/images/clients/chevron-orange.svg";

const VitalityFragment = (props: any) => {    
    const { menuSettings } = props;
    return (
        <> 
            <div className="sub-menu-parent">
                {/* <Accordion
                    responsiveBreakPoint={responsiveSettings.vitalityMenuBreakpoint}
                    controllerElement={(isExpanded:any) => (
                    <a>Services</a>
                    )}
                > */}
                <ul className="sub-menu vitality-accordion-container">
                    {/* <li><div className="menu-arrow-flex"><a>Assess</a><span className="menu-arrow-new"><Image src={chevronOrange} /></span></div> */}
                    <Accordion
                        responsiveBreakPoint={responsiveSettings.vitalityMenuBreakpoint}
                        controllerElement={(isExpanded:any) => (
                        <h2 className="accordion-action-header">Assess
                            <span className="menu-arrow-new"><Image src={chevronOrange} /></span> 
                        </h2>                                
                        )}
                    >
                        <ul className="sub-menu">
                            <li><a href="https://vitalityworks.health/service/wellbeing-360/" target="_blank" rel="noreferrer">Wellbeing360™</a></li>
                            <li><a href="https://vitalityworks.health/service/health-checks/" target="_blank" rel="noreferrer">Health Checks</a></li>
                            <li><a href="https://vitalityworks.health/service/functional-movement-screening/" target="_blank" rel="noreferrer">Functional Movement Screening</a></li>
                            <li><a href="https://vitalityworks.health/service/skin-checks/" target="_blank" rel="noreferrer">Skin Checks</a></li>
                        </ul>
                    </Accordion>
                    {/* </li> */}
                    {/* <li><div className="menu-arrow-flex"><a>Prevent</a><span className="menu-arrow-new"><Image src={chevronOrange} /></span></div> */}
                    <Accordion
                        responsiveBreakPoint={responsiveSettings.vitalityMenuBreakpoint}
                        controllerElement={(isExpanded:any) => (
                        <h2 className="accordion-action-header">Prevent
                            <span className="menu-arrow-new"><Image src={chevronOrange} /></span> 
                        </h2>
                        )}
                    >
                        <ul className="sub-menu">
                            <li><a href="https://vitalityworks.health/service/thrive-mental-health/" target="_blank" rel="noreferrer">Thrive Mental Health</a></li>
                            <li><a href="https://vitalityworks.health/service/workplace-flu-vaccination/" target="_blank" rel="noreferrer">Workplace Flu Vaccination</a></li>
                            <li><a href="https://vitalityworks.health/service/covid-19-workplace-vaccination/" target="_blank" rel="noreferrer">COVID-19 Workplace Vaccinations</a></li>
                            <li><a href="https://vitalityworks.health/service/safe-spine-safe-mind/" target="_blank" rel="noreferrer">SafeSpine™ SafeMind</a></li>
                            <li><a href="https://vitalityworks.health/service/ergonomic-workplace-assessment/" target="_blank" rel="noreferrer">Ergonomic Workplace Assessments</a></li>
                            <li><a href="https://vitalityworks.health/service/healthy-minds-program/" target="_blank" rel="noreferrer">Healthy Minds Program</a></li>
                            <li><a href="https://vitalityworks.health/service/health-seminars-workshops/" target="_blank" rel="noreferrer">Health Seminars &amp; Workshops</a></li>
                        </ul>
                    </Accordion>
                    {/* </li> */}
                    {/* <li><div className="menu-arrow-flex"><a>Thrive</a><span className="menu-arrow-new"><Image src={chevronOrange} /></span></div> */}
                    <Accordion
                        responsiveBreakPoint={responsiveSettings.vitalityMenuBreakpoint}
                        controllerElement={(isExpanded:any) => (
                        <h2>Thrive
                            <span className="menu-arrow-new"><Image src={chevronOrange} /></span> 
                        </h2>                                
                        )}
                    >
                        <ul className="sub-menu">
                            <li><a href="https://vitalityworks.health/service/mental-health-essentials/" target="_blank" rel="noreferrer">Mental Health Essentials</a></li>
                            <li><a href="https://vitalityworks.health/service/stress-resilience-good-day-project/" target="_blank" rel="noreferrer">Good Day Project™ – Stress &amp; Resilience</a></li>
                            <li><a href="https://vitalityworks.health/service/health-coaching/" target="_blank" rel="noreferrer">Health Coaching</a></li>
                            <li><a href="https://vitalityworks.health/service/vitality-hub-wellness-program/" target="_blank" rel="noreferrer">Vitality Hub – Online Wellbeing Platform</a></li>
                            <li><a href="https://vitalityworks.health/service/charge/" target="_blank" rel="noreferrer">Charge – FIFO Workers Lifestyle Program</a></li>
                        </ul>
                    </Accordion>
                    {/* </li> */}
                    <li className="custom-content">
                        <p>
                            <img className="alignnone  wp-image-12092" src="https://vitalityworks.health/wp-content/uploads/2024/04/SafetyWorks_Logo-CMYK-011-300x48.png" alt="" width="138" height="22" />
                        </p>
                        <p className="mb-20">SafetyWorks consulting assess and manage workplace risk across health, safety and wellbeing-with a culture and leadership focus.</p>
                        <p ><strong>Systems risk &amp; assurance</strong></p>
                        <ul>
                            <li>Specialist incident investigations</li>
                            <li>Audits &amp; system development</li>
                        </ul>
                            <p><strong>Biopsychosocial safety &amp; wellbeing</strong></p>
                        <ul>
                            <li>Psychosocial risk assessments</li>
                            <li>Biopsychosocial strategy development</li>
                        </ul>
                            <p><strong>Leadership & culture</strong></p>
                            <ul>
                            <li>Safety maturity assessments</li>
                            <li>Leadership coaching</li>
                        </ul>
                        <p><a className="button" href="https://www.safetyworks.com.au/" target="_blank" rel="noreferrer">Read more</a></p>
                    </li>
                </ul>
                {/* </Accordion> */}
            </div>
        </>
    );
};


export default VitalityFragment;