import React from "react";

const Iframe = (props: any) => {
    const { url } = props;

    const loadUrl = () => {
        setTimeout(() => {
            return <iframe className={"esign"} src={url}></iframe>;
        }, 500);
    };
    return (
        <>
            {/* {loadUrl()} */}
            <iframe className={"esign"} src={url}></iframe>
        </>
    );
};

export default Iframe;