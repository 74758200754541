import React, { useEffect, useState } from "react";
import "../../../styles/careers/header.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../component/loader/Loader";
import Button from "../../../component/button/Button";
import Logo from "../../../assets/images/Logo.png";
import { clientIds, clients, customMenuUISettings, customLinkData } from "../../../config/clients/clients";
import DropDown from "../../../component/dropdown/dropdown";
import { boolean } from "yup";
import DefaultCustomMenu from "./CustomMenuTemplates/DefaultCustomMenu";
import CustomMegaMenu from "./CustomMenuTemplates/CustomMegaMenu";

const CustomHeader = (props: any) => {

    const { clientId }  = props;
    const [menuSettings, setMenuSettings] = useState({
        id : "",
        classref : "",
        logoimg : "",
        moblogoimg: "",
        bannerImg : "",
        artifactImg1: "",
        artifactImg2: "",
        artifactImg3: "",
        copyrightLogo : "",
        footerLogoImg : "",
        menuToggle: "",
        menuType : "1",
        menuToggleClose: "",
        homeLinkHref: ""
    });
    const [count, setCount] = useState(0);

    useEffect(() => {
        let interval: any;
        //Implementing the setInterval method
        if(count <= 6) {
            interval = setInterval(() => {
                setCount((count) => count + 1);
            }, 5000);
        } else {
            clearInterval(interval);
            setCount(0);
        }
        
        //Clearing the interval
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let currentClientSetting:any = customMenuUISettings.find(client => client.id === clientId); 
        setMenuSettings(currentClientSetting);
    });    

    const renderMenu = () => {
        switch(clientId) {
            case clientIds.pioneer: return <DefaultCustomMenu menuSettings={menuSettings} clientId={clientId}/>;
            case clientIds.upandgo: return <DefaultCustomMenu menuSettings={menuSettings} clientId={clientId}/>;
            case clientIds.vitality: return <CustomMegaMenu menuSettings={menuSettings} clientId={clientId}/>; 
            default: return "";
        }
    };

    
    
    return (
        <>            
            <nav className={menuSettings.classref}>
                { renderMenu() }
                <div className="body-content">
                    <div className="container-new">
                        <div className="banner-new">
                            <>
                                {
                                    (clientId === clientIds.pioneer) ?
                                     <> 
                                        <div className="banner-pioneer">
                                            <img src={process.env.PUBLIC_URL + menuSettings.bannerImg} />
                                            <div className="banner-new-title">A FRESH APPROACH TO BREAKFAST</div>
                                        </div>
                                    </>: ""
                                }
                            </>
                            {
                                (clientId === clientIds.upandgo) ? <>
                                    <div className="banner-inner">
                                        <div className="img1">
                                            <img src={process.env.PUBLIC_URL + menuSettings.artifactImg2} />                                    
                                        </div>
                                        <div className="img2">
                                            <img src={process.env.PUBLIC_URL + menuSettings.artifactImg3} />
                                        </div>
                                        <div className="img3">
                                            <img src={process.env.PUBLIC_URL + menuSettings.artifactImg1} />
                                        </div>
                                    </div>
                                </> : ""
                            }
                            {
                                (clientId === clientIds.vitality) ?
                                    <> 
                                    <div className="banner-vitality">
                                        <div className="banner-img">
                                            <img src={process.env.PUBLIC_URL + menuSettings.bannerImg} />
                                        </div>
                                        <div className="page-title-content">
                                            <div className="animate-text">
                                                <div className="slider-items">
                                                    <div className={`slide-item ${count === 0 ? "active" : ""}`} data-count="slide-0">
                                                        <p>Next in<br/>Workplace Health</p>
                                                    </div>
                                                    <div className={`slide-item ${count === 1 ? "active" : ""}`} data-count="slide-1">
                                                        <p>Next in<br/> Mental Health</p>
                                                    </div>
                                                    <div className={`slide-item ${count === 2 ? "active" : ""}`} data-count="slide-2">
                                                        <p>Next in<br/> Workplace Safety</p>
                                                    </div>
                                                    <div className={`slide-item ${count === 3 ? "active" : ""}`} data-count="slide-3">
                                                        <p>Next in<br/> Team Health</p>
                                                    </div>
                                                    <div className={`slide-item ${count === 4 ? "active" : ""}`} data-count="slide-4">
                                                        <p>Next in<br/> Wellbeing</p>
                                                    </div>
                                                    <div className={`slide-item ${count === 5 ? "active" : ""}`} data-count="slide-5">
                                                        <p>Next in<br/> Prevention</p>
                                                    </div>
                                                    <div className={`slide-item ${count === 6 ? "active" : ""}`} data-count="slide-6">
                                                        <p>Next in<br/> Partnerships</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <h1 className="grey">Transformative workplace
                                            <br/> health solutions for a safe
                                            <br/> and well workforce.</h1>
                                        </div>
                                    </div>
                                </>: ""
                            }
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default React.memo(CustomHeader);