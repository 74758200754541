import React from "react";
interface UserLabel {
    name?: string | undefined,
    label: string | undefined,
    className?: string | undefined,
    required?: boolean,
    subLabel?: string | number,
}

const Label = (props: UserLabel) => {
    const { name, label, className, required, subLabel } = props;
    return (
        <>
            <label id={name} className={className} >{label} {subLabel ? <span className="sub-label">{subLabel}</span> : ""} {required ? <span className="star">*</span> : ""}</label>
        </>
    );
};

export default Label;