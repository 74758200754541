import React, { useEffect, useState } from "react";
import Image from "../../../component/image/Image";
import FiltersIcon from "../../../assets/images/clients/filters.svg";
import CloseIcon from "../../../assets/images/clients/close.svg";
import ArrowRight from "../../../assets/images/clients/arrow-right.svg";
import Input from "../../../component/input/Input";
import Select from "../../../component/multiSelect/MultiSelect";
import { useLocation, useParams } from "react-router-dom";
import { clientIds } from "../../../config/clients/clients";
import SingleSelect from "../../../component/singleSelect/SingleSelect";
import { useSelector } from "react-redux";

const JobFilterClient = (props: any) => {

    const { setTableControl, tableControl, filterListData, searchControlSubmit, withinDisabled, withinDisabledLocClass } = props;

    const [optimizeSearchBox, setOptimizeSearchBox] = useState("");
    const clientId = useParams();
    const location = useLocation();
    const clientConfig = useSelector((state: any) => state.careers.cliendConfig);

    useEffect(() => {
        if(clientIds?.hvtc === clientConfig?.data?.data?.clientSettings?.clientId && location.pathname === "/"){
            setOptimizeSearchBox("");
        } else if(clientIds?.hvtc === clientConfig?.data?.data?.clientSettings?.clientId && clientId?.from !== "direct"){
            setOptimizeSearchBox("module-careers-filters-apply-new");
        }
    }, [location.pathname, clientConfig]);

    const submitSearch = () => {
        searchControlSubmit();
    };
    
    // const selectOption = (keyword: string, e: any) => {
    //     let value = e.target.value;
    //     let result = "";

    //     if(keyword === "distance"){
    //         result = (filterListData.jobDistances)?.find((distance: any) => (distance?.value === Number(value)));
    //     }
        
    //     return result;
    // };

    return (
        <>
            <div className={`module-careers-filters ${optimizeSearchBox}`}>
                <form className="flex">
                    <input type="checkbox" className="filters-checkbox" />
                    <div className="heading">
                        <div className="icon">
                            <Image src={FiltersIcon} alt="filters" />
                            <Image src={CloseIcon} alt="close" />
                        </div>
                        <h2 className="h2-client teal-purple">Find your <em>future</em><span className="stop">.</span></h2>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <div className="form-fields search">
                                <div className="form-group dropdown-field multi-select jobcategory-select cust-common-select-dropdown">
                                    <label>Category</label>
                                    <Select className="form-control normal-text-input mq-normal-text-input" isSearchable={true} placeholder="All categories" isMulti={true} onChange={(e: any) => {
                                        setTableControl((prs: any) => {
                                            return {
                                                ...prs,
                                                searchCat: e
                                            };
                                        });
                                    }} value={tableControl.searchCat} options={filterListData.jobCategories} />
                                </div>
                                <div className="form-group dropdown-field multi-select jobcategory-select cust-common-select-dropdown">
                                    <label>Job Types</label>
                                    <Select className="form-control normal-text-input mq-normal-text-input" isSearchable={true} placeholder="All jobs" isMulti={true} onChange={(e: any) => {
                                        setTableControl((prs: any) => {
                                            return {
                                                ...prs,
                                                searchJob: e
                                            };
                                        });
                                    }} value={tableControl.searchJob} options={filterListData.jobTypes} />
                                </div>
                                <div className="form-group dropdown-field multi-select jobcategory-select cust-common-select-dropdown">
                                    <label>Locations</label>
                                    <Select className="form-control normal-text-input mq-normal-text-input" isSearchable={true} placeholder="All locations" isMulti={true}
                                        onChange={(e: any) => {
                                        setTableControl((prs: any) => {
                                            return {
                                                ...prs,
                                                searchLocation: e
                                            };
                                        });
                                    }} value={tableControl.searchLocation} options={filterListData.jobLocations} />
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="form-fields">
                                
                                <div className="form-group text-field cust-postcode-field">
                                    <label>Postcode</label>
                                    <Input type={"number"} maxLength={4} placeholder="Enter postcode" className="form-control mq-normal-text-input cust-number-field" value={tableControl.postCode}
                                        onChange={(e: { target: { value: any; }; }) =>
                                            setTableControl((prs: any) => {
                                                return {
                                                    ...prs,
                                                    postCode: e.target.value
                                                };
                                            })
                                        }
                                    />
                                </div>
                                <div className="form-group dropdown-field">
                                    {/* <label>Within</label>
                                    <select className="form-control normal-text-input mq-normal-text-input select-form-control-active"
                                        placeholder="" onChange={(e: any) => {
                                        setTableControl((prs: any) => {
                                            return {
                                                ...prs,
                                                distanceRadius: selectOption("distance", e)
                                            };
                                        });
                                        }} value={tableControl.distanceRadius.value}>
                                        {/* <option value="All">{"All"}</option> */}
                                        {/* {filterListData.jobDistances?.map((ress: any, id: number) => {
                                            return (
                                                <option key={id} value={ress.value}>{ress.label}</option>
                                            );
                                        })} */}
                                    {/* </select>  */}
                                    <div className={`cust-common-select cust-common-select-dropdown cust-within-field  ${withinDisabledLocClass}`}>
                                        <label>Within</label>
                                        <SingleSelect className="joblist-multi-select distance-search" isDisabled={withinDisabled}
                                            placeholder="Any distance" onChange={(e: any) => {
                                                setTableControl((prs: any) => {
                                                    return {
                                                        ...prs,
                                                        distanceRadius: e
                                                    };
                                                });

                                            }}
                                        isClearable={true} value={tableControl.distanceRadius} options={filterListData.jobDistances} />
                                    </div>
                                    {withinDisabled ? <div className="disabled-location-status">Enable location access to use this filter.</div> : "" }
                                </div>
                                <div className="button-group">
                                    <button type="button" onClick={() => submitSearch()} className="butn"><span>Filter Search</span></button>
                                </div>
                                <div className="cutout">
                                    <div className="form-group text-field cust-search-jobs-by-keywords">
                                        <label>search jobs by keywords</label>
                                        <Input
                                            className={`form-control active filter-in normal-text-input mq-normal-text-input ${tableControl.searchText ? "filter-in-active" : ""}`}
                                            type={"text"}
                                            value={tableControl.searchText}
                                            placeholder="search job by keywords"
                                            name={"search"}
                                            onChange={(e: { target: { value: any; }; }) => {
                                                setTableControl((prs: any) => {
                                                    return {
                                                        ...prs,
                                                        searchText: e.target.value
                                                    };
                                                });
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="button-group">
                                        <button type="button" onClick={() => submitSearch()}>
                                            <Image src={ArrowRight} alt="arrow" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default JobFilterClient;