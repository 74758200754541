import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "./TextError";
import { valudatesetformstatus } from "../../pages/onboarding/Onboarding";

const CheckBoxGroup = (props: any) => {
    const { name, label, option, className, allData, completedPage, ...rest } = props;

    const fieldValidation = (value: any) => {
        let error;
        if (allData.validate) {
            if (allData.type === "checkbox-group" || allData.type === "checkbox") {
                allData?.validations.forEach((element: any) => {
                    if (allData.type === "checkbox-group" || allData.type === "checkbox") {
                        if ((!value || value?.length === 0) && valudatesetformstatus !== "draft" ) {
                            if (element.type === "required") {
                                error = element.params[0];
                            }
                        } else if (!value && valudatesetformstatus === "draft" && completedPage) {
                            if (element.type === "required") {
                                error = element.params[0];
                            }
                        }
                    }
                });
            }
        }
        return error;
    };
    return (
        <>
            <Field name={name} validate={fieldValidation} onBlur={(e: any) => e.preventDefault()} {...rest}>
                {
                    ({ field }: any) => {
                        return option.map((ress: any) => {
                            return (
                                <>
                                    <div key={ress.value} className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={allData.name}
                                            {...field}
                                            value={ress.value}
                                            checked={field?.value?.includes(ress.value)}
                                        />
                                        <label className="form-check-label" htmlFor={allData.name}>{ress.label} {allData.required && allData.type === "checkbox" ? <span className="star">*</span> : ""}</label>
                                    </div>
                                </>
                            );

                        });
                    }
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />

        </>
    );
};

export default CheckBoxGroup;