// export let oldFile: any = {};

let oldFile: any = {};
export function updateOldFile(newValue: any) {
    oldFile = newValue; // Modify oldFile in the source module
}
export { oldFile };

export const stepes = 140;


export const domainWithHttp:string = window.location.protocol + "//" + window.location.hostname;

