import React, { useEffect, useState } from "react";
import "../../../styles/careers/header.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { clientSettingsConfig } from "../../../redux/slices/careersSlices.reducer";
import Loader from "../../../component/loader/Loader";
import Button from "../../../component/button/Button";
import Logo from "../../../assets/images/Logo.png";
import { clientIds, clients, customMenuIds } from "../../../config/clients/clients";
import MegaMenu from "../../../component/megaMenu/MegaMenu";
import PifsImg1 from "../../../assets/images/pifs_1.png";
import PifsImg2 from "../../../assets/images/pifs_imf_2.jpg";
import PifsLogoBlack from "../../../assets/images/logo_black.svg";
import PifsLogoWhite from "../../../assets/images/logo-white.svg";
import CustomHeader from "./CareersCustomMenu";
let count = 0;
const CompletionHeader = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const cliendId = useParams();
    const navigate = useNavigate();

    const cliendConfig = useSelector((state: any) => state.careers.cliendConfig);
    const resetActiveTap = useSelector(
        (state: any) => state.careers.resetActiveTap
    );
    const loader = useSelector((state: any) => {
        return state?.careers?.loading;
    });
    const activeClient = useSelector((state: any) => {
        return state?.careers?.activeClient;
    });

    const [currentTap, setCurrentTap] = useState<string | number>(0);
    const [currentTapSubMenu, setCurrentTapSubMenu] = useState<boolean>(false);
    const [toggleStyle, setToggleStyle] = useState<string>("");
    const [logoUpdate, setLogoUpdata] = useState("");
    const [checkActivite, setCheckActivite] = useState(false);
    // const [showLink, setShowLink] = useState(false);

    useEffect(() => {
        if (activeClient?.length > 0) {
            document.body.className = "";
            document.body.classList.add(activeClient[0]["className"]);
            if(location.pathname === "/job-alerts") {
                document.body.classList.add("job-alerts");
            }            
        }
    }, [activeClient]);

    useEffect(() => {
        dispatch<any>(clientSettingsConfig());
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (cliendConfig.status === 200) {
            const dataress = cliendConfig?.data?.data?.clientSettings;
            console.log(dataress);
            sessionStorage.setItem("pr-color", dataress?.brandColor);
            document.title = `${cliendConfig?.data?.data?.clientSettings?.titleTabText}`;
            document.documentElement.style.setProperty(
                "--completion-primary-color",
                dataress?.brandColor
            );
            document.documentElement.style.setProperty(
                "--primary-color",
                dataress?.brandColor
            );
            document.documentElement.style.setProperty(
                "--completion-secondary-color",
                dataress?.secondaryColor
            );
            document.documentElement.style.setProperty(
                "--theam-secondary-color",
                dataress?.secondaryColor
            );
            // document.title = `${candidateData?.clientDetails?.titleTabText} - Compliance`;
            let link: any = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
            }
            link.href = cliendConfig?.data?.data?.clientSettings?.favIcon;
        }
    }, [cliendConfig]);

    useEffect(() => {
        // console.log(activeClient);
        if (activeClient?.length > 0) {
            document.body.classList.add(activeClient[0]["className"]);
        }
    }, [activeClient]);
    useEffect(() => {
        if (location.pathname !== "/") {
            // console.log(cliendId);
            const addHeaderHight: any = document.getElementById("careers-body");
            // addHeaderHight.style.top = "106px";
            // addHeaderHight.classList.add("careers-view");
            // addHeaderHight.classList.add("careers-view");
            // const conpletionViewHeader: any = document.getElementById("careers-header");
            // conpletionViewHeader.classList.add("careers-view-header");
        } else {
            const addHeaderHight: any = document.getElementById("careers-body");
            // addHeaderHight.style.top = "106px";
            addHeaderHight.classList.remove("careers-view");
        }
    }, [location]);

    // console.log(clients?.filter((ress) => ress?.clientId === cliendId.userId));

    const getCurrentMenu = (params: number | string) => {
        // setCurrentTap(0);
        // if (currentTapSubMenu) {
        //     console.log(currentTapSubMenu);
        //     setCurrentTap(params);
        // setCurrentTapSubMenu(false);
        // } else {
        // console.log(currentTapSubMenu);
        if (!checkActivite) {
            setCurrentTap((prs) => {
                if (prs === params || params === 0) {
                    setLogoUpdata("");
                } else {
                    setLogoUpdata("0");
                }
                return prs === params ? 0 : params;
            });
        }

        // }
        // setCurrentTapSubMenu(false);
    };
    // useEffect(() => {
    //     if (resetActiveTap) {
    //         setCurrentTap(0);
    //     }
    //     console.log(resetActiveTap);
    // }, [resetActiveTap]);
    useEffect(() => {
        if (toggleStyle === "") {
            setCurrentTap(0);
            setLogoUpdata("");
        }
    }, [toggleStyle]);

    useEffect(() => {
        // console.log(currentTapSubMenu);
        if (currentTapSubMenu) {
            setCurrentTap(2);
            setLogoUpdata("0");

            count++;
            // setCurrentTap(prs)
        } else {
            if (count !== 0) {
                setCurrentTap(2);
                setLogoUpdata("0");
            }
        }
    }, [currentTapSubMenu]);

    // useEffect(() => {
    //     if(location.pathname === `/careers/job-list/${cliendId?.userId}` || 
    //        location.pathname === `/careers/job-list/${cliendId?.userId}/view/${cliendId?.id}` ||
    //        location.pathname === `/careers/job-list/${cliendId?.userId}/view/${cliendId?.id}/apply-job/register/from/direct` ){
    //         setShowLink(false);
    //     } else if(cliendId?.from !== 'direct'){
    //         setShowLink(!false);
    //     }
    // }, [location.pathname]);

    //Resolves if the current client has a custom menu using the inbuilt clientids
    const hasCustomMenu = (id:any) => {
        return customMenuIds.includes(id);
    };
    return (
        <>
            <div>
                <Loader loading={loader} />
            </div>
            <div
                onClick={() => { getCurrentMenu(0); }}
                id={"careers-header"}
                className={`bg-dark 
                    ${currentTap === 1 || currentTap === 2 || currentTap === 3
                        ? "active"
                        : " "
                    } 
                    ${location.pathname !== "/"
                        ? "careers-view-header"
                        : ""
                    }
                    ${(location.pathname.indexOf("/apply-job/") !== -1)
                        ? "careers-apply-header"
                        : ""
                    }
                    `}
            ></div>
            {/* dev tech debt :needs a switch case based template implementation */}            
            {cliendConfig?.data?.data?.clientSettings?.clientId === clientIds?.pifs ? (
                <div className={`head navbar ${toggleStyle}`}>
                    <div
                        className="head-logo"
                        onClick={() =>
                            window.open(
                                "https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/publications",
                                "_blank"
                            )
                        }
                    >
                        {logoUpdate !== "" || toggleStyle !== "" ? (
                            <>
                                <img role="button" src={PifsLogoBlack} alt="" />
                                {/* <img
                                        src="https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/themes/custom/pifs/logo.svg"
                                        alt=""
                                    /> */}
                            </>
                        ) : (
                            <>
                                <img role="button" src={PifsLogoWhite} alt="" />
                                {/* <img src="https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/themes/custom/pifs/logo-white.svg" alt="" /> */}
                            </>
                        )}
                    </div>
                    {/* <div className="icon-fount">
                        <i className="fa fa-bars"></i>
                    </div> */}
                    <button
                        className="navbar-toggler hamburger-menu__wrapper"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => {
                            setToggleStyle((prs) => (prs === "" ? "mobile-head" : ""));
                            // setLogoUpdata(prs => prs === "" ? "0" : "");
                            // getCurrentMenu(0)
                        }}
                    >
                        {/* <span className="navbar-toggler-icon"></span> */}
                        <div className="hamburger-menu__icon">
                            <span className="line line-1"></span>
                            <span className="line line-2"></span>
                            <span className="line line-3"></span>
                        </div>
                    </button>

                    <div
                        className="head-menu collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul
                            className={`${currentTap === 1 || currentTap === 2 || currentTap === 3
                                ? "active-mega-menu"
                                : " "
                                } head-menu-ul`}
                        >
                            <li
                                className={`${currentTap === 1 ? "active-menu" : " "}`}
                                onClick={() => getCurrentMenu(1)}
                            >
                                <a className="" role="button">
                                    Who we are<i className="fa  fa-angle-down"></i>
                                </a>
                                {/* {currentTap === 1 ? */}
                                <div
                                    onMouseEnter={(e: any) => {
                                        // console.log("true");
                                        setCheckActivite(true);
                                    }}
                                    onMouseLeave={(e: any) => {
                                        // console.log(false);
                                        setCheckActivite(false);
                                    }}
                                    className={"mega-menu"}
                                >
                                    <div className="d-flex  main-menu ">
                                        {/* <div className="menu-second-level-item-content-wrapper"> */}
                                        <div className="mega-menu-callout level-0">
                                            <div className="mega-menu-image">
                                                <img src={PifsImg1} alt="" />
                                                <div className="level-0-text">
                                                    <div className="h2">
                                                        2050 Strategy for the Blue Pacific Continent
                                                    </div>
                                                    <Button
                                                        clasName="btn btn-primary "
                                                        label={"Read More"}
                                                        onClick={() =>
                                                            window.open("https://example.com", "_blank")
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex  text-menu cust-menu-inner">
                                            <div className="col-md-4">
                                                <div className="mega-menu-callout">
                                                    <div className="mega-menu-image">
                                                        <div className="who-we-are h2">Who we are</div>
                                                        <div className="founded">
                                                            Founded in 1971, the Pacific Islands Forum is the
                                                            region&apos;s top political and economic
                                                            organisation, with 18 members across the Pacific
                                                            Ocean.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mega-menu-callout level-1">
                                                    <div className="mega-menu-image">
                                                        <div className="list-start">
                                                            <ul>
                                                                <li>Members and Leaders</li>
                                                                <li>Delegation in Geneva</li>
                                                                <li>Forum Secretariat</li>

                                                                <li
                                                                    role="button"
                                                                    onClick={() =>
                                                                        window.open(
                                                                            "https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/timeline-major-events-1971",
                                                                            "_blank"
                                                                        )
                                                                    }
                                                                >
                                                                    Forum Timeline
                                                                </li>
                                                                <li>Map</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mega-menu-callout level-1">
                                                    <div className="mega-menu-image">
                                                        <div className="list-start">
                                                            <ul>
                                                                <li
                                                                    role="button"
                                                                    onClick={() =>
                                                                        window.open(
                                                                            "https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/pacific-islands-forum",
                                                                            "_blank"
                                                                        )
                                                                    }
                                                                >
                                                                    The Pacific Islands Forum
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* :
                                    ""
                                // } */}
                            </li>
                            <li
                                className={`${currentTap === 2 ? "active-menu" : " "}`}
                                onClick={() => getCurrentMenu(2)}
                            >
                                <a
                                    className=""
                                    role="button"
                                // data-bs-toggle="dropdown"
                                // aria-expandeds="false"
                                >
                                    What we do<i className="fa  fa-angle-down"></i>
                                </a>
                                {/* {currentTap === 2 ? */}
                                <div
                                    onMouseEnter={(e: any) => {
                                        // console.log("true");
                                        setCheckActivite(true);
                                    }}
                                    onMouseLeave={(e: any) => {
                                        // console.log(false);
                                        setCheckActivite(false);
                                    }}
                                    className="mega-menu d-block show"
                                >
                                    <div className="d-flex  main-menu ">
                                        {/* <div className="menu-second-level-item-content-wrapper"> */}
                                        <div className="mega-menu-callout level-0-1">
                                            <div className="mega-menu-image mega-menu-image-what-we-do">
                                                <img src={PifsImg2} alt="" />

                                                <div className={`level-0-text level-0-text-2-hight ${currentTapSubMenu ? "level-0-text-2-hight-2050" : ""}`}>
                                                    <div className="h2">
                                                        2050 Strategy for the Blue Pacific Continent
                                                    </div>
                                                    <Button
                                                        clasName="btn btn-primary "
                                                        label={
                                                            <div>
                                                                {"Read More"}
                                                                <span></span>
                                                            </div>
                                                        }
                                                        onClick={() =>
                                                            window.open("https://example.com", "_blank")
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex  text-menu text-menu-1 cust-menu-inner">
                                            <div
                                                className={currentTapSubMenu ? "col-md-5" : "col-md-4"}
                                            >
                                                <div className="mega-menu-callout">
                                                    {/* {currentTapSubMenu ? ( */}
                                                    <div className={`mega-menu-image mega-menu-inner ${currentTapSubMenu ? "mega-menu-inner-active" : ""}`}>

                                                        <div
                                                            role="button"
                                                            onClick={() => setCurrentTapSubMenu(false)}
                                                            className="goback"
                                                        >
                                                            <i className="fa fa-angle-left"></i>{" "}
                                                            <span className="ps-2">Go Back</span>
                                                        </div>
                                                        <div className="who-we-are h2 ">
                                                            Economic and Trade Development
                                                        </div>
                                                    </div>
                                                    {/* ) : ( */}
                                                    <div className={`mega-menu-image mega-menu-inner-disabled ${currentTapSubMenu ? "" : "mega-menu-inner-disabled-active"}`}>
                                                        <div className="who-we-are h2">What we do</div>
                                                        <div className="founded">
                                                            The Pacific Island Forum is the region’s premier
                                                            political and economic policy organisation. We
                                                            work towards a Pacific Vision of peace, harmony
                                                            and prosperity.
                                                        </div>
                                                    </div>
                                                    {/* )} */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mega-menu-callout level-1">
                                                    <div className="mega-menu-image">
                                                        <div className="list-start">
                                                            <ul>
                                                                {currentTapSubMenu ? (
                                                                    <li
                                                                        onClick={() =>
                                                                            window.open(
                                                                                "https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/",
                                                                                "_blank"
                                                                            )
                                                                        }
                                                                    >
                                                                        Post-Cotonou Negotiations
                                                                    </li>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {!currentTapSubMenu ? (
                                                                    <>
                                                                        <li
                                                                            onClick={() =>
                                                                                window.open(
                                                                                    "https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/",
                                                                                    "_blank"
                                                                                )
                                                                            }
                                                                        >
                                                                            Political Leadership and Regionalism
                                                                        </li>
                                                                        <li>
                                                                            <div
                                                                                role="button"
                                                                                onClick={() => {
                                                                                    setCurrentTapSubMenu(true);
                                                                                    setLogoUpdata("");
                                                                                }}
                                                                                className="d-flex justify-content-between align-items-center"
                                                                            >
                                                                                <div>
                                                                                    Economic and Trade Development
                                                                                </div>
                                                                                <i className="fa fa-chevron-right"></i>
                                                                            </div>
                                                                        </li>
                                                                        <li
                                                                            onClick={() =>
                                                                                window.open(
                                                                                    "https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/2050",
                                                                                    "_blank"
                                                                                )
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            2050 Strategy for the Blue Pacific
                                                                            Continent
                                                                        </li>
                                                                        <li>Climate Change and Resilience</li>
                                                                        <li>Peace and Security</li>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mega-menu-callout level-1">
                                                    <div className="mega-menu-image">
                                                        <div className="list-start">
                                                            <ul>
                                                                {!currentTapSubMenu ? (
                                                                    <li>People-Centered Development </li>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* : ""} */}
                                {/* </div> */}
                            </li>
                            <li
                                className={`${currentTap === 3 ? "active-menu" : " "}`}
                                onClick={() => getCurrentMenu(3)}
                            >
                                <a
                                    className=""
                                    role="button"
                                // data-bs-toggle="dropdown"
                                // aria-expandeds="false"
                                >
                                    Working with us
                                    <i className="fa  fa-angle-down"></i>
                                </a>
                                {/* {currentTap === 3 ? */}
                                <div
                                    onMouseEnter={(e: any) => {
                                        // console.log("true");
                                        setCheckActivite(true);
                                    }}
                                    onMouseLeave={(e: any) => {
                                        // console.log(false);
                                        setCheckActivite(false);
                                    }}
                                    className="mega-menu d-block show"
                                >
                                    <div className="d-flex  main-menu ">
                                        {/* <div className="menu-second-level-item-content-wrapper"> */}
                                        <div className="mega-menu-callout level-0-2">
                                            <div className="mega-menu-image">
                                                <img src={PifsImg2} alt="" />

                                                <div className="level-0-text">
                                                    <div className="h2">
                                                        2050 Strategy for the Blue Pacific Continent
                                                    </div>
                                                    <Button
                                                        clasName="btn btn-primary "
                                                        label={"Read More"}
                                                        onClick={() =>
                                                            window.open("https://example.com", "_blank")
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex  text-menu text-menu-2 cust-menu-inner">
                                            <div className="col-md-4">
                                                <div className="mega-menu-callout">
                                                    <div className="mega-menu-image">
                                                        <div className="who-we-are h2">Working with us</div>
                                                        <div className="founded"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mega-menu-callout level-1">
                                                    <div className="mega-menu-image">
                                                        <div className="list-start">
                                                            <ul>
                                                                <li>Careers</li>
                                                                <li> Partner Engagement</li>
                                                                <li>Tenders & Procurement</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mega-menu-callout level-1">
                                                    <div className="mega-menu-image">
                                                        <div className="list-start">
                                                            <ul>
                                                                {/* <li>People-Centered Development </li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* :
                                    ""
                                } */}
                            </li>
                            {/* getCurrentMenu(4);  */}
                            <li
                                role="button"
                                onClick={() => {
                                    window.open(
                                        "https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/publications",
                                        "_blank"
                                    );
                                }}
                            >
                                <a>Publications</a>
                            </li>
                            {/* getCurrentMenu(5); */}
                            <li
                                role="button"
                                onClick={() => {
                                    window.open(
                                        "https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/events",
                                        "_blank"
                                    );
                                }}
                            >
                                {/* <MegaMenu /> */}
                                <a>Events</a>
                            </li>
                            {/* getCurrentMenu(6); */}
                            <li role="button"
                                onClick={() => {
                                    window.open(
                                        "https://main-bvxea6i-ek7vzryqpkff2.au.platformsh.site/contact-us",
                                        "_blank"
                                    );
                                }}
                            >
                                <a>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="">
                            <li>Who we are</li>
                            <li>What we do</li>
                            <li>Working with us</li>
                            <li>Publications</li>
                            <li>Events</li>
                            <li>Contact Us</li>

                        </ul>
                    </div> */}
                </div>
            ) : (hasCustomMenu(cliendConfig?.data?.data?.clientSettings?.clientId) ? (
                <CustomHeader clientId={cliendConfig?.data?.data?.clientSettings?.clientId}/>
            ) 
                :(
                    <>
                    {/* { showLink ? 
                        <nav className="head navbar navbar-expand-lg completion-header1 careers-header-normal">
                            <div className="container-fluid">
                                <a className="navbar-brand" href="#">
                                    <img src={Logo} alt="" />
                                </a>
                            </div>
                        </nav>
                    : "" } */}
                    </>
            ))}

            {/* <div className="banner">
                <img src={Banner} alt="" />
            </div> */}
            {/* <MegaMenu /> */}
        </>
    );
};

export default CompletionHeader;
