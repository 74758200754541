import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import TextError from "./TextError";
import DatePicker from "react-datepicker";
import Image from "../image/Image";
import { valudatesetformstatus } from "../../pages/onboarding/Onboarding";

const FormikDatePicker = (props: any) => {
    const { name, label, option, className, dateFormat, allData, completedPage, ...rest } = props;

    const fieldValidation = (value: any) => {

        let error;
        if (allData.validate) {
            if (allData.type === "date") {
                allData?.validations.forEach((element: any) => {
                    // if (element.type === "required") {
                    //     if (!value && valudatesetformstatus !== "draft") {
                    //         console.log(element);

                    //         error = element.params[0];
                    //     } else if (!value && valudatesetformstatus === "draft" && completedPage) {
                    //         error = element.params[0];
                    //     }
                    // }
                    
                    if ((!value || value === null || value === undefined) && valudatesetformstatus !== "draft") {

                        if (element.type === "required") {
                            error = element.params[0];
                        }
                    } else if ((!value || value === null || value === undefined) && valudatesetformstatus === "draft" && completedPage) {

                        if (element.type === "required") {
                            error = element.params[0];
                        }
                    }
                });
            }
        }
        return error;
    };
    return (
        <>
            <div className="class-name position-relative">
                {/*  */}
                <Field name={name} validate={fieldValidation}
                    onBlur={(e: any) => e.preventDefault()} id={name}  {...rest} >
                    {({ field, form: { setFieldValue } }: any) => {
                        return (
                            <>
                                {/* <div>
                                    <i className="pi pi-calendar"></i>
                                </div> */}
                                <i role={"button"} onClick={() => document.getElementById(name + 1)?.click()} className="fa fa-calendar  form-date-icon" aria-hidden="true"></i>
                                <DatePicker
                                    id={name + 1}
                                    className={`${className}`}
                                    {...field}
                                    selected={field.value}
                                    onChange={(val) => {
                                        setFieldValue(field.name, val);
                                    }}
                                    dateFormat={dateFormat}
                                    onBlur={(e: any) => e.preventDefault()}
                                    minDate={allData.future ? new Date() : null}
                                    maxDate={allData.past ? new Date() : null}
                                    // peekNextMonth={false}
                                    showMonthDropdown
                                    showYearDropdown
                                    monthsShown={1}
                                    dropdownMode="select"
                                    placeholderText={allData.placeholder}

                                />
                            </>
                        );
                    }}
                </Field>
                <ErrorMessage name={name} component={TextError} />
            </div>
        </>
    );
};

export default FormikDatePicker;