import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./header/Header";
import React, { useEffect, useState } from "react";
import NotFountError from "../notfounterror/NotFountError";
const OnBoardingRoot = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [pageNotLoad, setPageNotLoad] = useState<boolean>(false);
    useEffect(() => {

        if (location.pathname === "/getCandidate" || location.pathname === "/getCandidate/") {
            // navigate("*");
            setPageNotLoad(true);
        } else {
            setPageNotLoad(false);
        }
    }, [location]);
    return (
        <>
            <Header />
            <div>
                {pageNotLoad ? <NotFountError /> : <Outlet />}

            </div>
        </>
    );
};

export default OnBoardingRoot;