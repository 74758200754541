import React from "react";
import Accordion from "../../../../component/accordion/Accordion";
import { responsiveSettings } from "../../../../config/clients/clients";

const CustomPioneerFooter = (props: any) => {

    const { menuSettings } = props;

    const MyControllerElement = ({isExpanded}:any) => {
        return (<button>{isExpanded ? "Hide" : "Show"} Content</button>);
    };
    
    return (
        <>          
            <div className="footer">
                <div className="footer-container">
                    <div className="footer-top">
                        We respect and honour Aboriginal and Torres Strait Islander Elders past, present and future. We acknowledge the stories, traditions and living cultures of Aboriginal and Torres Strait Islander peoples on this land and commit to building a brighter future together.
                    </div>
                    <div className="footer-bottom">
                        <div className="foo1">
                            <img src={process.env.PUBLIC_URL + menuSettings.footerLogoImg} />
                        </div>
                        <div className="foo2">
                            <ul>
                                <li>
                                    <a href="https://vitalityworks.health/about/" target="_blank" rel="noreferrer">About</a>
                                </li>
                                <li>
                                    <a href="https://vitalityworks.health/resources/" target="_blank" rel="noreferrer">Resources</a>
                                </li>
                                <li>
                                    <a href="https://vitalityworks.health/resources/?resources=case-studies" target="_blank" rel="noreferrer">Case Studies</a>
                                </li>
                                <li>
                                    <a href="https://vitalityworks.health/contact/" target="_blank" rel="noreferrer">Contact</a>
                                </li>
                                <li>
                                    <a href="https://vitalityworks.health/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="foo3">
                            <ul>
                                <li>
                                    <a href="#"><strong>Sign up</strong> to Vitality Works News</a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/vitality-works-sanitarium" rel="noreferrer" target="_blank"><strong>Follow</strong> us on LinkedIn</a>
                                </li>
                            </ul>
                            <div className="foo-cont">Thrive Oceania PTY Ltd</div>
                            <div className="foo-cont">ACN 663 269 574</div>
                            <ul className="mb-0">
                                <li>
                                    <a href="https://www.linkedin.com/company/vitality-works-sanitarium"><strong>Follow</strong> us on LinkedIn</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <div className="footer-container">
                    <div className="row">
                        <div className="col col-sm">
                            <p>© Copyright 2024. Vitality Works. All rights reserved. <a href="https://www.increaseo.com/" target="_blank" rel="noreferrer">Website by Increaseo.</a></p>
                        </div>		
                    </div>
                </div>
            </div>
        </>
    );
};


export default CustomPioneerFooter;