import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import userIcon from "../../../../assets/images/user_icon.png";
import DateIcon from "../../../../assets/images/date-icon.svg";
import JobIcon from "../../../../assets/images/jobIcon.png";
import Price from "../../../../assets/images/Price.png";
import Category from "../../../../assets/images/category.png";
import Image from "../../../../component/image/Image";
import LocationIcon from "../../../../assets/images/locationIcon.png";
import { publishingDate } from "../CareersFucConfig";
import { clientIds } from "../../../../config/clients/clients";

const JobPreView = ({ pageHight }: any) => {
    const jobId = useParams();
    const dispatch = useDispatch();
    const jobDetails = useSelector((state: any) => state.careers.jobDetails);
    const [sideBarStyle, setSideBarStyle] = useState("");
    const location = useLocation();
    const clientConfig = useSelector((state: any) => state.careers.cliendConfig);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(clientIds?.hvtc === clientConfig?.data?.data?.clientSettings?.clientId && jobId?.from !== "direct"){
            setSideBarStyle("");
        } else if(jobId?.from !== "direct"){
            setSideBarStyle("side-bar-container-new");
        }
    }, [location.pathname, clientConfig]);

    return (
        <>
            {jobDetails.status === 200 && jobDetails?.data?.statusCode === 200 ?
                <div className={`side-bar-container ${sideBarStyle}`} style={{ height: pageHight, overflow: "auto" }}>
                    <div>
                        <div className="job-heading">
                            {jobDetails?.data?.data?.job.title}
                        </div>
                        <div>
                            <div className="sub-head"> {jobDetails?.data?.data?.job?.category}</div>
                        </div>
                        <div>
                            <div className="para-text" dangerouslySetInnerHTML={{ __html: jobDetails?.data?.data?.job?.description }}>
                            </div>
                        </div>
                        <div className="job-pre-view-video">
                            {(jobDetails?.data?.data?.job?.videoLink !== "" && jobDetails?.data?.data?.job?.videoLink !== null) ?
                                <>
                                    <iframe src={jobDetails?.data?.data?.job?.videoLink} title='Your Career' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen></iframe>
                                    {/* <div className="pre-view" dangerouslySetInnerHTML={{ __html: jobDetails?.data?.data?.job?.videoLink }}>
                                    </div> */}
                                </>
                                :
                                <>
                                {(jobDetails?.data?.data?.job?.imageLink !== "" && jobDetails?.data?.data?.job?.imageLink !== null) ? (
                                    <>
                                        <Image width="400" src={jobDetails?.data?.data?.job?.imageLink} />
                                    </>
                                ) : (
                                    ""
                                )}
                                </>
                            }

                        </div>
                        <div>
                            <div className="d-flex align-items-center pt-2">
                                <Image src={LocationIcon} width={15} />
                                <div className="add-start" >
                                    <span className="mob-ipad-d-none">Location:</span>
                                    <span className="add-avalue">{jobDetails?.data?.data?.job?.location?.location}</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center pt-2">
                                <Image src={JobIcon} width={15} />
                                <div className="add-start">
                                    <span className="mob-ipad-d-none">Job Reference:</span>
                                    <span className="add-avalue">{jobDetails?.data?.data?.job?.referenceNo}</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center pt-2">
                                <Image src={userIcon} width={15} />
                                <div className="add-start" > <span className="mob-ipad-d-none">Job type:</span> <span className="add-avalue">{jobDetails?.data?.data?.job?.jobType}</span></div>
                            </div>
                            <div className="d-flex align-items-center pt-2">
                                <Image src={Category} width={15} />
                                <div className="add-start"><span className="mob-ipad-d-none">Category:</span> <span className="add-avalue">{jobDetails?.data?.data?.job?.category}</span></div>
                            </div>
                            {/* <div className="d-flex align-items-center pt-2">
                                <Image src={Price} width={15} />
                                <div className="add-start"> Salary:<span className="add-avalue">$80,000 + Super + Bonus</span></div>
                            </div> */}

                            <div className="d-flex align-items-center pt-2">
                                <Image src={DateIcon} />
                                <div className="add-start">
                                    <span className="mob-ipad-d-none">Posted On: </span>
                                    <span className="add-avalue">{publishingDate(jobDetails?.data?.data?.job?.postedAt)}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                ""
            }
        </>
    );
};

export default JobPreView;