import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { JobAlertErrors } from "../../../config/labels/errorLabel";
import { customEmail, keywordRuleset } from "../../../config/regex/regexPattern";
import TextError from "../../../component/formikComponent/TextError";
import SingleSelect from "../../../component/singleSelect/SingleSelect";
import { useDispatch, useSelector } from "react-redux";
import { getFrequencyList, getSearchData, submitJobAlertFrequency } from "../../../redux/slices/careersSlices.reducer";
import ChipInput from "../../../component/chipinput/ChipInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { careersMessages } from "../../../config/careers/careers";
import Image from "../../../component/image/Image";
import zealLogo from "../../../assets/images/clients/zeal-logo.png";
import { clientIds } from "../../../config/clients/clients";

const JobAlerts = (props: any) => {
    
    //Global states
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settingsLoaded = useSelector((state: any) => state.careers.settingsLoaded);
    const clientConfig = useSelector((state: any) => state.careers.cliendConfig);
    const allSearchData = useSelector((state: any) => state.careers.allSearchData);
    const frequencyList = useSelector((state: any) => state.careers.frequencyList);
    const jobAlertFrequencySubmission = useSelector((state: any) => state.careers.jobAlertFrequencySubmission);
    //Local states
    const [searchData, setSearchData] = useState({jobCategories: null});
    const [frequencyData, setFrequencyData] = useState();
    const [apiLoad, setApiLoad] = useState(false);
    const [chips, setChips] = useState<string[]>([]);
    const [additionalStates, setAdditionalStates] = useState({
        chipsDirty: false
    });
    const formRef = useRef<any>([]);

    const [jobAlertForm, setjobAlertForm] = useState({
        jobAlertEmail: "",
        jobAlertCategory: null,
        jobAlertFrequency: null,
        jobAlertKeywords: "" 
    });

    const validateSchema = () => {
        return Yup.object().shape({
                    jobAlertEmail: Yup.string()
                        .required(JobAlertErrors.email_required)
                        .matches(customEmail, JobAlertErrors.email_invalid),
                    jobAlertCategory: Yup.string()
                        .required(JobAlertErrors.alert_category_required),
                    jobAlertFrequency: Yup.string()  
                        .required(JobAlertErrors.alertfrequency_required),
                    jobAlertKeywords: Yup.string()
                        .required(JobAlertErrors.keyword_minimum)
                });
    };

    //Ensures preloaded api data is present or done loading
    useEffect(() => {
        if(settingsLoaded === true) {
            setApiLoad(true);
            //this class is already set in careers header, this is a backup just in case, can be removed
            document.body.classList.add("job-alerts");
        } else {
            setApiLoad(false);
        }
    }, [settingsLoaded]);
    
    //Primary API caller for the form
    useEffect(() => {
        if(apiLoad === true) {
            dispatch<any>(getSearchData(clientConfig?.data?.data?.clientSettings?.clientId));
            dispatch<any>(getFrequencyList());
        }
    }, [apiLoad]);

    //Api data format/reduction
    useEffect(() => {
        if (allSearchData?.status === 200) {
            if (allSearchData.data.statusCode === 200) {
                setSearchData((dataNode) => {
                    return {
                        ...dataNode,
                        jobCategories: allSearchData?.data?.data?.jobCategories.map((ress: any) => {
                            return {
                                ...ress,
                                value: ress.jobCategoryId,
                                label: ress.categoryName
                            };
                        })
                    };
                });
            }
        }
        if (frequencyList?.status === 200) {
            if (frequencyList.data.statusCode === 200) {
                let freqList = frequencyList?.data?.data.map ((freq: any) => {
                    return {
                        ...freq,
                        value: freq.JobAlertFrequencyId,
                        label: freq.FrequencyName
                    };
                });
                setFrequencyData(freqList);
            }
        }
    }, [allSearchData, frequencyList]);

    //Use for validation purpose
    useEffect(() => {
         
        if(additionalStates.chipsDirty && chips.length === 0) { 
            formRef.current.setFieldValue("jobAlertKeywords","");                 
        }
        else if(chips.length > 0) {
            setAdditionalStates({chipsDirty:true});
            formRef.current.setFieldValue("jobAlertKeywords", chips.join());
        }
    }, [chips]);

    //Post submission process
    useEffect(() => {
        //added to prevent on init run
        if(formRef.current.dirty) {
            if (jobAlertFrequencySubmission?.status === 200) {
            if (jobAlertFrequencySubmission?.data?.statusCode === 200) {
                toast(jobAlertFrequencySubmission?.data?.data?.result, {
                type: "success",
                autoClose: 5000,
                });
            } else if (jobAlertFrequencySubmission?.data?.statusCode === 400) {
                toast(jobAlertFrequencySubmission?.data?.data?.error?.error, {
                type: "error",
                autoClose: 60000,
                });
            }
            } else if (jobAlertFrequencySubmission?.status === 400) {
            toast(careersMessages.apiErrorMessage, {
                type: "error",
                autoClose: 60000,
            });
            }
        }
      }, [jobAlertFrequencySubmission]);
    
    const submitJobAlert = (formData:any, submitingState:any) => {
        formData.clientId = clientConfig?.data?.data?.clientSettings?.clientId;
        console.log(formData);
        dispatch<any>(submitJobAlertFrequency(formData));
    };

    const redirectListing = () => {
        navigate("/");
    };

    return (
        <>
            <div className= "container-fluid subheader-3">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-12 col-sm-12  ">
                  <div className="d-none d-sm-none d-md-block">
                    <p className="page-heading mb-0 page-heading-img">
                      {
                        (clientIds.hvtc === clientConfig?.data?.data?.clientSettings?.clientId) ?
                        <>
                          <button onClick={redirectListing}>
                            <Image src={zealLogo} alt="Zeal Logo" />
                          </button>
                        </>
                        : ""
                      }
                      Job Application
                    </p>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <Formik
                initialValues={jobAlertForm}
                validationSchema={validateSchema}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                    submitJobAlert(values, setSubmitting);
                }}
                innerRef={(f) => (formRef.current = f)}
            >
                {formik => (
                    <form onSubmit={formik.handleSubmit}> 
                    <div className="job-alerts-container">
                        <div className="mb-md-5 mb-3">
                            <div className="common-cont">Fill in the below form to sign up for job Alert </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row mb-md-3 g-md-3 g-sm-0 g-0">
                                    <div className="col-12 col-md-4 col-sm-12 ">
                                        <label htmlFor="jobAlertEmail" className="form-label mt-2">Email
                                            <span className="star">*</span>
                                        </label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-7">
                                        <input id="jobAlertEmail" className="form-control" type="email" {...formik.getFieldProps("jobAlertEmail")} />
                                        <ErrorMessage
                                            name={"jobAlertEmail"}
                                            component={TextError}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-md-3 g-md-3 g-sm-0 g-0">
                                    <div className="col-12 col-md-4 col-sm-12 ">
                                        <label id="jobAlertKeywordslabel" className="form-label mt-2">Keywords  <span className="star">*</span></label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-7">
                                        
                                        <ChipInput 
                                            className="form-control"
                                            chips={chips}
                                            setChips={setChips}
                                            regExValidator={{rule :keywordRuleset, errMessage: "Keywords can only be Letters, Numbers or contain .#!@$ special characters"}}
                                            required={true}
                                            requiredError={JobAlertErrors.keyword_minimum}
                                            formRef={formik}
                                            placeholder={"Press enter to add keywords"}
                                        />
                                        <ErrorMessage
                                            name={"jobAlertKeywords"}
                                            component={TextError}
                                        />
                                        <div className="notes-cont">Separate keywords by clicking enter</div>
                                    </div>                                    
                                </div>                                
                            </div>
                            <div className="col-lg-6">
                                <div className="row mb-md-3 g-md-3 g-sm-0 g-0">
                                    <div className="col-12 col-md-4 col-sm-12 ">
                                        <label id="jobAlertFrequencylabel" className="form-label mt-2">Alert Freqency  <span className="star">*</span></label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-7">
                                        <SingleSelect
                                            className="form-control-select form-control"
                                            placeholder="Select Frequency"
                                            onChange={(e: any) => {
                                                formik.setFieldValue("jobAlertFrequency", e.value);
                                            }}
                                            options={frequencyData}
                                            isSearchable={false}
                                        />
                                        <ErrorMessage
                                            name={"jobAlertFrequency"}
                                            component={TextError}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-md-3 g-md-3 g-sm-0 g-0">
                                    <div className="col-12 col-md-4 col-sm-12 ">
                                        <label id="jobAlertCategorylabel" className="form-label mt-2">Alert Category  <span className="star">*</span></label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-7">
                                        <SingleSelect
                                            className="form-control-select form-control"
                                            placeholder="Select Category"
                                            onChange={(e: any) => {
                                                formik.setFieldValue("jobAlertCategory", e.value);
                                            }}
                                            options={searchData.jobCategories}
                                            isSearchable={false}
                                        />
                                        <ErrorMessage
                                            name={"jobAlertCategory"}
                                            component={TextError}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col-md-12">
                                <div className="btn-section d-flex flex-wrap align-items-center justify-content-md-end  justify-content-center gap-3 mt-3">
                                    <button className="btn btn-primary completion-btn-secondary">
                                    <i className="pi pi-arrow-left me-1"></i> Back To Job Listings
                                    </button>
                                    <button className="btn btn-primary completion-btn-primary" type="submit">
                                        <i className="pi pi-check pi-check-round-white me-1"></i> Confirm & Submit
                                    </button>
                                </div>
                            </div>
                        </div>        


                    </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default JobAlerts;
