import React, { useEffect } from "react";
import Image from "../../component/image/Image";
import Vector from "../../assets/images/Vector.png";
import Button from "../../component/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateData, loderControl, loderOffControl } from "../../redux/slices/newRoleSlices";
import DonloadIcon from "../../assets/images/donload_icon.png";
import Loader from "../../component/loader/Loader";
import { useNavigate } from "react-router-dom";
import { domainWithHttp } from "../../config/onboarding/onBoarding";


const OnboardingResult = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const candidateData = useSelector((state: any) => { return state?.newRole?.candidateData; });
    // console.log(candidateData);
    const loader = useSelector((state: any) => { return state?.newRole?.loading; });


    useEffect(() => {
        // 
        if (candidateData?.statusCode === 200) {

            if (candidateData.currentPage === "-1" || candidateData.currentPage === -1) {
                dispatch(loderControl());
                navigate(`/getCandidate/${sessionStorage.getItem("clientId")}`);
            } else if (candidateData?.currentPage === "-2" || candidateData?.currentPage === -2) {
                // setTimeout(() => {
                    // dispatch(loderOffControl());
                // }, 5000);
            } else {
                dispatch(loderControl());
                navigate("/getCandidate/onboarding");
            }

            // if (candidateData === null) {
            //     dispatch<any>(loderControl());
            // }
            // if (candidateData?.statusCode !== 200 || (candidateData?.statusCode === 200 && (candidateData?.currentPag?.toString() !== "-2"))) {
            //     navigate("*");
            // }
            // if (candidateData === null && candidateData?.statusCode && candidateData?.statusCode !== 200) {
            //     navigate("*");
            // } else
            // if (candidateData?.statusCode === 200) {
            //     if (candidateData?.currentPag && candidateData?.currentPage?.toString() !== "-2" || candidateData?.currentPage !== -2) {
            //         console.log(235);

            //         navigate("*");
            //     }
            // } 
            // else if (candidateData?.statusCode !== 200) {
            //     navigate("*");
            // }

            // else if (candidateData === null || candidateData?.statusCode !== 200) {
            //     navigate("*");
            // } else {

            // }
        } else if (candidateData === null || candidateData?.statusCode === 400) {
            //   setTimeout(() => {
            navigate("*");
            //   }, 3000);
        }

    }, [candidateData]);

    useEffect(() => {
        const obj:any ={};
        obj["clientId"] = sessionStorage.getItem("clientId");
        obj["domainWithHttp"] = domainWithHttp;
        dispatch<any>(
          getCandidateData(obj)
          
        );
    }, []);

    const base64StringToBlob = (base64String: any) => {
        const byteCharacters = atob(base64String);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: "application/octet-stream" });
    };
    const downloadAllFile = () => {
        const blob = base64StringToBlob(candidateData.zip);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${localStorage.getItem("clindFirstName") ? localStorage.getItem("clindFirstName") : candidateData?.candidateDetails?.name}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };
    const downloadFile = (bash64: any) => {
        const base64String = bash64.url;
        const blob = base64StringToBlob(base64String);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${bash64.formName}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };
    return (
        <>
            <div>
                <Loader loading={loader} />
            </div>
            <div className="container">
                <div className="row align-items-center" style={{ height: "75vh" }}>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">
                        <div className="d-flex">
                            <Image src={candidateData?.clientDetails?.logo} clasName="img-fluid" width={300} />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">
                        <div className="mt-3">
                            <p className="text-primary welcome-text">Thank you {localStorage.getItem("clindFirstName") ? localStorage.getItem("clindFirstName") : candidateData?.candidateDetails?.name}.</p>
                            <p className="welcome-one">Your information has been saved successfully. </p>
                            <p className="welcome-one">  You can now close this window.</p>

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="welcome-result">
                                            Your {candidateData?.data?.length} Documents
                                        </div>
                                        <div>
                                            <Button label={
                                                <>
                                                    <div className="d-flex">
                                                        <div><Image src={DonloadIcon} /></div>
                                                        <div className="ms-1">Download All</div>
                                                    </div>
                                                </>
                                            } onClick={() => downloadAllFile()} clasName="btn btn-primary" />
                                        </div>
                                    </div>
                                </li>
                                {candidateData && candidateData?.data?.length > 0 && candidateData?.data?.map((ress: any) => {
                                    return (
                                        <li key={ress.clientId} className="list-group-item px-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="welcome-result welcome-result-1">
                                                    {ress.formName}
                                                </div>
                                                <div>
                                                    <Button onClick={() => downloadFile(ress)} label={
                                                        <>
                                                            <div className="d-flex">
                                                                <div><Image src={DonloadIcon} /></div>
                                                                <div className="ms-1">Download</div>
                                                            </div>
                                                        </>
                                                    } clasName="btn btn-primary" />
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                                {/* <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="welcome-result">
                                            Your 4 Documents
                                        </div>
                                        <div>
                                            <Button onClick={() => downloadAllFile()} label="Download All" clasName="btn btn-primary" />
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            ATO Tax File Declaration
                                        </div>
                                        <div>
                                            <Button label="Download All" clasName="btn btn-primary" />
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            Personal Details
                                        </div>
                                        <div>
                                            <Button label="Download All" clasName="btn btn-primary" />
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            COVID-19 Vaccination Screening
                                        </div>
                                        <div>
                                            <Button label="Download All" clasName="btn btn-primary" />
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item px-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            Privacy Declaration
                                        </div>
                                        <div>
                                            <Button label="Download All" clasName="btn btn-primary" />
                                        </div>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div >


        </>
    );
};

export default OnboardingResult;