import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import TextError from "./TextError";
import Input from "./Input";
import { childDisplay, valudatesetformstatus } from "../../pages/onboarding/Onboarding";
import { oldFile } from "../../config/onboarding/onBoarding";
const RadioButton = (props: any) => {
    const { name, label, option, className, allData, onChange, formik, ...rest } = props;

    const [controlChildValue, setControlChildValue] = useState({
        data: false,
        value: "Yes"
    });

    const fieldValidation = (value: any) => {
        let error;
        if (allData.validate) {
            if (allData.type === "radio-group") {
                allData?.validations.forEach((element: any) => {
                    if (allData.type === "radio-group") {
                        if (!value && valudatesetformstatus !== "draft") {
                            if (element.type ==="required") {
                                error = element.params[0];
                            }
                        }
                    }
                });
            }
        }
        return error;
    };
    const checkChildField = (e: any) => {



    };

    return (
        <>
            <Field name={name} validate={fieldValidation}  {...rest}>
                {
                    ({ field }: any) => {

                        if (formik.dirty) {
                            // console.log(formik.dirty);
                            // console.log(field,childDisplay);
                            // for (let i = 0; i < childDisplay.visibleArray.length; i++) {
                            // const element = childDisplay.visibleArray[i];
                            
                            // if (childDisplay.parentName === field?.name && controlChildValue?.data) {
                            //    console.log(field?.value);
                               
                            //     setControlChildValue((prs: any) => {
                            //         return {
                            //             ...prs,
                            //             data: false,
                            //             value: field?.value === "Yes" ? "No" : "Yes"
                            //         };
                            //     });
                            //     const getDataRemoveId = childDisplay?.visibleArray.filter((ress: any) => ress.parent_validate_value !== field?.value);
                            //     console.log(getDataRemoveId[0]?.child_display_id);
                            //     // if (controlChildValue) {
                            //     formik.setFieldValue(getDataRemoveId[0]?.child_display_id, []);
                            //     console.log(formik?.values[getDataRemoveId[0]?.child_display_id]);
                            //     oldFile[getDataRemoveId[0]?.child_display_id] = [];

                                //     setControlChildValue(false);
                                // }
                            // }

                            // }


                        }



                        // if (Object.keys(childDisplay)?.length > 0) {
                        //     if (field.name === childDisplay.parentName) {
                        //     // console.log(childDisplay);

                        //     }

                        // }
                        //     console.log(childDisplay);
                        //     const checkFieldId = [];
                        //     for (let i = 0; i < childDisplay?.child_display.length; i++) {
                        //         const element = childDisplay?.child_display[i];
                        //         console.log(element);
                        //         if (element?.parent_validate_value === "Yes" && field.value === "Yes") {
                        //             // element.hideName = element?.child_display_id;
                        //             childDisplay.hideName = [element?.child_display_id];
                        //         } else if (element?.parent_validate_value === "No" && field.value === "Yes") {
                        //             childDisplay.hideName = [element?.child_display_id];
                        //         } else {
                        //             checkFieldId.push(element?.child_display_id);
                        //             childDisplay.hideName = checkFieldId;

                        //         }
                        //         console.log(childDisplay);

                        //     }


                        // for (let i = 0; i < childDisplay.length; i++) {
                        //     const element = childDisplay[i];
                        //     console.log(element);

                        //     // if (field?.name === element?.parentName) {
                        //     //     console.log(element);


                        //     //     // element.hideName = [];
                        //     //     // for (let k = 0; k < element.child_display.length; k++) {
                        //     //     //     const element_1 = element.child_display[k];
                        //     //     //     // console.log(element_1);
                        //     //     //     if (field?.value && field?.value !== element_1?.parent_validate_value) {
                        //     //     //         // const aaa = element?.hideName?.push(element_1?.child_display_id)
                        //     //     //         // console.log("if", element_1?.child_display_id);

                        //     //     //         element.hideName = [`${element_1?.child_display_id}`];
                        //     //     //         console.log(element.hideName);

                        //     //     //         // formik.setFieldValue(element.hideName[0], []);
                        //     //     //         // oldFile[element.hideName[0]] = [];

                        //     //     //     }
                        //     //     //     else {

                        //     //     //         // console.log("elese", element_1?.child_display_id);

                        //     //     //         // if (field?.value && formik?.values[element_1?.child_display_id]?.length > 0) {
                        //     //     //         //     console.log(element_1?.child_display_id);

                        //     //     //         //     formik.setFieldValue(element_1?.child_display_id, []);
                        //     //     //         // }
                        //     //     //         // if (field?.value && field?.value === element_1?.parent_validate_value) {
                        //     //     //         // formik.setFieldValue(element_1?.child_display_id, []);
                        //     //     //         // }
                        //     //     //     }
                        //     //     // }
                        //     // }

                        // }


                        // childDisplay?.forEach((element: any) => {
                        // console.log(element);
                        // element.hideName = [];

                        //     element?.child_display?.forEach((data: any) => {
                        //         // element.hideName.push(data?.child_display_id);
                        //         // removeDuplicates(element.hideName);
                        //         // console.log(data,field);
                        //         // console.log(data?.parent_validate_value, field?.value);
                        //         // console.log(element.hideName);

                        //         // if ((field?.value === "Yes" || field?.value === "yes") && (data?.parent_validate_value === "Yes" || data?.parent_validate_value === "yes")) {
                        //         //     element?.hideName?.splice(data?.child_display_id, 1);
                        //         //     // console.log(element?.hideName);

                        //         // } else if ((field?.value === "No" || field?.value === "no") && (data?.parent_validate_value === "No" || data?.parent_validate_value === "no")) {
                        //         //     element?.hideName?.splice(data?.child_display_id, 1);
                        //         // } else {
                        //         //     if (field.value) {
                        //         //         // console.log(element.hideName);
                        //         //         // 
                        //         //         element.hideName.push(data?.child_display_id);
                        //         //     }
                        //         // }
                        //         //  console.log(element.hideName);
                        //     });

                        // });
                        // if (field.name === childDisplay[0]?.parentName) {
                        //     console.log(436);


                        // }

                        // }
                        return option.map((ress: any) => {
                            return (
                                <>
                                    <div key={ress.value} className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id={ress.value}
                                            {...field}
                                            value={ress.value}
                                            checked={field.value === ress.value}
                                        />
                                        <label className="form-check-label" htmlFor={ress.value} dangerouslySetInnerHTML={{
                                                  __html: ress.label
                                                }}></label>
                                    </div>
                                </>
                            );

                        });
                    }
                }

            </Field>
            <ErrorMessage name={name} component={TextError} />

        </>
    );
};

export default RadioButton;