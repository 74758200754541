import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Select, { StylesConfig } from "react-select";


export default function SingleSelect(props: any) {
    const { onInputChange, className, options, defaultValue, isClearable, value, placeholder, onChange, isSearchable, isDisabled } = props;
    const cliendConfig = useSelector((state: any) => state.careers.cliendConfig);
    const [prColor, setPrColor] = useState("");

    useEffect(() => {
        if (cliendConfig.status === 200) {
            const dataress = cliendConfig?.data?.data?.clientSettings;
            setPrColor(dataress?.secondaryColor);
            // document.documentElement.style.setProperty("--theam-secondary-color", dataress?.secondaryColor);
        }


    }, [cliendConfig]);
    const selectValue = (s: any) => {
        onChange(s);
    };
    const colourStyles: StylesConfig<any> = {
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? prColor
                : isSelected
                    ? prColor
                    : undefined,
            zIndex: 1,
            padding: 4,
            fontSize: 14,
            color: isFocused
                ? "#FBFBFB"
                : isSelected
                    ? "#FBFBFB"
                    : undefined,

        }),
        // control: (styles) => (console.log(styles),
        // { ...styles, backgroundColor: "white" }),
        // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        //   return {
        // ...styles,
        // backgroundColor: isDisabled
        //   ? undefined
        //   : isSelected
        //   ? data.color
        //   : isFocused
        //   ? color.alpha(0.1).css()
        //   : undefined,
        // color: isDisabled
        //   ? "#ccc"
        //   : isSelected
        //   ? chroma.contrast(color, "white") > 2
        //     ? "white"
        //     : "black"
        //   : data.color,
        // cursor: isDisabled ? "not-allowed" : "default",

        //     ":active": {
        //       ...styles[":active"],
        //       backgroundColor: !isDisabled
        //         ? isSelected
        //           ? "red"
        //           : ""
        //         : undefined,
        //     },
        //   };
        // },
        // input: (styles) => ({ ...styles, ...dot() }),
        // placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
        // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };
    return (
        <Select
            className={className}
            onChange={(s: any) => selectValue(s)}
            defaultValue={defaultValue}
            // isMulti
            // name=""
            isClearable={isClearable}
            options={options}
            value={value}
            // classNamePrefix={""}
            placeholder={placeholder}
            styles={colourStyles}
            isSearchable={isSearchable}
            onInputChange={onInputChange}
            isDisabled={isDisabled}
        />
    );
}