import React, { useRef, useEffect, useState } from "react";
import Button from "../../../../component/button/Button";
import Label from "../../../../component/label/Label";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage, useFormikContext, FieldArray  } from "formik";
import {
  useLocation,
  useNavigate, useParams
} from "react-router-dom";
import { Modal, Tooltip } from "react-bootstrap";
import Loader from "../../../../component/loader/Loader";
import Image from "../../../../component/image/Image";
import DateIcon from "../../../../assets/images/date-icon.svg";
import DeleteIcon from "../../../../assets/images/delete-icon.svg";
import JobIcon from "../../../../assets/images/jobIcon.png";
import "../../../../styles/careers/careers.scss";
import JobPreView from "./JobPreView";
import ResumeIcon from "../../../../assets/images/Resume.png";
import CloseIcon from "../../../../assets/images/close_icon.png";
import TextError from "../../../../component/formikComponent/TextError";
import * as Yup from "yup";
import { jopApplicationError, defaultFieldError } from "../../../../config/labels/errorLabel";
import {
  FileSize,
  characters,
  format,
  linkedinProfileUrl,
  pinFormat,
  supportFormat,
  customEmail,
} from "../../../../config/regex/regexPattern";
import JobView from "../jobView/JobView";
import {
  careersContryList,
  careersStateList,
  getJobDetails,
  getQuestionList,
  removSubmiutJob,
  submitJob,
} from "../../../../redux/slices/careersSlices.reducer";
import { publishingDate } from "../CareersFucConfig";
import LinkedInIconJob from "../../../../assets/images/LinkedIn-Icon-job.png";
import QuestionsOpenIcon from "../../../../assets/images/questions-open.png";
import QuestionsCloseIcon from "../../../../assets/images/questions-close.png";
import SingleSelect from "../../../../component/singleSelect/SingleSelect";
import Model from "../../../../component/modal/Modal";
import LetterIcon from "../../../../assets/images/Letter.png";
import CareersFooter from "../../CareersFooter/CareersFooter";
import { apiPost } from "../../../../service/apiService";
import { endPoint } from "../../../../config/endPoint";
import { loginData } from "../../../../config/careers/careers";
import { validateImgUrl, verifyImageUrl } from "../../../../config/globalEvent";
import { clientIds } from "../../../../config/clients/clients";
import ArrowLeft from "../../../../assets/images/clients/arrow-left.svg";
import zealLogo from "../../../../assets/images/clients/zeal-logo.png";


const timeLineInData = [
  {
    index: 0,
    label: "Details",
    currentPage: true,
    currentPageStyle: "current-page",
    completedPage: false,
    prsMoveColor: "",
    prsActive: false,
    prsMoveColorBorder: "",
    prsComColorBorder: "",
    journeyLine: false
  },
  {
    index: 1,
    label: "Documentation",
    currentPage: false,
    currentPageStyle: "",
    completedPage: false,
    prsMoveColor: "",
    prsActive: false,
    prsMoveColorBorder: "",
    prsComColorBorder: "",
    journeyLine: false
  },
  {
    index: 2,
    label: "Questions",
    currentPage: false,
    currentPageStyle: "",
    completedPage: false,
    prsMoveColor: "",
    prsActive: false,
    prsMoveColorBorder: "",
    prsComColorBorder: "",
    journeyLine: false
  },
];



const JobApplay = (props: any) => {
  const { jobFilterData } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = useParams();
  const jobDetails = useSelector((state: any) => state.careers.jobDetails);
  const submitJobRess = useSelector((state: any) => state.careers.submitJob);
  const countryList = useSelector((state: any) => state.careers.countryList);
  const stateList = useSelector((state: any) => state.careers.stateList);
  const formRef = useRef<any>([]);
  const questionId = useSelector((stete: any) => {
    return stete?.careers?.getQuestionId;
  });
  const clientConfig = useSelector((state: any) => state.careers.cliendConfig);
  // const queryParams = new URLSearchParams(location.search);
  // console.log(queryParams);
  const [jobApplyForm, setJobApplyForm] = useState({
    title: "",
    firstName: "",
    lastName: "",
    preferredName: "",
    phone: "",
    emailAddress: "",
    address_1: "",
    address_2: "",
    suburb_city: "",
    state: "",
    post_code: "",
    country: "",
    interestprimarycategory: "",
    interestsubcategories: "",
    resume: null,
    coverLetter: null,
    linkedinProfile: "",
    addDoc: null,
    tAndC: false,
    categoryList: []
  });
  const [jobPreViewSection, setJobPreViewSection] = useState(false);
  const observedDiv: any = useRef(null);
  const submitButtonRef: any = useRef(null);
  const dynamikFormDataRef: any = useRef(null);
  const sideBarWidth: any = useRef(null);
  const footerheight: any = useRef(null);
  const contactDetails: any = useRef(null);
  const jobDataDiv: any = useRef(null);
  const documentationDiv: any = useRef(null);
  const overAllSection: any = useRef(null);
  const residentialAddress: any = useRef(null);
  const [isCustomFormInvalid, setCustomFormValidity]: any = useState<boolean>(true);
  const [customFormRequiredFields, setCustomFormRequiredFields] = useState<any>([]);
  const [customFormRequiredReference, setCustomFormRequiredReference] = useState<any>([]);
  const [questionOpenAndClose, setQuestionOpenAndClose] = useState(true);
  const [country, setCountry] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [jobModel, setJobModel] = useState(false);
  const [viewJobControl, setViewJobControl] = useState<boolean>(true);
  const [careersTotalBody, setCareersTotalBody] = useState(0);
  const [timeListData, setTimeLineData] = useState(timeLineInData);
  const [profileQuestion, setProfileQuestion] = useState<any>({});
  const [selectedCategoryDropdown, setSelectedCategoryDropdown] = useState<any>([{}]);
  const [currentSelectedCategoryPair, setCurrentSelectedCategoryPair] = useState<any>({});
  const [currentSubCategory, setCurrentSubcategory] = useState({selected:""});
  const [addedCategoryReferenceList, setCategoryReferenceList] = useState<any>([]);
  const [serverFormKeys, setServerFormKeys] = useState<string[]>([]);
  const [showLink, setShowLink] = useState(false);
  const [subHeaderStyle, setSubHeaderStyle] = useState("");
  const [jobBodyStyle, setJobBodyStyle] = useState("job-body");
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [qaShowHide, setQaShowHide] = useState<string>("  ");
  const FormDataDiv: any = useRef(null);
  const routeParams = useParams();

  const redirectListing = () => {
    navigate("/");
  };

  useEffect(() => {
    if(clientId?.from !== "direct"){
      setShowLink(!false);
      // setSubHeaderStyle("subheader-3-new");
      // setJobBodyStyle("job-body-new");
    }
  }, [location.pathname]);

  useEffect(() => {
    if(clientIds?.hvtc === clientConfig?.data?.data?.clientSettings?.clientId && clientId?.from !== "direct"){
      setShowSearchFilter(!false);
      setJobBodyStyle("job-body-search-new");
    }
  }, [location.pathname, clientConfig]);

  useEffect(() => {
     //if(location.pathname === `/careers/job-list/${clientId?.userId}/view/${clientId?.id}/apply-job/register/from/${routeParams?.from}`){
        dispatch<any>(getQuestionList());
        dispatch<any>(getJobDetails(clientId?.id));
     //}
  }, [location.pathname, clientId?.id]);

  // useEffect(() => {
  // //   const getToken = async () => {
  // //     const ress = await apiPost(endPoint.candidateToken, loginData);
  // //     if (ress.status === 200) {
  // //       // console.log(ress?.data?.data);
  // //       sessionStorage.setItem("Token", ress?.data?.data?.token);
  //       // dispatch<any>(getQuestionList());
  //       dispatch<any>(getJobDetails(clientId?.id));
  // //     }
  // //   };
  // //   getToken();
  // }, [clientId?.id]);

  // useEffect(() => {
  //   if (questionId?.status === 200) {
  //     console.log(111,questionId);
  //     dispatch<any>(getJobDetails(questionId?.data?.data));
  //   }
  // }, [questionId]);


  useEffect(() => {
    // if (
    //   location.pathname === `/careers/job-list/${clientId?.userId}/apply-job`
    // ) {
    //   setViewJobControl(false);
    // }
    dispatch<any>(careersContryList());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (stateList.status === 200) {
      const stateListData = stateList?.data?.data?.map((ress: any) => {
        return {
          ...ress,
          label: ress?.name,
          value: ress?.code,
        };
      });
      setStates(stateListData);
    }
  }, [stateList]);
  useEffect(() => {
    if (countryList.status === 200) {
      const countryListData = countryList?.data?.data?.map((ress: any) => {
        return {
          ...ress,
          label: ress.name,
          value: ress.countryId,
        };
      });
      setCountry(countryListData);
    }
  }, [countryList]);

  useEffect(() => {
    if (jobDetails.status === 200) {
      const jobQuestions = { ...jobDetails?.data?.data?.job };
      
      if (jobQuestions?.generalJobApp === 1) {
        setViewJobControl(false);
      } 
      else {
        setViewJobControl(!false);
      }

      jobQuestions?.questions?.forEach((element: any) => {
        if (element?.required === 1) {
          setServerFormKeys((prs) => [...prs, element.extId]);
          if(customFormRequiredFields.length <= 0) {
            setCustomFormRequiredFields((fields:any) => [...fields, element.extId.toString()]);
            setCustomFormRequiredReference((fields:any) => [...fields, 
              {
                fieldId : element.extId.toString(),
                label : element.text
              }
            ]);
          }
        }

        if (element.type === "multiple") {
          setJobApplyForm((prs) => {
            return {
              ...prs,
              [element.extId]: [],
            };
          });
        } else {
          setJobApplyForm((prs) => {
            return {
              ...prs,
              [element.extId]: "",
            };
          });
        }
      });

      const profileQuestion: any = { ...jobDetails?.data?.data?.job?.profile };

      if (
        jobQuestions?.questions?.length === undefined &&
        Object.keys(profileQuestion)?.length === 0
      ) {
        const updatedtimeListData = timeListData.filter((timeList) => { 
          return timeList.index !== 2; 
        });
        setTimeLineData(updatedtimeListData);
      }
      if (Object.keys(profileQuestion)?.length) {
        const newProfileQuestion = [];
        const obj: any = {};

        Object.keys(profileQuestion)?.forEach((ress) => {
          const subKey = Object.keys(profileQuestion[ress]);
          const subKeyArray: any = [];
          if (profileQuestion[ress]?.attributes?.type === "multiselect") {
            setJobApplyForm((prs) => {
              return {
                ...prs,
                [ress]: [],
              };
            });
          } else if (
            profileQuestion[ress]?.attributes?.type === "singleselect"
          ) {
            setJobApplyForm((prs) => {
              return {
                ...prs,
                [ress]: "",
              };
            });
          }

          if (profileQuestion[ress]?.attributes?.required) {
            setServerFormKeys((prs) => [...prs, ress]);
            if(customFormRequiredFields.length <= 0) {
              setCustomFormRequiredFields((fields:any) => [...fields, ress]);
            }
          }

          // console.log(profileQuestion[ress][subKey[2]]);
          profileQuestion[ress][subKey[2]].forEach((data: any) => {
            // console.log(data);
            let subkeyArrData = {
              label: data.value,
              value: data.attributes.id,
              id: data.attributes.id + "_" + ress?.toString(),
              depdropdown: data.subcategories || null
            };
            subKeyArray.push(subkeyArrData);
          });
          obj[ress] = {
            attributes: profileQuestion[ress]["attributes"],
            option: subKeyArray,
            label: profileQuestion[ress]["label"],
            fieldId: ress,
          };
        });
        //Adding profile questions to error summary
        Object.keys(obj)?.forEach((ress:any) => {
          if(obj[ress]?.attributes?.required) {
            setCustomFormRequiredReference((fields:any) => [...fields, 
              {
                fieldId : obj[ress]?.fieldId,
                label : obj[ress]?.label
              }
            ]);
          }
        });
        setProfileQuestion(obj);        
      }
    }
  }, [jobDetails]);
  useEffect(() => {
    if (submitJobRess.status === 200) {
      if (submitJobRess?.data?.statusCode === 200) {
        toast(submitJobRess?.data?.data?.result, {
          type: "success",
          autoClose: 5000,
        });
        dispatch<any>(removSubmiutJob());
        setTimeout(() => {
          navigate("/");
        }, 5000);
      } else if (submitJobRess?.data?.statusCode === 400) {
        toast(submitJobRess?.data?.data?.error?.error, {
          type: "error",
          autoClose: 60000,
        });
        dispatch<any>(removSubmiutJob());
      }
    } else if (submitJobRess.status === 400) {
      //const keys = Object.keys(submitJobRess?.data?.data?.errors);
      const errorMessage = `We are currently experiencing technical difficulties with our application submission process on our website. Some candidates may encounter issues preventing them from completing their applications successfully. 
      If you are experiencing difficulties in submitting your application, please reach out to us directly at recruitment@zealfutures.com.au. Our team will assist you in ensuring that your application is properly received and processed.`;
      toast(errorMessage, {
        type: "error",
        autoClose: 60000,
      });
      dispatch<any>(removSubmiutJob());
    }
  }, [submitJobRess]);

  const jobPreView = () => {
    // console.log(overAllSection?.current?.offsetHeight);
    // setTimeout((/) => {
    setJobPreViewSection(!jobPreViewSection);

    setTimeout(() => {
      const offsetHeight =
        document.getElementById("careers-body")?.offsetHeight;
      if (offsetHeight) {
        setCareersTotalBody(offsetHeight);
      }
      window.scroll(0, 0);
    }, 500);

    // }, 500);/
  };
  const checkWithFile = (value: any) => {
    // return true;
    if (value !== null) {
      return value !== null &&
        value instanceof File &&
        value["size"] <= FileSize
        ? true
        : false;
    } else {
      return true;
    }
  };
  const checkWithFileFormat = (value: any) => {
    if (value !== null) {
      return value !== null &&
        value instanceof File &&
        supportFormat.includes(value.type)
        ? true
        : false;
    } else {
      return true;
    }
  };
  const getFileExt = (params: any) => {
    if (params instanceof File) {
      // const aaaWithoutExtension:any = params?.name?.toString()?.lastIndexOf(".");
      // console.log(aaaWithoutExtension);
      const parts = params?.name?.split(".");
      const fileExtension = parts[parts.length - 1];
      return fileExtension;

      // return params.name.split(".")[1];
    } else {
      return "";
    }
  };
  const validateSchema = () =>
    Yup.object().shape({
      title: Yup.string().required(jopApplicationError.title_required),
      firstName: Yup.string()
        .required(jopApplicationError.first_name_required)
        .matches(characters, jopApplicationError.first_name_invalid),
      lastName: Yup.string()
        .required(jopApplicationError.last_name_required)
        .matches(characters, jopApplicationError.last_name_invalid),
      // preferredName: Yup.string().required(jopApplicationError.preferred_name_required),
      phone: Yup.string()
        .required(jopApplicationError.mobile_required)
        .matches(format, jopApplicationError.mobile_invalid),
      emailAddress: Yup.string()
        .required(jopApplicationError.email_required)
        .matches(customEmail, jopApplicationError.email_invalid),
      // address_1: Yup.string().required(jopApplicationError.address_1_required),
      // address_2: Yup.string().required(jopApplicationError.address_2_required),
      // suburb_city: Yup.string().required(jopApplicationError.suburb_city_required),
      state: Yup.string().required(jopApplicationError.state_required),
      post_code: Yup.string().matches(
        pinFormat,
        jopApplicationError.post_code_invalid
      ),
      country: Yup.string().required(jopApplicationError.country_required),
      // country: Yup.object().shape({
      //     value: Yup.string().required(jopApplicationError.country_required),
      // }),
      resume: Yup.mixed()
        .required(jopApplicationError.resume_required)
        .test(
          "fileSize",
          jopApplicationError.file_size_invalid,
          (value: any) => value !== null && value["size"] <= FileSize
        )
        .test(
          "fileFormat",
          jopApplicationError.file_invalid,
          // (value: any) => value !== null && supportFormat.includes(value.type)
          (value: any) => value !== null && getFileExt(value) === "mdi" ? supportFormat.includes(getFileExt(value)) : value !== null && supportFormat.includes(value.type)
        ),
      coverLetter: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          jopApplicationError.file_size_invalid,
          (value: any) => {
            return checkWithFile(value);
          }
        )
        .test("fileFormat", jopApplicationError.file_invalid, (value: any) => {
          if(getFileExt(value) === "mdi"){
            return supportFormat.includes(getFileExt(value));
          } else {
            return checkWithFileFormat(value);
          }
        }),
      linkedinProfile: Yup.string().matches(
        linkedinProfileUrl,
        jopApplicationError.linkedin_profile_invalid
      ),
      // linkedinProfile: Yup.mixed()
      //   .nullable()
      //   .test(
      //     "fileSize",
      //     jopApplicationError.file_size_invalid,
      //     (value: any) => {
      //       return checkWithFile(value);
      //     }
      //   )
      //   .test("fileFormat", jopApplicationError.file_invalid, (value: any) => {
      //     return checkWithFileFormat(value);
      //   }),
      addDoc: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          jopApplicationError.file_size_invalid,
          (value: any) => {
            return checkWithFile(value);
          }
        )
        .test("fileFormat", jopApplicationError.file_invalid, (value: any) => {
          if(getFileExt(value) === "mdi"){
            return supportFormat.includes(getFileExt(value));
          } else {
            return checkWithFileFormat(value);
          }
        }),
      categoryList: Yup.array()
        .test("categoryList", "Please select atleast one interest", (value:any) => {
          if(Object.keys(profileQuestion).length > 0 && "categories" in profileQuestion) {
            if(value.length >= 1) {
              return true;
            }
            else {
              return false;
            }
          }
          else {
            return true;
          }
        }),
      tAndC: Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions"),      
    });
  // const removeFile = (name: string, formik: any) => {
  //   const removeData: any = document.getElementById(name);
  //   if (removeData) {
  //     removeData.value = null;
  //     formik?.setFieldValue(name, null);
  //     asyncFileUpdate();
  //   }
  // };
  // const getFileExt = (params: any) => {
  //   if (params instanceof File) {
  //     // const aaaWithoutExtension:any = params?.name?.toString()?.lastIndexOf(".");
  //     // console.log(aaaWithoutExtension);
  //     const parts = params?.name?.split(".");
  //     const fileExtension = parts[parts.length - 1];
  //     return fileExtension;

  //     // return params.name.split(".")[1];
  //   } else {
  //     return "";
  //   }
  // };

  const getFileName = (params: any) => {
    if (params instanceof File) {
      return params.name;
    } else {
      return "";
    }
  };

  const getFileTobash64 = (params: any) => {
    if (params instanceof File) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(params);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    } else {
      return null;
    }
  };

  const formatDependentCategories = (apiData:any) => {
    let formattedData = {...apiData};
    let formattedOptions:any = [];
    formattedData.option?.forEach((apiDataNode:any) => {   
      let apiDataRef:any;   
      //tests for existing parent category present without any subcats selected, to skip the sub cat processing
      if(addedCategoryReferenceList.filter((e:any)=> {
        return apiDataNode.value === e.categoryId && e.subCategoryId === null;
      }).length === 0) {
        //no full parent category selection found, processing sub categories
        let processedSubCats:any = [];
        apiDataNode.depdropdown.subcategory.forEach((subcat:any) => {          
          if(addedCategoryReferenceList.filter((catPair:any) => catPair.subCategoryId === subcat.attributes.id).length > 0){
            processedSubCats.push(subcat);
          }
        });
        //shallow copy to edit the read-only looping iterator, inside the loop
        if(processedSubCats.length > 0) {
          apiDataRef = {...apiDataNode};
          apiDataRef.depdropdown = {          
            attributes : apiDataNode.depdropdown.attributes,
            label : apiDataNode.depdropdown.label,
            subcategory : processedSubCats
          };
          formattedOptions.push(apiDataRef);
        }
      }
      else {
        //full category found, dropping sub categories and pushing the full parent inside the submit data;
        formattedOptions.push(apiDataNode);
      }
    });
    formattedData.option = formattedOptions;
    return formattedData;
  };

  const formatSubmissionJson = (submissionData:any) => {
    let subData:any;
    let catData:any = [];  
    //process category array
    submissionData.option.forEach((opt:any) => {      
      catData.push({
        attributes: {
          id: opt.value
        },
        value : opt.label,
        subCategories : {
          subCategory : opt.depdropdown.subcategory
        }
      });
    });
    subData = {      
      attributes :{
        label : submissionData.label,
        type: submissionData.attributes.type,
        required: submissionData.attributes.required
      },
      category: catData
    };
    return subData;  
  };

  const submitApplayJob = async (params: any) => {    
    const userJobData = jobDetails?.data?.data?.job?.questions?.map(
      (ress: any) => {
        const questions = Object.keys(params).filter(
          (data: any) => data === ress.extId.toString()
        );
        if (params[questions[0]].length > 0) {
          if (Array.isArray(params[questions[0]])) {
            return {
              ...ress,
              answers: ress.answers.filter((data: any) =>
                params[questions[0]].some((rr: string) => rr === data.id)
              ),
            };
          } else {
            if (ress.type === "single") {
              return {
                ...ress,
                answers: ress.answers.filter(
                  (data: any) => data.id === params[questions[0]]
                ),
              };
            } else {
              return {
                ...ress,
                answers: [params[questions[0]]],
              };
            }
          }
        } else {
          return {
            ...ress,
            answers: [],
          };
        }
      }
    );
    // console.log(userJobData);

    // const candidateProfile = jobDetails?.data?.data?.job?.profile?.map((ress: any) => {
    //     console.log(jobDetails?.data?.data?.job?.profile);

    //     return ress;
    // });
    // console.log(jobDetails);
    const candidateProfile: any = {};
    if (jobDetails?.data?.data?.job?.profile !== null) {
      const profileDataUpdata = Object.keys(
        jobDetails?.data?.data?.job?.profile
      );
      
      const profile = { ...jobDetails?.data?.data?.job?.profile };
      const userUpdateParams = Object.keys(params);

      for (let i = 0; i < profileDataUpdata.length; i++) {
        const element = profileDataUpdata[i];

        const subKey1: any = {};
        const subKeys = Object.keys(profile[element]);
        for (let k = 0; k < subKeys.length; k++) {
          const elementSub = subKeys[k];
          if (Array.isArray(profile[element][elementSub])) {
            const lastValue: any[] = [];
            let arrParam = params[element];
            const aa = profile[element][elementSub].map((ress: any) => {               
              if (ress.attributes?.id === params[element]) {
                subKey1[elementSub] = ress;
              } else {
                for (let h = 0; h < arrParam.length; h++) {
                  //Fix added for the initial bug that occured due to expecting all params[element] to be an array, it started iterating over string, this normalizes all incoming as array
                  if(typeof params[element] === "string") {
                    arrParam = [params[element]];
                  }
                  const element5 = arrParam[h];
                  if (ress.attributes?.id === element5?.split("_")[0]) {                    
                    lastValue.push(ress);
                  }
                }
                subKey1[elementSub] = lastValue;
              }

              return ress;
            });
          } else {
            subKey1[elementSub] = profile[element][elementSub];
          }
        }
        candidateProfile[element] = subKey1;
      }
    }
    //interject for adding dependent dropdown data to submission process
    if(Object.keys(profileQuestion)?.length > 0) {
      let preformattedCategories = formatDependentCategories(profileQuestion.categories);
      candidateProfile["categories"] = formatSubmissionJson(preformattedCategories);
    }

    const filterCountryCode = countryList?.data?.data?.filter(
      (ress: any) => ress.countryId.toString() === params.country
    );


    const obj: any = {};
    obj["CandidateProfile"] = Object.keys(candidateProfile)?.length > 0 ? candidateProfile : [];
    obj["title"] = params.title;
    obj["firstName"] = params.firstName;
    obj["lastName"] = params.lastName;
    obj["preferredName"] = params.preferredName;
    obj["email"] = params.emailAddress;
    obj["contactPhone"] = params.phone;
    obj["resumeDocName"] = await getFileName(params.resume);
    obj["resumeDocExt"] = await getFileExt(params.resume);
    obj["resumeBase64"] = await getFileTobash64(params.resume);
    // obj["linkedInProfile"] = await getFileTobash64(params.linkedinProfile);
    obj["linkedInProfile"] = params.linkedinProfile;

    obj["coverDocName"] = await getFileName(params.coverLetter);
    obj["coverDocExt"] = await getFileExt(params.coverLetter);
    obj["coverBase64"] = await getFileTobash64(params.coverLetter);
    obj["otherDocName"] = await getFileName(params.addDoc);
    obj["otherDocExt"] = await getFileExt(params.addDoc);
    obj["otherBase64"] = await getFileTobash64(params.addDoc);
    obj["infoBlock"] = "";
    obj["address1"] = params.address_1;
    obj["address2"] = params.address_2;
    obj["city"] = params.suburb_city;
    obj["state"] = params.state;
    obj["postCode"] = params.post_code;
    // obj["country"] = params.country;
    obj["country"] = filterCountryCode[0]?.name;
    obj["source"] = clientId?.from;
    obj["extId"] = jobDetails?.data?.data?.job?.extId;
    obj["screeningAnswers"] = userJobData?.some(
      (ress: any) => ress.answers?.length > 0
    )
      ? userJobData
      : [];
    dispatch<any>(submitJob(obj));
  };
  const titleList = [
    {
      label: "Mrs",
      value: "Mrs",
    },
    {
      label: "Miss",
      value: "Miss",
    },
    {
      label: "Mr",
      value: "Mr",
    },
  ];
  const getStatusList = (params: string) => {
    dispatch<any>(careersStateList(params));
  };
  const jobViewodelClose = () => {
    setJobModel(false);
  };
  const JobViewModelHeader = () => {
    return (
      <>
        <Modal.Header>
          {/* <Modal.Title> */}
          <p className="mb-0 job-view-model d-flex w-100">
            <div className=" preview-header">Preview Job Ad</div>
            {/* <div> */}
            <div className="preview-close-icon ms-auto">
              {/* onClick={() => saveModelClose()} */}
              <i
                role="button"
                className="pi pi-times"
                onClick={() => jobViewodelClose()}
              ></i>
            </div>
            {/* </div> */}
          </p>
          {/* </Modal.Title> */}
        </Modal.Header>
      </>
    );
  };
  const JobViewModel = () => {
    return (
      <>
        <Modal.Body className="p-0">
          <div className="d-flex  justify-content-between">
            <div>
              <div>
                <JobPreView />
                {/* <JobView /> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  };

  const updateTimeLine:any = (timeListData: any, index: number) => {
    const formErrorKeys:any = formRef?.current?.errors;
    return timeListData.map((ress: any) => {
      if (index === 1) {        
        //TIMELINE 2 file upload
        if (ress.index === 0) {
          if (
            !formRef.current.dirty ||
            formErrorKeys["title"] ||
            formErrorKeys["firstName"] ||
            formErrorKeys["lastName"] ||
            formErrorKeys["country"] ||
            formErrorKeys["phone"] ||
            formErrorKeys["emailAddress"] ||
            formErrorKeys["state"]
          ) {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "current-page",
              completedPage: false,
              prsActive: true,
              prsMoveColor: "",
              prsComColorBorder: "#3A3E54",
              journeyLine:true
            };
          } else {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "",
              completedPage: true,
              prsMoveColor: "job-timeline-border",
              prsMoveColorBorder: "job-timeline-border",
              prsActive: true,
              journeyLine:true
            };
          }
        } else if (ress.index === 1) {    
          if (
            !formRef.current.dirty ||
            formErrorKeys["resume"]
          ) {
            return {
              ...ress,
              currentPage: true,
              currentPageStyle: "current-page",
              completedPage: false,
              prsActive: true,
              prsMoveColor: "",
              journeyLine: (timeListData[2] && timeListData[2].completedPage) ? true : false
            };
          } else {
            return {
              ...ress,
              currentPage: true,
              currentPageStyle: "current-page",
              completedPage: true,
              prsMoveColor: "",
              prsMoveColorBorder: ress?.prsActive ? "" : "",
              prsActive: true,
              journeyLine: (timeListData[2] && timeListData[2].completedPage) ? true : false
            };
          }
        } else if (ress.index === 2) {          
          if(!formRef.current.dirty || isCustomFormInvalid) {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "",
              completedPage: false,
              prsMoveColor: "",
              prsMoveColorBorder: ress?.prsActive ? "" : "",
              prsActive: true,
              journeyLine:false 
            };
          } 
          else {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "",
              completedPage: true,
              prsMoveColor: "",
              prsMoveColorBorder: ress?.prsActive ? "" : "",
              prsActive: true,
              journeyLine:false
            };
          }
        } else {
          return ress;
        }
        // }
      } else if (index === 2) {
        //TIMELINE 3
        if (ress.index === 0) {
          if (
            !formRef.current.dirty ||
            formErrorKeys["title"] ||
            formErrorKeys["firstName"] ||
            formErrorKeys["lastName"] ||
            formErrorKeys["country"] ||
            formErrorKeys["phone"] ||
            formErrorKeys["emailAddress"] ||
            formErrorKeys["state"]
          ) {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "current-page",
              completedPage: false,
              prsActive: true,
              prsMoveColor: "",
              prsComColorBorder: "#3A3E54",
              journeyLine:true
            };
          } else {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "",
              completedPage: true,
              prsMoveColor: "job-timeline-border",
              prsMoveColorBorder: "job-timeline-border",
              prsActive: true,
              journeyLine:true
            };
          }
        } else if (ress.index === 1) {
          if (
            !formRef.current.dirty ||
            formErrorKeys["resume"]
          ) {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "current-page",
              completedPage: false,
              prsActive: true,
              prsMoveColor: "",
              journeyLine:true
            };
          } else {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "current-page",
              completedPage: true,
              prsMoveColor: "",
              prsMoveColorBorder: ress?.prsActive ? "" : "",
              prsActive: true,
              journeyLine:true
            };
          }
        } else if (ress.index === 2) {
          if (isCustomFormInvalid) {
            return {
              ...ress,
              currentPage: true,
              currentPageStyle: "",
              completedPage: false,
              prsMoveColor: "",
              prsMoveColorBorder: ress?.prsActive ? "" : "",
              prsActive: true,
              journeyLine:false
            };
          } 
          else {
            return {
              ...ress,
              currentPage: true,
              currentPageStyle: "",
              completedPage: true,
              prsMoveColor: "",
              prsMoveColorBorder: ress?.prsActive ? "" : "",
              prsActive: true,
              journeyLine:false
            };
          }
        }
        // }
      } else if (index === 0) {
        if (ress.index === 0) {
          if (
            !formRef.current.dirty ||
            formErrorKeys["title"] ||
            formErrorKeys["firstName"] ||
            formErrorKeys["lastName"] ||
            formErrorKeys["country"] ||
            formErrorKeys["phone"] ||
            formErrorKeys["emailAddress"] ||
            formErrorKeys["state"]
          ) {
            return {
              ...ress,
              currentPage: true,
              currentPageStyle: "current-page",
              completedPage: false,
              prsActive: true,
              prsMoveColor: "",
              prsComColorBorder: "#3A3E54",
              journeyLine: (ress.journeyLine && timeListData[1].completedPage) ? true : false
            };
          } else {
            return {
              ...ress,
              currentPage: true,
              currentPageStyle: "current-page",
              completedPage: true,
              prsMoveColor: "",
              prsMoveColorBorder: "",
              prsActive: true,
              journeyLine: (ress.journeyLine && timeListData[1].completedPage) ? true : false
            };
          }
        } else if (ress.index === 1) {
          if (
            !formRef.current.dirty ||
            formErrorKeys["resume"]
          ) {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "current-page",
              completedPage: false,
              prsActive: true,
              prsMoveColor: "",
              prsComColorBorder: "",
              journeyLine: (timeListData[2] && timeListData[2].completedPage) ? true : false
            };
          } else {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "",
              completedPage: true,
              prsMoveColor: "",
              prsMoveColorBorder: ress?.prsActive ? "" : "",
              prsActive: true,
              journeyLine: (timeListData[2] && timeListData[2].completedPage) ? true : false
            };
          }
        } else if (ress.index === 2) {
          if(isCustomFormInvalid) {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "",
              completedPage: false,
              prsMoveColor: "",
              prsMoveColorBorder: ress?.prsActive ? "" : "",
              prsActive: true,
              journeyLine:false
            };
          } 
          else {
            return {
              ...ress,
              currentPage: false,
              currentPageStyle: "",
              completedPage: true,
              prsMoveColor: "",
              prsMoveColorBorder: ress?.prsActive ? "" : "",
              prsActive: true,
              journeyLine:false
            };
          }
        }
      }
      return ress;
    });
  };

  const moveToNextForm = (index: number) => {
    // console.log(index);
    const formErrorKeys = Object.keys(formRef?.current?.errors);
    const formOneKeys = [
      "title",
      "firstName",
      "lastName",
      "phone",
      "emailAddress",
      "state",
    ];

    // console.log(formErrorKeys);

    if (timeListData[index]?.index === 0) {
      // console.log("if", updateFormData[index]);
      window.scrollTo(0, 0);
      // if (commonKeys?.length === 0) {
      setTimeLineData(updateTimeLine(timeListData, index));
      // }
    } else if (timeListData[index]?.index === 1) {
      // const formOneKeys = ["resume"];
      // const commonKeys = formOneKeys.filter(key => formErrorKeys.includes(key));
      jobDataDiv?.current?.offsetHeight !== undefined ? 
        window.scrollTo(
          0,
          contactDetails?.current?.offsetHeight +
          jobDataDiv?.current?.offsetHeight +
          70
        )
        : window.scrollTo(
          0,
          contactDetails?.current?.offsetHeight +
          FormDataDiv?.current?.offsetTop - 50
        );
      // if (commonKeys?.length > 0) {
      setTimeLineData(updateTimeLine(timeListData, index));
      // }
    } else {
      // console.log("else", timeListData[index]);
      jobDataDiv?.current?.offsetHeight !== undefined ? 
      window.scrollTo(
        0,
        contactDetails?.current?.offsetHeight +
        jobDataDiv?.current?.offsetHeight +
        documentationDiv?.current?.offsetHeight +
        100
      )
        : window.scrollTo(
          0,
          contactDetails?.current?.offsetHeight +
          FormDataDiv?.current?.offsetTop +
          documentationDiv?.current?.offsetHeight -
          20
        );
      setTimeLineData(updateTimeLine(timeListData, index));
    }
  };
  let count = 0;
  const getformStatus = (e: any, isFileUploaded?: boolean) => {

    // const formErrorKeys = Object.keys(formRef?.current?.errors);
    const formErrorKeys: any = formRef?.current?.errors;
    // console.log(formErrorKeys);

    // const formOneKeys = ["title", "firstName", "lastName", "country", "phone",
    // "emailAddress", "state", "post_code"];
    // const commonKeys = formOneKeys.filter(key => !formErrorKeys.includes(key));
    // console.log(commonKeys);
    if (Object.keys(formErrorKeys).length > 0) {
      setTimeout(() => {
        if (isFileUploaded) {
          setTimeLineData(updateTimeLine(timeListData, 1));
        }
        else {
          setTimeLineData(updateTimeLine(timeListData, 0));
        }
      },1);
      // if (formErrorKeys["resume"] && formErrorKeys["resume"] !== null) {
      //     setTimeLineData(updateTimeLine(timeListData, 1));

      // }
    }
    //  else {
    //   setTimeLineData(timeLineInData);
    // }

    // if (commonKeys?.length === 0) {
    //     setTimeLineData(updateTimeLine(timeListData, 0));
    // }
    // else if(commonKeys?.length > 0){
    //     const formOneKeys = ["resume"];
    //     const commonKeys = formOneKeys.filter(key => formErrorKeys.includes(key));
    // }
  };

  const checkCustomValidationErrors = () => {
    /**
     * Checks custom form builder-form for validation errors
     * only checks if the entire form is dirty and custom form elements are touched atleast once
     */
    if (formRef.current?.dirty && Object.keys(formRef.current?.touched).length > 0) {
      let isInvalid;
      setTimeout(() => {
        isInvalid = Object.keys(formRef.current.errors).some((field: any) => (customFormRequiredFields.includes(field)));
        setCustomFormValidity(isInvalid);
      }, 1);
    }
  };

  useEffect(() => {
    if (formRef.current.dirty) {
      setTimeLineData(updateTimeLine(timeListData, 2));
    }
  }, [isCustomFormInvalid]);

  const fieldValidation = (value: any) => {    
    let error;
    checkCustomValidationErrors();
    if (typeof value === "string") {
      if (!value || value === undefined || value === null) {
        error = "This field is required";
      }
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        error = "This field is required";
      }
    }

    return error;
  };

  // console.log(observedDiv?.current?.offsetHeight , submitButtonRef?.current?.offsetHeight , dynamikFormDataRef?.current?.offsetHeight);
  // console.log(observedDiv?.current?.offsetHeight , submitButtonRef?.current?.offsetHeight , dynamikFormDataRef?.current?.offsetHeight);

  const asyncFileUpdate = () => {
    /**
     *  Implementation due to direct DOM manipulation, checks if there is a file uploaded into resume section
     */
    setTimeout(() => {
      getformStatus(null, true);
    }, 1);
  };

  const parseTNC = () => {
    //Terms and conditions builder, uses dangerouslyset for dynamic links
    let clientConfigData:any = clientConfig?.data?.data?.clientSettings;
    let modifiedTerms = clientConfigData?.terms?.text;    
    let linkString1 = `<a target="_blank" href="${clientConfigData?.terms.link1Url}">${clientConfigData?.terms.link1Text}</a>`;
    let linkString2 = `<a target="_blank" href="${clientConfigData?.terms.link2Url}">${clientConfigData?.terms.link2Text}</a>`;
    modifiedTerms = modifiedTerms?.replace("link1", linkString1);
    modifiedTerms = modifiedTerms?.replace("link2", linkString2);
    return (
      <>
        <label htmlFor="tAndC" dangerouslySetInnerHTML={{__html: modifiedTerms}}></label>
      </>
    );
  };

  const removeFile = (name: string, formik: any) => {
    const removeData: any = document.getElementById(name);
    if (removeData) {
      removeData.value = null;
      formik?.setFieldValue(name, null);
      asyncFileUpdate();
    }
  };

  useEffect(() => {
    if(questionOpenAndClose === false){
      setQaShowHide("qahide");
    } else {
      setQaShowHide("qashow");
    }
  }, [questionOpenAndClose]);

  const getSummaryReference:any = (errorRef:any, customLabelData:any) => {    
    let finalErrorData:any = [];
    let uniqueErrorArray:any;
    for (let key of Object.keys(errorRef)) {   
      if(customLabelData && customLabelData.length > 0) {     
        customLabelData.forEach((element:any) => {
          if(element.fieldId === key.toString()) {
            finalErrorData.push({errorLabel : `The field "${element.label}" is required`});
          }
          else if(errorRef[key] !== defaultFieldError) {
            finalErrorData.push({errorLabel : errorRef[key]});
          }
        });
      }
      else {
        finalErrorData.push({errorLabel : errorRef[key]});
      }
    }
    //Formik's current implementation creates duplicates of errors, this is a fix for that problem, the below operation wont be required once that part is optimized/fixed
    uniqueErrorArray = finalErrorData.filter((object:any,index:any) => index === finalErrorData.findIndex((obj:any) => JSON.stringify(obj) === JSON.stringify(object)));    
    
    return uniqueErrorArray;
  };

  const ErrorSummary = (props:any) => {
    const { errors } = props;
    let errorMap:any = [];
    if(Object.keys(errors).length > 0) {
      errorMap = getSummaryReference(errors, customFormRequiredReference);
    }
    return (    
      <>
      { (errorMap.length > 0) &&
        <div className="error-summary card custom-card p-3">
          <h2 className="error-message">There were some errors in your job application, please fix them to proceed with the application submission process</h2>
          <ul>
          {
            errorMap.map((err:any, i: number) => {
              return (
                <>
                  <li className="error-message" key={i}>{`${err.errorLabel}`}</li>
                </>
              );
            })
          }
          </ul>
        </div>
        }
      </>  
    );
  };
  //clears secondary subcategory selectionss on hitting the X button
  const onClearPrimaryCategory = (formikHook:any) => {
    setSelectedCategoryDropdown({
      parentCategoryId: null,
      depdropdown: null
    });
    setCurrentSelectedCategoryPair({
      categoryId: null,
      categoryLabel : null,
      subCategoryId : null,
      subcategoryLabel : null
    });
    formikHook.setFieldValue("interestprimarycategory", "");
    formikHook.setFieldValue("interestsubcategories", "");
  };

  //clears secondary subcategory selections on hitting the X button
  const onClearSubCategory = (formikHook:any) => {
    setCurrentSelectedCategoryPair({
      ...currentSelectedCategoryPair,
      subCategoryId : null,
      subcategoryLabel : null
    });
    formikHook.setFieldValue("interestsubcategories", "");
  };

  const findExistingCategoryPairs = (pairToCheck:any) =>{
    let returnObject:any = {
      existingPairs : [],
      errStatus: ""
    };
    returnObject.existingPairs = addedCategoryReferenceList.filter((entry:any) => {
        return Object.keys(pairToCheck).every((key:any) => {
          //if the new pair has an empty sub category, check for other existing entries with the same parent category
          if(pairToCheck.subCategoryId === null) {
            returnObject.errStatus = "You have already selected the main interest, please remove that to add sub interests separately";
            return (entry.categoryId === pairToCheck.categoryId);
          }
          // if adding pair doesnt have an empty sub category, check for unique pairs or existing emptys
          else{
            returnObject.errStatus = "You have already selected this interest, please choose another";
            return (entry[key] === pairToCheck[key]) || (entry.categoryId === pairToCheck.categoryId && entry.subCategoryId === null);
          }            
        });
    });    

    return returnObject;
  };

  //Returns all pairs that are not the provided pair, to be used for post deletion state update
  const filteredCategoryPair = (pairToRemove:any) =>{
    let filteredList:any = [];
    addedCategoryReferenceList.forEach((item:any) => {      
       if((item.categoryId !== pairToRemove.categoryId) || (item.subCategoryId !== pairToRemove.subCategoryId)) {
        filteredList.push(item);
       }
    });
    return filteredList;
  };

  //Dependent dropdown parent category change event
  const onSelectCategory = (formikAccess: any, dropdownRef: any, parentCategoryId: any, questions: any) => {
    //sets the sub category dropdown values based off parent    
    setSelectedCategoryDropdown({
      parentCategoryId: parentCategoryId,
      depdropdown : questions[0]?.depdropdown
    });
    //manually sets selected category and first subcategory as a pair ready to insert
    setCurrentSelectedCategoryPair({
      categoryId: parentCategoryId,
      categoryLabel : questions[0]?.label,
      subCategoryId : null,
      subcategoryLabel : null
    });
    formikAccess.setFieldValue(dropdownRef, parentCategoryId);
  };

  //Dependent dropdown child category change event
  const onSelectSubCategory = (formikAccess: any, selectRef: any, value: any, subQuestions: any) => {
    formikAccess.setFieldValue(selectRef, value);
    setCurrentSelectedCategoryPair({
      ...currentSelectedCategoryPair,
      subCategoryId : value ? value : null,
      subcategoryLabel : value ? subQuestions.find((e:any) => e.attributes.id === value).value: null
    });
  };

  //Add interest to the right side button event
  const addInterest = (formikAcceess: any, parentCategoryArray: any) => {
    let existingPairFinder:any = {};
    //Empty pair values check before adding (for form init)
    if((currentSelectedCategoryPair.categoryId && currentSelectedCategoryPair.categoryId !== "") && Object.keys(currentSelectedCategoryPair).length > 0){
      existingPairFinder = findExistingCategoryPairs(currentSelectedCategoryPair);
      if(!(existingPairFinder.existingPairs.length > 0)) {
        //setting a hidden variable alongside for formik's internal validator, TODO: clean up with a better setup
        let categoryRef = [...addedCategoryReferenceList,currentSelectedCategoryPair];        
        setCategoryReferenceList(categoryRef);
        formikAcceess.setFieldValue("categoryList", categoryRef);
        formikAcceess.setStatus({message : null});
      }
      else{
        formikAcceess.setStatus({message : existingPairFinder.errStatus});
      }
    }
    else{
      formikAcceess.setStatus({message : "Please select an option before adding"});
    }
  };

  //Delete button action for removing category pairs
  //TODO
  const removeCategoryPair = (formikAccess: any, pair:any) => {
    let categoryRef = filteredCategoryPair(pair);
    //setting a hidden variable alongside for formik's internal validator, TODO: clean up with a better setup
    setCategoryReferenceList(categoryRef);
    formikAccess.setFieldValue("categoryList", categoryRef);
    formikAccess.setFieldTouched("categoryList", true);
  };
  
  return (
    <>
      {jobDetails.status === 200 && jobDetails?.data?.statusCode === 200 ? (
        <>
          <div>{/* <Loader loading={loader} /> */}</div>
          { showSearchFilter ? jobFilterData() : "" }
          <div className= { `container-fluid subheader-3 ${subHeaderStyle}`}>
            <>
              <div className="row">
                <div className="col-md-6 col-lg-6 col-12 col-sm-12  ">
                  <div className="d-none d-sm-none d-md-block">
                    <p className="page-heading mb-0 page-heading-img">
                      {
                        (clientIds.hvtc === clientConfig?.data?.data?.clientSettings?.clientId) ?
                        <>
                          <button onClick={redirectListing}>
                            <Image src={zealLogo} alt="Zeal Logo" />
                          </button>
                        </>
                        : ""
                      }
                      Job Application
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-12 col-sm-12 d-flex justify-content-sm-center justify-content-center justify-content-md-end pe-auto ">
                  <div className="careers-timeline careers-timeline-new">
                    <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                      {/* <div className="careers-timeline  careers-timeline-new"> */}
                      <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                        <div className="timeline-scroll timeline-scroll-new">
                          {timeListData.map((ress, i: number) => {
                            return (
                              <>
                                <div className="d-flex">
                                  <div
                                    key={ress.label}
                                    className="d-flex align-items-center1 time-line"
                                  >
                                    {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>{ress.label}</Tooltip>}> */}
                                    <div
                                      role={"button"}
                                      onClick={() => moveToNextForm(ress.index)}
                                      className={`time-line-circle ${ress.currentPageStyle} ${ress.prsMoveColor}`}
                                    >
                                      <div
                                        role="button"
                                        className={"time-line-label"}
                                      >
                                        <span
                                          className={i === 1 ? "doc-label" : ""}
                                        >
                                          {ress.label}
                                        </span>
                                      </div>
                                      {ress.currentPage ? (
                                        <div className="time-line-inner-outer">
                                          <div className="time-line-inner-outer-par">
                                            <div className="time-line-inner-circle"></div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {ress.completedPage ? (
                                        <>
                                          <div className="time-line-inner-outer">
                                            <div className="time-line-inner-outer-par">
                                              <div
                                                className={"time-line-inner-circle d-none"}
                                              ></div>
                                              <i
                                                className={"pi pi-check timeline-done"}
                                              ></i>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    {i + 1 !== timeListData.length ? (
                                      <>
                                        {ress?.journeyLine ? (
                                          <div
                                            className={"current-page-bar"}
                                            style={{
                                              minWidth: "115px",
                                              height: "2px",
                                            }}
                                          ></div>
                                        ) : (
                                          <div
                                            style={{
                                              minWidth: "115px",
                                              height: "2px",
                                              background: "#BBBBBB",
                                            }}
                                          ></div>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <br />
            </>
          </div>

          <div
            id="overAllSection"
            ref={overAllSection}
            className={`container-fluid pt-4 ${jobBodyStyle} position-relative mobile-screen-padd-bot custom-job-apply-container`}
          >
            <div>
              {viewJobControl ? (
                <Button
                  onClick={() => jobPreView()}
                  type="button"
                  icon={
                    jobPreViewSection ? "pi pi-arrow-right" : "pi pi-arrow-left"
                  }
                  position="left"
                  label={jobPreViewSection ? "Close Job Ad" : "Preview Job Ad"}
                  clasName={`btn btn-primary completion-btn-primary btn-custom-moving ${jobPreViewSection ? "btn-control-view" : ""
                    }`}
                />
              ) : (
                ""
              )}
              <div className="row" ref={observedDiv}>
                {viewJobControl ? (
                  <>
                    { (showLink && showSearchFilter) ? 
                      <div className="module-careers-listings">
                        <div className="flex">
                          <div className="job-view">
                            <div className="job-detail p-0 bg-transparent">
                              <div className="backtolisting">
                                <button type="button" className="arrow prev" onClick={redirectListing}>
                                    <span className="icon">
                                        <Image src={ArrowLeft} alt="arrow" />
                                    </span>
                                    <h6 className="mb-0">BACK TO LISTINGS</h6>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    : showLink ? 
                    <div className="mb-3">
                      <button className="btn btn-primary completion-btn-primary" onClick={redirectListing}><i className="pi pi-arrow-left me-2"></i>BACK TO LISTINGS</button>
                    </div>
                    :
                      ""
                    }
                    <div
                      ref={jobDataDiv}
                      className="col-xl-6 col-lg-10 col-md-10 col-sm-10 col-12"
                    >
                      <div className="card custom-card">
                        <div className="card-body ">
                          <div className="d-flex d-md-flex d-sm-flex align-items-center">
                            <div className="">
                              <div className="job-text">
                                {jobDetails?.data?.data?.job?.title}
                              </div>
                              <div className="cbd">
                                {
                                  jobDetails?.data?.data?.job?.location
                                    ?.location
                                }
                              </div>
                              <div className="d-flex d-md-flex d-lg-flex d-sm-flex align-items-center mt-md-3 mt-sm-0 mt-0">
                                <div className="mt-3 mt-sm-3 mt-md-0">
                                  <div className="d-flex align-items-center">
                                    <Image
                                      clasName="me-1"
                                      src={JobIcon}
                                      width={15}
                                    />
                                    <div className="job-ref">
                                      <span className="reference mob-ipad-d-none">
                                        Job Reference :{" "}
                                      </span>
                                      <span className="date-yest">
                                        {
                                          jobDetails?.data?.data?.job
                                            ?.referenceNo
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="ms-2 ms-sm-2 mt-3 mt-sm-3 mt-md-0 ms-md-0">
                                  <div className="d-flex align-items-center">
                                    <Image
                                      clasName="ms-md-3 ms-sm-0  me-0 me-sm-0 me-md-1"
                                      src={DateIcon}
                                      width={15}
                                    />
                                    <div className="job-ref">
                                      <span className="reference mob-ipad-d-none">
                                        Posted On :
                                      </span>{" "}
                                      <span className="date-yest ps-1 ps-sm-1 ms-md-0">
                                        {publishingDate(
                                          jobDetails?.data?.data?.job?.postedAt
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="ms-auto mt-sm-2 mt-2 mt-md-0">
                              <Image
                                width={100}
                                // src={validateImgUrl(jobDetails?.data?.data?.job?.logo)}
                                src={
                                  jobDetails?.data?.data?.job?.logo
                                    ? jobDetails?.data?.data?.job?.logo
                                    : "https://stag-compliance-careers.s3.ap-southeast-2.amazonaws.com/career-doc-settings/uploads/no_image.png"
                                }
                                clasName="img-fluid"
                                alt="Logo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-2 col-sm-2 col-0"></div>
                  </>
                ) : (
                  ""
                )}
                <div
                  ref={FormDataDiv}
                  className={`mt-4 mobil-button-div${jobPreViewSection ? "col-md-6" : "col-md-12"
                    }`}
                >
                  {Object.keys(jobApplyForm).length > 0 ? (
                    <Formik
                      initialValues={jobApplyForm}
                      validationSchema={validateSchema}
                      enableReinitialize
                      onSubmit={(values) => {
                        submitApplayJob(values);
                      }}
                      innerRef={(f) => (formRef.current = f)}
                    >
                      {(formik) => (
                        // console.log(formik),
                        // {Object.keys(formik?.errors)?.length?getformStatus(formik):""},
                        // ,                        
                        <Form>
                          {/* <ScrollToError/> */}
                          <div className="form-space job-apply">
                            <div ref={contactDetails} className="row">
                              <div
                                className={`${jobPreViewSection ? "col-md-6" : "col-md-6"
                                  }`}
                              >
                                <h3 className="adres-form-heading">
                                  Contact Details
                                </h3>
                                <div className="row mb-md-3 g-md-3 g-sm-0 g-0 align-items-center">
                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="title"
                                      className="form-label"
                                      label={"Title"}
                                      required={true}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-6 position-relative">
                                    {/* <span className="job-ap-select-box" style={{ minWidth: " 100%" }} > */}
                                    {/* <Field as={"select"} name={"title"} id={"title"} className={`form-select ${formik?.values?.title ? "" : "select-place-holder"}`}
                                                                        onBlur={(e: any) => e.preventDefault()}  >
                                                                        <option value={""} disabled selected hidden>{"Select title"}</option>
                                                                        <option value={"Mr"} >{"Mr"}</option>
                                                                        <option value={"Miss"} >{"Miss"}</option>
                                                                        <option value={"Mrs"} >{"Mrs"}</option>
                                                                    </Field> */}
                                    <SingleSelect
                                      className="form-control-select form-control"
                                      placeholder="Select title..."
                                      // value={tableControl.pageSize}
                                      onChange={(e: any) => {
                                        formik.setFieldValue("title", e.value);
                                      }}
                                      onInputChange={(e: any) => {
                                        getformStatus(formik);
                                      }}
                                      options={titleList}
                                      isSearchable={false}
                                    />
                                    {/* </span> */}
                                    {/* <i className="pi pi-angle-down dropdown-arrow"></i> */}
                                    <ErrorMessage
                                      name={"title"}
                                      component={TextError}
                                    />
                                  </div>
                                  {/* <div className="col-0 col-sm-0 col-md-2 position-relative"></div> */}
                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="firstName"
                                      className="form-label"
                                      label={"First Name"}
                                      required={true}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      as={"input"}
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      placeholder="Enter first name..."
                                      name={"firstName"}
                                      id={"firstName"}
                                      className="form-control"
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"firstName"}
                                      component={TextError}
                                    />
                                  </div>

                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="lastName"
                                      className="form-label"
                                      label={"Last Name"}
                                      required={true}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      as={"input"}
                                      placeholder="Enter last name..."
                                      name={"lastName"}
                                      id={"lastName"}
                                      className="form-control"
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"lastName"}
                                      component={TextError}
                                    />
                                  </div>

                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="preferredName"
                                      className="form-label"
                                      label={"Preferred Name"}
                                      required={false}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      as={"input"}
                                      placeholder="Enter preferred name..."
                                      name={"preferredName"}
                                      id={"preferredName"}
                                      className="form-control"
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"preferredName"}
                                      component={TextError}
                                    />
                                  </div>

                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="phone"
                                      className="form-label"
                                      label={"Mobile"}
                                      required={true}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      as={"input"}
                                      placeholder="Enter number..."
                                      name={"phone"}
                                      id={"phone"}
                                      className="form-control"
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"phone"}
                                      component={TextError}
                                    />
                                  </div>

                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="emailAddress"
                                      className="form-label"
                                      label={"Email"}
                                      required={true}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      as={"input"}
                                      type="email"
                                      placeholder="Enter email..."
                                      name={"emailAddress"}
                                      id={"emailAddress"}
                                      className="form-control"
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"emailAddress"}
                                      component={TextError}
                                    />
                                  </div>
                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="linkedinProfile"
                                      className="form-label"
                                      label={"Linkedin Profile"}
                                      required={false}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      as={"input"}
                                      type="text"
                                      placeholder="Enter linkedin profile url..."
                                      name={"linkedinProfile"}
                                      id={"linkedinProfile"}
                                      className="form-control"
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"linkedinProfile"}
                                      component={TextError}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${jobPreViewSection ? "col-md-0" : "d-none"
                                  }`}
                              ></div>
                              <div
                                ref={residentialAddress}
                                className={`${jobPreViewSection ? "col-md-6" : "col-md-6"
                                  }`}
                              >
                                <h3 className="adres-form-heading">
                                  Residential Address
                                </h3>
                                <div className="row mb-md-3 g-md-3 g-sm-0 g-0 align-items-center">
                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="address_1"
                                      className="form-label"
                                      label={"Address 1"}
                                      required={false}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      as={"input"}
                                      placeholder="Enter address..."
                                      name={"address_1"}
                                      id={"address_1"}
                                      className="form-control"
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"address_1"}
                                      component={TextError}
                                    />
                                  </div>

                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="address_2"
                                      className="form-label"
                                      label={"Address 2"}
                                      required={false}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      as={"input"}
                                      placeholder="Enter address..."
                                      name={"address_2"}
                                      id={"address_2"}
                                      className="form-control"
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"address_2"}
                                      component={TextError}
                                    />
                                  </div>

                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="suburb_city"
                                      className="form-label"
                                      label={"Suburb/City"}
                                      required={false}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8 position-relative">
                                    <Field
                                      as={"input"}
                                      placeholder="Enter suburb/city..."
                                      name={"suburb_city"}
                                      id={"suburb_city"}
                                      className="form-control"
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"suburb_city"}
                                      component={TextError}
                                    />
                                  </div>
                                  <div className={"col-12 col-md-4 col-sm-12 position-relative"}>
                                    <Label
                                      name="country"
                                      className="form-label"
                                      label={"Country"}
                                      required={true}
                                    />
                                  </div>

                                  <div className="col-12 col-sm-12 col-md-8 position-relative">
                                    <SingleSelect
                                      className="form-control-select form-control"
                                      placeholder="Select country..."
                                      // value={tableControl.pageSize}
                                      onChange={(e: any) => {
                                        formik.setFieldValue("state", "");
                                        formik.setFieldValue(
                                          "country",
                                          e?.value?.toString()
                                        ),
                                          getStatusList(e.value);
                                      }}
                                      onInputChange={(e: any) => {
                                        getformStatus(formik);
                                      }}
                                      isSearchable={true}
                                      options={country}
                                    />
                                    {/* <span className="job-ap-select-box" style={{ minWidth: " 100%" }} >
                                                                    <Field as={"select"} onChange={(e: any) => { formik.setFieldValue("country", e.target.value), getStatusList(e.target.value); }} name={"country"} id={"country"} className={`form-select ${formik?.values?.country ? "" : "select-place-holder"}`}
                                                                        onBlur={(e: any) => e.preventDefault()}  >
                                                                        <option value={""} disabled selected hidden>{"Select Country"}</option>
                                                                        {country.map((item: any) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id} >{item.name}</option>
                                                                            );
                                                                        })};
                                                                    </Field>
                                                                </span> */}
                                    <ErrorMessage
                                      name={"country"}
                                      component={TextError}
                                    />
                                  </div>

                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="state"
                                      className="form-label"
                                      label={"State"}
                                      required={true}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8 position-relative">
                                    <SingleSelect
                                      className="form-control-select form-control"
                                      placeholder="Select state..."
                                      // value={tableControl.pageSize}
                                      onChange={(e: any) => {
                                        formik.setFieldValue(
                                          "state",
                                          e.value?.toString()
                                        );
                                      }}
                                      onInputChange={(e: any) => {
                                        getformStatus(formik);
                                      }}
                                      options={states}
                                      isSearchable={true}
                                      value={
                                        formik?.values["state"]
                                          ? states.filter(
                                            (ress: any) =>
                                              ress?.value ===
                                              formik?.values["state"]
                                          )[0]
                                          : ""
                                      }
                                    />
                                    {/* <span className="job-ap-select-box" style={{ minWidth: " 100%" }} >
                                                                    <Field as={"select"} name={"state"} id={"state"} className={`form-select ${formik?.values?.state ? "" : "select-place-holder"}`}
                                                                        onBlur={(e: any) => e.preventDefault()}  >
                                                                        <option value={""} disabled selected hidden>{"Select state"}</option>
                                                                        {states.map((item: any) => {
                                                                            return (
                                                                                <option key={item.id} value={item.id} >{item.name}</option>
                                                                            );
                                                                        })};
                                                                    </Field>
                                                                </span> */}
                                    {/* <Field as={"input"} placeholder="Select state" name={"state"} id={"state"} className="form-control"
                                                                    onBlur={(e: any) => e.preventDefault()}  >
                                                                </Field> */}
                                    <ErrorMessage
                                      name={"state"}
                                      component={TextError}
                                    />
                                  </div>

                                  <div className={"col-12 col-md-4 col-sm-12 "}>
                                    <Label
                                      name="post_code"
                                      className="form-label"
                                      label={"Post Code"}
                                      required={false}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      as={"input"}
                                      placeholder="Enter post code..."
                                      name={"post_code"}
                                      id={"post_code"}
                                      className="form-control"
                                      onKeyDown={(e: any) =>
                                        getformStatus(formik)
                                      }
                                      onBlur={(e: any) => e.preventDefault()}
                                    ></Field>
                                    <ErrorMessage
                                      name={"post_code"}
                                      component={TextError}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/* <div id="documentation-section" > */}
                            <div
                              className={`${jobPreViewSection ? "col-6" : "col-12"
                                }`}
                              ref={documentationDiv}
                            >
                              <div>
                                <h3 className="adres-form-heading">
                                  Documentation
                                </h3>
                                <div className="d-flex align-items-center my-2">
                                  <i className="pi pi-exclamation-circle"></i>
                                  <div className="ms-2">
                                    Size limit: <b>2 MB</b>. Formats allowed:
                                    .doc, .docx, .pdf, .mdi, .txt,
                                    .rtf
                                  </div>
                                </div>
                              </div>
                              <div className="card custom-card p-3">
                                <div className="card-body px-0 py-4 py-sm-5 p-md-3">
                                  {/* <div className="row"> */}
                                  {/* <div className="col-md-12"> */}
                                  <div className="row">
                                    <div
                                      className={`${jobPreViewSection ? "col-9" : "col-12"
                                        }`}
                                    >
                                      <div className="row">
                                        <div
                                          className={`${jobPreViewSection
                                            ? "col-md-6 col-lg-6 col-sm-6 col-6"
                                            : "col-md-4 col-lg-4 col-sm-6 col-6"
                                            }`}
                                        >
                                          <div className="">
                                            <div
                                              className={`file-circle ${formik?.values["resume"]
                                                ? "befor-file-upload"
                                                : "befor-file-circle"
                                                }`}
                                            >
                                              {formik?.values["resume"] ? (
                                                <Image
                                                  role="button"
                                                  onClick={() =>
                                                    removeFile("resume", formik)
                                                  }
                                                  clasName="close-icon"
                                                  src={CloseIcon}
                                                />
                                              ) : (
                                                ""
                                              )}
                                              <Image
                                                role="button"
                                                onClick={() =>
                                                  document
                                                    .getElementById("resume")
                                                    ?.click()
                                                }
                                                src={ResumeIcon}
                                                alt="Resume"
                                              />
                                              <div className="d-none">
                                                <Field
                                                  name={"resume"}
                                                  onBlur={(e: any) =>
                                                    e.preventDefault()
                                                  }
                                                >
                                                  {({
                                                    field,
                                                    form: {
                                                      setFieldValue,
                                                      values,
                                                    },
                                                  }: any) => {
                                                    const fileInput: any =
                                                      document.getElementById(
                                                        "resume"
                                                      );
                                                    if (fileInput) {
                                                      fileInput.value = null;
                                                    }
                                                    return (
                                                      <>
                                                        <input
                                                          id={"resume"}
                                                          onBlur={(e: any) =>
                                                            e.preventDefault()
                                                          }
                                                          type="file"
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            setFieldValue(
                                                              field.name,
                                                              e?.target
                                                                ?.files[0]
                                                            );
                                                            asyncFileUpdate();
                                                          }}
                                                          multiple={true}
                                                        />
                                                      </>
                                                    );
                                                  }}
                                                </Field>
                                              </div>
                                            </div>
                                            <div className="text-center">
                                              <Label
                                                className="form-label"
                                                label={"Resume"}
                                                required={true}
                                              />
                                              <div className="select-file-name">
                                                {formik?.values["resume"] !==
                                                  null
                                                  ? formik?.values["resume"][
                                                  "name"
                                                  ]
                                                  : ""}
                                              </div>
                                              <ErrorMessage
                                                name={"resume"}
                                                component={TextError}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={`${jobPreViewSection
                                            ? "col-md-6 col-lg-6 col-sm-6 col-6"
                                            : "col-md-4 col-lg-4 col-sm-6 col-6"
                                            }`}
                                        >
                                          <div className="">
                                            <div
                                              className={`file-circle ${formik?.values["coverLetter"]
                                                ? "befor-file-upload"
                                                : "befor-file-circle"
                                                }`}
                                            >
                                              {formik?.values["coverLetter"] ? (
                                                <Image
                                                  onClick={() =>
                                                    removeFile(
                                                      "coverLetter",
                                                      formik
                                                    )
                                                  }
                                                  clasName="close-icon"
                                                  src={CloseIcon}
                                                />
                                              ) : (
                                                ""
                                              )}
                                              <Image
                                                role="button"
                                                onClick={() =>
                                                  document
                                                    .getElementById(
                                                      "coverLetter"
                                                    )
                                                    ?.click()
                                                }
                                                src={LetterIcon}
                                                alt="Cover Letter"
                                              />
                                              <div className="d-none">
                                                <Field
                                                  name={"coverLetter"}
                                                  onBlur={(e: any) =>
                                                    e.preventDefault()
                                                  }
                                                >
                                                  {({
                                                    field,
                                                    form: {
                                                      setFieldValue,
                                                      values,
                                                    },
                                                  }: any) => {
                                                    const fileInput: any =
                                                      document.getElementById(
                                                        "coverLetter"
                                                      );
                                                    if (fileInput) {
                                                      fileInput.value = null;
                                                    }
                                                    return (
                                                      <>
                                                        <input
                                                          id={"coverLetter"}
                                                          onBlur={(e: any) =>
                                                            e.preventDefault()
                                                          }
                                                          type="file"
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            setFieldValue(
                                                              field.name,
                                                              e?.target
                                                                ?.files[0]
                                                            );
                                                          }}
                                                          multiple={true}
                                                        />
                                                      </>
                                                    );
                                                  }}
                                                </Field>
                                              </div>
                                            </div>
                                            <div className="text-center">
                                              <Label
                                                className="form-label"
                                                label={"Cover Letter"}
                                              />
                                              <div className="select-file-name">
                                                {formik?.values[
                                                  "coverLetter"
                                                ] !== null
                                                  ? formik?.values[
                                                  "coverLetter"
                                                  ]["name"]
                                                  : ""}
                                              </div>
                                              <ErrorMessage
                                                name={"coverLetter"}
                                                component={TextError}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div
                                          className={`${jobPreViewSection
                                            ? "col-md-6 col-lg-6 col-sm-6 col-6 mt-5"
                                            : "col-md-3 col-lg-3 col-sm-6 col-6"
                                            }`}
                                        >
                                          <div className="">
                                            <div
                                              className={`file-circle ${formik?.values[
                                                "linkedinProfile"
                                              ]
                                                ? "befor-file-upload"
                                                : "befor-file-circle"
                                                }`}
                                            >
                                              {formik?.values[
                                                "linkedinProfile"
                                              ] ? (
                                                <Image
                                                  onClick={() =>
                                                    removeFile(
                                                      "linkedinProfile",
                                                      formik
                                                    )
                                                  }
                                                  clasName="close-icon"
                                                  src={CloseIcon}
                                                />
                                              ) : (
                                                ""
                                              )}
                                              <Image
                                                role="button"
                                                onClick={() =>
                                                  document
                                                    .getElementById(
                                                      "linkedinProfile"
                                                    )
                                                    ?.click()
                                                }
                                                src={LinkedInIconJob}
                                                alt="linkedin"
                                                width={40}
                                              />
                                              <div className="d-none">
                                                <Field
                                                  name={"linkedinProfile"}
                                                  onBlur={(e: any) =>
                                                    e.preventDefault()
                                                  }
                                                >
                                                  {({
                                                    field,
                                                    form: {
                                                      setFieldValue,
                                                      values,
                                                    },
                                                  }: any) => {
                                                    const fileInput: any =
                                                      document.getElementById(
                                                        "linkedinProfile"
                                                      );
                                                    if (fileInput) {
                                                      fileInput.value = null;
                                                    }
                                                    return (
                                                      <>
                                                        <input
                                                          id={"linkedinProfile"}
                                                          onBlur={(e: any) =>
                                                            e.preventDefault()
                                                          }
                                                          type="file"
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            setFieldValue(
                                                              field.name,
                                                              e?.target
                                                                ?.files[0]
                                                            );
                                                          }}
                                                          multiple={true}
                                                        />
                                                      </>
                                                    );
                                                  }}
                                                </Field>
                                              </div>
                                            </div>
                                            <div className="text-center">
                                              <Label
                                                className="form-label"
                                                label={"Linkedin Profile"}
                                              />
                                              <div className="select-file-name">
                                                {formik?.values[
                                                  "linkedinProfile"
                                                ] !== null
                                                  ? formik?.values[
                                                  "linkedinProfile"
                                                  ]["name"]
                                                  : ""}
                                              </div>
                                              <ErrorMessage
                                                name={"linkedinProfile"}
                                                component={TextError}
                                              />
                                            </div>
                                          </div>
                                        </div> */}
                                        <div
                                          className={`${jobPreViewSection
                                            ? "col-md-6 col-lg-6 col-sm-6 col-6 mt-5"
                                            : "col-md-4 col-lg-4 col-sm-6 col-6"
                                            }`}
                                        >
                                          <div className="">
                                            <div
                                              className={`file-circle ${formik?.values["addDoc"]
                                                ? "befor-file-upload"
                                                : "befor-file-circle"
                                                }`}
                                            >
                                              {formik?.values["addDoc"] ? (
                                                <Image
                                                  onClick={() =>
                                                    removeFile("addDoc", formik)
                                                  }
                                                  clasName="close-icon"
                                                  src={CloseIcon}
                                                />
                                              ) : (
                                                ""
                                              )}
                                              <Image
                                                role="button"
                                                onClick={() =>
                                                  document
                                                    .getElementById("addDoc")
                                                    ?.click()
                                                }
                                                src={LetterIcon}
                                                alt="Resume"
                                              />
                                              <div className="d-none">
                                                <Field
                                                  name={"addDoc"}
                                                  onBlur={(e: any) =>
                                                    e.preventDefault()
                                                  }
                                                >
                                                  {({
                                                    field,
                                                    form: {
                                                      setFieldValue,
                                                      values,
                                                    },
                                                  }: any) => {
                                                    const fileInput: any =
                                                      document.getElementById(
                                                        "addDoc"
                                                      );
                                                    if (fileInput) {
                                                      fileInput.value = null;
                                                    }
                                                    return (
                                                      <>
                                                        <input
                                                          id={"addDoc"}
                                                          onBlur={(e: any) =>
                                                            e.preventDefault()
                                                          }
                                                          type="file"
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            setFieldValue(
                                                              field.name,
                                                              e?.target
                                                                ?.files[0]
                                                            );
                                                          }}
                                                          multiple={true}
                                                        />
                                                      </>
                                                    );
                                                  }}
                                                </Field>
                                              </div>
                                            </div>
                                            <div className="text-center">
                                              <Label
                                                className="form-label"
                                                label={
                                                  "Additional Documentation"
                                                }
                                              />
                                              <div className="select-file-name">
                                                {formik?.values["addDoc"] !==
                                                  null
                                                  ? formik?.values["addDoc"][
                                                  "name"
                                                  ]
                                                  : ""}
                                              </div>
                                              <ErrorMessage
                                                name={"addDoc"}
                                                component={TextError}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* </div> */}

                                  {/* </div> */}
                                </div>
                              </div>
                            </div>

                            {jobDetails !== null &&
                              jobDetails?.data?.data?.job?.questions?.length >
                              0 ? (
                              <hr />
                            ) : (
                              ""
                            )}

                            <div
                              className={`${jobPreViewSection ? "col-md-6" : "col-md-12"
                                }`}
                            >
                              {(jobDetails !== null &&
                                jobDetails?.data?.data?.job?.questions?.length >
                                0) ||
                                Object.keys(profileQuestion)?.length > 0 ? (
                                <div
                                  ref={dynamikFormDataRef}
                                  className="questions-head d-flex d-sm-flex mt-4 pt-2  d-md-flex mb-sm-3 mb-3 mb-md-2"
                                >
                                  {questionOpenAndClose &&
                                    (jobDetails?.data?.data?.job?.questions
                                      ?.length > 0 ||
                                      Object.keys(profileQuestion)?.length >
                                      0) ? (
                                    // <div role="button" onClick={() => setQuestionOpenAndClose(false)}>
                                    //     <Image src={QuestionsOpenIcon} clasName="mb-1" />
                                    // </div>
                                    <div
                                      role="button"
                                      onClick={() =>
                                        setQuestionOpenAndClose(false)
                                      }
                                    >
                                      <div className="question-open-icon">
                                        <i className="pi pi-minus"></i>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      role="button"
                                      onClick={() =>
                                        setQuestionOpenAndClose(true)
                                      }
                                    >
                                      <div className="question-clos-icon">
                                        <i className="pi pi-plus"></i>
                                      </div>
                                    </div>
                                  )}
                                  <div className="ps-2 questions-header">
                                    Questions
                                    {!isCustomFormInvalid ? (
                                      <span className="btn btn-primary completion-btn-primary completion-tag">Complete</span>
                                    ) : ""
                                    }
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className={`${qaShowHide}`}>
                                { jobDetails?.data?.data?.job?.questions?.length >
                                  0 ? (
                                  <div>
                                    {jobDetails?.data?.data?.job?.questions?.map(
                                      (ress: any) => {
                                        return (
                                          <div key={ress.extId}>
                                            {ress.type === "open-ended" ? (
                                              <>
                                                <div className="mb-3">
                                                  <Label
                                                    className="form-label"
                                                    label={ress.text}
                                                    required={
                                                      ress.required === 1
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  <Field
                                                    onBlur={(e: any) =>
                                                      e.preventDefault()
                                                    }
                                                    rows="4"
                                                    as="textarea"
                                                    name={ress.extId}
                                                    className={"form-control"}
                                                    validate={
                                                      ress.required === 1
                                                        ? fieldValidation
                                                        : ""
                                                    }
                                                  />
                                                  <ErrorMessage
                                                    name={ress.extId}
                                                    component={TextError}
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {ress.type === "single" ? (
                                              <>
                                                <div className="mb-3">
                                                  <Label
                                                    className="form-label"
                                                    label={ress.text}
                                                    required={
                                                      ress.required === 1
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  <div>
                                                    <Field
                                                      name={ress.extId}
                                                      validate={
                                                        ress.required === 1
                                                          ? fieldValidation
                                                          : ""
                                                      }
                                                    >
                                                      {({ field }: any) => {
                                                        return ress.answers.map(
                                                          (ress: any) => {
                                                            // console.log("test",ress);
                                                            return (
                                                              <>
                                                                <div
                                                                  key={ress.id}
                                                                  className="form-check form-check-inline"
                                                                >
                                                                  <input
                                                                    className="form-check-input form-check-radio"
                                                                    type="radio"
                                                                    id={ress.id}
                                                                    {...field}
                                                                    value={
                                                                      ress.id
                                                                    }
                                                                  />
                                                                  <label
                                                                    className="form-check-label"
                                                                    htmlFor={
                                                                      ress.id
                                                                    }
                                                                  >
                                                                    {ress.text}
                                                                  </label>
                                                                </div>
                                                              </>
                                                            );
                                                          }
                                                        );
                                                      }}
                                                    </Field>
                                                  </div>

                                                  <ErrorMessage
                                                    name={ress.extId}
                                                    component={TextError}
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}

                                            {ress.type === "multiple" ? (
                                              <>
                                                <div className="mb-3">
                                                  <Label
                                                    className="form-label"
                                                    label={ress.text}
                                                    required={
                                                      ress.required === 1
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  <div>
                                                    <Field
                                                      name={ress.extId}
                                                      onBlur={(e: any) =>
                                                        e.preventDefault()
                                                      }
                                                      validate={
                                                        ress.required === 1
                                                          ? fieldValidation
                                                          : ""
                                                      }
                                                    >
                                                      {({ field }: any) => {
                                                        return ress.answers.map(
                                                          (ress: any) => {
                                                            return (
                                                              <>
                                                                <div
                                                                  key={ress.id}
                                                                  className="form-check form-check-inline"
                                                                >
                                                                  <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={ress.id}
                                                                    {...field}
                                                                    value={
                                                                      ress.id
                                                                    }
                                                                    checked={field?.value?.includes(
                                                                      ress.id
                                                                    )}
                                                                  />
                                                                  <label
                                                                    className="form-check-label"
                                                                    htmlFor={
                                                                      ress.id
                                                                    }
                                                                  >
                                                                    {ress.text}
                                                                  </label>
                                                                </div>
                                                              </>
                                                            );
                                                          }
                                                        );
                                                      }}
                                                    </Field>
                                                  </div>
                                                  <ErrorMessage
                                                    name={ress.extId}
                                                    component={TextError}
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className={`${qaShowHide}`}>
                                { Object.keys(profileQuestion)?.length > 0 ? (
                                  <>
                                    <div className="interests">General</div>
                                    <div className="row">
                                      {Object.keys(profileQuestion).map(
                                        (ress, i) => {
                                          // console.log(ress);

                                          return (
                                            <>
                                              {profileQuestion[ress]["attributes"]
                                                ?.type === "singleselect" ? (
                                                <div key={i} className="mb-3">
                                                  <Label
                                                    name={
                                                      profileQuestion[ress]
                                                        ?.fieldId
                                                    }
                                                    className="form-label"
                                                    label={
                                                      profileQuestion[ress]?.label
                                                    }
                                                    required={
                                                      profileQuestion[ress]
                                                        ?.attributes?.required
                                                    }
                                                  />
                                                  <div>
                                                    {/* {profileQuestion[ress]?.fieldId} */}
                                                    <Field
                                                      name={
                                                        profileQuestion[ress]
                                                          ?.fieldId
                                                      }
                                                      validate={
                                                        profileQuestion[ress]
                                                          ?.attributes?.required
                                                          ? fieldValidation
                                                          : ""
                                                      }
                                                    >
                                                      {({ field }: any) => {
                                                        // console.log(field);
                                                        return profileQuestion[
                                                          ress
                                                        ].option?.map(
                                                          (subRess: any) => {
                                                            // console.log("check", subRess);
                                                            return (
                                                              <>
                                                                <div
                                                                  key={subRess.id}
                                                                  className="form-check form-check-inline"
                                                                >
                                                                  <input
                                                                    className="form-check-input form-check-radio"
                                                                    type="radio"
                                                                    id={
                                                                      subRess.id
                                                                    }
                                                                    {...field}
                                                                    value={
                                                                      subRess.id
                                                                    }
                                                                  />
                                                                  <label
                                                                    className="form-check-label"
                                                                    htmlFor={
                                                                      subRess.id
                                                                    }
                                                                  >
                                                                    {
                                                                      subRess.label
                                                                    }
                                                                  </label>
                                                                </div>
                                                              </>
                                                            );
                                                          }
                                                        );
                                                      }}
                                                    </Field>
                                                  </div>
                                                  <ErrorMessage
                                                    name={
                                                      profileQuestion[ress]
                                                        ?.fieldId
                                                    }
                                                    component={TextError}
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              { (profileQuestion[ress]["attributes"]?.type === "multiselect" &&
                                                  profileQuestion[ress]["attributes"]?.subtype === "depdropdown") ? (
                                                <>
                                                  <div className="interests-section-parent">
                                                    <div className="interests-section-left">
                                                    <Label className="adres-form-heading"
                                                      label="Interests"
                                                      required={
                                                        profileQuestion[ress]
                                                          ?.attributes?.required
                                                      }
                                                    />
                                                    <div className="form-group-inline">
                                                      <Label
                                                        name="Category"
                                                        className="form-label"
                                                        label={profileQuestion[ress]?.label  || "Category"}
                                                      />
                                                      <SingleSelect
                                                        className="form-control-select form-control"
                                                        placeholder="Please select an option"
                                                        // value={tableControl.pageSize}
                                                        onChange={(e: any) => {
                                                          const { value } = e;
                                                          formik.setFieldValue("interestprimarycategory", value?.toString());
                                                          formik.setFieldValue("interestsubcategories", "");
                                                          onSelectCategory(
                                                            formik, 
                                                            e.target,
                                                            value, 
                                                            profileQuestion[ress].option.filter((o: any) => o.value === value)
                                                          );
                                                        }}
                                                        onInputChange={(e: any) => {
                                                          getformStatus(formik);
                                                        }}
                                                        isSearchable={true}
                                                        options={profileQuestion[ress].option}
                                                        value={
                                                          formik?.values["interestprimarycategory"]
                                                            ? selectedCategoryDropdown?.depdropdown?.subcategory?.filter(
                                                              (ress: any) =>
                                                                ress?.value ===
                                                                formik?.values["interestprimarycategory"]
                                                            )[0]
                                                            : ""
                                                        }
                                                      />
                                                      {
                                                        formik?.values["interestprimarycategory"] ? (
                                                          <span 
                                                            className="category-icon pi pi-times" 
                                                            title="clear category selection"
                                                            onClick={
                                                              () => onClearPrimaryCategory(formik)
                                                            }
                                                          ></span>
                                                        ) : ""
                                                      }
                                                      </div>
                                                      <div className="form-group-inline">
                                                        {/* <label className="form-label">{selectedCategoryDropdown?.depdropdown?.label || "Sub-Category"}</label> */}
                                                        <Label
                                                          name="Sub-Category"
                                                          className="form-label"
                                                          label={selectedCategoryDropdown?.depdropdown?.label || "Sub-Category"}
                                                        />
                                                        <SingleSelect
                                                          className="form-control-select form-control"
                                                          placeholder="Please select an option"
                                                          onChange={(e: any) => {
                                                            const { value, id } = e;
                                                            formik.setFieldValue(
                                                              "interestsubcategories",
                                                              value?.toString()
                                                            );
                                                            onSelectSubCategory(formik, e.target, value, selectedCategoryDropdown?.depdropdown?.subcategory);
                                                          }}
                                                          onInputChange={(e: any) => {
                                                            getformStatus(formik);
                                                          }}
                                                          isSearchable={true}
                                                          value={
                                                            formik?.values["interestsubcategories"]
                                                              ? selectedCategoryDropdown?.depdropdown?.subcategory?.filter(
                                                                (ress: any) =>
                                                                  ress?.value ===
                                                                  formik?.values["interestsubcategories"]
                                                              )[0]
                                                              : ""
                                                          }
                                                          options={selectedCategoryDropdown?.depdropdown?.subcategory?.map((e:any) => ({
                                                            label: e.value,
                                                            value: e.attributes.id
                                                          }))}
                                                        />   
                                                        {
                                                          formik?.values["interestsubcategories"] ? (
                                                            <span 
                                                              className="category-icon pi pi-times" 
                                                              title="clear category selection"
                                                              onClick={
                                                                () => onClearSubCategory(formik)
                                                              }
                                                            ></span>  
                                                          ) : ""
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                      <button type="button" className="btn btn-primary completion-btn-primary"
                                                        onClick={() =>
                                                          addInterest(formik, profileQuestion[ress].option)
                                                        }
                                                      >Add Interest <i className="pi pi-arrow-right"></i></button>
                                                    </div>
                                                    {formik.status && formik.status.message && (
                                                      <div className="error-message">{`${formik.status.message}`}</div>
                                                    )}
                                                    
                                                    </div>
                                                    <div className="interests-section-right">
                                                      <h3 className="adres-form-heading">Selected Interests</h3>
                                                      <div className="interests-section-right-box-parent"> 
                                                      <Field type="textarea" name="categoryList" hidden="hidden"></Field>
                                                      <FieldArray
                                                        name="categoryList"
                                                        render={(arrayHelpers:any) => { 
                                                          return (
                                                            <>
                                                              {
                                                                addedCategoryReferenceList.map((pairItem:any, index:any) => {
                                                                  return(
                                                                    <>
                                                                    {
                                                                      pairItem?.categoryLabel ? (
                                                                        <div key={index}>
                                                                          <div className="interests-section-right-box">
                                                                            <div className="interest-select-value">{`${pairItem?.categoryLabel} ${pairItem?.subcategoryLabel ? (" - " + pairItem?.subcategoryLabel) : ""}`}</div>   
                                                                            <a className="delete-interest" onClick={() => removeCategoryPair(formik, pairItem)}>
                                                                              <Image src={DeleteIcon} alt="delete" />
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      ) : ""
                                                                    }
                                                                    </>
                                                                  );
                                                                })
                                                              }
                                                            </>
                                                        );}
                                                      }
                                                      />                                                        
                                                      </div>
                                                    </div>                                                                                                        
                                                  </div>     
                                                  <div className="ps-3">
                                                    <ErrorMessage name="categoryList" component={TextError}/>
                                                  </div>                                                  
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              { (profileQuestion[ress]["attributes"]?.type === "multiselect" &&
                                                  profileQuestion[ress]["attributes"]?.subtype !== "depdropdown") ? (
                                                <>
                                                  <div className="mb-3">
                                                    <Label
                                                      className="form-label"
                                                      label={
                                                        profileQuestion[ress]
                                                          ?.label
                                                      }
                                                      required={
                                                        profileQuestion[ress]
                                                          ?.attributes?.required
                                                      }
                                                    />
                                                    <div className="card p-2  job-multi-checkbox">
                                                      <Field
                                                        name={
                                                          profileQuestion[ress]
                                                            ?.fieldId
                                                        }
                                                        onBlur={(e: any) =>
                                                          e.preventDefault()
                                                        }
                                                        validate={
                                                          profileQuestion[ress]
                                                            ?.attributes?.required
                                                            ? fieldValidation
                                                            : ""
                                                        }
                                                      >
                                                        {({ field }: any) => {
                                                          return profileQuestion[
                                                            ress
                                                          ].option?.map(
                                                            (subRess: any) => {
                                                              // console.log(subRess);

                                                              return (
                                                                <>
                                                                  <div
                                                                    key={
                                                                      subRess.id
                                                                    }
                                                                    className="form-check form-check-inline"
                                                                  >
                                                                    <input
                                                                      className="form-check-input"
                                                                      type="checkbox"
                                                                      id={
                                                                        subRess.id
                                                                      }
                                                                      {...field}
                                                                      value={
                                                                        subRess.id
                                                                      }
                                                                      checked={field?.value?.includes(
                                                                        subRess.id
                                                                      )}
                                                                    />
                                                                    <label
                                                                      className="form-check-label"
                                                                      htmlFor={
                                                                        subRess.id
                                                                      }
                                                                    >
                                                                      {
                                                                        subRess.label
                                                                      }
                                                                    </label>
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          );
                                                        }}
                                                      </Field>
                                                    </div>
                                                    <div className="pt-1">
                                                      <ErrorMessage
                                                        name={
                                                          profileQuestion[ress]
                                                            ?.fieldId
                                                        }
                                                        component={TextError}
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className={`${jobPreViewSection ? "col-6" : "col-12"
                                }`}>
                              <div className="form-check form-check-inline">
                                <Field type="checkbox" name="tAndC" className="form-check-input"  id="tAndC" />                              
                                {parseTNC()}
                              </div>
                              <ErrorMessage name="tAndC" component={TextError} className="invalid-feedback" />                              
                            </div>
                          </div>
                          {
                            formik?.errors && <ErrorSummary errors={formik.errors}/>
                          }                      
                          <div
                            className="text-end mb-3 d-xl-block d-none"
                            ref={submitButtonRef}
                          >
                            <Button
                              id="form-submit"
                              type="submit"
                              icon="pi pi-arrow-right"
                              position=""
                              label={
                                <div className="d-flex">
                                  <div className="submit-job">
                                    <i className="pi pi-check "></i>
                                  </div>
                                  <div className="">Submit Application</div>
                                </div>
                              }
                              clasName="btn btn-primary completion-btn-primary mt-3"
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    ""
                  )}
                </div>
                
              </div>
              <div
                ref={sideBarWidth}
                className={`mt-4 ${jobPreViewSection ? "col-md-6 open-animet" : "d-none"
                  }`}
              >
                {/* style={{ minHeight: jobPreViewSection ? `${observedDiv?.current?.offsetHeight + submitButtonRef?.current?.offsetHeight + dynamikFormDataRef?.current?.offsetHeight + 68}px` : "0px" }} */}
                <div className="job-sidebar">
                  {/* <JobView /> */}
                  {/* pageHight={jobPreViewSection ? `${observedDiv?.current?.offsetHeight + submitButtonRef?.current?.offsetHeight + dynamikFormDataRef?.current?.offsetHeight + footerheight?.current?.offsetHeight}px` : "0px"}  */}
                  {/* // pageHight={(observedDiv?.current?.offsetHeight + submitButtonRef?.current?.offsetHeight + dynamikFormDataRef?.current?.offsetHeight + footerheight?.current?.offsetHeight) + 27} */}

                  <JobPreView
                    pageHight={careersTotalBody}
                  // pageHight={overAllSection?.current?.offsetHeight + residentialAddress?.current?.offsetHeight + documentationDiv?.current?.offsetHeight + footerheight?.current?.offsetHeight + 24}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-xl-none d-lg-block d-md-block d-sm-block d-block mobile-screen">
            <Button
              onClick={() => setJobModel(true)}
              type="button"
              icon=""
              position=""
              label={
                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-3">
                    <i className="pi pi-eye "></i>
                  </div>
                  <div className="preview">Preview Job Ad</div>
                </div>
              }
              clasName="btn  w-100 job-view-button mt-3"
            />
            <Button
              onClick={() => document.getElementById("form-submit")?.click()}
              type="button"
              icon="pi pi-arrow-right"
              position=""
              label={
                <div className="d-flex justify-content-center">
                  <div className="submit-job">
                    <i className="pi pi-check "></i>
                  </div>
                  <div className="">Submit Application</div>
                </div>
              }
              clasName="btn btn-primary w-100 completion-btn-primary mt-3 mb-3"
            />
          </div>
          <div ref={footerheight} className="footer-par">
            {clientIds.pifs === clientConfig?.data?.data?.clientSettings?.clientId ? (
              <>
                <CareersFooter />
              </>
            ) : (
              ""
            )}
            {/* <CareersFooter /> */}
          </div>
          <Model
            className="modal-mobile-control"
            show={jobModel}
            size="lg"
            modelHeader={JobViewModelHeader()}
            modelBody={JobViewModel()}
          />
        </>
      ) : (
        <>
          {jobDetails?.data?.statusCode === 400 ? (
            <div className="card m-5">
              <div className="card-body text-center">
                {jobDetails?.data?.message}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default JobApplay;
