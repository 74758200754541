import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { mapSecretkey } from "../../../config/map/mapData";
import { useNavigate } from "react-router-dom";

const JobMapList = (props: any) => {
    const { jobList } = props;
    const navigate = useNavigate();

    const defaultProps = {
        center: {
            lat: 59.955413,
            lng: 30.337844
        },
        zoom: 11
    };
    const locationView = (params: any) => {

        return (
            <>



            </>
        );
    };

    const Markers = (props: any) => {
        const { color, name } = props;
        const [openClose, setopenClose] = useState<boolean>(false);
        useEffect(() => {
            if (props.$hover) {
                setopenClose(props.$hover);
            }
        }, [props.$hover]);

        return (
            <>
                <div className="d-flex justify-content-center">
                    <div
                        className="pin bounce"

                        title={name.title}
                    />
                    <div className="pulse" >
                    </div>
                    {openClose ?
                        <div onMouseOut={() => setopenClose(false)} onMouseOver={() => setopenClose(true)} className="location-tool-tip">
                            <div className="d-flex justify-content-between p-1">
                                <div className="">
                                    <h4 role="button" className="mb-0 title" onClick={() =>
                                        navigate(`view/${name.id}`, { state: "jobView" })
                                    }>{name.title}</h4>
                                    <div className="location.text">{name?.location?.location}</div>
                                </div>
                                <div className="text-end">
                                    <i className="pi pi-star icon-star"></i>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div className="location-para overflow-para" dangerouslySetInnerHTML={{ __html: name.description }}></div>
                            </div>
                        </div>
                        :
                        ""
                    }
                </div >
            </>
        );
    };

    return (
        <>
            <div className="" style={{ height: "100vh", width: "100%" }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: mapSecretkey }}
                    defaultCenter={{
                        lat: parseFloat(jobList[0].location.latitude.toString()),
                        lng: parseFloat(jobList[0].location.longitude.toString()),
                    }}
                    defaultZoom={10}
                >
                    {jobList && jobList.map((ress: any) => (
                        <Markers
                            key={ress.id}
                            lat={parseFloat(ress.location.latitude.toString())}
                            lng={parseFloat(ress.location.longitude.toString())}
                            name={ress}
                            color="red"
                        />
                    ))}
                </GoogleMapReact>
            </div>
        </>
    );
};

export default JobMapList;

