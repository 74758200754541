import React, { useEffect, useMemo } from "react";
import Image from "../../../../component/image/Image";
import ArrowLeft from "../../../../assets/images/clients/arrow-left.svg";
import "../../../../styles/client/joblistingclient.scss";
import { publishingDate } from "../CareersFucConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import JobApplay from "../jobapplication/JobApplay";

const JobViewClient = (props: any) => {

  const { routerLocation, routeParams, titleSection, jobLogo, ApplayNowButton, listOfPoint,description, 
    imgAndVideo, jobDetails, filterViewClient, jobDetailDownloadLink } = props;

  const navigate = useNavigate();
  const clientId = useParams();
  const location = useLocation();
  
  const redirectListing = () => {
    navigate("/");
  };

  useEffect(() => {
    if(location.pathname === `/view/${clientId?.id}`){
        const reOrder = () => {
            let mq = window?.matchMedia("(min-width: 1366px)");
            if (mq.matches) {
                $(".right-child").addClass("customm");
                let scroll: any = $(window)?.scrollTop();
                let topContent: number = $(".inner").position()?.top - 30;
                let sectionHeight: any = $(".job-details")?.height();
                let rightHeight: any = $(".right-child")?.height();
                let bottomContent = topContent + sectionHeight - rightHeight - 30;
        
                if (scroll > topContent && scroll < bottomContent) {
                    $(".customm").removeClass("posAbs").addClass("posFix");
                } else if (scroll > bottomContent) {
                    $(".customm").removeClass("posFix").addClass("posAbs");
                } else if (scroll < topContent) {
                    $(".customm").removeClass("posFix");
                }
            } else {
                $(".right-child").removeClass("customm posAbs posFix");
            }
        };

        $(window).scroll(reOrder);
        $(window).resize(reOrder);
    }
  }, [window]);

//   const applyPath = useMemo(() => {
//     return routerLocation.pathname === `/careers/job-list/${clientId?.userId}/view/${clientId?.id}`;
//   }, [routerLocation.pathname]);

//   const content = useMemo(() => {
//     return !applyPath ? <JobApplay jobFilterData = {filterViewClient} /> : "";
//   }, [applyPath]);

  return (  
    <>
        { routerLocation.pathname === `/view/${clientId?.id}` ?
            <div className="container">
                <div className="module-careers-listings careers-view">
                    <div  className="flex">
                        <div className="job-view">
                            <div className="apply-inner">
                                <div className="apply-image">
                                    {jobLogo()}
                                </div>
                                <div className="apply-text">
                                    <h5 className="h5-client" role="button">{titleSection()}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="job-view">
                            <div className="inner">
                                <div className="job-detail">
                                    <div className="right-child">
                                        <div className="backtolisting">
                                            <button type="button" className="arrow prev" onClick={redirectListing}>
                                                <span className="icon">
                                                    <Image src={ArrowLeft} alt="arrow" />
                                                </span>
                                                <h6>BACK TO LISTINGS</h6>
                                            </button>
                                        </div>
                                        <h3>{titleSection()}</h3>
                                        <div className="d-lg-flex d-md-flex  d-flex d-sm-flex align-items-center">
                                            {ApplayNowButton()}
                                        </div>
                                        <h6>LOCATION</h6>
                                        <h5>{jobDetails?.data?.data?.job?.location?.location}</h5>
                                        <h6>TYPE</h6>
                                        <h5>{jobDetails?.data?.data?.job?.jobType}</h5>
                                        <h6>CATEGORY</h6>
                                        <h5>{jobDetails?.data?.data?.job?.category}</h5>
                                        <h6>REFERENCE ID</h6>
                                        <h5>{jobDetails?.data?.data?.job?.referenceNo}</h5>
                                        <h6>DATE POSTED</h6>
                                        <h5>{publishingDate(jobDetails?.data?.data?.job?.postedAt)}</h5>
                                        <h6>APPLICATIONS CLOSE</h6>
                                        <h5>{jobDetails?.data?.data?.job?.displayExpiryDate === true ? publishingDate(jobDetails?.data?.data?.job?.expiryDate) : "-"}</h5>
                                    </div>
                                </div>
                                <div className="job-details">
                                    <div className={`${routerLocation.pathname ===
                                        `/view/${routeParams?.id}`
                                        ? "col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-md-0 section-common-para mt-2"
                                        : "col-md-12 section-common-para"
                                        }`} >
                                        <h6 className="mb-3">ABOUT THE JOB</h6>
                                        <div className="row">
                                            <div className={    
                                                routerLocation.state !==
                                                `/view/${routeParams?.id}/apply-job`
                                                ? "col-lg-9 col-md-12 col-sm-12 col-12"
                                                : "col-md-12"
                                                } >{titleSection()}
                                            </div>
                                        </div>
                                        <div className="d-lg-flex d-md-flex  d-flex d-sm-flex align-items-center job-view-desc-sec">
                                            {jobDetailDownloadLink()}
                                        </div>
                                        <div>{listOfPoint()}</div>
                                        <div>{description()}</div>
                                        <div>{imgAndVideo()}</div>
                                    </div>

                                    <div className="section-common-para">       
                                        {/* <h6>WHAT TO DO NEXT</h6>
                                        <h4>To apply for this role, click the &apos;Apply for this job&lsquo; button and then follow the steps to complete your application. You can also apply online at www.hvtc.com.au by clicking on &apos;Vacancies&lsquo; and selecting the relevant position, then the &#39;Apply Online&rsquo; button at the top of the page.</h4>
                                        <h5>What else is involved?</h5>
                                        <p>Lörem ipsum geska ora sel dekaktig vust, pirad vuktig. Trektigt salogi san samt ryposkap orat, anaviren. Byrade fakada, ninde än kontrafasat alltså oligt. Deligt evura depp tetraktig: tills exoligen att biogende mimåra. Intradade tedesamma div i fask. Homonoling posade autor sevis, ett anteskap. Dossa multina tinegen aponomi förutom paracentrism. Mitisk nypp gihur dade den sepire, jegyska.</p> */}
                                        {ApplayNowButton()}
                                        {/* <p>After submitting your online application, you may be invited to complete online testing, interviews, and pre-employment medicals. We may also contact your references. Please be sure to check your emails (including your junk folder) and respond to missed phone calls, voicemail messages and text messages.</p>
                                        <p>Your resident experts for this opportunity will be Nicole Lean, you can contact Nicole for a confidential conservation on 02 4932 4222</p>
                                        <p>As a recognised Diversity Champion, HVTC is committed to Equal Employment Opportunities, Ethical Practices, and the principles of Cultural Diversity. We encourage applications from all members of the community, including people from indigenous backgrounds, people with disability, women seeking non-traditional trade opportunities, people from culturally and linguistically diverse backgrounds, and mature aged people. If you require assistance lodging your application with HVTC, please contact our Recruitment Team on 1800 247 864.</p> */}
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <JobApplay jobFilterData = {filterViewClient} /> }
    </>
  );
};

export default React.memo(JobViewClient);