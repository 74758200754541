import React from "react";
import { ImageInterFace } from "../../config/interface/htmlInterFace";
const Image = (props: ImageInterFace) => {
    const { src, clasName, alt, width, height, onClick, role } = props;
    return (
        <>
            <img role={role} src={src} alt={alt} onClick={onClick} className={clasName} width={width} height={height} />
        </>
    );
};

export default Image;