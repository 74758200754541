import React, { useEffect, useState } from "react";
import Image from "../../component/image/Image";
import chevronOrange from "../../assets/images/clients/chevron-orange.svg";

const Accordion = ({controllerElement, children, responsiveBreakPoint}:any) => {

    const [isExpanded, setIsExpanded] = useState(true);

    const [isDisabled, setDisable] = useState(true);

    useEffect(() => {
        if (responsiveBreakPoint && screen.width < responsiveBreakPoint) {
            setDisable(false);
            setIsExpanded(false);
        }
    }, []);

    const clickHandler = () => {
        setIsExpanded(prevIsExpanded => !prevIsExpanded);
    };

    return (
        <>
        <div className="accordion-parent">
            <div className={isExpanded ? "accordion-open" : "accordion-closed"}
                {
                    ...(!isDisabled && { onClick : clickHandler})
                }
            >
                {controllerElement(isExpanded)}
            </div>
            {isExpanded && <div className="accordion-open-children">{children}</div>}
            </div>
        </>
    );
};

export default Accordion;