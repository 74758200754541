import React from "react";
import { ButtonInterFace } from "../../config/interface/htmlInterFace";

const Button = (props: ButtonInterFace) => {
    const { label, clasName, type, onClick, icon, position, id } = props;
    return (
        <>
            <button id={id} onClick={onClick} className={clasName} type={type}>
                {position === "left" ?
                    <span className="me-2"> <i className={icon} ></i></span>
                    : ""}
                {label}
                {position === "right" ?
                    <span className="ms-2"> <i className={icon} ></i> </span>
                    :
                    ""
                }
            </button>
        </>
    );
};

export default Button;