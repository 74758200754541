import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import { apiGet, apiPost } from "../../service/apiService";
import { endPoint } from "../../config/endPoint";
import { clients } from "../../config/clients/clients";
import { domainWithHttp } from "../../config/onboarding/onBoarding";
import { candidateDataPostProcess } from "../../service/apiPostProcess";

interface NewRoleSlice {
    loading: boolean,
    error: any,
    candidateData: any | null,
    createCandidateDataDocuments: any | null,
    teamConfig: any | null
    activeClient: any | null
}

const initialState: NewRoleSlice = {
    loading: false,
    error: null,
    candidateData: null,
    createCandidateDataDocuments: null,
    teamConfig: null,
    activeClient: null
};

const getActiveSession = {
    clientId : sessionStorage.getItem("clientId"),
    domainWithHttp : domainWithHttp
};

export const getCandidateData = createAsyncThunk("newRole/getcandidatedata", async (payload: any) => {
    // const ress = await apiGet(endPoint.getcandidatedata + `${payload}`, payload);
    const ress = await apiGet(endPoint.getcandidatedata + `/${payload?.clientId + "?domainWithHttp=" + payload?.domainWithHttp}`, payload);    
    return candidateDataPostProcess(ress);
});

export const createCandidateDataDocuments = createAsyncThunk("newRole/createCandidateDataDocuments", async (payload: any) => {
    const ress = await apiPost(endPoint.createcandidatedata + (`?${"domainWithHttp=" + getActiveSession.domainWithHttp}`), payload);
    return ress;
});
export const complianceTeamConfig = createAsyncThunk("newRole/complianceTeamConfig", async (payload: any) => {
    const ress = await apiGet(endPoint.complianceConfig + `${payload}` + (`?${"domainWithHttp=" + getActiveSession.domainWithHttp}`), "");
    return ress;
});

const newRoleSlices = createSlice({
    name: "newRole",
    initialState,
    reducers: {
        loderControl: (state) => {
            state.loading = true;
        },
        loderOffControl: (state) => {
            state.loading = false;
        },
        removeCandidateData: (state) => {
            state.candidateData = null;
        },
        updateUserData: (state, action) => {
            state.candidateData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCandidateData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCandidateData.fulfilled, (state, action: any) => {
                state.loading = false;
                state.candidateData = action.payload.data;
                state.createCandidateDataDocuments = null;
            })
            .addCase(getCandidateData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;

            })

            .addCase(createCandidateDataDocuments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createCandidateDataDocuments.fulfilled, (state, action: any) => {
                state.loading = false;
                state.createCandidateDataDocuments = action.payload;
            })
            .addCase(createCandidateDataDocuments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(complianceTeamConfig.fulfilled, (state, action) => {
                // state.loading = false;
                state.teamConfig = action.payload.data;
                state.activeClient = action?.payload?.data?.statusCode === 200 && clients.filter((ress: any) => ress?.clientId === action.payload?.data?.data?.clientSettings?.clientId);
                // console.log(action?.payload?.data?.statusCode === 200 && clients.filter((ress: any) => ress?.clientId === action.payload?.data?.data?.clientSettings?.clientId));

            })
            .addMatcher(isPending(complianceTeamConfig), (state, action: any) => {
                state.loading = true;
            })
            .addMatcher(isRejected(complianceTeamConfig), (state, action: any) => {
                state.loading = false;
            });


    },
});
export const { loderControl, loderOffControl, removeCandidateData, updateUserData } = newRoleSlices.actions;
export default newRoleSlices.reducer;
