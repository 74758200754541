import React from "react";
import MobilSearch from "../../../assets/images/mobil-search.png";
import MobilSearchNew from "../../../assets/images/mobil-search-2.png";
import Image from "../../../component/image/Image";
import SingleSelect from "../../../component/singleSelect/SingleSelect";
import ShareIcon from "../../../assets/images/share_icon.png";
import DataIcon from "../../../assets/images/date-icon.svg";
import Pagination from "../../../component/pagination/Pagination";
import JobMapList from "./JobMapList";
import FilterPageTwo from "./FilterPageTow";
import CareersFooter from "../CareersFooter/CareersFooter";
import JobView from "./jobView/JobView";
import { customFooterIds } from "../../../config/clients/clients";
import CareersCustomFooter from "../CareersFooter/CareersCustomFooterBase";

const JobListingsSplit = (props: any) => {

  const { location, clientId, checkListControl, jobsList, searchMobileFiler, filterViews, setListView, listView, 
    setTableControl, tableControl, sortList, pageSizeChange, displayPage, filterView, moveToJobViewPage,
    publishingDate, pageChange, clientIds, filterListData, searchControlSubmit, navigateToJob }  = props;
 
    //Resolves if the current client has a custom footer using the inbuilt clientids
    const hasCustomFooter = (id:any) => {
        return customFooterIds.includes(id);
    };
  return (
    <div>
        {location.pathname === "/" ?
            <div className="">
                <div className="container-fluid job-list pb-3">

                    <div className="page-content-inner pt-lg-4 pt-md-4 pt-sm-3 pt-3">
                        <div className="row">
                            <div className="col-xxl-8 col-lg-8 col-md-12 col-sm-12 col-12 mob-job-search-list-hide">
                                <div className="job-filter-box">
                                    <ul className="job-filter-box-list">
                                        <li className="li-1"><p>
                                            {
                                                checkListControl ?
                                                    <>Search Results</>
                                                    :
                                                    <>Showing All</>
                                            }
                                            <span className="primary-color ms-2">{jobsList?.data?.data?.jobs?.totalRecord}</span></p>

                                            <div className="d-sm-flex d-lg-none d-md-flex  d-flex justify-content-end ms-auto">
                                                <Image onClick={() => searchMobileFiler("listAndMapView")} src={MobilSearchNew} />
                                                <Image onClick={() => searchMobileFiler("filterView")} clasName={"ms-4"} src={MobilSearch} />
                                            </div>
                                        </li>

                                        {filterViews.listAndMapView ?
                                            <>
                                                <li className="ml-auto">
                                                    <div className="custom-btn-small-parent">
                                                        <button onClick={() => setListView("list")} className={`btn custom-btn-smal  btn-sm ${listView === "list" ? "active" : ""} `}>List View</button>
                                                        <button onClick={() => setListView("map")} className={`btn custom-btn-smal  btn-sm ${listView === "map" ? "active" : ""} `}>Map View</button>
                                                    </div>
                                                </li>

                                                <li className=" mob-menu-hide mob-sort-filter">
                                                    <div className="d-flex top-filter position-relative">
                                                        <div className="custom-single-filter-parent">
                                                        <label>Sort:</label>
                                                        <SingleSelect className="sort-control"
                                                            placeholder="" onChange={(e: any) => {
                                                                setTableControl((prs: any) => {
                                                                    return {
                                                                        ...prs,
                                                                        sort: e
                                                                    };
                                                                });

                                                            }}
                                                            value={tableControl.sort}
                                                            options={sortList}
                                                            isSearchable={false}

                                                        />

                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="mob-menu-hide mob-sort-filter">
                                                    <div className="position-relative">
                                                        <div className="d-flex top-filter pl-0">
                                                            <div className="custom-single-filter-parent">
                                                            <label>Display:</label>
                                                            {/* <span className="select-box">
                                                        <select id="per-page" value={tableControl.pageSize}
                                                            onChange={(e) => pageSizeChange(e)}
                                                            className=" custon-select border-0 form-control form-select drop-down-arrow">
                                                            {displayPage.map((ress) => {
                                                                return (
                                                                    <option key={ress.id} value={ress.id}>{ress.name}</option>
                                                                );
                                                            })}
                                                        </select>

                                                    </span> */}

                                                            <SingleSelect className="sort-control"
                                                                placeholder="" onChange={(e: any) => {
                                                                    pageSizeChange(e);
                                                                }}
                                                                value={tableControl.pageSize}
                                                                options={displayPage}
                                                                isSearchable={false}
                                                            />

                                                            {/* <div className="drop-down-arrow"></div> */}
                                                            {/* <i className="pi pi-angle-down element-control sort-icon"></i> */}
                                                        </div>
                                                        </div>
                                                    </div>

                                                </li>
                                            </>
                                            : ""}

                                    </ul>
                                </div>
                                {
                                    filterViews.filterView ?
                                        <div className="col-xxl-0 col-lg-0 col-md-12 col-sm-12 col-12 mob-job-search-parent d-md-block d-sm-block d-lg-none">
                                            {filterView()}
                                            {/* <JobFilter filter={filterListData} tableControl={tableControl} setTableControl={setTableControl} removeSearchForm={removeSearchForm} searchControlSubmit={searchControlSubmit} /> */}

                                        </div>
                                        : ""
                                }
                                {tableControl.data.length > 0 ?
                                    <>

                                        {listView === "list" ?
                                            <>
                                                {tableControl.data.map((ress: any, i: number) => {

                                                    return (
                                                        <div key={ress.id} className="job-list-parent">
                                                            <div className="job-list-heading">
                                                                <div className="row align-items-center">
                                                                    <div className="col-lg-9 col-sm-9 col-9 col-mob-12">
                                                                        <h2 role="button" onClick={() => moveToJobViewPage(ress)}>{ress.title}</h2>
                                                                        <h3>{ress.location?.location}</h3>
                                                                        <div className="job-details-parent">
                                                                            {/* <div className="common-before-icon">
                                                                                <span className="badge">
                                                                                    <i className="pi pi-thumbs-up-fill pe-2"></i>
                                                                                    Like
                                                                                </span>
                                                                            </div> */}
                                                                            {/* <div className="common-before-icon">
                                                                                <span className="badge d-flex align-items-center">
                                                                                    <Image src={ShareIcon} width={15} />
                                                                                    <div className="ps-1">
                                                                                        Share
                                                                                    </div>
                                                                                </span>
                                                                            </div> */}
                                                                            <div className="common-before-icon post-to pl-0">
                                                                                <Image src={DataIcon} />
                                                                                <span className="mob-hide-content ms-1 d-sm-none d-none d-md-block">
                                                                                    Posted On:
                                                                                </span>
                                                                                <span className="post-on ms-md-1 ms-sm-1 ms-1">{publishingDate(ress.postedAt)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-sm-3 col-3 col-md-3 pl-0 text-right">
                                                                        <div className="job-list-img text-center">
                                                                            <img src={ress.logo ?
                                                                                ress.logo
                                                                                :
                                                                                "https://stag-compliance-careers.s3.ap-southeast-2.amazonaws.com/career-doc-settings/uploads/no_image.png"
                                                                            } className="img-fluid" alt="logo" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="job-list-content">
                                                                <div className="row">
                                                                    <div className="col-lg-12 "  >
                                                                        <div className="overflow-para job-view-description" dangerouslySetInnerHTML={{ __html: ress.description }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                })}
                                                <div className="d-flex justify-content-center mt-3">
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={tableControl.currentPage}
                                                        totalCount={tableControl.totalCount}
                                                        pageSize={tableControl.pageSize?.value}
                                                        onPageChange={(page: any) => pageChange(page)}

                                                    />
                                                </div>
                                            </>
                                            :
                                            <JobMapList jobList={tableControl.data} />
                                        }
                                    </>
                                    :
                                    <>
                                        <div>
                                            <div className="job-list-parent">
                                                <div className="text-center no-found-data">
                                                    {tableControl.massage}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="display-control mt-3">
                                    <FilterPageTwo navigateToJob={navigateToJob} />
                                </div>

                            </div>
                            <div className="col-xxl-4 col-lg-4 col-md-0 col-sm-0 col-0 mob-job-search-parent d-md-none d-sm-none d-lg-block d-none mt-lg-0 mt-3">
                                {filterView()}
                                {/* <JobFilter filter={filterListData} tableControl={tableControl} setTableControl={setTableControl} removeSearchForm={removeSearchForm} searchControlSubmit={searchControlSubmit} /> */}

                            </div>
                        </div>
                    </div>
                </div>
                {
                    clientIds.pifs === clientId ?
                        <>
                            <CareersFooter />
                        </>
                            : hasCustomFooter(clientId) ?
                                <>
                                    <CareersCustomFooter/>
                                </>
                                :
                                ""
                }
            </div>
            :
            <>
                <JobView 
                    jobDataView={filterView()}
                    filterListData = {filterListData} 
                    tableControl = {tableControl} 
                    setTableControl = {setTableControl}
                    searchControlSubmit = {searchControlSubmit}
                    clientId = {clientId}
                />
            </>
        }
    </div>
  );
};

export default JobListingsSplit;