import React from "react";
import classnames from "classnames";
import "../../styles/client/joblistingclient.scss";
import Image from "../image/Image";
import ArrowLeft from "../../assets/images/clients/arrow-left.svg";
import ArrowRight from "../../assets/images/clients/arrow-right.svg";
import { usePagination } from "./usePagination";

const PaginationClient = (props: any) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange && paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const lastPage = paginationRange && paginationRange[paginationRange.length - 1];

    return (
        <>
            <button type="button" className={classnames("arrow prev", {
                    disabled: currentPage === 1
                })} onClick={onPrevious}>
                <span className="icon">
                    <Image src={ArrowLeft} alt="arrow" />
                </span>
                <span>Previous</span>
            </button>
            <div className="pages">
                {paginationRange.map((pageNumber: any, idx) => (
                    <button key={idx} type="button" className="arrow">
                        { (currentPage === pageNumber) ? 
                            <span className="icon page active" onClick={() => onPageChange(pageNumber)}>{`0${pageNumber}`}</span> : 
                            (pageNumber === "...") ? <span className="page">...</span> : 
                            <a className="page" onClick={() => onPageChange(pageNumber)}>{`0${pageNumber}`}</a> }
                    </button>
                ))}
            </div>
            <button type="button" className={classnames("arrow next", {
                    disabled: currentPage === lastPage
                })} onClick={onNext}>
                <span>Next</span>
                <span className="icon">
                    <Image src={ArrowRight} alt="arrow" />
                </span>
            </button>
        </>
    );
};

export default PaginationClient;