import { monthNames } from "../../../config/careers/careers";

export const publishingDate = (params: any) => {
  const ressData = new Date(params);
  // alert(ressData);
  const ressDataYear = Number(ressData.getFullYear());
  const ressDataMonth = Number(ressData.getMonth());
  const ressDataDay = Number(ressData.getDate());
  const currentDate: any = new Date();
  const currentDateYear = Number(currentDate.getFullYear());
  const currentDateMonth = Number(currentDate.getMonth());
  const currentDateDay = Number(currentDate.getDate());

  // let postedAt = "";
  if (
    ressDataDay === currentDateDay &&
    ressDataMonth === currentDateMonth &&
    ressDataYear === currentDateYear
  ) {
    return "Today";
  } else if (
    ressDataDay === currentDateDay - 1 &&
    ressDataMonth === currentDateMonth &&
    ressDataYear === currentDateYear
  ) {
    return "Yesterday";
  } else {

    return `${ressDataDay} ${
      monthNames[ressDataMonth === 0 ? 0 : Number(ressDataMonth)]
    } ${ressDataYear}`;
    // return ressData?.toString();
  }
  // return postedAt;
};
