export const clientNames = {
  holmesglen: "Holmesglen",
  fair: "Fair",
  pifs: "PIFS",
  jasmine: "Jasmine",
  holmesglen_copy: "Holmesglen_Copy",
  maha: "Maha",
  daflin: "Daflin",
  arun: "Arun",
  holmesglen_changes : "Holmesglen_Changes",
  demo_staging : "Demo_Staging",
  hvtc : "HVTC",
  pioneer : "Pioneer",
  upandgo : "Up_And_Go",
  vitality : "Vitality"
};

export const clientIds = {
  holmesglen: "f73c6f10-b6f6-42cf-8e6e-9ac42af573c8",
  fair: "f9d5e7f2-52c7-11ee-be56-0242ac120002",
  pifs: "e14dda40-66e9-492c-860c-e69f5e1c6c40",
  jasmine: "c258be90-2ab6-11ee-a4a3-311ec0a0253b",
  holmesglen_copy: "932673ae-81b0-431e-bdbb-dc099c377547",
  maha: "7652d2c0-2ab7-11ee-9b1d-2ba861fc4215",
  daflin: "632c3660-2ab7-11ee-a126-19e89bb358ab",
  arun: "4bba73b0-2ab7-11ee-b79e-cd40a2528b86",
  holmesglen_changes : "0881e07e-def0-4304-b617-6e9db792f438",
  demo_staging : "eaf27354-9fb9-4800-8e7a-fed01ebebbf5",
  hvtc : "11b87bdd-d2a8-47d1-b91a-e966ef5b7ea6",
  pioneer : "f0a199c4-fec6-410f-9d5b-a4a7cebee28f",
  upandgo : "1f44f206-32b1-46c3-9627-0b4e1ac02c27",
  vitality : "9b63a1f8-1792-40d7-bac2-10c28fb637c4"
};

// This array contains all ids that have the new custom menu setup, add ids here to extend this list to new clients
export const customMenuIds = [clientIds.pioneer, clientIds.upandgo, clientIds.vitality];
export const customFooterIds = [clientIds.pioneer, clientIds.upandgo, clientIds.vitality];

/**
 * uses this setting for custom menu's UI elements and assets
 * @description menuType : 1 - normal menu, 2 - mega menu
 */
export const customMenuUISettings = [
  {
    id : clientIds.pioneer,
    classref : "pioneer-foods custom-menu",
    submenuContainer : false,
    logoimg : "/images/pioneer/logo.png",
    moblogoimg: "/images/pioneer/logo-new.png",
    bannerImg : "/images/pioneer/banner.jpg",
    artifactImg1 : "",
    artifactImg2 : "",
    copyrightLogo : "/images/pioneer/BRCGS_CERT_FOOD_LOGO_RGB.svg",
    footerLogoImg : "/images/pioneer/foo-logo.png",
    menuType : "1",
    menuToggle: "/images/pioneer/menu_toggle.png",
    homeLinkHref: "https://pioneer-foods-uk.com/"
  },
  {
    id : clientIds.upandgo,
    classref : "upandgo custom-menu",
    submenuContainer : false,
    logoimg : "/images/upandgo/foo-logo.svg",
    bannerImg : "/images/upandgo/banner.jpg",
    artifactImg1 : "/images/upandgo/home-powered-by-oats.png",
    artifactImg2 : "/images/upandgo/home-shake-up-your-morning-title.png",
    artifactImg3 : "/images/upandgo/home-banana-honey-hero.png",
    copyrightLogo : "",
    footerLogoImg : "/images/upandgo/foo-logo.svg",
    menuToggle: "/images/upandgo/menu-open-black.svg",
    menuToggleClose: "/images/upandgo/menu-close-black.svg",
    menuType : "1",
    tiktokIcon: "/images/upandgo/logo-tiktok.svg",
    homeLinkHref: "https://www.upandgo.co.uk/"
  },
  {
    id : clientIds.vitality,
    classref : "vitality custom-menu",
    submenuContainer : true,
    logoimg : "/images/vitalityworks/logo.png",
    bannerImg : "/images/vitalityworks/banner.jpg",
    artifactImg1 : "",
    artifactImg2 : "",
    copyrightLogo : "",
    footerLogoImg : "/images/vitalityworks/foo-logo.svg",
    menuToggle: "/images/vitalityworks/menu-open-black.svg",
    menuToggleClose: "/images/vitalityworks/menu-close-black.svg",
    menuType : "2",
    tiktokIcon: "/images/vitalityworks/logo-tiktok.svg",
    homeLinkHref: "https://vitalityworks.health"
  }
];

//Controls responsive UI elements, ex: accordion component
export const responsiveSettings = {
  pioneerMenuBreakpoint : 768,
  upandgoFooterBreakpoint : 899,
  vitalityMenuBreakpoint : 960
};

export const customFooterUISettings = [
  {
    id : clientIds.pioneer,
    logoimg : "../../images/pioneer/logo.png",
    bannerImg : "../../images/pioneer/banner.jpg",
    copyrightLogo : "../../images/pioneer/BRCGS_CERT_FOOD_LOGO_RGB.svg",
    footerLogoImg : "../../images/pioneer/foo-logo.svg",
    menuType : "1"
  },
  {
    id : clientIds.upandgo,
    logoimg : "../../images/upandgo/logo.png",
    bannerImg : "../../images/upandgo/banner.jpg",
    copyrightLogo : "../../images/upandgo/BRCGS_CERT_FOOD_LOGO_RGB.svg",
    footerLogoImg : "../../images/upandgo/foo-logo.svg",
    menuType : "1"
  },
  {
    id : clientIds.vitality,
    logoimg : "../../images/vitalityworks/logo.png",
    bannerImg : "../../images/vitalityworks/banner.jpg",
    copyrightLogo : "../../images/vitalityworks/BRCGS_CERT_FOOD_LOGO_RGB.svg",
    footerLogoImg : "../../images/vitalityworks/foo-logo.svg",
    menuType : "1"
  }
];

/**
 * customLinkData
 * @description custom menu links and control data 
 * @param id - id to be used as a reference
 * @param href - link for the main menu item
 * @param name - text for the menu item
 * @param active - maintains hovered over state UI
 * @param open - state for setting sub menu open/close
 * @param custommenuTemplate - in case we need to inject a full html template into a submenu, eg., mega menus
 * @param subType subType - string, for added changes to different sub menu types
 * @param submenu/icon - for adding icons classes for icon type sub menus
 */
export const customLinkData = [
  {
    id : clientIds.pioneer,
    linkList : [
      {
        id: 1,
        href : "https://pioneer-foods-uk.com/",
        name : "Home",
        active : false,
        open : false,
        
      },
      {
        id: 2,
        href : "https://pioneer-foods-uk.com/about",
        name : "About us",
        active : false,
        open : false,
        submenu : [
          {
            href : "https://pioneer-foods-uk.com/about/our-factories",
            name : "Our Factories"
          },
          {
            href : "https://pioneer-foods-uk.com/about/quality-statement-and-accreditations",
            name : "Quality statement & Accredition"
          },
          {
            href : "https://pioneer-foods-uk.com/about/our-customers",
            name : "Our Customers"
          },
          {
            href : "https://pioneer-foods-uk.com/about/whole-grain-from-source",
            name : "Whole Grain from Source"
          },
          {
            href : "https://pioneer-foods-uk.com/about/its-all-in-the-mix",
            name : "It's all in the mix"
          }
        ]
      },
      {
        id: 3,
        href : "https://pioneer-foods-uk.com/services/export-markets",
        name : "Services",
        active : false,
        open : false,
        submenu : [
          {
            href : "https://pioneer-foods-uk.com/services/export-markets",
            name : "Export Markets"
          },
          {
            href : "https://pioneer-foods-uk.com/services/packing-solutions",
            name : "Packing Solutions"
          }
        ]
      },
      {
        id: 4,
        href : "https://pioneer-foods-uk.com/cereals",
        name : "Cereals",
        active : false,
        open : false,
        submenu : [
          {
            href : "https://pioneer-foods-uk.com/cereals/porridges",
            name : "Porridge"
          },
          {
            href : "https://pioneer-foods-uk.com/cereals/wheat-biscuits",
            name : "Wheat Biscuits"
          },
          {
            href : "https://pioneer-foods-uk.com/cereals/muesli",
            name : "Muesli"
          },
          {
            href : "https://pioneer-foods-uk.com/cereals/granola",
            name : "Granola"
          },
          {
            href : "https://pioneer-foods-uk.com/cereals/multigrain-flakes",
            name : "Multigrain Flakes"
          },
          {
            href : "https://pioneer-foods-uk.com/cereals/branflakes",
            name : "Bran Flakes"
          },
        ]
      },
      {
        id: 5,
        href : "https://pioneer-foods-uk.com/fruit-snacks",
        name : "Fruit Snacks",
        active : false,
        open : false
      },
      {
        id: 6,
        href : "https://pioneer-foods-uk.com/news",
        name : "News",
        active : false,
        open : false
      },
      {
        id: 7,
        href : "https://pioneer-foods-uk.com/contact-us",
        name : "Contact",
        active : false,
        open : false
      }
    ]
  },
  {
    id : clientIds.upandgo,
    linkList : [
      {
        id: 1,
        href : "https://www.upandgo.co.uk/",
        name : "Home",
        active : false,
        open : false
      },
      {
        id: 2,
        href : "https://www.upandgo.co.uk/our-story",
        name : "Our Story",
        active : true,
        open : false,
      },
      {
        id: 3,
        href : "https://www.upandgo.co.uk/our-products",
        name : "Our Products",
        active : false,
        open : false
      },
      {
        id: 4,
        href : "https://www.upandgo.co.uk/faq",
        name : "FAQ",
        active : false,
        open : false
      },
      {
        id: 5,
        href : "https://www.upandgo.co.uk/find-us",
        name : "Find Us",
        active : false,
        open : false
      },
      {
        id: 6,
        href : "https://www.upandgo.co.uk/contact-us",
        name : "Contact Us",
        active : false,
        open : false
      }
    ]
  },
  {
    id: clientIds.vitality,
    linkList : [
      // {
      //   id: 1,
      //   href : "https://vitalityworks.health",
      //   name : "Home",
      //   active : false,
      //   open : false,
      //   customMenuTemplate : false,
      //   subType: null
      // },
      {
        id: 1,
        href : "https://vitalityworks.health/about",
        name : "About",
        active : false,
        open : false,
        customMenuTemplate : false,
        subType: null,
      },
      {
        id: 2,
        href : false,
        name : "Services",
        active : false,
        open : false,
        customMenuTemplate : true,
        subType: "icons",
        submenu : null
      },
      {
        id: 3,
        href : false,
        name : "Industries",
        active : false,
        open : false,
        customMenuTemplate : false,
        subType: "icons",
        submenu : [
          {
            href : "https://vitalityworks.health/industry/agriculture",
            name : "Agriculture",
            icon : "../../images/vitalityworks/icons/industries/agriculture.svg"
          },
          {
            href : "https://vitalityworks.health/industry/construction",
            name : "Construction",
            icon : "../../images/vitalityworks/icons/industries/construction.svg"
          },
          {
            href : "https://vitalityworks.health/industry/energy",
            name : "Energy",
            icon : "../../images/vitalityworks/icons/industries/energy.svg",
          },
          {
            href : "https://vitalityworks.health/industry/financial-services",
            name : "Financial Services",
            icon : "../../images/vitalityworks/icons/industries/financial-services.svg"            
          },
          {
            href : "https://vitalityworks.health/industry/government",
            name : "Government",
            icon : "../../images/vitalityworks/icons/industries/government.svg"            
          },
          {
            href : "https://vitalityworks.health/industry/manufacturing",
            name : "Manufacturing",
            icon : "../../images/vitalityworks/icons/industries/manufacturing.svg"            
          },
          {
            href : "https://vitalityworks.health/industry/mining-resources",
            name : "Mining & Resources",
            icon : "../../images/vitalityworks/icons/industries/mining-resources.svg"            
          },
          {
            href : "https://vitalityworks.health/industry/transport",
            name : "Transport",
            icon : "../../images/vitalityworks/icons/industries/transport.svg"            
          },
          {
            href : "https://vitalityworks.health/industry/utilities",
            name : "Utilities",
            icon : "../../images/vitalityworks/icons/industries/utilities.svg"            
          }
        ]
      },
      {
        id: 4,
        href : "https://vitalityworks.health/resources",
        name : "Resources",
        active : false,
        open : false,
        customMenuTemplate : false,
        subType: "icons",
        submenu : [
          {
            href : "https://vitalityworks.health/category/blog-articles",
            name : "Blog Articles",
            icon : "../../images/vitalityworks/icons/resources/blog-articles.svg"
          },
          {
            href : "https://vitalityworks.health/category/white-papers",
            name : "White Papers",
            icon : "../../images/vitalityworks/icons/resources/white-papers.svg"
          },
          {
            href : "https://vitalityworks.health/category/useful-resources",
            name : "Useful Resources",
            icon : "../../images/vitalityworks/icons/resources/useful-resources.svg",
          },
          {
            href : "https://vitalityworks.health/resources/?resources=case-studies",
            name : "Case Studies",
            icon : "../../images/vitalityworks/icons/resources/case-studies.svg"            
          }
        ]
      }
    ]
  }
];

export const clients = [
  {
    clientId: clientIds?.arun,
    name: clientNames?.arun,
    className: "test",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.daflin,
    name: clientNames?.daflin,
    className: "test",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.maha,
    name: clientNames?.maha,
    className: "test",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.holmesglen_copy,
    name: "Holmesglen - Copy",
    className: clientNames?.holmesglen_copy,
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.jasmine,
    name: clientNames?.jasmine,
    className: "test",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.pifs,
    name: clientNames?.pifs,
    className: "pifs",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.holmesglen,
    name: clientNames?.holmesglen,
    className: "holmesglen",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.fair,
    name: clientNames?.fair,
    className: "fair",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.holmesglen_changes,
    name: clientNames?.holmesglen_changes,
    className: "holmesglen",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.demo_staging,
    name: clientNames?.demo_staging,
    className: "holmesglen",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.hvtc,
    name: clientNames?.hvtc,
    className: "hvtc",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.pioneer,
    name: clientNames?.pioneer,
    className: "pioneer-foods",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.upandgo,
    name: clientNames?.upandgo,
    className: "upandgo",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  },
  {
    clientId: clientIds?.vitality,
    name: clientNames?.vitality,
    className: "vitality",
    youtubePage:
      "https://www.youtube.com/c/PacificIslandsForumSecretariat/videos",
    faceBookPage: "https://www.facebook.com//ForumSec/",
    xTwitterPage: "https://www.twitter.com//ForumSEC",
    linkedinPage:
      "https://www.linkedin.com//company/pacific-islands-forum?originalSubdomain=fj",
  }
];
