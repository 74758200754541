import React from "react";
import Modal from "react-bootstrap/Modal";

const Model = (props: any) => {
    const { show, modelClose, modelHeader, modelBody, modelFooter, size,className } = props;
    return (
        <>
            <Modal className={className} size={size} show={show} onHide={modelClose} animation={true} centered>
                {/* <Modal.Header closeButton> */}
                {modelHeader}
                {/* </Modal.Header> */}
                {/* <Modal.Body> */}
                {modelBody}
                {/* </Modal.Body> */}
                {/* <Modal.Footer> */}
                {modelFooter}
                {/* </Modal.Footer> */}
            </Modal>
        </>
    );
};

export default Model;