export const displayPage = [
    {
        label: "5 per page",
        value: 5
    },
    {
        label: "10 per page",
        value: 10
    },
    {
        label: "20 per page",
        value: 20
    },
    {
        label: "25 per page",
        value: 25
    }
];

export const sortList: any = [
    { value: "newest", label: "Newest - top" },
    { value: "oldest", label: "Newest - bottom" },
    { value: "a_to_z", label: "A - Z" },
    { value: "z_to_a", label: "Z - A" }
];


// export const monthNames = [
//     "January", "February", "March", "April", "May", "June",
//     "July", "August", "September", "October", "November", "December"
// ];

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const loginData = {
    email: "admin@mail.com",
    password: "Password@123"
};
function titleSection() {

}
// export const temConfig = [
//     { name: "title", displaySection: titleSection() },
//     { name: "location", displaySection: "titleSection()" },
//     { name: "apply_now", displaySection: "titleSection()" },
//     { name: "media", displaySection: "titleSection()" },
//     { name: "jobreferance", displaySection: "titleSection()" },
//     { name: "social_share", displaySection: "titleSection()" },
//     { name: "job_types", displaySection: "titleSection()" },
// ];


export const careersMessages = {
    apiErrorMessage : "We are currently experiencing technical difficulties with our application submission process on our website. Some candidates may encounter issues preventing them from completing their applications successfully.If you are experiencing difficulties in submitting your application, please reach out to us directly at recruitment@zealfutures.com.au. Our team will assist you in ensuring that your application is properly received and processed."
};

