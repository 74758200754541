import React from "react";

const CustomPioneerFooter = (props: any) => {

    const { menuSettings } = props;

    return (
        <>          
            <div className="footer-section">
                <div className="footer">
                    <div className="container-new">
                        <div className="footer-list">
                            <div className="foo1">
                            <a href="https://pioneerfoods.co.za/" rel="noreferrer" target="_blank"><img src={process.env.PUBLIC_URL + menuSettings.footerLogoImg} /></a>
                            </div>
                            <div className="foo2">
                                <h2>Wellingborough</h2>
                                <div className="foo-cont">Pioneer Foods (UK) Ltd</div>
                                <div className="foo-cont">40 Bradfield Road, Finedon Industrial</div>
                                <div className="foo-cont">Estate Wellingborough, Northamptonshire</div>
                                <div className="foo-cont">NN8 4 HB</div>
                                <div className="foo-cont">United Kingdom</div>
                                <div className="foo-cont">+44 (0) 1933 229139</div>
                            </div>
                            <div className="foo3">
                                <h2>Peterborough</h2>
                                <div className="foo-cont">Pioneer Foods (UK) Ltd</div>
                                <div className="foo-cont">Unit 2, Southgate Way, Orton Southgate, Peterborough</div>
                                <div className="foo-cont">PE2 6YG</div>
                                <div className="foo-cont">United Kingdom</div>
                                <div className="foo-cont">+44(0) 1733 362900</div>
                            </div>
                            <div className="foo4">
                                <h2></h2>
                                <ul>
                                    <li>
                                        <a href="https://pioneer-foods-uk.com/legal/terms-and-conditions" rel="noreferrer" target="_blank">Terms & Conditions</a>
                                    </li>
                                    <li>
                                        <a href="https://pioneer-foods-uk.com/legal/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="https://pioneer-foods-uk.com/legal/cookie-policy" rel="noreferrer" target="_blank">Cookie Policy</a>
                                    </li>
                                    <li>
                                        <a href="https://pioneer-foods-uk.com/legal/modern-slavery-policy" rel="noreferrer" target="_blank">Modern Slavery Policy</a>
                                    </li>
                                    <li>
                                        <a href="https://pioneer-foods-uk.com/legal/pioneer-foods-gender-pay-statement" rel="noreferrer" target="_blank">Gender Pay Statement</a>
                                    </li>
                                    <li>
                                        <a href="https://pioneer-foods-uk.com/files/download/8b51b5c413a617d" rel="noreferrer" target="_blank">Terms and conditions of business</a>
                                    </li>
                                    <li>
                                        <a href="https://pioneer-foods-uk.com/legal/general-terms-and-conditions" rel="noreferrer" target="_blank">General Terms and Conditions</a>
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                        <div className="footer-copyright">
                            <div className="fc-cont1">
                            <a href="https://pioneer-foods-uk.com/#" rel="noreferrer" target="_blank"><img src={process.env.PUBLIC_URL + menuSettings.copyrightLogo} /></a>
                            </div>
                            <div className="fc-cont2">
                                © 2024 Pioneer Foods Ltd.
                            </div>
                            <div className="fc-cont3">
                                Site by <a href="http://www.kisscom.co.uk" rel="noreferrer" target="_blank">KISS</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </>
    );
};


export default CustomPioneerFooter;