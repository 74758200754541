import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "./TextError";
import { valudatesetformstatus } from "../../pages/onboarding/Onboarding";


const TextArea = (props: any) => {
    const { name, label, className, allData, ...rest } = props;

    const fieldValidation = (value: any) => {
        let error;
        if (allData.validate) {
            if (allData.subtype === "textarea") {
                allData?.validations.forEach((element: any) => {
                    if (allData.subtype === "textarea") {
                        if (!value && valudatesetformstatus !== "draft") {
                            error = element.params[0];
                        }
                    }
                });
            }
        }
        return error;
    };
    return (
        <>
            <div className="class-name">
                <Field onBlur={(e: any) => e.preventDefault()} placeholder={allData.placeholder} validate={fieldValidation} as="textarea" name={name} id={name} className={className} {...rest} />
                <ErrorMessage name={name} component={TextError} />
            </div>
        </>
    );
};

export default TextArea;