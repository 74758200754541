import React, { useEffect, useRef, useState } from "react";


const ResultView = (props: any) => {

    const { result, message } = props;
    
    return(
        <div className="subscription-container row justify-content-center">
            <div className="card col-3 m-5 p-4">
                <div className="card-body text-center">
                    <span className={(result === "success") ? "pi pi-check-circle" : "pi pi-times-circle"}></span>
                    <p className="mt-5">{message}</p>
                </div>
                {
                    (result === "success") ? 
                        <button className="btn btn-primary completion-btn-primary">Back To Job Listings</button>
                        : ""
                }
                
            </div>
            
        </div>
    );
};

export default ResultView;