import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import CompletionSubHeader from "./header/CareersSubHeader";
import { persistor } from "../../redux/store";
import { apiPost } from "../../service/apiService";
import { endPoint } from "../../config/endPoint";
import { loginData } from "../../config/careers/careers";
import { useDispatch, useSelector } from "react-redux";
import { loderControl } from "../../redux/slices/careersSlices.reducer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CareersHeader from "./header/CareersHeader";
import { clientIds } from "../../config/clients/clients";

const CompletionRoot = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const routeParams = useParams();
  const [clientIdFound, setClientIdFound] = useState<boolean>(false);
  const [jobViewDesign, setJobViewDesign] = useState<boolean>(false);
  const clientConfig = useSelector((state: any) => state.careers.cliendConfig);

  useEffect(() => {
  //   // sessionStorage.removeItem("Token");
  //   dispatch<any>(loderControl());
  //   const getToken = async () => {
  //     const ress = await apiPost(endPoint.candidateToken, loginData);
      persistor.flush().then(() => {
        return persistor.purge();
      });
  //     if (ress.status === 200) {
  //       // console.log(ress?.data?.data);
  //       sessionStorage.setItem("Token", ress?.data?.data?.token);
        navigate(location.pathname);
  //     } else {
  //       console.log(ress);
  //     }
  //   };
  //   getToken();
  }, []);

  // useEffect(() => {
  //     console.log(3456789);
  //     console.log(location);

  //     navigate(location.pathname);

  // }, [sessionStorage.getItem("Token")]);

  useEffect(() => {
    if(location.pathname === "/" || 
    location.pathname === `/view/${routeParams?.id}` || 
    location.pathname === `/view/${routeParams?.id}/apply-job/from/direct` || 
    location.pathname === `/view/${routeParams?.id}/apply-job/register/from/direct`){
      setClientIdFound(false);
    } else {
      setClientIdFound(!false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if(clientIds?.hvtc === clientConfig?.data?.data?.clientSettings?.clientId && location.pathname === `/view/${routeParams?.id}`){
      setJobViewDesign(!false);
    } else {
      setJobViewDesign(false);
    }
  }, [location.pathname, clientConfig]);

  return (
    <>
      {/* {sessionStorage.getItem("Token") ? ( */}
        <>
          <div className="conpletion-header-div">
            <CareersHeader />
          </div>
          <div id="careers-body" className= { jobViewDesign ? "careers-bg mob-job-view" : clientIdFound ? "careers-bg-new" : "careers-bg " }>
            <div className={ clientIds?.hvtc === clientConfig?.data?.data?.clientSettings?.clientId ? 
            (location.pathname === `/view/${routeParams?.id}/apply-job/from/${routeParams?.from}` || 
            location.pathname === `/view/${routeParams?.id}/apply-job/register/from/${routeParams?.from}`
            ) ? 
            "apply-body" : "client-body" : "" }>
              <Outlet />
            </div>
          </div>
        </>
      {/* ) : (
        ""
      )} */}
    </>
  );
};

export default CompletionRoot;
