import React, { useEffect, useState } from "react";
import "../../../styles/careers/subheader.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { clientIds } from "../../../config/clients/clients";
const CompletionSubHeader = () => {
    const cliendId = useParams();
    const location = useLocation();
    const clientConfig = useSelector((state: any) => state.careers.cliendConfig);

    const [checkSubMenu, setCheckSubMenu] = useState(false);

    useEffect(() => {
        if (clientConfig?.data?.data?.clientSettings?.clientId === clientIds?.hvtc || location.pathname === `/view/${cliendId?.id}/apply-job/from/${cliendId?.from}`) {
            setCheckSubMenu(!false);
        }
    }, [location, clientConfig]);
    return (
        <>
            { !checkSubMenu ? 
                <div className="nav-menu nav-menu-black">
                    <div id="mt-minus" className="container-fluid">
                        <ul className="nav nav-list pt-3" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link  active" >Job Listings</a>
                            </li>
                            {/* <li className="ms-auto">
                                <div className="btn-section">
                                    <a className="btn btn-primary btn-new">Login / Sign Up</a>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            : "" }
        </>
    );
};
export default CompletionSubHeader;