import axios from "axios";


const baseUrl = process.env.REACT_APP_API_BASE_URL;




export async function apiPost(api: string, params: any, urlparams?:any) {
    try {
        const response: any = await axios.post(baseUrl + api + (urlparams? `?${"domainWithHttp=" + urlparams.domainWithHttp}` : ""), params);
        return response;
    } catch (error: any) {
        return error.response;
    }
}

export async function apiGet(api: string, params: any, urlparams?:any) {
    try {
        const response = await axios.get(baseUrl + api + (urlparams? `?${"domainWithHttp=" + urlparams.domainWithHttp}` : ""), {
            params: params
        });
        return response;
    } catch (error: any) {
        return error.response;
    }

}

export async function apiPut(api: string, params: any) {
    try {
        const response = await axios.put(baseUrl + api, params);
        return response;
    } catch (error) {
        return error;
    }

}



export async function apiDelete(api: string, params: any) {
    try {
        const response = await axios.delete(baseUrl + api, {
            params: params
        });
        return response;
    } catch (error) {
        return error;
    }

}
