import { ErrorMessage, Field } from "formik";
import * as React from "react";
import TextError from "./TextError";
import { checkValidationElement, setchioldValidation, valudatesetformstatus } from "../../pages/onboarding/Onboarding";

const Select = (props: any) => {
    const { name, label, type, className, allData, formik, onChange, completedPage, ...rest } = props;


    const fieldValidation = (value: any) => {
        // console.log(value);
        if (allData.is_child_validate) {
            // for (const checkValidation of checkValidationElement) {

            // }

            const checkData = checkValidationElement.map((ress: any) => {
                return {
                    ...ress,
                    childValidation: allData.name === ress.parent_name && ress.parent_value === value ? true : false
                };
            });
            // checkValidationElement = checkData
            setchioldValidation(checkData);
        }


        let error;
        if (allData.validate) {
            if (allData.type === "select") {
                allData?.validations.forEach((element: any) => {
                    if (element.type === "required") {
                        if (!value && valudatesetformstatus !== "draft") {
                            error = element.params[0];
                        }
                    }
                });
            }
        }
        return error;
    };

    return (
        <>
            <div className="class-name position-relative">
                {/* validate={fieldValidation} */}
                <Field as={"select"} name={name} id={name} className={`form-select ${formik?.values[name] ? "" : "select-place-holder"}`}
                    onBlur={(e: any) => e.preventDefault()} validate={fieldValidation}
                    {...rest} >
                    <option value={""} disabled selected hidden>{allData.placeholder}</option>
                    {allData?.values && allData?.values.map((ress: any) => {
                        return (
                            <option key={ress.value} value={ress.value}>{ress.label}</option>
                        );
                    })}
                </Field>
                <i className="pi pi-angle-down dropdown-arrow"></i>
                <ErrorMessage name={name} component={TextError} />
            </div>

        </>
    );
};

export default Select;