import React, { useEffect } from "react";
import Button from "../../component/button/Button";
import Image from "../../component/image/Image";
// import Vector from "../../assets/images/Vector_1.png";
import "../../styles/pages/welcome.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { complianceTeamConfig, getCandidateData, loderControl, loderOffControl, removeCandidateData, updateUserData } from "../../redux/slices/newRoleSlices";
import { toast } from "react-toastify";
import Loader from "../../component/loader/Loader";
import { apiPost } from "../../service/apiService";
import { endPoint } from "../../config/endPoint";
import { loginData } from "../../config/careers/careers";
import { persistor } from "../../redux/store";
import { domainWithHttp } from "../../config/onboarding/onBoarding";
const WelcomeScreen = () => {
    const dispatch = useDispatch();

    const candidateData = useSelector((state: any) => { return state?.newRole?.candidateData; });
    const loader = useSelector((state: any) => { return state?.newRole?.loading; });

    const navicate = useNavigate();
    const { clientId } = useParams();
    const location = useLocation();

    useEffect(() => {

    //     const getToken = async () => {

            // dispatch<any>(removeCandidateData());
            persistor.flush().then(() => {
                return persistor.purge();
            });
            dispatch<any>(loderControl());
            // sessionStorage.removeItem("navigateTo");
    //         const ress = await apiPost(endPoint.candidateToken, loginData);

    //         if (ress.status === 200) {
    //             console.log(ress?.data?.data);
    //             sessionStorage.setItem("Token", ress?.data?.data?.token);
    

                if (clientId) {
                    sessionStorage.removeItem("navigateTo");
                    const obj:any ={};
                    obj["clientId"] = clientId;
                    obj["domainWithHttp"] = domainWithHttp;
                    dispatch<any>(getCandidateData(obj));

                    dispatch<any>(complianceTeamConfig(`/${clientId}`));
                    sessionStorage.setItem("clientId", clientId);
                }
    //         }
    //     };
    //     getToken();
    //     // persistor.flush().then(() => {
    //     //     return persistor.purge();
    //     // });
    }, []);




    useEffect(() => {

        if (candidateData?.statusCode === 200) {
            // document.documentElement.style.setProperty("--primary-color", candidateData?.clientDetails?.brandColor);

            // return;
            if (candidateData.currentPage === -2 || candidateData.currentPage === "-2") {
                // console.log(candidateData);
                dispatch(loderControl());
                navicate("/getCandidate/onboarding-result");
            } else if (candidateData.currentPage === "-1" || candidateData.currentPage === -1) {
                console.log();
                // setTimeout(() => {
                //     dispatch(loderOffControl());
                // }, 5000);
            } else {
                dispatch(loderControl());
                navicate("/getCandidate/onboarding");

            }

        } else if (candidateData?.status === 400) {
            navicate("*");
            toast(candidateData?.data?.message, {
                type: "error"
            });
        } else {
            // console.log(candidateData);

        }
    }, [candidateData]);
    const moveToNextPage = () => {
        const params = { ...candidateData };
        params.currentPage = 1;
        dispatch(updateUserData(params));
        // navicate("/getCandidate/onboarding");
    };
    const controlDivElement = () => {
        return (
            <>
                <div className="">
                    <p className="text-primary welcome-text"> {candidateData?.candidateDetails?.firstName}, your application has progressed
                       to the next stage of the recruitment process
                    </p>
                    <div className="welcome-one" dangerouslySetInnerHTML={{ __html: candidateData?.clientDetails?.description }}>

                    </div>
                </div>
                <div className="mb-3">
                    <Button label="Get Started" icon="pi pi-arrow-right" position="right" onClick={() => moveToNextPage()} type="button" clasName="btn btn-primary" />
                </div>
            </>
        );
    };

    return (
        <>
            <div>
                <Loader loading={loader} />
            </div>
            {candidateData?.statusCode === 200 ?
                <div className="container">
                    <div className="row align-items-center" style={{ height: "75vh" }}>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">

                            <div className="d-flex mt-5 mb-2">
                                <Image src={candidateData?.clientDetails?.logo} clasName="img-fluid" width={300} height={300} />
                            </div>
                            {candidateData?.clientDetails?.videoUrl !== "" || candidateData?.clientDetails?.imageUrl !== "" ?
                                controlDivElement()
                                :
                                ""
                            }

                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 col-xxl-6">

                            {candidateData?.clientDetails?.videoUrl !== "" || candidateData?.clientDetails?.imageUrl !== "" ?
                                <>
                                    <div className={candidateData?.clientDetails?.videoUrl !== "" ? "d-block" : "d-none"}>
                                        {/* src={candidateData?.clientDetails?.videoUrl} */}
                                        <video controls width="100%" src={candidateData?.clientDetails?.videoUrl} className="border">
                                            {/* <source src={src} type="video/mp4" /> */}
                                        </video>
                                    </div>
                                    <div className={candidateData?.clientDetails?.imageUrl !== "" ? "d-block" : "d-none"}>
                                        <Image src={candidateData?.clientDetails?.imageUrl} clasName="img-fluid" width={300} height={300} />
                                    </div>
                                </>

                                :
                                controlDivElement()

                            }
                        </div>
                    </div>
                </div >
                :
                <>
                    {candidateData?.message ?
                        <div className="row justify-content-center align-item-center">
                            <div className="col-md-5">
                                <div className="mt-5 pt-5">
                                    <div className="alert alert-danger" role="alert">
                                        {candidateData?.message}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                    }
                </>

            }
        </>
    );
};
export default WelcomeScreen;