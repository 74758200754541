import React, { useEffect, useState } from "react";
import Button from "../../../component/button/Button";
import Input from "../../../component/input/Input";
// import Select, { ValueContainerProps, components } from "react-select";
import { useLocation, useParams } from "react-router-dom";
import Select from "../../../component/multiSelect/MultiSelect";
import SingleSelect from "../../../component/singleSelect/SingleSelect";
import JobSearch from "../../../assets/images/search-job.png";
import Image from "../../../component/image/Image";
import { useSelector } from "react-redux";
import LinkedIn from "../../../assets/images/linkedIn-white.png";
import FaceBook from "../../../assets/images/facebook-white.png";
import FilterPageTwo from "./FilterPageTow";
import { clientIds } from "../../../config/clients/clients";

const JobFilter = (props: any) => {
    const location = useLocation();
    const routeParams = useParams();
    const jobsList = useSelector((state: any) => state.careers.allJobList);
    const cliendConfig = useSelector((state: any) => state.careers.cliendConfig);

    const { navigateToJob, navigateToJobAlerts, filter, checkListControl, tableControl, searchControlSubmit, setTableControl, removeSearchForm } = props;
    const submitSearch = () => {
        // if (routeParams.clientId !== clientIds.pifs) {
        //     window.scrollTo(0, 0);
        // }
        searchControlSubmit();
    };
    useEffect(() => {
        if (cliendConfig.status === 200) {
            const dataress = cliendConfig?.data?.data?.clientSettings;
            // console.log(dataress);


        }
    }, [cliendConfig]);

    const openLinkedinUrl = () => {
        window.open(cliendConfig?.data?.data?.clientSettings?.linkedinUrl, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");
    };
    const openFaceBookUrl = () => {
        window.open(cliendConfig?.data?.data?.clientSettings?.facebookUrl, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");
    };
    return (
        <>
            <div className={`job-search-box ${location.pathname === `/view/${routeParams?.id}` ? "filter-background" : ""}`}>
                <div className="d-flex align-items-center mb-2">
                    <i className="pi pi-search top-search fw-bolder pe-2"></i>
                    <h2 className="search-job mb-0">
                        Search Jobs
                    </h2>

                    {/* && jobsList?.data?.data?.jobs?.item?.length > 0  */}
                    {location.pathname !== `/view/${routeParams?.id}` && checkListControl ?
                        <div className="btn-new btn-white">
                            <Button onClick={() => removeSearchForm()} clasName="btn completion-btn-primary" icon={"pi pi-times custom-close-filter"} position="left" label={"Clear Filters"} />
                        </div>
                        :
                        ""
                    }

                </div>
                <div className="form-group search-bg-form-group position-relative common-before-icon search-icon">
                    {/* <Image src={JobSearch} clasName="filer-search-icon" /> */}
                    <i className="pi pi-search  fw-bolder filer-search-icon"></i>

                    <Input
                        className={`form-control active filter-in ${tableControl.searchText ? "filter-in-active" : ""}`}
                        type={"text"}
                        value={tableControl.searchText}
                        placeholder="Search..."
                        name={"search"}
                        onChange={(e: { target: { value: any; }; }) => {
                            setTableControl((prs: any) => {
                                return {
                                    ...prs,
                                    searchText: e.target.value
                                };
                            });
                        }
                        }
                    />
                </div>

                {filter.jobCategories ?
                    <div className={`cust-select-parent cust-select-category-parent mt-2 ${tableControl.searchCat.length > 0 ? "location-border" : ""}`}>
                        <label>Category :</label>
                        {/* {catSelect()} */}
                        <Select isSearchable={true} className="joblist-multi-select" placeholder="All" isMulti={true} onChange={(e: any) => {
                            setTableControl((prs: any) => {
                                return {
                                    ...prs,
                                    searchCat: e
                                };
                            });
                        }} value={tableControl.searchCat} options={filter.jobCategories} />
                        {/* <i className="pi pi-angle-down"></i> */}
                    </div>
                    :
                    ""
                }

                {/* <div>
                    <CategorySelect />
                </div> */}

                {filter.jobTypes ?
                    <div className={`cust-select-parent cust-select-category-parent  mt-2 ${tableControl.searchJob.length > 0 ? "location-border" : ""}`}>
                        <label>Job Types :</label>
                        <Select isSearchable={true} className={"joblist-multi-select job-select"} placeholder="All" isMulti={true} onChange={(e: any) => {
                            setTableControl((prs: any) => {
                                return {
                                    ...prs,
                                    searchJob: e
                                };
                            });
                        }} value={tableControl.searchJob} options={filter.jobTypes} />
                        {/* {joTypeSelect()} */}

                        {/* <select className="form-control job-type" value={tableControl.searchJob}
                                                onChange={(e) => setTableControl((prs) => {
                                                    return {
                                                        ...prs,
                                                        searchJob: e.target.value
                                                    };
                                                })}
                                            >
                                                <>
                                                    <option key={""} value={""}>{"All"}</option>
                                                    {filterListData.jobTypes && (filterListData.jobTypes as any[]).map((ress: any) => {
                                                        return (
                                                            <option key={ress.jobTypeId} value={ress.jobTypeId}>
                                                                {ress.typeName}
                                                            </option>
                                                        );
                                                    })}
                                                </>
                                            </select> */}
                        {/* <i className="pi pi-angle-down"></i> */}
                    </div>
                    :
                    ""
                }
                {
                    filter.jobLocations ?
                        <div className={`cust-select-parent cust-select-category-parent mt-2 ${tableControl.searchLocation.length > 0 ? "location-border" : ""}`}>
                            <label>Locations :</label>
                            {/* {locationSelect()} */}
                            <Select isSearchable={true} className="joblist-multi-select location-select" placeholder="All" isMulti={true}
                                onChange={(e: any) => {
                                    setTableControl((prs: any) => {
                                        return {
                                            ...prs,
                                            searchLocation: e
                                        };
                                    });
                                }} value={tableControl.searchLocation} options={filter.jobLocations} />
                            {/* <Select
                                className="joblist-multi-select"
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                options={filterListData.jobLocations}
                                onChange={(e: any) => setTableControl((prs: any) => {
                                    return {
                                        ...prs,
                                        searchLocation: e
                                    };
                                })}
                                isMulti
                            /> */}

                            {/* <i className="pi pi-angle-down"></i> */}
                        </div>
                        :
                        ""
                }

                <div className=" post-code mt-2">
                    <label>Postcode :</label>
                    <Input type={"number"} maxLength={4} placeholder="Enter postcode" className="w-100 form-control post-code-filter cust-number-field" value={tableControl.postCode}
                        onChange={(e: { target: { value: any; }; }) =>
                            setTableControl((prs: any) => {
                                return {
                                    ...prs,
                                    postCode: e.target.value
                                };
                            })
                        }

                    />

                    {/* <i className="pi pi-angle-down"></i> */}
                </div>
                {/* <div className="text-center my-3">
                                            <p className="or-text">AND</p>
                                        </div> */}
                {/* <div className="d-flex align-items-center mt-2">
                    <label>Postcode:</label>
                    <Input type={"text"} className="form-control ms-2" value={tableControl.postCode}
                        onChange={(e: { target: { value: any; }; }) =>
                            setTableControl((prs: any) => {
                                return {
                                    ...prs,
                                    postCode: e.target.value
                                };
                            })
                        }
                    />
                </div> */}
                {/* cust-select-parent  */}
                {/* cust-select-category-parent   */}
                <div className={`mt-2 cust-select-parent cust-select-category-parent ${tableControl.distanceRadius ? "location-border" : ""}`}>
                    <label>Distance radius :</label>
                    <SingleSelect className="joblist-multi-select distance-search"
                        placeholder="Any" onChange={(e: any) => {
                            setTableControl((prs: any) => {
                                return {
                                    ...prs,
                                    distanceRadius: e
                                };
                            });

                        }}
                        isClearable={true} value={tableControl.distanceRadius} options={filter.jobDistances} />
                    {/* <Select className="joblist-multi-select distance-search"jobDistances
                        placeholder="Any" isMulti={false} isSearchable={false} onChange={(e: any) => {
                            setTableControl((prs: any) => {
                                return {
                                    ...prs,
                                    distanceRadius: e
                                };
                            });
                        }} value={tableControl.distanceRadius} options={filter.jobDistances} /> */}
                    {/* <select className="form-control border-0"
                        value={tableControl.distanceRadius}
                        onChange={(e: { target: { value: any; }; }) =>
                            setTableControl((prs: any) => {
                                return {
                                    ...prs,
                                    distanceRadius: e.target.value
                                };
                            })
                        }
                    >
                        <option value={""}>All</option>
                        {filter.jobDistances && (filter.jobDistances as any[])?.map((ress: any) => {
                            return (
                                <option key={ress.value} value={ress.value}>{ress.label}</option>
                            );
                        })}
                    </select>
                    <i className="pi pi-angle-down element-control"></i> */}
                </div>

                <div className="btn-section mt-3">
                    <Button
                        clasName="btn btn-primary completion-btn-primary w-100"
                        label={"Search"}
                        position="left"
                        icon="pi pi-search"
                        onClick={() => submitSearch()}
                    />
                </div>
            </div>
            <br />
            {location.pathname === `/view/${routeParams?.id}` ?
                <>
                    <div className={`job-search-box-mobile job-search-box ${location.pathname === `/view/${routeParams?.id}` ? "filter-background" : ""}`}>
                        <div className="btn-section mt-3">
                            <div className="text-center">
                                <h2 className="job-view-text">Follow us to stay updated!</h2>
                            </div>

                            <Button onClick={() => openLinkedinUrl()} clasName="btn btn-primary completion-btn-primary w-100 mb-3" label={
                                <>
                                    <div className="d-flex justify-content-center align-items-center sm-linkedin-icon">
                                        <Image src={LinkedIn} />
                                        <div className="ms-2 icon-align">
                                            {"LinkedIn"}
                                        </div>
                                    </div>

                                </>

                            } />
                            <Button onClick={() => openFaceBookUrl()} clasName="btn btn-primary completion-btn-primary w-100" label={
                                <>
                                    <div className="d-flex justify-content-center align-items-center sm-fb-icon">
                                        <Image src={FaceBook} />
                                        <div className="ms-2 icon-align">
                                            {"Facebook"}
                                        </div>
                                    </div>

                                </>
                            } />
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="custom-boc-tow">
                        <FilterPageTwo navigateToJob={navigateToJob} navigateToJobAlerts={navigateToJobAlerts} />
                    </div>
                </>
                // <div className={"job-search-box"}>
                //     <div className="btn-section mt-3">
                //         <div className="text-center">
                //             <h2 className="job-view-text">No position matches your interest?</h2>
                //             <p className="infi-text">Submit your information below and
                //                 <br />
                //                 we will retain it for future consideration
                //             </p>
                //         </div>
                //         <Button clasName="btn btn-primary completion-btn-primary w-100 mb-3" label={"Register Your Interest"} />
                //         <Button clasName="btn btn-primary completion-btn-primary w-100" label={"Job Alerts"} />
                //     </div>
                // </div>
            }
        </>
    );
};

export default JobFilter;