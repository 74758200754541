import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ResultView from "./ResultViewComponent";
import { verifyEmail } from "../../../redux/slices/careersSlices.reducer";

const VerifyEmail = (props: any) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pageParams = useParams();
    const settingsLoaded = useSelector((state: any) => state.careers.settingsLoaded);
    const clientConfig = useSelector((state: any) => state.careers.cliendConfig);

    const [apiLoad, setApiLoad] = useState(false);
    const [verificationToken, setVerificationToken] = useState<string>("");

    //allowed message strings : success, failure
    const verificationResult:string = "success";
    const messages = {
        success : "Your email address was successfully verified",
        failure : "Your email address could not be verified" 
    };

    const redirectListing = () => {
        navigate("/");
    };

    useEffect(() => {
        if(pageParams?.token) {
            setVerificationToken(pageParams?.token);
        }
        else{
            //TODO : insert redirect to 404 or listings
        }
    }, [location.pathname]);

    //Ensures preloaded api data is present or done loading
    useEffect(() => {
        if(settingsLoaded === true) {
            setApiLoad(true);
        } else {
            setApiLoad(false);
        }
    }, [settingsLoaded]);

    useEffect(()=>{
        dispatch<any>(verifyEmail(verificationToken));
    }, [verificationToken]);

    return(
        <>
            <ResultView 
                result={verificationResult}
                message={messages.success}
            />
        </>
    );
};

export default VerifyEmail;