import React from "react";
import Input from "../component/formikComponent/Input";
import TextArea from "../component/formikComponent/TextArea";
import RadioButton from "../component/formikComponent/RadioButton";
import CheckBoxGroup from "../component/formikComponent/CheckBoxGroup";
import CustomDatePicker from "../component/dataPikker/CustomDatePicker";
import FormikDatePicker from "../component/formikComponent/FormDatePicker";
import FileUpload from "../component/formikComponent/FileUplaod";
import Select from "../component/formikComponent/Select";
function FormikControl(props: any) {

    const { control, className, value, onChange, dateFormat, formikData, prsFile, formik, completedPage, formstatus, ...rest } = props;




    switch (control) {
        case "input":
            return <Input className={className} completedPage={completedPage} {...rest} />;
        case "file":
            return <FileUpload completedPage={completedPage} className={className} formikData={formikData} value={value}  {...rest} />;
        case "textarea":
            return <TextArea as="textarea" completedPage={completedPage} className={className} {...rest} />;
        case "date":
            return <FormikDatePicker completedPage={completedPage} className={className} dateFormat={dateFormat} {...rest} />;
        case "radio":
            return <RadioButton formik={formik} {...rest} />;
        case "checkbox":
            return <CheckBoxGroup  {...rest} />;
        case "select":
            return <Select formik={formik}  {...rest} />;
        default:
            return null;
            break;
    }

}

export default FormikControl;