import React from "react";
interface UserInput {
    type: string,
    className?: string,
    placeholder?: string,
    name?: string,
    value?: string | number,
    onChange?: (e: any) => void,
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
    multiple?: boolean,
    id?: string,
    checked?: boolean
    onKeyDown?: (e: any) => void,
    maxLength?: number
}
const Input = (props: UserInput) => {
    const { className, placeholder, type, name, value, onChange, multiple, checked, maxLength } = props;
    return (
        <>
            <input onChange={onChange}
                onKeyDown={(e: any) => {
                    if (e.target.type === "number") {
                        if (["e", "E"].includes(e.key)) {
                            e.preventDefault();
                        }
                    }

                    return e;
                }}
                onInput={(e : any) => {
                    if (e.target.type === "number") {
                        e.target.value = e.target.value.slice(0, 4);
                    }
                }}
                checked={checked} multiple={multiple}
                maxLength={maxLength} id={name} type={type}
                name={name} className={className} value={value} placeholder={placeholder} />
        </>
    );
};
export default Input;