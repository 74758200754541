import { render } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomPioneerFooter from "./customFooterTemplates/PioneerCustomFooter";
import UpandgoCustomFooter from "./customFooterTemplates/UpandgoCustomFooter";
import { clientIds, customFooterIds, customLinkData, customMenuUISettings } from "../../../config/clients/clients";
import VitalityCustomFooter from "./customFooterTemplates/VitalityCustomFooter";
import { useSelector } from "react-redux";


const CareersCustomFooter = () => {
    const clientConfig = useSelector((state: any) => state.careers.cliendConfig);

    /**
     * * @returns template from adjacent customfootertemplates folder
     * Places to add - client ids, customFooterIds in client constants 
     */

    const [menuSettings, setMenuSettings] = useState({
        id : "",
        classref : "",
        logoimg : "",
        bannerImg : "",
        artifactImg1: "",
        artifactImg2: "",
        copyrightLogo : "",
        footerLogoImg : "",
        menuType : "1",
        tiktokIcon: ""
    });

    useEffect(() => {
        let currentClientSetting:any = customMenuUISettings.find(client => client.id === clientConfig?.data?.data?.clientSettings?.clientId);   
        setMenuSettings(currentClientSetting);
    }, [clientConfig]);

    const renderFooter = () => {
        switch(clientConfig?.data?.data?.clientSettings?.clientId){
            case clientIds.pioneer: return <CustomPioneerFooter menuSettings={menuSettings}/>;
            case clientIds.upandgo: return <UpandgoCustomFooter menuSettings={menuSettings}/>;
            case clientIds.vitality: return <VitalityCustomFooter menuSettings={menuSettings}/>;
            default: return "";
        }
    };

    return(
        <>            
            { renderFooter() }            
        </>
    );
};


export default CareersCustomFooter;