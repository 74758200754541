import React, { useEffect, useState } from "react";
import { customLinkData, customMenuUISettings } from "../../../../config/clients/clients";
import DropDown from "../../../../component/dropdown/dropdown";

const DefaultCustomMenu = (props: any) => {

    const { menuSettings, clientId } = props;
    const [navOpen, setNavOpen] = useState<boolean>(false);
    
    const [linkListData, setLinkListData] = useState<any>({
        id : "",
        linkList : []
    });

    useEffect(() => { 
        if (menuSettings) {
            setLinkListData(customLinkData.find(client => client.id === clientId));
        }
    }, [customLinkData, menuSettings]);

    const toggleLinkListData = (currentId:any, toggle:boolean) => {
        setLinkListData((listItems:any) => {
            let modifiedList = listItems.linkList;
            let currentItemIndex = modifiedList.findIndex((item: { id: any; }) => item.id === currentId);            
            modifiedList[currentItemIndex]["open"] = toggle;
            return {
                ...listItems,
                linkList : modifiedList
            };
          });
    };

    //Mouse enter to toggle dropdown, acts as touch during mobile responsive
    const onMouseEnter = (currentId:any) => {
        toggleLinkListData(currentId, true);
    };

    const onMouseLeave = (currentId:any) => {
        toggleLinkListData(currentId, false);
    };

    const toggleNavBar = () => {
        navOpen ? setNavOpen(false) : setNavOpen(true);
        if(navOpen) {
            document.body.classList.remove("menu-open-body");
        } else {
            document.body.classList.add("menu-open-body");
        }
    };

    return (
        <> 
            <header>
                <div className="header">
                    <div className="container-new">
                        <div className="head-parent">
                            <div className="head-left">
                                <div className="logo-new">
                                    <a href={menuSettings.homeLinkHref} rel="noreferrer" target="_blank">
                                        <img src={process.env.PUBLIC_URL + menuSettings.logoimg} className="cust-logo-desktop-img"/>
                                        <img src={process.env.PUBLIC_URL + menuSettings.moblogoimg}  className="cust-logo-mob-img" />
                                    </a>
                                </div>
                            </div>
                            <button className={navOpen ? "responsive-nav-btn menu-open" : "responsive-nav-btn menu-closed"} onClick={toggleNavBar}><img className="menu-open-icon" src={process.env.PUBLIC_URL + menuSettings.menuToggle}/><img className="menu-close-icon" src={process.env.PUBLIC_URL + menuSettings.menuToggleClose}/></button>
                            <div className="head-right">

                                <nav className={navOpen ? "nav-open" : "nav-closed"}>
                                    <ul className="submenu-list">
                                        {linkListData.linkList.map((listItem:any) => {
                                            return (
                                                <li 
                                                    key={listItem.href}
                                                    onMouseEnter={() => onMouseEnter(listItem.id)}
                                                    onMouseLeave={() => onMouseLeave(listItem.id)}
                                                    className={listItem.open ? "open" : ""}
                                                >
                                                    <a href={listItem.href} title={listItem.name} rel="noreferrer" target="_blank"> {listItem.name} {listItem.open}</a>                                                        
                                                    <DropDown submenuItems={listItem.submenu} menuSettings={menuSettings}/>
                                                    <span className="menu-arrow" onClick={() => onMouseEnter(listItem.id)}></span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>    
            </header>
        </>
    );
};


export default DefaultCustomMenu;