import React, { useEffect, useRef, useState } from "react";
import CompletionSubHeader from "../header/CareersSubHeader";
import Pagination from "../../../component/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../component/button/Button";
import DataIcon from "../../../assets/images/date-icon.svg";
import Image from "../../../component/image/Image";
import { getJobList, getSearchData, getJobDetails, loderControl, loderControlFalse, getQuestionList, searchJobScroll } from "../../../redux/slices/careersSlices.reducer";
import { endPoint } from "../../../config/endPoint";
import Input from "../../../component/input/Input";
import { displayPage, monthNames, sortList } from "../../../config/careers/careers";
import JobMapList from "./JobMapList";
import Loader from "../../../component/loader/Loader";
import JobView from "./jobView/JobView";
import makeAnimated from "react-select/animated";
import JobFilter from "./JobFilter";
import { publishingDate } from "./CareersFucConfig";
import ShareIcon from "../../../assets/images/share_icon.png";
import SingleSelect from "../../../component/singleSelect/SingleSelect";
import Banner from "../../../assets/images/job-list-banner.png";
import Footer from "../../../assets/images/job-list-footer.png";
import FilterPageTwo from "./FilterPageTow";
import MobilSearch from "../../../assets/images/mobil-search.png";
import MobilSearchNew from "../../../assets/images/mobil-search-2.png";
import Logo from "../../../assets/images/Logo.png";
import CareersFooter from "../CareersFooter/CareersFooter";
import { clientIds } from "../../../config/clients/clients";
import Wave from "../../../assets/images/wave.svg";
import PifsBanner from "../../../assets/images/hero-banner-image.jpg";
import JobListingsSplit from "./JobListingsSplit";
import JobListingsClient from "./JobListingsClient";
// import ShareIcon from "../../../assets/images/share_icon.png";

const JobListings = () => {
    const animatedComponents = makeAnimated();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const routeParams = useParams();
    const jobsList = useSelector((state: any) => state.careers.allJobList);
    const allSearchData = useSelector((state: any) => state.careers.allSearchData);
    const loader = useSelector((state: any) => { return state?.careers?.loading; });
    const questionId = useSelector((stete: any) => { return stete?.careers?.getQuestionId; });
    const jobListScroll = useSelector((stete: any) => { return stete?.careers?.jobListScroll; });
    const clientConfig = useSelector((state: any) => state.careers.cliendConfig);
    const settingsLoaded = useSelector((state: any) => state.careers.settingsLoaded);

    const [tableControl, setTableControl] = useState<any>({
        currentPage: 1,
        totalCount: "",
        pageSize: displayPage[1],
        searchText: "",
        searchCat: [],
        searchJob: [],
        searchLocation: [],
        postCode: "",
        distanceRadius: "",
        jobListAPiCall: "",
        data: [],
        massage: "",
        sort: sortList[0]
    });
    
    const [filterListData, setFilterSetData] = useState({
        jobCategories: null,
        jobLocations: null,
        jobTypes: null,
        jobDistances: null
    });
    const [listView, setListView] = useState("list");

    const [closeAll, setCloseAll] = useState(false);
    const lewLocationState = { ...location };
    const [removeFilterData, setRemoveFilterData] = useState(false);
    const [checkListControl, setCheckListControl] = useState<boolean>(false);
    const [browserLocation, setBrowserLocation] = useState<any>({});
    const [filterViews, setfilterViews] = useState({
        filterView: false,
        listAndMapView: false
    });
    const jobListDev: any = useRef(null);
    // const [scrollValue, setScrollValue] = useState(0);
    const [clientDesign, setClientDesign] = useState("");
    const [locationAccess, setLocationAccess] = useState<boolean>(false);
    const [withinDisabled, setWithinDisabled] = useState<boolean>(true);
    const [withinDisabledLocClass, setWithinDisabledLocClass] = useState("");
    const [apiLoad, setApiLoad] = useState(false);

    const checkClientDesign = () => {
        if(clientIds?.hvtc === clientConfig?.data?.data?.clientSettings?.clientId){
            setClientDesign("hvtc");
        } else {
            setClientDesign("common");
        }
    };

    useEffect(() => {
        checkClientDesign();
    }, [clientConfig]);

    useEffect(() => {
        if(location.pathname === "/" && settingsLoaded === true) {
            setApiLoad(!false);
        } else {
            setApiLoad(false);
        }
    }, [location.pathname, settingsLoaded]);

    useEffect(() => {
        if (allSearchData?.status === 200) {
            if (allSearchData.data.statusCode === 200) {
                setFilterSetData((prs) => {
                    return {
                        ...prs,
                        jobCategories: allSearchData?.data?.data?.jobCategories.map((ress: any) => {
                            return {
                                ...ress,
                                value: ress.jobCategoryId,
                                label: ress.categoryName
                            };
                        }),
                        jobLocations: allSearchData?.data?.data?.jobLocations.map((ress: any) => {
                            return {
                                ...ress,
                                value: ress.jobLocationId,
                                label: ress.locationName
                            };
                        }),
                        jobTypes: allSearchData?.data?.data?.jobTypes.map((ress: any) => {
                            return {
                                ...ress,
                                value: ress.jobTypeId,
                                label: ress.typeName
                            };
                        }),
                        jobDistances: allSearchData?.data?.data?.jobDistances.map((ress: any) => {
                            return {
                                ...ress,
                                value: ress.distance,
                                label: ress.distanceLabel
                            };
                        }),
                    };
                });
            }
        }
    }, [allSearchData]);

    const pageChange = (params: number | string) => {
        setTableControl((prs: any) => {
            return {
                ...prs,
                currentPage: params
            };
        });
        // window.scroll(0, jobListScroll);
        setTimeout(function() {window.scrollTo(0, jobListScroll);},1);
    };

    const getListOfJob = async () => {
        const apiParams: any = {};
        const searchText = sessionStorage.getItem("jobsearchtext");
        const jobsearchCategory = sessionStorage.getItem("jobsearchCategory");
        const jobsearchJobTypes = sessionStorage.getItem("jobsearchJobTypes");
        const jobsearchlocation = sessionStorage.getItem("jobsearchlocation");
        const jobsearchPostcode = sessionStorage.getItem("jobsearchPostcode");
        const jobsearchDistanceradius = sessionStorage.getItem("jobsearchDistanceradius");
        if (tableControl.searchText || (searchText !== null && searchText)) {
            apiParams["searchKeyword"] = tableControl.searchText ? tableControl.searchText : searchText;
        }
        if (tableControl.distanceRadius && Object.keys(tableControl.distanceRadius)?.length > 0 && "distance" in tableControl.distanceRadius) {
            apiParams["distance"] = tableControl.distanceRadius?.distance;
            apiParams["latitude"] = browserLocation?.coords?.latitude;
            apiParams["longitude"] = browserLocation?.coords?.longitude;
        } else {
            if (jobsearchDistanceradius && jobsearchDistanceradius !== null && Object.keys(JSON.parse(jobsearchDistanceradius))?.length > 0) {
                apiParams["distance"] = JSON.parse(jobsearchDistanceradius)?.distance;
                apiParams["latitude"] = browserLocation?.coords?.latitude;
                apiParams["longitude"] = browserLocation?.coords?.longitude;
            }
        }
        if (tableControl.searchCat.length > 0) {
            apiParams["categoryId"] = tableControl.searchCat.map((ress: any) => ress.jobCategoryId)?.toString();
        } else {
            if (jobsearchCategory && jobsearchCategory !== null && JSON.parse(jobsearchCategory)?.length > 0) {
                apiParams["categoryId"] = JSON.parse(jobsearchCategory)?.map((ress: any) => ress.jobCategoryId)?.toString();
            }
        }
        if (tableControl.searchLocation.length > 0) {
            apiParams["location"] = tableControl.searchLocation.map((ress: any) => ress.jobLocationId)?.toString();
        } else {
            if (jobsearchlocation && jobsearchlocation !== null && JSON.parse(jobsearchlocation)?.length > 0) {
                apiParams["location"] = JSON.parse(jobsearchlocation)?.map((ress: any) => ress.jobLocationId)?.toString();
            }

        }


        if (tableControl.searchJob.length > 0) {
            apiParams["jobType"] = tableControl.searchJob.map((ress: any) => ress.jobTypeId)?.toString();
        } else {
            if (jobsearchJobTypes && jobsearchJobTypes !== null && JSON.parse(jobsearchJobTypes)?.length > 0) {
                apiParams["jobType"] = JSON.parse(jobsearchJobTypes)?.map((ress: any) => ress.jobTypeId)?.toString();
            }
        }
        if (tableControl.sort) {
            apiParams["sort"] = tableControl.sort?.value;
        }
        if (tableControl.postCode || (jobsearchPostcode !== null && jobsearchPostcode)) {
            apiParams["postCode"] = tableControl.postCode ? tableControl.postCode : jobsearchPostcode;
        }
        apiParams["perPage"] = tableControl.pageSize?.value;
        apiParams["page"] = tableControl.currentPage;
        // apiParams["latitude"] = "";
        // apiParams["longitude"] = "";
        const obj: any = {};
        obj["url"] = clientConfig?.data?.data?.clientSettings?.clientId;
        obj["params"] = apiParams;
        dispatch<any>(getJobList(obj));
        sessionStorage.removeItem("jobsearchtext");
        sessionStorage.removeItem("jobsearchCategory");
        sessionStorage.removeItem("jobsearchJobTypes");
        sessionStorage.removeItem("jobsearchlocation");
        sessionStorage.removeItem("jobsearchPostcode");
        sessionStorage.removeItem("jobsearchDistanceradius");
    };

    useEffect(() => {
        if(apiLoad === true) {
            const searchText = sessionStorage.getItem("jobsearchtext");
            const jobsearchCategory = sessionStorage.getItem("jobsearchCategory");
            const jobsearchJobTypes = sessionStorage.getItem("jobsearchJobTypes");
            const jobsearchlocation = sessionStorage.getItem("jobsearchlocation");
            const jobsearchPostcode = sessionStorage.getItem("jobsearchPostcode");
            const jobsearchDistanceradius = sessionStorage.getItem("jobsearchDistanceradius");

            if (searchText || jobsearchCategory || jobsearchJobTypes || jobsearchlocation || jobsearchPostcode || jobsearchDistanceradius) {
                setCheckListControl(true);
            }
            const updatedTableControl = { ...tableControl };
            updatedTableControl.searchText = searchText ? searchText : "";
            updatedTableControl.searchCat = jobsearchCategory !== null ? JSON.parse(jobsearchCategory) : [];
            updatedTableControl.searchJob = jobsearchJobTypes !== null ? JSON.parse(jobsearchJobTypes) : [];
            updatedTableControl.searchLocation = jobsearchlocation !== null ? JSON.parse(jobsearchlocation) : [];
            updatedTableControl.postCode = jobsearchPostcode ? jobsearchPostcode : "";
            updatedTableControl.distanceRadius = jobsearchDistanceradius !== null ? JSON.parse(jobsearchDistanceradius) : "";
            setTableControl(updatedTableControl);

            // setTableControl((prs: any) => {
            //     return {
            //         ...prs,
            //         searchText: searchText ? searchText : "",
            //         searchCat: jobsearchCategory !== null ? JSON.parse(jobsearchCategory) : [],
            //         searchJob: jobsearchJobTypes !== null ? JSON.parse(jobsearchJobTypes) : [],
            //         searchLocation: jobsearchlocation !== null ? JSON.parse(jobsearchlocation) : [],
            //         postCode: jobsearchPostcode ? jobsearchPostcode : "",
            //         distanceRadius: jobsearchDistanceradius !== null ? JSON.parse(jobsearchDistanceradius) : ""
            //     };
            // });
            getListOfJob();
            //
            dispatch<any>(getSearchData(clientConfig?.data?.data?.clientSettings?.clientId));
            // console.log(325);
            dispatch<any>(getQuestionList());
            // sessionStorage.setItem("jobListScrool", "")
        }
    }, [apiLoad]);

    // useEffect(() => {
    //     dispatch<any>(loderControl());
    //     if (sessionStorage.getItem("Token")) {
    //         getListOfJob();
    //     }
    // }, [sessionStorage.getItem("Token")]);
    // const joblistScroll = () => {
    //     console.log(scrollValue);

    //     window.scrollTo(0, scrollValue);
    // };

    useEffect(() => {
        // console.log(jobListScroll);
        dispatch(loderControl());
        if (jobListScroll > 0 && location.pathname === "/") {
            setTimeout(() => {
                window.scrollTo(0, jobListScroll);
                dispatch(loderControlFalse());
            }, 1200);
        } else {
            // console.log("gghggg");

            setTimeout(() => {
                dispatch(loderControlFalse());
            }, 1200);
        }

    }, [jobListScroll]);
    const searchControlSubmit = () => {
        // window.scrollTo(0, 0);
        if (location.pathname !== "/") {
            sessionStorage.setItem("jobsearchtext", tableControl.searchText);
            sessionStorage.setItem("jobsearchCategory", JSON.stringify(tableControl.searchCat));
            sessionStorage.setItem("jobsearchJobTypes", JSON.stringify(tableControl.searchJob));
            sessionStorage.setItem("jobsearchlocation", JSON.stringify(tableControl.searchLocation));
            sessionStorage.setItem("jobsearchPostcode", tableControl.postCode);
            sessionStorage.setItem("jobsearchDistanceradius", JSON.stringify(tableControl.distanceRadius));
            // console.log(jobListDev?.current?.offsetHeight);
            // navigate(-1);
            // console.log(11111, jobListDev?.current?.offsetHeight);


            navigate("/");
            if (clientConfig?.data?.data?.clientSettings?.clientId !== clientIds.pifs) {
                window.scrollTo(0, 0);
            }
            // joblistScroll();

        } else {
            if(tableControl.currentPage === 2){
                tableControl.currentPage = 1;
            }
            getListOfJob();
            setCheckListControl(true);
            // if(tableControl.searchText === ""){
            //     setCheckListControl(false);
            // }
            // if (jobListDev && jobListDev?.current && jobListDev?.current?.offsetHeight) {
            //     // console.log(jobListDev?.current?.offsetHeight);
            //     // setScrollValue(jobListDev?.current?.offsetHeight);
            //     // sessionStorage.setItem("jobListScrool", jobListDev?.current?.offsetHeight);
            //     // joblistScroll();
            // }
            // window.scrollTo(0, jobListScroll);
            // console.log(jobListScroll);

            window.scrollTo(0, jobListScroll);

            // window.scrollTo(0, jobListDev?.current?.offsetHeight);
            // setScrollValue(450);
        }
    };

    useEffect(() => {
        const pageControl = Number(tableControl.totalCount) / tableControl.pageSize?.value;
        if (pageControl) {
            const checkFloorType = Math.floor(pageControl);
            const perPageCheck = checkFloorType + 1;
            if (checkFloorType === pageControl) {
                getListOfJob();
            } else {
                if (tableControl.currentPage > perPageCheck) {
                    setTableControl((prs: any) => {
                        return {
                            ...prs,
                            currentPage: perPageCheck
                        };
                    });
                } else {
                    getListOfJob();
                }

            }
        }
    }, [tableControl.pageSize?.value, tableControl.currentPage, tableControl.sort]);

    useEffect(() => {
        // dispatch<any>(loderControl());
        if (jobsList?.status === 200) {
            //  dispatch<any>(loderControlFalse());
            if (jobsList?.data?.statusCode === 200) {
                const data = jobsList?.data?.data?.jobs;
                setTableControl((prs: any) => {
                    return {
                        ...prs,
                        // data: data.items.map(async (data: any) => {
                        //     return {
                        //         ...data,
                        //         postedAt: await publishingDate(data.postedAt)
                        //     };
                        // }),
                        data: data.items,
                        totalCount: data.totalRecord,
                        massage: "",
                        // currentPage: Number(tableControl.currentPage) === data.currentPage ? tableControl.currentPage : data.perPage
                    };
                });
                if (jobListScroll === 0) {
                    dispatch(searchJobScroll(jobListDev?.current?.offsetHeight));
                }
            } else if (jobsList?.data?.statusCode === 400) {
                setTableControl((prs: any) => {
                    return {
                        ...prs,
                        data: [],
                        totalCount: 0,
                        massage: jobsList?.data?.message
                    };
                });
            }
        } else {
            if (jobsList?.status === 401) {
                // dispatch<any>(loderControl());
                // getListOfJob();
                navigate("/*");
            }

        }
    }, [jobsList]);

    useEffect(() => {
        if (removeFilterData) {
            setTableControl((prs: any) => {
                return {
                    ...prs,
                    searchLocation: [],
                    searchCat: [],
                    searchText: "",
                    searchJob: [],
                    postCode: "",
                    distanceRadius: ""
                };
            });


            // setTimeout(() => {
            // getListOfJob();
            // setRemoveFilterData(false);

            // }, 500);

        }
    }, [removeFilterData]);
    useEffect(() => {
        if (removeFilterData) {
            getListOfJob();
            setRemoveFilterData(false);
        }

    }, [tableControl]);

    const removeSearchForm = () => {
        setRemoveFilterData(true);
        setCheckListControl(false);
    };

    const pageSizeChange = (e: any) => {
        setTableControl((prs: any) => {
            return {
                ...prs,
                pageSize: e
            };
        });
    };

    useEffect(() => {
        if (location.pathname === `/view/${routeParams?.id}`) {
            dispatch<any>(getJobDetails(routeParams?.id));
            window.scrollTo(0, 0);
        }
    }, [location.state]);



    const updateCat = (params: any) => {
        setTableControl((prs: any) => {
            return {
                ...prs,
                searchCat: params
            };
        });
    };


    const navigateToJob = () => {
        navigate(`/view/${questionId?.data?.data}/apply-job/register/from/direct`);
    };

    const navigateToJobAlerts = () => {
        navigate("/job-alerts");
    };


    const filterView = () => {
        return (
            <>
                <JobFilter navigateToJob={navigateToJob} navigateToJobAlerts={navigateToJobAlerts} checkListControl={checkListControl} filter={filterListData} tableControl={tableControl} setTableControl={setTableControl} removeSearchForm={removeSearchForm} searchControlSubmit={searchControlSubmit} />
            </>
        );
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((ress: any) => {
            setBrowserLocation(ress);
            setLocationAccess(true);
        });
    }, []);
    
    /* Check if the location is enabled or not in browser */
    useEffect(() => {
        navigator.permissions.query({ name: "geolocation" }).then((permissionStatus) => {
            // below condition is to check during onload
            if(permissionStatus.state === "granted"){
                setLocationAccess(true);
            } else {
                setLocationAccess(false);
            }
            
            // below onchange is to get during chnages in the browser
            permissionStatus.onchange = () => {
                if(permissionStatus.state === "granted"){
                    setLocationAccess(true);
                } else {
                    setLocationAccess(false);
                }
            };
        });
    }, []);

    const attemptLocation = () => {
        if(locationAccess){
            setWithinDisabled(false);
            setWithinDisabledLocClass("");
        } else {
            setWithinDisabled(true);
            setWithinDisabledLocClass("disabled-location");
        }
    };

    useEffect(() => {
        attemptLocation();
    }, [locationAccess]);
    
    const searchMobileFiler = (params: any) => {
        setfilterViews((prs: any) => {
            return {
                ...prs,
                [params]: prs[params] ? false : true
            };
        });
    };

    useEffect(() => {
        // console.log(window.innerWidth, 922);

        if (window.innerWidth > 991.98) {
            setfilterViews((prs: any) => {
                return {
                    ...prs,
                    filterView: true,
                    listAndMapView: true
                };
            });
        } else {
            setfilterViews((prs: any) => {
                return {
                    ...prs,
                    filterView: false,
                    listAndMapView: false
                };
            });
        }
    }, []);

    const moveToJobViewPage = (params: any) => {
        if (questionId?.data?.data !== params.id) {
            navigate(`/view/${params.id}`);
        }
        else {
            navigate(`/view/${params.id}/apply-job/from/direct`);
        }
        // navigate(`view/${ress.id}`, { state: "jobView" })}
    };
    
    return (
        <>
            <div>
                <Loader loading={loader} />
            </div>
            <div className="wave-bg-parent">
                {location.pathname === "/" && clientIds?.pifs === clientConfig?.data?.data?.clientSettings?.clientId ?
                    // <div>
                    //     <Image src={Banner} clasName="img-fluid" />
                    // </div>
                    <div className="banner" ref={jobListDev}>
                        <img src={PifsBanner} alt="" />
                        <h1>Careers</h1>
                    </div>

                    :
                    ""
                }
                <div className="wave-bg">
                    <img src={Wave} className="img-wave" alt="wave image" />
                </div>


            </div>
            <div className="wave-bg-how">
                <h1>Careers</h1>
            </div>
            <CompletionSubHeader />
            
            {
                clientDesign === "hvtc" ? 
                    <JobListingsClient
                        location = {location}
                        clientId = {clientConfig?.data?.data?.clientSettings?.clientId}
                        checkListControl = {checkListControl}
                        jobsList = {jobsList}
                        setTableControl = {setTableControl}
                        tableControl = {tableControl}
                        sortList = {sortList}
                        displayPage = {displayPage}
                        filterView = {filterView}
                        moveToJobViewPage = {moveToJobViewPage}
                        pageChange = {pageChange}
                        filterListData = {filterListData}
                        searchControlSubmit = {searchControlSubmit}
                        pageSizeChange = {pageSizeChange}
                        withinDisabled = {withinDisabled}
                        withinDisabledLocClass = {withinDisabledLocClass}
                    />
                    : clientDesign === "common" ? 
                    <JobListingsSplit
                        location = {location}
                        clientId = {clientConfig?.data?.data?.clientSettings?.clientId}
                        checkListControl = {checkListControl}
                        jobsList = {jobsList}
                        searchMobileFiler = {searchMobileFiler}
                        filterViews = {filterViews}
                        setListView = {setListView}
                        listView = {listView}
                        setTableControl = {setTableControl}
                        tableControl = {tableControl}
                        sortList = {sortList}
                        pageSizeChange = {pageSizeChange}
                        displayPage = {displayPage}
                        filterView = {filterView}
                        moveToJobViewPage = {moveToJobViewPage}
                        publishingDate = {publishingDate}
                        pageChange = {pageChange}
                        navigateToJob = {navigateToJob}
                        clientIds = {clientIds}
                        filterListData = {filterListData}
                        searchControlSubmit = {searchControlSubmit}
                    />
                    : ""
            }
            
            {/* {location.state === null ?
                <div>
                    <Image src={Footer} clasName="img-fluid" />
                </div>
                :
                ""
            } */}
        </>
    );
};

export default JobListings;