import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "./TextError";
import { characters, format, pinFormat, posportVisa, customEmail } from "../../config/regex/regexPattern";
import { valudatesetformstatus } from "../../pages/onboarding/Onboarding";
import { clientIds } from "../../config/clients/clients";

const Input = (props: any) => {
    const { name, label, type, className, allData, completedPage, ...rest } = props;
    const fieldValidation = (value: any) => {
        let error: any;
        if (allData.validate) {
            if (allData.subtype === "text" || allData.subtype === "email" || allData.subtype === "number") {
                allData?.validations.forEach((element: any) => {
                    if (allData.type === "text" && allData.subtype === "text") {
                        if (!value && valudatesetformstatus !== "draft") {
                            if (element.type === "required") {
                                error = element.params[0];
                            }
                        } else if (!value && valudatesetformstatus === "draft" && completedPage) {
                            if (element.type === "required") {
                                error = element.params[0];
                            }
                        } else {
                            if (value) {
                                if (element.type === "pin_format") {
                                    if (!pinFormat.test(value)) {
                                        error = element.params[0];
                                    }
                                }
                                else if (element.type === "passport_number") {
                                    if (!posportVisa.test(value?.trim())) {
                                        error = element.params[0];
                                    }
                                } else if (element.type === "characters") {
                                    if (!characters.test(value)) {
                                        error = element.params[0];
                                    }
                                }

                            }
                        }
                    }
                    if (allData.type === "text" && allData.subtype === "email") {
                        if (element.type === "required" && !value && valudatesetformstatus !== "draft") {
                            error = element.params[0];
                        } else if (!value && valudatesetformstatus === "draft" && completedPage) {
                            if (element.type === "required") {
                                error = element.params[0];
                            }
                        } else if (element.type === "email" && value) {
                            if (!customEmail.test(value)) {
                                error = element.params[0];
                            }
                        }
                    }
                    if (allData.type === "number") {
                        if (!value && valudatesetformstatus !== "draft") {

                            if (element.type === "required") {
                                error = element.params[0];
                            }
                        } else if (!value && valudatesetformstatus === "draft" && completedPage) {
                            if (element.type === "required") {
                                error = element.params[0];
                            }
                        } else {
                            if (value) {
                                if (element.type === "format" && (sessionStorage.getItem("clientId") === clientIds.holmesglen)) {
                                    if (!format.test(value)) {
                                        error = element.params[0];
                                    }
                                }
                            }

                        }
                    }
                    if (allData.type === "iframe" && !value) {
                        if (element.type === "required") {
                            error = element.params[0];
                        }
                    }
                });

            }
        }
        // console.log(error);

        return error;
    };

    return (
        <>
            <div className="class-name">
                <Field name={name} id={name} type={"text"} validate={fieldValidation}
                    onBlur={(e: any) => e.preventDefault()} placeholder={allData.placeholder}
                    //  onKeyDown={(e: any) => {
                    //     if (e.target.type === "number") {
                    //         if (["e", "E"].includes(e.key)) {
                    //             e.preventDefault();
                    //         }
                    //     }
                    //     return e;
                    // }  } 
                    className={className} {...rest} />
                <ErrorMessage name={name} component={TextError} />
            </div>
        </>
    );
};

export default Input;