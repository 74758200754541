import React, { useRef, useEffect, useState } from "react";
import Button from "../../component/button/Button";
import "../../styles/pages/welcome.scss";
import Label from "../../component/label/Label";
import {
  createCandidateDataDocuments,
  getCandidateData,
  loderControl,
  loderOffControl,
} from "../../redux/slices/newRoleSlices";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, useFormikContext, Field, FieldArray } from "formik";
import * as htmlToImage from "html-to-image";
import FormikControl from "../../formikControl/FormikControl";
import Model from "../../component/modal/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import { apiPost } from "../../service/apiService";
import { endPoint } from "../../config/endPoint";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "../../component/loader/Loader";
import Iframe from "../../component/formikComponent/Iframe";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { htmlTostring } from "../../config/regex/regexPattern";
import { loginData } from "../../config/careers/careers";
import {
  domainWithHttp,
  oldFile,
  stepes,
  updateOldFile,
} from "../../config/onboarding/onBoarding";
import { ExportTableUrlData } from "../../types/types";
import Image from "../../component/image/Image";
import Bin from "../../assets/images/bin.png";
import "../../styles/timeline.scss";
import Slider from "react-slick";
import { number } from "yup";
interface CandidateData {
  activeStyle: boolean;
  candidateId: string;
  clientId: string;
  fields: any[];
  formId: string;
  label: string;
  active?: boolean;
  indata?: any;
  data?: any[];
  currentPage?: boolean;
  currentPageStyle?: string;
  completedPage?: string;
}

let formstatus = "draft";
let checkTimeline = "";
export let valudatesetformstatus = "";

export const updateValudatesetFormstatus = (params: any) => {
  valudatesetformstatus = params;
};

let formstatusTwo = false;
export let checkValidationElement: any = [];

export const setchioldValidation = (params: any) => {
  checkValidationElement = params;
};

export let childDisplay: any = {};
let apiIndex = 0;
let fileValue = 0;
let changeParams: any = null;

const OnBoarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const candidateData = useSelector((state: any) => {
    return state?.newRole?.candidateData;
  });


  const teamConfig = useSelector((state: any) => {
    return state?.newRole?.teamConfig;
  });
  const loader = useSelector((state: any) => {
    return state?.newRole?.loading;
  });
  const getAPIError = useSelector((state: any) => {
    return state?.newRole?.error;
  });
  const candidateDataDocuments = useSelector((state: any) => {
    return state?.newRole?.createCandidateDataDocuments;
  });
  const [updateFormData, setUpdateFormData] = useState<CandidateData[]>([]);

  const formRef = useRef<any>([]);
  const [screenShort, setScreenShort] = useState({
    active: true,
  });

  const [pageResload, setPageReload] = useState(false);

  const [countControl, setCountControl] = useState({
    show: false,
    uploadList: 0,
    fileCount: 0,
    fileUrls: [],
    saveModel: false,
    saveDraftTest: false,
    fileUplaod: false,
    parentage: 0,
    pageReload: false,
    timelineUpdate: false
  });

  const [iframeData, setIframeData] = useState({
    data: [],
    index: null,
    apiCall: false,
    errorSign: false,
    sigcontrol: false,
  });

  const ref = useRef(null);

  const [saveModel, setSaveModel] = useState(false);
  const [totalFile, setTotalFile] = useState<any>([]);
  const [movinIndex, setMovingIndex] = useState({
    fieldIndex: 0,
    fileIndex: 0,
    indexClose: false,
    totalFileUploadIndex: 0,
  });
  const [fileUploadValue, setFileUploadValue] = useState(0);
  const [uploadFileUrl, setUplaodFileUrl] = useState<any>([]);
  const [saveAndExitModel, setSveAndExitModel] = useState(false);
  const [saveToExitModel, setSaveToExitModel] = useState(false);
  const [dataIsUpdated, setDataIsUpdated] = useState(false);
  const [slickStyleUpdate, setSlickStyleUpdate] = useState({
    index: 5,
    currentPage: 0,
    nextIndex: 4
  });
  const [getScreenWidth, setGetScreenWidth] = useState({
    width: 0,
    height: 0,
    showCount: 5
  });
  const [getOrientationChange, setOrientationChange] = useState({
    timeStamp: "0"
  });
  const sliderRef = useRef<any>(null);
  const [getFilesForDeletion, setFilesForDeletion] = useState<any>([]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 75,
    arrow: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          initialSlide: 2
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ],
    afterChange: (current: any) => {
      setSlickStyleUpdate((prs) => {
        return {
          ...prs,
          currentPage: current,
          // index: current === 0 ? 5 : ((current + 1) * 5),
          // frontIndex: current === 0 ? 5 : (((current + 1) * 5) - 5)
        };
      });

    },
    beforeChange: (currentSlide: number, nextSlide: number) => {
      setSlickStyleUpdate((prs) => {
        return {
          ...prs,
          // index: current === 0 ? 5 : ((current + 1) * 5),
          nextIndex: getScreenWidth.showCount === 5 ? nextSlide + 4 : nextSlide + 2
        };
      });
    }

  };

  useEffect(() => {
    if (screen.width < 575) {
      setGetScreenWidth((prs: any) => {
        return {
          ...prs,
          width: screen.width,
          showCount: 3
        };
      });
    }
  }, []);
  
  let orientationChanged:any = 0;
  const handleOrientationChange = (e:any) => {
    setOrientationChange((orientationState: any) => {
      return {
        ...orientationState,
        timeStamp: e.timeStamp
      };
    });   
  };
  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange);
  }, []);

  useEffect(() => {
    if (!countControl.saveModel && !saveModel && pageResload) {
      window.location.reload();
    }
  }, [countControl]);

  useEffect(() => {
    if (candidateData?.statusCode === 200) {
      const navigateTo = sessionStorage.getItem("navigateTo");
      if (navigateTo === null || navigateTo === undefined || navigateTo === "") {
        sessionStorage.setItem("navigateTo", candidateData?.currentPage);
      }
      setCountControl((prs) => {
        return {
          ...prs,
          show: false,
          timelineUpdate: false
        };
      });
      setScreenShort((prs) => {
        return {
          ...prs,
          active: true,
        };
      });
      if (
        candidateData?.currentPage === -2 ||
        candidateData?.currentPage === "-2"
      ) {
        navigate("/getCandidate/onboarding-result");
        dispatch(loderControl());
      } else if (
        candidateData?.currentPage === "-1" ||
        candidateData?.currentPage === -1
      ) {
        navigate(`/getCandidate/${sessionStorage.getItem("clientId")}`);
        dispatch(loderControl());
      } else {
        // setTimeout(() => {
        dispatch(loderOffControl());
        // }, 5000);
      }
      const candidateDataRess = { ...candidateData };
      candidateDataRess["finalStep"] =
        candidateDataRess["finalStep"] === null
          ? 0
          : candidateDataRess["finalStep"];
      if (sessionStorage.getItem("navigateTo")) {
        candidateDataRess["currentPage"] = Number(
          sessionStorage.getItem("navigateTo")
        );
      }
      const modifideFormData = candidateDataRess?.data.map(
        (data: any, i: number) => {
          const indata: any = {};
          const control = { ...data };
          control.index = i;
          control.active = false;
          control.currentPage = false;
          control.currentPageStyle = "";
          if (
            (candidateDataRess?.currentPage === "-1" && i === 0) ||
            (i === 0 && candidateDataRess?.currentPage === 1)
          ) {
            control.currentPage = true;
            control.currentPageStyle = "current-page";
            control.active = true;
          } else if (candidateDataRess?.currentPage > i + 1) {
            control.completedPage = true;
            // control.currentPageStyle = "current-page";
          } else if (candidateDataRess?.currentPage === i + 1) {
            control.active = true;
            // control.currentPageStyle = "current-page";
            control.currentPage = true;
          }

          if (candidateDataRess?.finalStep > i + 1) {
            control.currentPageStyle = "current-page";
            control.completedPage = true;

          } else if (candidateDataRess?.currentPage === i + 1) {
            control.currentPageStyle = "current-page";
          }

          const sunFormIndate1: any = [];
          const sunFormIndate2: any = [];

          let sunIndexIndateCount = 0;
          const formFields = control?.fields?.map((element: any) => {
            const element1 = { ...element };
            if (element.firstName) {
              localStorage.setItem("clindFirstName", element.value);
            }
            // get subform 1 value
            if (element?.subForm && element.name === "subForm-1") {
              const obj: any = {};
              element.subForm.forEach((item: any, i: number) => {
                if (item.type === "date") {
                  if (item.value) {
                    obj[item.name] = new Date(item.value);
                  } else {
                    obj[item.name] = item.value;
                  }
                } else {
                  if (item.type === "file") {
                    obj[item.name] = item.value;
                    oldFile[`subForm_1.${sunIndexIndateCount}.${item.name}`] =
                      item.value;
                  } else {
                    obj[item.name] = item.value;
                  }
                }
              });
              sunFormIndate1.push(obj);
              sunIndexIndateCount = sunIndexIndateCount + 1;
              indata.subForm_1 = sunFormIndate1;
            }
            //  get subform 2 value
            if (element?.subForm && element.name === "subForm-2") {
              const obj: any = {};
              element.subForm.forEach((item: any, i: number) => {
                if (item.type === "date") {
                  if (item.value) {
                    obj[item.name] = new Date(item.value);
                  } else {
                    obj[item.name] = item.value;
                  }
                } else {
                  if (item.type === "file") {
                    const subFormIndex1 = control?.fields.filter(
                      (ress: any) => ress.name === "subForm-1"
                    );
                    obj[item.name] = item.value;
                    oldFile[
                      `subForm_2.${sunIndexIndateCount - subFormIndex1.length
                      }.${item.name}`
                    ] = item.value;
                  } else {
                    obj[item.name] = item.value;
                  }
                }
              });
              sunFormIndate2.push(obj);
              sunIndexIndateCount = sunIndexIndateCount + 1;
              indata.subForm_2 = sunFormIndate2;
            }

            if (element.type === "date") {
              if (element.value) {
                indata[element.name] = new Date(element.value);
              } else {
                indata[element.name] = element.value;
              }
            } else if (element.type === "radio-group") {
              if (element?.is_child_validate === null) {
                childDisplay = {
                  parentName: element.name,
                  child_display: element.child_display?.map((ress: any) => {
                    return ress.child_display_id;
                  }),
                  visibleArray: element.child_display,
                  hideName: null,
                };
              }
              indata[element.name] = element.value;
            } else if (element.type === "select") {
              if (element.is_child_validate) {
                checkValidationElement.push({
                  parent_value: element.child_validate.parent_validate_value,
                  child_name: element.child_validate.child_validate_id,
                  parent_name: element.name,
                });
              }
              indata[element.name] = element.value;
            } else if (element.type === "file") {
              indata[element.name] =
                element.value !== undefined ? element.value : [];
              oldFile[element.name] =
                element.value !== undefined ? element.value : [];
            } else {
              if (element.type === "iframe") {
                const obj = { ...element };
                obj.index = i;
                indata[element.name] = element.value;
                setIframeData((prs: any) => {
                  return {
                    ...prs,
                    data: [...prs.data, obj],
                  };
                });
              } else {
                if (element?.firstName && !element.value) {
                  indata[element.name] =
                    candidateData?.candidateDetails?.firstName;
                  element1["value"] = candidateData?.candidateDetails?.firstName;
                } else if (element?.lastName && !element.value) {
                  indata[element.name] =
                    candidateData?.candidateDetails?.lastName;
                  element1["value"] = candidateData?.candidateDetails?.lastName;
                } else if (element?.email && !element.value) {
                  indata[element.name] = candidateData?.candidateDetails?.email;
                  element1["value"] = candidateData?.candidateDetails?.email;
                } else if (element?.phone && !element.value) {
                  indata[element.name] = candidateData?.candidateDetails?.phone;
                  element1["value"] = candidateData?.candidateDetails?.phone;
                } else if (
                  element.name !== "subForm-1" &&
                  element.name !== "subForm-2"
                ) {
                  indata[element.name] = element.value;
                }
              }
            }
            return element1;
          });
          return { ...control, indata: indata, fields: formFields };
        }
      );
      setUpdateFormData(modifideFormData);
    } else if (candidateData === null || candidateData?.statusCode === 400) {
      navigate("*");
    }
  }, [candidateData]);

  useEffect(() => {
    if (candidateDataDocuments !== null) {
      if (candidateDataDocuments.status === 200) {
        const obj: any = {};
        obj["clientId"] = sessionStorage.getItem("clientId");
        obj["domainWithHttp"] = domainWithHttp;
        dispatch<any>(
          getCandidateData(obj)
        );
      }
    }
  }, [candidateDataDocuments]);

  const fileNameModifide = (fileData: any) => {
    const aaaWithoutExtension = fileData?.file?.name.replace(/\.[^.]+$/, "");
    let result = "";
    if (aaaWithoutExtension?.length > 30) {
      const lastDotIndex = fileData?.file?.name?.toString()?.lastIndexOf(".");
      const extension = fileData?.file?.name?.substring(lastDotIndex + 1);
      const prifix = fileData?.file?.name?.substring(0, 15);
      const subfix = aaaWithoutExtension?.slice(-10);
      result = prifix + "..." + subfix + "." + extension;
    } else {
      result = fileData?.file?.name;
    }
    return result;
  };

  const deleteFilesFromServer = async () => {
    const pathParamObj:any = {};
    pathParamObj["clientId"] = sessionStorage.getItem("clientId");
    pathParamObj["domainWithHttp"] = domainWithHttp;
    const response = await apiPost(endPoint.deleteFile, {fileUrl:getFilesForDeletion}, pathParamObj);
    return response;
  };

  const exportPDF = async (params: any, index: any) => {
    const doc = new jsPDF();
    const pdfTable = params.filter((ress: any) => ress.active)[0];
    const tableBody: any = [];
    const baseUrl = process.env.REACT_APP_API_BASE_URL + "download?FileUrl=";
    //get current time stamp
    let currDate = new Date();
    let currYear = currDate.getFullYear();
    let currMonth = currDate.getMonth() + 1;
    let currDay = currDate.getDate();
    let currHours = currDate.getHours();
    let currMinutes = currDate.getMinutes();
    let currSeconds = currDate.getSeconds();
    let headerText = "Document created on : "+ currDay.toString().padStart(2, "0") + "-" + currMonth.toString().padStart(2, "0") + "-" + currYear + " " + currHours + ":" + currMinutes + ":" + currSeconds;
    pdfTable?.fields?.forEach((element: any) => {
      if (element.type === "date") {
        if (element.value) {
          const date =
            element.value.getDate().toString().padStart(2, "0") +
            "-" +
            (element.value.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            element.value.getFullYear();
          tableBody.push([element.pdfLabel, date]);
        }
      } else if (element.type === "file") {
        const fileName: any[] = [];
        if (element?.value.length > 0) {
          element?.value.forEach(async (tt: any, rowIndex: any) => {
            // console.log(88888, tt);

            const result = fileNameModifide(tt);
            fileName.push({
              name: result,
              url: `${baseUrl}${tt.file.url}`, //ress.file.url,
            });
          });
          tableBody.push([
            element.pdfLabel,
            {
              items: fileName,
              type: "file-url",
            },
          ]);
        } else {
          tableBody.push([element.pdfLabel, ""]);
        }
      } else if (
        element.type === "checkbox-group" ||
        element.type === "checkbox"
      ) {
        if (element.label) {
          tableBody.push([element.pdfLabel, element.value]);
        } else {
          tableBody.push([element.values[0].label, element.value]);
        }
      } else if (element.type === "html") {
        const strippedString = element?.value?.replace(htmlTostring, "");
      } else if (element.name === "subForm-1" || element.name === "subForm-2") {
        element.subForm.forEach((item: any) => {
          if (item.type === "text") {
            tableBody.push([item.pdfLabel, item.value]);
          } else {
            const fileName: any = [];
            if (Array.isArray(item.value)) {
              item.value.forEach((ress: any) => {
                const result = fileNameModifide(ress);
                fileName.push({
                  name: result,
                  url: `${baseUrl}${ress.file.url}`, //ress.file.url,
                });
              });

              tableBody.push([
                item.pdfLabel,
                {
                  items: fileName,
                  type: "file-url",
                },
              ]);
            } else {
              tableBody.push([item.pdfLabel, item.value]);
            }
          }
        });
      } else if (element.type !== "iframe" && element.type !== "hr") {
        tableBody.push([element.pdfLabel, element.value]);
      }
    });
    const tableStyles = {
      fillColor: candidateData?.clientDetails.brandColor,
      fontStyle: "bold",
      fontSize: 10,
    };
    const links = [];

    autoTable(doc, {
      head: [["Label", "Value"]],
      body: tableBody,
      headStyles: tableStyles as any,
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 100 }

      },

      didParseCell: (data) => {
        if (data.section === "body" && data.column.index === 1) {
          if (Object.prototype.hasOwnProperty.call(data.cell.raw, "type")) {
            const urlData = data.cell.raw as ExportTableUrlData;
            if (urlData.type === "file-url") {
              const n = urlData.items.length;
              if (n > 1) {
                const currentHeight = 5;
                data.cell.styles.minCellHeight = currentHeight * n + 2;
                data.cell.styles.cellWidth = 100;
                data.cell.styles.minCellWidth = 100;
                data.cell.styles.overflow = "hidden";
                data.row.height = currentHeight * n + 2;
              }
              data.cell.text = [""];
            }
          }
        }
      },

      didDrawCell: (data) => {
        if (data.section === "body" && data.column.index === 1) {
          if (Object.prototype.hasOwnProperty.call(data.cell.raw, "type")) {
            const urlData = data.cell.raw as ExportTableUrlData;
            if (urlData.type === "file-url") {
              let yPos = 5;
              urlData.items.forEach((itm) => {
                doc.textWithLink(
                  itm.name,
                  data.cell.x + 1,
                  data.cell.y + yPos,
                  { url: itm.url }
                );
                yPos += 5;
              });
            }
          }
        }
      },
      didDrawPage: (data) => {
        doc.setFontSize(8);
        doc.text(headerText, data.settings.margin.left, 10);
      },
    });
    // doc.save("table.pdf");
    const pdfDataUri = doc.output("datauristring");
    return await pdfDataUri;
  };

  const submitCandidateData = async (params: any) => {
    if (formstatusTwo) {
      formstatus = "draft";
    } else {
      sessionStorage.removeItem("navigateTo");
    }
    setFileUploadValue(0);
    const fd = new FormData();
    let base64Image: any = null;
    // return;
    params?.forEach(async (ress: any, i: number) => {
      if (ress.active) {
        if (formstatus === "draft") {

          fd.append("formStatus", "draft");
        } else {
          base64Image = await exportPDF(params, i);
          fd.append(
            "formStatus",
            updateFormData.length !== i + 1 ? "submit" : "complete"
          );
        }
        fd.append("currentPage", (i + 1)?.toString());
        fd.append("candidateId", ress.candidateId);
        fd.append("clientId", ress.clientId);
        fd.append("formName", ress.label);
        fd.append(
          "finalStep",
          candidateData.finalStep === null ? 0 : candidateData.finalStep
        );
        // fd.append("finalStep", candidateData.finalStep === null ? 0 : candidateData.finalStep);
        fd.append("navigateTo", sessionStorage.getItem("navigateTo") || "");
        if (formstatus !== "draft") {
          // fd.append("pdfDoc", base64Image.split("data:image/jpeg;base64,")[1]);
          fd.append(
            "pdfDoc",
            base64Image.split(
              "data:application/pdf;filename=generated.pdf;base64,"
            )[1]
          );
        }
      }
    });
    fd.append("data", JSON.stringify(params));

    // return;
    if (checkTimeline === "") {

      const candidateDataRess = await dispatch<any>(
        createCandidateDataDocuments(fd)
      );

      window.scrollTo(0, 0);
      if (formstatus === "draft" && candidateDataRess?.payload?.status === 200) {
        if (iframeData.sigcontrol) {
          setPageReload(true);
        } else {
          if (!saveToExitModel && !countControl.timelineUpdate) {
            setCountControl((prs) => {
              return {
                ...prs,
                saveDraftTest: true,
                saveModel: true,
              };
            });
          }
          setPageReload(true);
        }
      } else {
        setScreenShort((prs) => {
          return {
            ...prs,
            saveModel: false,
          };
        });
      }
    }

  };
  useEffect(() => {
    if (movinIndex.indexClose) {
      let apiCall = 0;
      const fileObjIndex = 0;
      let controlApiCall = 1;
      const activeFormData: any = updateFormData.filter((ress) => ress?.active);
      const obj = { ...activeFormData[0] };
      const fData = new FormData();
      const pathParamObj:any = {};
      pathParamObj["clientId"] = sessionStorage.getItem("clientId");
      pathParamObj["domainWithHttp"] = domainWithHttp;
      fData.append("clientId", obj["clientId"]);
      fData.append("candidateId", obj["candidateId"]);
      fData.append("formId", obj["formId"]);
      if (totalFile.length > 0) {
        fData.append("fieldId", totalFile[0]["name"]);
        fData.append("files", totalFile[fileObjIndex]?.value[apiCall]);
      }
      const selectedFileupload = async () => {
        const response = await apiPost(
          endPoint.createCandidateDataDocuments,
          fData,
          pathParamObj
        );
        if (response.status === 200) {
          controlApiCall = controlApiCall + 1;
          const obj = {
            parentName: totalFile[fileObjIndex]?.value[apiCall]["name"],
            url: response?.data?.data?.url[0],
            fileName: response?.data?.data?.fileName,
            childName: "",
          };
          apiCall = apiCall + 1;
          setTotalFile((oldArray: any) => [
            ...oldArray.map((ress: any) => {
              return ress;
            }),
          ]);

          if (controlApiCall >= movinIndex.fileIndex) {
            selectedFileupload();
          } else {
            setMovingIndex((prs) => {
              return {
                ...prs,
                indexClose: false,
              };
            });
          }
        }
      };
      if (movinIndex.indexClose) {
        selectedFileupload();
      }
    }
  }, [totalFile, movinIndex]);

  useEffect(() => {
    if (Math.round(fileUploadValue) === 100) {
      setSaveModel(false);
      let subFormIndex = 0;
      const sendData = updateFormData.map((ress, i) => {
        if ((changeParams && changeParams?.prsIndex === i) || ress.active) {
          const paramsData = formRef.current[i].values;
          return {
            ...ress,
            fields: ress.fields.map((data, i) => {
              const obj = { ...data };
              if (obj?.subForm?.length > 0) {
                const subFormList = data?.subForm?.map(
                  (subData: any, j: number) => {
                    if (subData.type === "file") {
                      if (data.name === "subForm-1") {
                        const filterFile = uploadFileUrl.filter(
                          (gg: any) =>
                            gg?.file?.fieldId ===
                            `subForm_1.${subFormIndex}.${subData.name}`
                        );
                        const oldFileUrls = oldFile[
                          `subForm_1.${subFormIndex}.${subData.name}`
                        ]?.filter((aa: any) => !(aa instanceof File));
                        if (filterFile.length > 0) {
                          return {
                            ...subData,
                            value: oldFileUrls.concat(filterFile),
                          };
                        } else {
                          return {
                            ...subData,
                            value: oldFileUrls === undefined ? [] : oldFileUrls,
                          };
                        }
                      } else {
                        const filterFile = uploadFileUrl.filter(
                          (gg: any) =>
                            gg?.file?.fieldId ===
                            `subForm_2.${subFormIndex - paramsData.subForm_1.length
                            }.${subData.name}`
                        );
                        const oldFileUrls = oldFile[
                          `subForm_2.${subFormIndex - paramsData.subForm_1.length
                          }.${subData.name}`
                        ]?.filter((aa: any) => !(aa instanceof File));
                        if (filterFile.length > 0) {
                          return {
                            ...subData,
                            value: oldFileUrls.concat(filterFile),
                          };
                        } else {
                          return {
                            ...subData,
                            value: oldFileUrls === undefined ? [] : oldFileUrls,
                          };
                        }
                      }
                    } else {
                      if (data.name === "subForm-1") {
                        return {
                          ...subData,
                          value:
                            paramsData.subForm_1[subFormIndex][subData.name],
                        };
                      } else {
                        return {
                          ...subData,
                          value:
                            paramsData.subForm_2[
                            subFormIndex - paramsData.subForm_1.length
                            ][subData.name],
                        };
                      }
                    }
                  }
                );
                subFormIndex = subFormIndex + 1;
                return {
                  ...data,
                  subForm: subFormList,
                };
              } else {
                if (data.type === "file") {
                  const fileUrl: any = [];
                  if (uploadFileUrl.length > 0) {
                    const filterFile = uploadFileUrl.filter(
                      (gg: any) => gg?.file?.fieldId === obj.name
                    );
                    const oldFileUrls = Array.from(oldFile[data.name])?.filter(
                      (aa: any) => !(aa instanceof File)
                    );
                    if (filterFile.length > 0) {
                      obj.value = oldFileUrls.concat(filterFile);
                    } else {
                      obj.value = oldFileUrls === undefined ? [] : oldFileUrls;
                    }
                  } else {
                    obj.value = oldFile[data.name];
                  }
                } else if (iframeData.apiCall && obj.type === "iframe") {
                  obj.value = "completed";
                } else if (obj.type !== "iframe" || obj.type !== "file") {
                  obj.value = paramsData[data.name];
                }
                const userFirstName = Object.keys(obj);
                const checkFirstName = userFirstName.find((res: any) => res.includes("firstName"));
                if (checkFirstName === "firstName") {
                  localStorage.setItem("clindFirstName", obj["value"]);
                }
                return obj;
              }
            }),
          };
        } else {
          return ress;
        }
      });
      submitCandidateData(sendData);
    }
  }, [fileUploadValue]);

  const fileUploadApiCall = (params: any) => {
    const checkAndSplit = params?.filter(
      (ress: any) => ress?.file instanceof File
    );
const findPDFLabel = (fileId:any, formObject:any) => {
      let fileIdString = fileId.toString();
      let fileName = fileIdString.split(".").pop();
      let subformName = fileIdString.substring(0, fileIdString.indexOf("_"));
      let subformIndex = fileIdString.substring(fileIdString.indexOf("_") + 1, fileIdString.indexOf("_") + 2);  //Numeric identifier for field set, ie., different PDF labels      
      let subformFinalIndexArrayPointer = fileIdString.substring(fileIdString.indexOf(".") + 1, fileIdString.indexOf(".") + 2);
      let subformFinalIndex:any, labelList:any, subformLocation:any;
      if(subformName.length > 0) {
        // has subforms
        subformFinalIndex = formObject.filter((f: {}) => Object.keys(f).find((e) => e === subformName));
        subformLocation = subformFinalIndex.find((e: { name: string; }) => e.name === ("subForm-" + subformIndex)); //Finds the subform under the key: name which has the string "subForm"
         //get pdf label after finding file name attached to subform
        return subformLocation.subForm?.find((o: { name: any; }) => o.name === fileName).pdfLabel;
      }
      else {
        //no subforms
        labelList = formObject.filter((f: {}) => Object.keys(f).find((e) => e === "pdfLabel"));
        return formObject.find((f: { name: any; }) => f.name === fileId).pdfLabel;
      }
    };
    if (checkAndSplit.length > 0) {
      apiIndex = 0;
      fileValue = 100 / checkAndSplit.length;
      setSaveModel(true);
      const activeFormData: any = updateFormData.filter((ress) => ress?.active);
      const obj = { ...activeFormData[0] };
const pathParamObj:any = {};
      pathParamObj["clientId"] = sessionStorage.getItem("clientId");
      pathParamObj["domainWithHttp"] = domainWithHttp;
      const filesSubmit = async () => {
        const fData = new FormData();
        fData.append("pdfLabel", findPDFLabel(checkAndSplit[apiIndex]?.fieldId, obj?.fields));
        fData.append("formLabel", obj["label"]);
        fData.append("clientId", obj["clientId"]);
        fData.append("candidateId", obj["candidateId"]);
        fData.append("formId", obj["formId"]);
        fData.append("fieldId", checkAndSplit[apiIndex]?.fieldId);
        fData.append("files", checkAndSplit[apiIndex]?.file);
        const response: any = await apiPost(
          endPoint.createCandidateDataDocuments,
          fData,
          pathParamObj
        );
        if (response.status === 200) {
          const obj: any = {};
          obj["file"] = {
            fieldId: response?.data?.data?.fieldId,
            url: response?.data?.data?.url[0],
            name: response?.data?.data?.fileName,
          };
          obj["name"] = response?.data?.data?.fileName;
          obj["newUrl"] = true;
          setUplaodFileUrl((prs: any) => [...prs, obj]);
          setFileUploadValue((prevValue) => prevValue + fileValue);
          apiIndex++;
          if (checkAndSplit.length > apiIndex) {
            filesSubmit();
          }
        } else {
          console.log();
        }
      };
      if (checkAndSplit?.length > 0) {
        filesSubmit();
      }
    } else {
      setFileUploadValue(100);
    }
    return;
  };

  const removeJsonFieldValue = (params: any) => {
    return {
      ...params,
      subForm: params.subForm.map((ress: any) => {
        return {
          ...ress,
          value: "",
        };
      }),
    };
  };

  const fileUploadData = async () => {
    setSveAndExitModel(false);
    const updateNewSubForm = updateFormData.map((ress, i) => {
      if ((changeParams && changeParams?.prsIndex === i) || ress.active) {
        const paramsData = formRef?.current[i]?.values;
        const getSubFormFromList1 = ress.fields.filter(
          (ress) => ress?.subForm && ress.name === "subForm-1"
        );
        const getSubFormFromList2 = ress.fields.filter(
          (ress) => ress?.subForm && ress.name === "subForm-2"
        );
        const notGetSubFormFromList = ress.fields.filter(
          (ress) => !ress.subForm
        );
        const field: any = [];
        ress.fields.forEach((element: any) => {
          if (element.name !== "subForm-1" && element.name !== "subForm-2") {
            const obj = { ...element };
            obj.value = "";
            field.push(element);
          } else {
            if (element.name === "subForm-1" && element.status) {
              paramsData.subForm_1.forEach((xx: any, i: number) => {
                const updatedObject = {
                  ...getSubFormFromList1[0],
                  status: i === 0 ? true : false,
                };
                field.push(removeJsonFieldValue(updatedObject));
              });
            } else if (element.name === "subForm-2" && element.status) {
              paramsData.subForm_2.forEach((xx: any, i: number) => {
                const updatedObject = {
                  ...getSubFormFromList2[0],
                  status: i === 0 ? true : false,
                };
                field.push(removeJsonFieldValue(updatedObject));
              });
            }
          }
        });

        return {
          ...ress,
          fields: field,
        };
      }
      return ress;
    });
    setUpdateFormData(updateNewSubForm);
    const multiFileListConvertToSingle: any = [];
    setUplaodFileUrl([]);

    Object.keys(oldFile).forEach(async (ress) => {
      for (const fileObj of oldFile[ress]) {
        if (fileObj instanceof File) {
          multiFileListConvertToSingle.push({
            file: fileObj,
            name: fileObj.name,
            fieldId: ress,
          });
        } else {
          multiFileListConvertToSingle.push({
            file: fileObj.file,
            name: fileObj.name,
            fieldId: ress,
          });
        }
      }
    });
    if (multiFileListConvertToSingle.length > 0) {
      fileUploadApiCall(multiFileListConvertToSingle);
    } else {
      setFileUploadValue(100);
    }
    if (getFilesForDeletion?.length > 0) {
      deleteFilesFromServer();   
    }    
  };

  const saveModelClose = () => {
    setCountControl((prs) => {
      return {
        ...prs,
        saveModel: false,
        saveDraftTest: false,
      };
    });
  };
  const saveAndEditTime = (formIndex: any) => {
    setTimeout(() => {
      const paramsData = formRef.current[formIndex].errors;
      const errorData: any = Object.values(paramsData);
      if (errorData?.length > 0) {
        setSveAndExitModel(false);
        sessionStorage.setItem("navigateTo", (formIndex + 1).toString());
        return;
      }
    }, 1000);
    // else {
    //   // fileUploadData();

    // }


    formstatus = "draft";
  };


  const saveAndEdit = () => {
    formstatusTwo = true;
    const prsForm: any = updateFormData.filter((ress: any) => ress.active);
    if (
      candidateData.finalStep === null ||
      prsForm[0]["index"] + 1 === Number(candidateData.finalStep)
    ) {

      fileUploadData();
      formstatus = "draft";
      setCountControl((prs) => {
        return {
          ...prs,
          saveModel: false,
          fileUplaod: false,
        };
      });
      setSveAndExitModel(false);
      setSaveToExitModel(false);
    } else {
      fileUploadData();
      // document.getElementById(`submitBtn_${prsForm[0]["index"]}`)?.click();
      // // setSveAndExitModel(false);
      setCountControl((prs) => {
        return {
          ...prs,
          saveModel: false,
          fileUplaod: false,
        };
      });
    }
  };

  const removeCurrentData = () => {
    const params: any = changeParams.params;
    if (
      changeParams?.index !== null &&
      candidateData?.finalStep >= changeParams?.index
    ) {
      const modifideFormData = updateFormData.map((data: any, k) => {
        const obj = { ...data };
        obj.active = false;
        if (params?.formId === data.formId) {
          obj.active = true;
          obj.currentPage = true;
          obj.currentPageStyle = "current-page";
        } else {
          if (candidateData?.finalStep > k + 1) {
            obj.completedPage = true;
            obj.currentPageStyle = "current-page";
            obj.currentPage = false;
          } else {

            obj.completedPage = false;
            obj.currentPageStyle = "null";
            obj.currentPage = false;
          }
        }
        if (params?.index === k) {
          return {
            ...obj,
            indata: params.indata,
          };
        } else {
          return obj;
        }
      });
      setUpdateFormData(modifideFormData);
      setSveAndExitModel(false);
    }
  };
  const getCurrentPage = (params: any, i: number) => {
    const navigateTo: any = sessionStorage.getItem("navigateTo");

    if (navigateTo !== null && navigateTo !== undefined && Number(navigateTo) === (i)) {
      return;
    } else {
      setSveAndExitModel(false);
      setSaveToExitModel(true);
      const getPrsActiveIndex: any[] = updateFormData.filter(
        (ress) => ress.active
      );
      const formData = formRef.current[getPrsActiveIndex[0]["index"]];
      changeParams = {
        params: params,
        index: i,
        prsIndexValues: formData,
        prsIndex: getPrsActiveIndex[0]["index"],
      };
      sessionStorage.setItem("navigateTo", i.toString());
      // sessionStorage.setItem("prs", changeParams.prsIndex);
      setDataIsUpdated(false);
      const checkingDataUpdate = Object.keys(formData?.initialValues).map(
        (ress) => {
          if (Array.isArray(formData.initialValues[ress])) {
            if (
              formData.initialValues[ress].length !== formData.values[ress].length
            ) {
              setDataIsUpdated(true);
              return true;
            } else {
              let hasMismatch = false;
              formData.initialValues[ress].forEach((item: any, i: number) => {
                if (item.name !== formData.values[ress][i]?.name) {
                  setDataIsUpdated(true);
                  hasMismatch = true;
                } else {
                  Object.keys(item).forEach((xx) => {
                    if (
                      formData.initialValues[ress][i][xx] !==
                      formData.values[ress][i][xx]
                    ) {
                      hasMismatch = true;
                      setDataIsUpdated(true);
                    }
                  });
                }
              });
              return hasMismatch;
              // }else{

              // }
            }
            // }
          } else if (formData.initialValues[ress] !== formData.values[ress]) {
            setDataIsUpdated(true);
            return true;
          }
          return null;
        }
      );

      const trueValues = checkingDataUpdate.some(
        (element, k) => element === true
      );

      if (trueValues) {
        setSveAndExitModel(true);
      } else {
        removeCurrentData();
      }
    }

  };

  const removeEditData = () => {
    navigate(0);
  };
  const checkWithFormStatus = () => {
    formstatus = "draft";


    const prsForm: any = updateFormData.filter(
      (ress: any) => ress.index === changeParams.prsIndex
    );
    if (prsForm[0]["index"] + 1 === Number(candidateData.finalStep)) {
      checkTimeline = "cheking";
      updateValudatesetFormstatus("draft");
      document.getElementById(`submitBtn_${prsForm[0]["index"]}`)?.click();
      setCountControl((prs) => {
        return {
          ...prs,
          timelineUpdate: true
        };
      });
      saveAndEditTime(prsForm[0]["index"]);
    } else {
      checkTimeline = "";
      document.getElementById(`submitBtn_${prsForm[0]["index"]}`)?.click();
      setSveAndExitModel(false);
      sessionStorage.setItem("navigateTo", (prsForm[0]["index"] + 1).toString());

    }
    formstatusTwo = true;
  };
  const modelHeader = () => {
    return (
      <>
        <Modal.Header>
          <div>File upload</div>
        </Modal.Header>
      </>
    );
  };

  const modelBody = () => {
    return (
      <>
        <Modal.Body>
          <ProgressBar
            now={Math.round(fileUploadValue)}
            animated
            label={`${Math.round(fileUploadValue)}%`}
          />
          <div className="file-name-list">
            <ul className="list-group list-group-flush">
              {uploadFileUrl.map((ress: any) => {
                return (
                  <>
                    {ress.newUrl ? (
                      <li
                        key={ress.name}
                        className="list-group-item px-0 d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">{ress.file.name}</div>
                        <span className="">
                          <div className="upload-file-list-name">
                            <i className="pi pi-check"></i>
                          </div>
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </ul>
          </div>
        </Modal.Body>
      </>
    );
  };

  const closeModel = () => {
    setCountControl((prs) => {
      return {
        ...prs,
        show: false,
      };
    });
  };
  const saveModelBody = () => {
    return (
      <>
        {!countControl.saveDraftTest ? (
          <>
            <Modal.Body className="p-3">
              <div className="d-flex">
                <div>
                  <h3 className="heading-3">
                    Are you sure you want to save and exit?
                  </h3>
                  <p className="save-para mb-0">
                  You will be able to return to finish this form at any time before the due date via the unique link in your email.
                  </p>
                </div>
                <div>
                  <div>
                    <i
                      role="button"
                      onClick={() => saveModelClose()}
                      className="pi pi-times"
                    ></i>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="p-3">
              <div className="text-end">
                <Button
                  label={"No, Continue Onboarding"}
                  onClick={() => {
                    saveModelClose();
                  }}
                  clasName="btn save-close-button"
                />
                <Button
                  label={
                    <>
                      <div className="d-flex">
                        <div className="yes-save-div">
                          <i className="pi pi-check"></i>
                        </div>
                        <span className="ms-2">Yes, Save & Complete Later</span>
                      </div>
                    </>
                  }
                  onClick={() => {
                    saveAndEdit();
                  }}
                  clasName="btn btn-primary ms-3"
                />
              </div>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body className="p-3">
              <div className="d-flex  justify-content-between">
                <div>
                  <h3 className="success-text d-flex">
                    <div className="bg-round">
                      <i className="pi pi-check"></i>
                    </div>
                    <span className="ps-2">
                      Your form has been saved successfully
                    </span>
                  </h3>
                  <p className="save-para-1 mb-0">
                    You may now close this window.
                  </p>
                </div>
                <div>
                  <div>
                    <i
                      role="button"
                      onClick={() => saveModelClose()}
                      className="pi pi-times"
                    ></i>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </>
    );
  };

  const confirmMass = () => {
    return (
      <>
        <Modal.Body className="p-3">
          <div className="d-flex justify-content-between">
            <div>
              <h3 className="heading-3">
                Are you sure you want to save existing data?
              </h3>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-3">
          <div className="text-center w-100">
            <Button
              label={"No"}
              onClick={() => {
                removeEditData();
              }}
              clasName="btn save-close-button"
            />
            <Button
              label={
                <>
                  <div className="d-flex">
                    <div className="yes-save-div">
                      <i className="pi pi-check"></i>
                    </div>{" "}
                    <span className="ms-2">Yes</span>
                  </div>
                </>
              }
              onClick={() => checkWithFormStatus()}
              clasName="btn btn-primary ms-3"
            />
          </div>
        </Modal.Footer>
      </>
    );
  };
  
  const removeFile = (index: any, filename: any, name: any, formik: any, fileItem?:any) => {
    const checkFieldId = name.split(".");
    const removeFileInStore: any[] = [];
    //initial call to delete file from server
    if (fileItem?.file?.url) {
      setFilesForDeletion((files:any) => [...files, fileItem?.file?.url]);
    }
    //remove locally
    if (checkFieldId.length === 3) {
      oldFile[name]?.forEach((data: any) => {
        if (data.name !== filename) {
          removeFileInStore.push(data);
        }
      });
      oldFile[name] = removeFileInStore;
      formik?.setFieldValue(name, removeFileInStore);
    } else {
      oldFile[name]?.forEach((data: any) => {
        if (data.name !== filename) {
          removeFileInStore.push(data);
        }
      });
      // oldFile[name] = removeFileInStore;
      const updateFile = { ...oldFile };
      updateFile[name] = removeFileInStore;
      updateOldFile(updateFile);
      formik?.setFieldValue(name, removeFileInStore);
    }
  };

  const base64StringToBlob = (base64String: any) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: "application/octet-stream" });
  };
  const downloadFile = async (params: any) => {
    const obj: any = {};
const pathParamObj:any = {};
    obj["url"] = params?.file?.url;
    pathParamObj["clientId"] = sessionStorage.getItem("clientId");
    pathParamObj["domainWithHttp"] = domainWithHttp;
    const responce = await apiPost(endPoint.download, obj, pathParamObj);
    if (responce.status === 200) {
      const blob = base64StringToBlob(responce?.data?.data);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = params?.file?.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };
  const removeFileControl = (formik: any, data: any, index: number) => {
    return (
      <>
        <ol className="list-group list-group-numbered">
          {formik.values[data.name]?.map((item: any, i: number) => {
            return (
              <>
                <li
                  key={i}
                  className="list-group-item d-flex justify-content-between align-items-start selected-file-name"
                >
                  <div className="ms-2 me-auto">
                    <div className="">
                      {item instanceof File ? item.name : item.name}
                    </div>
                  </div>
                  {/* formik?.setFieldValue(data.name, removeFile(i, item.name, data.name, formik)) */}
                  <span role="button" className="">
                    {item instanceof File ? (
                      ""
                    ) : (
                      <i
                        className="pi pi-download me-3"
                        onClick={() => downloadFile(item)}
                      ></i>
                    )}
                    {formik.submitCount !== 0 &&
                      formik.errors &&
                      formik.errors[data.name] &&
                      Array.isArray(formik.errors[data.name]) ? (
                      <>
                        <span className="error-massage p-0 me-5">
                          {
                            formik.errors[data.name]
                              ?.filter(
                                (data: any) =>
                                  typeof data === "string" &&
                                  data.split("_+_")[0] === item.name
                              )[0]
                              ?.split("_+_")[1]
                          }
                        </span>
                      </>
                    ) : (
                      ""
                    )}

                    <i
                      onClick={() =>                        
                        removeFile(i, item.name, data.name, formik, item)
                      }
                      className="pi pi-times"
                    ></i>
                  </span>
                </li>
              </>
            );
          })}
        </ol>
      </>
    );
  };

  useEffect(() => {
    if (iframeData.apiCall) {
      const obj:any = {};
      const pathParamObj:any = {};
      const controlEsign = updateFormData.filter((data: any) => data.active);
      obj["candidateId"] = controlEsign[0]?.candidateId;
      obj["clientId"] = controlEsign[0]?.clientId;
      obj["agreementId"] = controlEsign[0]?.fields.filter(
        (tt) => tt.type === "iframe"
      )[0]?.agreementId;
      pathParamObj["clientId"] = sessionStorage.getItem("clientId");
      pathParamObj["domainWithHttp"] = domainWithHttp;
      const sineData = async () => {
        dispatch<any>(loderControl());
        const response = await apiPost(endPoint.agreement, obj, pathParamObj);
        if (response.status === 200) {
          formstatus = "draft";
          fileUploadData();
        }
      };
      sineData();
    }
  }, [iframeData]);

  const handleIframeMessage = async (event: MessageEvent<any>) => {
    if (typeof event?.data === "string") {
      const jsonObject = JSON.parse(event?.data);
      if (jsonObject.type === "ESIGN") {
        setIframeData((prs: any) => {
          return {
            ...prs,
            apiCall: true,
            sigcontrol: true,
          };
        });
      }
    }
  };
  useEffect(() => {
    window.addEventListener("message", handleIframeMessage);
    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  const addNewChildForm = (params: any) => {
    const obj: any = {};
    Object.keys(params).forEach((ress) => {
      obj[ress] = "";
    });
    return obj;
  };
  const removeSubFormIndex = (params: any, index: number, formName: string) => {
    const obj: any = {};
    Object.keys(params[0]).forEach((ress) => {
      const removeFileData = Object.keys(oldFile).filter(
        (data) =>
          `${formName}.${index}.${data}` === `${formName}.${index}.${ress}`
      );
      delete oldFile[`${formName}.${index}.${removeFileData}`];
      Object.keys(oldFile).forEach((ress) => {
        const sumformKey = ress?.split(".");
        if (sumformKey.length === 3 && sumformKey[0] === formName) {
          if (parseInt(sumformKey[1]) === index) {                        
            delete oldFile[
              sumformKey[0] + "." + sumformKey[1] + "." + sumformKey[2]
            ];
          }
        }
      });
    });
    updateOldFile(oldFile);
    return index;
  };
  const subForm_2 = (formik: any, htmlForm: any) => {
    const getChildFormJson = htmlForm.filter(
      (data: any) => data.subForm && data.name === "subForm-2"
    );
    return (
      <>
        {getChildFormJson.length > 0 &&
          formik?.values?.subForm_2?.length > 0 ? (
          <>
            <FieldArray name="subForm_2">
              {({ insert, remove, push, form }) => (
                <>
                  {formik?.values?.subForm_2?.map(
                    (item: any, subIndex: number) => {
                      return (
                        <>
                          <hr className="sub-form-line" />

                          <div className="row g-3 align-items-center">
                            {getChildFormJson[0].subForm.map(
                              (setChildField: any) => {
                                return (
                                  <>
                                    <div className={setChildField?.className}>
                                      <div className="row">
                                        <div
                                          className={`${setChildField?.lableClass}`}
                                        >
                                          <Label
                                            name={setChildField?.label}
                                            className="form-label mb-0"
                                            label={setChildField?.label}
                                            subLabel={setChildField?.subLabel}
                                            required={setChildField?.required}
                                          />
                                        </div>
                                        <small className="form-description">
                                          {setChildField?.description}
                                        </small>

                                        <div
                                          className={`${setChildField?.inputClass} mt-2`}
                                        >
                                          {setChildField?.type === "date" ? (
                                            <>
                                              <FormikControl
                                                control="date"
                                                type={setChildField?.type}
                                                name={`subForm_2.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                allData={setChildField}
                                                dateFormat={
                                                  setChildField?.dateFormat
                                                }
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField?.type === "file" ? (
                                            <>
                                              <FormikControl
                                                control="file"
                                                name={`subForm_2.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                option={setChildField?.values}
                                                allData={setChildField}
                                              />
                                              <>
                                                <div className="mt-2">
                                                  {formik?.values?.subForm_2[
                                                    subIndex
                                                  ][setChildField.name]
                                                    ?.length > 0 ? (
                                                    <>
                                                      <ol className="list-group list-group-numbered">
                                                        {Array.isArray(
                                                          formik.values
                                                            ?.subForm_2[
                                                          subIndex
                                                          ][setChildField?.name]
                                                        )
                                                          ? formik.values?.subForm_2[
                                                            subIndex
                                                          ][
                                                            setChildField
                                                              ?.name
                                                          ].map(
                                                            (
                                                              ress: any,
                                                              i: number
                                                            ) => {
                                                              return (
                                                                <>
                                                                  {/* <div className={item.UrlClass}> */}
                                                                  <li
                                                                    key={i}
                                                                    className="list-group-item d-flex justify-content-between align-items-start selected-file-name"
                                                                  >
                                                                    <div className="ms-2 me-auto">
                                                                      <div className="">
                                                                        {ress instanceof
                                                                          File
                                                                          ? ress.name
                                                                          : ress
                                                                            ?.file
                                                                            ?.name}
                                                                      </div>
                                                                    </div>
                                                                    <span
                                                                      role="button"
                                                                      className=""
                                                                    >
                                                                      {ress instanceof
                                                                        File ? (
                                                                        ""
                                                                      ) : (
                                                                        <i
                                                                          onClick={() =>
                                                                            downloadFile(
                                                                              ress
                                                                            )
                                                                          }
                                                                          className="pi pi-download me-3"
                                                                        ></i>
                                                                      )}
                                                                      {/* {formik.submitCount !==
                                                                        0 &&
                                                                        formik
                                                                          .errors
                                                                          ?.subForm_2 &&
                                                                        formik
                                                                          .errors
                                                                          ?.subForm_2[
                                                                        subIndex
                                                                        ] &&
                                                                        Array.isArray(
                                                                          formik
                                                                            .errors
                                                                            ?.subForm_2[
                                                                          subIndex
                                                                          ][
                                                                          setChildField
                                                                            ?.name
                                                                          ]
                                                                        ) ? (
                                                                        <>
                                                                          {formik.errors?.subForm_2[
                                                                            subIndex
                                                                          ][
                                                                            setChildField
                                                                              ?.name
                                                                          ]?.filter(
                                                                            (
                                                                              data: string
                                                                            ) =>
                                                                              data ===
                                                                              (ress instanceof
                                                                                File
                                                                                ? ress.name
                                                                                : ress
                                                                                  ?.file
                                                                                  ?.name)
                                                                          )
                                                                            ?.length ===
                                                                            1 ? (
                                                                            <span className="error-massage me-5">
                                                                              File
                                                                              type
                                                                              is
                                                                              not
                                                                              allowed
                                                                            </span>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                        </>
                                                                      ) : (
                                                                        ""
                                                                      )} */}
                                                                      <span className="error-massage me-5">
                                                                        {
                                                                          formik?.submitCount !== 0 &&
                                                                          formik?.errors &&
                                                                          formik?.errors?.subForm_2 &&
                                                                          Array.isArray(formik?.errors?.subForm_2) &&  // Check if subForm_1 is an array
                                                                          formik?.errors?.subForm_2.length > 0 &&
                                                                          formik?.errors?.subForm_2[subIndex] &&
                                                                          formik?.errors?.subForm_2[subIndex][setChildField?.name] &&
                                                                          Array.isArray(formik?.errors?.subForm_2[subIndex][setChildField?.name]) &&  // Check if the nested array is an array
                                                                          formik?.errors?.subForm_2[subIndex][setChildField?.name]?.length > 0 &&
                                                                          Array.isArray(formik?.errors?.subForm_2[subIndex][setChildField?.name]) &&  // Check if the nested array is an array
                                                                          formik?.errors?.subForm_2[subIndex][setChildField?.name]?.filter((data: any) => typeof data === "string" && data.split("_+_")[0] === ress.name)?.[0]?.split("_+_")[1]
                                                                        }
                                                                      </span>
                                                                      <i
                                                                        className="pi pi-times"
                                                                        onClick={() =>
                                                                          removeFile(
                                                                            i,
                                                                            ress.name,
                                                                            `subForm_2.${subIndex}.${setChildField.name}`,
                                                                            formik,
                                                                            ress
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </span>
                                                                  </li>
                                                                </>
                                                              );
                                                            }
                                                          )
                                                          : null}
                                                      </ol>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField.type ===
                                            "checkbox-group" ||
                                            setChildField.type === "checkbox" ? (
                                            <>
                                              <FormikControl
                                                control="checkbox"
                                                name={`subForm_2.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                option={setChildField?.values}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField?.type ===
                                            "radio-group" ? (
                                            <>
                                              <FormikControl
                                                control="radio"
                                                name={`subForm_2.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                option={setChildField?.values}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField?.type === "html" ? (
                                            <>
                                              <div
                                                className="com-html-text"
                                                dangerouslySetInnerHTML={{
                                                  __html: setChildField?.value,
                                                }}
                                              ></div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField?.type === "select" ? (
                                            <>
                                              <FormikControl
                                                control="select"
                                                type={setChildField?.type}
                                                name={`subForm_2.${subIndex}.${setChildField?.name}`}
                                                className={"form-select"}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {setChildField.type === "text" ||
                                            setChildField.type === "number" ? (
                                            <>
                                              <FormikControl
                                                control="input"
                                                type={setChildField?.type}
                                                name={`subForm_2.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField.type === "textarea" ? (
                                            <>
                                              <FormikControl
                                                control="textarea"
                                                type={setChildField?.type}
                                                name={`subForm_2.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {formik?.values?.subForm_2?.length >
                                          1 ? (
                                          <>
                                            {setChildField?.addButton ? (
                                              <div
                                                className={`${setChildField?.removeButtonClass} d-flex align-items-center justify-content-end`}
                                              >
                                                <Button
                                                  clasName="btn btn-secondary add-subform-button"
                                                  type="button"
                                                  onClick={() =>
                                                    remove(
                                                      removeSubFormIndex(
                                                        formik?.initialValues
                                                          ?.subForm_2,
                                                        subIndex,
                                                        "subForm_2"
                                                      )
                                                    )
                                                  }
                                                  label={
                                                    <>
                                                      <div className="d-flex align-items-center">
                                                        <Image
                                                          src={Bin}
                                                          clasName="me-2"
                                                        />{" "}
                                                        <div>
                                                          {
                                                            getChildFormJson[0]
                                                              ?.removeButtonLabel
                                                          }
                                                        </div>
                                                      </div>
                                                    </>
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </>
                      );
                    }
                  )}

                  {getChildFormJson[0]?.AddButtonCount > formik?.values?.subForm_2?.length ?
                    <div>
                      <div className="text-center my-2">
                        <Button
                          position="left"
                          icon="pi pi-plus add-subform-icon"
                          label={getChildFormJson[0]?.AddButtonLabel}
                          type="button"
                          onClick={() =>
                            push(
                              addNewChildForm(formik?.initialValues?.subForm_2[0])
                            )
                          }
                          clasName="btn add-subform-button"
                        />
                      </div>
                    </div>
                    :
                    ""
                  }
                  <hr className="sub-form-line" />
                </>
              )}
            </FieldArray>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const subForm_1 = (formik: any, htmlForm: any) => {
    const getChildFormJson = htmlForm.filter(
      (data: any) => data.subForm && data.name === "subForm-1"
    );
    return (
      <>
        {getChildFormJson.length > 0 &&
          formik?.values?.subForm_1?.length > 0 ? (
          <>
            <FieldArray name="subForm_1">
              {({ insert, remove, push, form }) => (
                <>
                  {formik?.values?.subForm_1?.map(
                    (item: any, subIndex: number) => {
                      return (
                        <>
                          <hr className="sub-form-line" />
                          <div className="row g-3 align-items-center">
                            {getChildFormJson[0].subForm.map(
                              (setChildField: any) => {
                                return (
                                  <>
                                    <div className={setChildField?.className}>
                                      <div className="row">
                                        <div
                                          className={`${setChildField?.lableClass}`}
                                        >
                                          <Label
                                            name={setChildField?.label}
                                            className={"form-label mb-0"}
                                            label={setChildField?.label}
                                            subLabel={setChildField?.subLabel}
                                            required={setChildField?.required}
                                          />
                                        </div>
                                        <small className="form-description">
                                          {setChildField?.description}
                                        </small>
                                        <div
                                          className={`${setChildField?.inputClass} mt-2`}
                                        >
                                          {setChildField?.type === "date" ? (
                                            <>
                                              {/* <Image src={DataIcon} /> */}
                                              <FormikControl
                                                control="date"
                                                type={setChildField?.type}
                                                name={`subForm_1.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                allData={setChildField}
                                                dateFormat={
                                                  setChildField?.dateFormat
                                                }
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField?.type === "file" ? (
                                            <>
                                              <FormikControl
                                                control="file"
                                                name={`subForm_1.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                option={setChildField?.values}
                                                allData={setChildField}
                                              />
                                              <>
                                                <div className="mt-2">
                                                  {formik?.values?.subForm_1[
                                                    subIndex
                                                  ][setChildField.name]
                                                    ?.length > 0 ? (
                                                    <>
                                                      <ol className="list-group list-group-numbered">
                                                        {Array.isArray(
                                                          formik.values
                                                            ?.subForm_1[
                                                          subIndex
                                                          ][setChildField?.name]
                                                        )
                                                          ? formik.values?.subForm_1[
                                                            subIndex
                                                          ][
                                                            setChildField
                                                              ?.name
                                                          ].map(
                                                            (
                                                              ress: any,
                                                              i: number
                                                            ) => {


                                                              return (
                                                                <>
                                                                  {/* <div className={item.UrlClass}> */}
                                                                  <li
                                                                    key={i}
                                                                    className="list-group-item d-flex justify-content-between align-items-start selected-file-name"
                                                                  >
                                                                    <div className="ms-2 me-auto">
                                                                      <div className="">
                                                                        {ress instanceof
                                                                          File
                                                                          ? ress.name
                                                                          : ress
                                                                            ?.file
                                                                            ?.name}
                                                                      </div>
                                                                    </div>
                                                                    <span
                                                                      role="button"
                                                                      className=""
                                                                    >
                                                                      {ress instanceof
                                                                        File ? (
                                                                        ""
                                                                      ) : (
                                                                        <i
                                                                          onClick={() =>
                                                                            downloadFile(
                                                                              ress
                                                                            )
                                                                          }
                                                                          className="pi pi-download me-3"
                                                                        ></i>
                                                                      )}
                                                                      <span className="error-massage me-5">
                                                                        {
                                                                          formik?.submitCount !== 0 &&
                                                                          formik?.errors &&
                                                                          formik?.errors?.subForm_1 &&
                                                                          Array.isArray(formik?.errors?.subForm_1) &&  // Check if subForm_1 is an array
                                                                          formik?.errors?.subForm_1.length > 0 &&
                                                                          formik?.errors?.subForm_1[subIndex] &&
                                                                          formik?.errors?.subForm_1[subIndex][setChildField?.name] &&
                                                                          Array.isArray(formik?.errors?.subForm_1[subIndex][setChildField?.name]) &&  // Check if the nested array is an array
                                                                          formik?.errors?.subForm_1[subIndex][setChildField?.name]?.length > 0 &&
                                                                          Array.isArray(formik?.errors?.subForm_1[subIndex][setChildField?.name]) &&  // Check if the nested array is an array
                                                                          formik?.errors?.subForm_1[subIndex][setChildField?.name]?.filter((data: any) => typeof data === "string" && data.split("_+_")[0] === ress.name)?.[0]?.split("_+_")[1]


                                                                        }
                                                                      </span>

                                                                      {/* {
                                                                        // formik.submitCount !==
                                                                        //   0 &&
                                                                        formik
                                                                          .errors
                                                                          ?.subForm_1 &&
                                                                          formik
                                                                            .errors
                                                                            ?.subForm_1[
                                                                          subIndex
                                                                          ] &&
                                                                          Array.isArray(
                                                                            formik
                                                                              .errors
                                                                              ?.subForm_1[
                                                                            subIndex
                                                                            ][
                                                                            setChildField
                                                                              ?.name
                                                                            ]
                                                                          ) ? (
                                                                          <>
                                                                            {formik.errors?.subForm_1[
                                                                              subIndex
                                                                            ][
                                                                              setChildField
                                                                                ?.name
                                                                            ]?.filter(
                                                                              (
                                                                                data: string
                                                                              ) =>
                                                                                data ===
                                                                                (ress instanceof
                                                                                  File
                                                                                  ? ress.name
                                                                                  : ress
                                                                                    ?.file
                                                                                    ?.name)
                                                                            )
                                                                              ?.length ===
                                                                              1 ? (
                                                                              <span className="error-massage me-5">
                                                                                File
                                                                                type
                                                                                is
                                                                                not
                                                                                allowed
                                                                              </span>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                          </>
                                                                        ) : (
                                                                          ""
                                                                        )
                                                                      } */}
                                                                      <i
                                                                        className="pi pi-times"
                                                                        onClick={() =>
                                                                          removeFile(
                                                                            i,
                                                                            ress.name,
                                                                            `subForm_1.${subIndex}.${setChildField.name}`,
                                                                            formik,
                                                                            ress
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </span>

                                                                    {/* <span>{formik?.error?.subForm_1}</span> */}
                                                                  </li>
                                                                  {/* </div> */}
                                                                </>
                                                              );
                                                            }
                                                          )
                                                          : null}
                                                      </ol>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField.type ===
                                            "checkbox-group" ||
                                            setChildField.type === "checkbox" ? (
                                            <>
                                              <FormikControl
                                                control="checkbox"
                                                name={`subForm_1.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                option={setChildField?.values}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField?.type ===
                                            "radio-group" ? (
                                            <>
                                              <FormikControl
                                                control="radio"
                                                name={`subForm_1.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                option={setChildField?.values}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField?.type === "html" ? (
                                            <>
                                              <div
                                                className="com-html-text"
                                                dangerouslySetInnerHTML={{
                                                  __html: setChildField?.value,
                                                }}
                                              ></div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField?.type === "select" ? (
                                            <>
                                              <FormikControl
                                                control="select"
                                                type={setChildField?.type}
                                                name={`subForm_1.${subIndex}.${setChildField?.name}`}
                                                className={"form-select"}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {setChildField.type === "text" ||
                                            setChildField.type === "number" ? (
                                            <>
                                              <FormikControl
                                                control="input"
                                                type={setChildField?.type}
                                                name={`subForm_1.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {setChildField.type === "textarea" ? (
                                            <>
                                              <FormikControl
                                                control="textarea"
                                                type={setChildField?.type}
                                                name={`subForm_1.${subIndex}.${setChildField?.name}`}
                                                className={"form-control"}
                                                allData={setChildField}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {formik?.values?.subForm_1?.length >
                                          1 ? (
                                          <>
                                            {setChildField?.addButton ? (
                                              <div
                                                className={`${setChildField?.removeButtonClass} d-flex align-items-center justify-content-end`}
                                              >
                                                <Button
                                                  clasName="btn add-subform-button"
                                                  type="button"
                                                  onClick={() =>
                                                    remove(
                                                      removeSubFormIndex(
                                                        formik?.initialValues
                                                          ?.subForm_1,
                                                        subIndex,
                                                        "subForm_1"
                                                      )
                                                    )
                                                  }
                                                  label={
                                                    <>
                                                      <div className="d-flex align-items-center">
                                                        <Image
                                                          src={Bin}
                                                          clasName="me-2"
                                                        />{" "}
                                                        <div>
                                                          {
                                                            getChildFormJson[0]
                                                              ?.removeButtonLabel
                                                          }
                                                        </div>
                                                      </div>
                                                    </>
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                            {/* <div className="d-none">
                                                        <div className="text-center my-2">
                                                            <Button id="subForm-1-addbutton" position="left" icon="pi pi-plus add-subform-icon" label={getChildFormJson[0]?.AddButtonLabel} type="button" onClick={() => push(addNewChildForm(formik?.initialValues?.subForm_1[0]))} clasName="btn btn-primary " />
                                                        </div>
                                                    </div> */}
                          </div>
                        </>
                      );
                    }
                  )}
                  {getChildFormJson[0]?.AddButtonCount > formik?.values?.subForm_1?.length ?
                    <div className="text-center mt-3">
                      <Button
                        id="subForm-1-addbutton"
                        position="left"
                        icon="pi pi-plus add-subform-icon"
                        label={getChildFormJson[0]?.AddButtonLabel}
                        type="button"
                        onClick={() =>
                          push(
                            addNewChildForm(formik?.initialValues?.subForm_1[0])
                          )
                        }
                        clasName="btn add-subform-button "
                      />
                    </div>
                    :
                    ""
                  }
                </>
              )}
            </FieldArray>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const inputTypeFile = (formik: any, data: any, i: number) => {
    return (
      <>
        <>
          <>
            <FormikControl
              control="file"
              name={data.name}
              className={"form-control"}
              option={data.values}
              allData={data}
              value={formik.values[data.name]}
              formikData={formik}
            />
            <>
              {formik.values[data.name]?.length > 0 ? (
                <>
                  <div className={"mt-2"}>
                    {removeFileControl(formik, data, i)}
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          </>
        </>
      </>
    );
  };
  const errorControl = (data: any, formik: any) => {
    if (data?.name && formik?.values[data?.name]?.length > 0) {
      formik.setFieldValue(data?.name, []);
      oldFile[data.name] = [];
    }

    return "";
  };

  useEffect(() => {
    if (updateFormData?.length > 0) {
      const currentIndex: any = updateFormData.filter((ress) => ress?.currentPage);
      setTimeout(() => {
        sliderRef.current.slickGoTo(currentIndex[0]?.index);
      }, 800);


    }


  }, [updateFormData]);
  const parseErrors = (errDetails:any) =>{
    return Object.values(errDetails);
  };

  const ErrorSummary = (props:any) => {
    const { errors } = props;
    let errorMap:any = [];
    if(Object.keys(errors).length > 0) {
      errorMap = parseErrors(errors);
    }
    return (    
      <>
      { (errorMap.length > 0) &&
        <div className="margin-bottom error-summary card custom-card p-3">
          <h2 className="error-message">There were some errors in your form, please fix them to proceed with the onboarding process</h2>
          <ul>
          {
            errorMap.map((err:any, i: number) => {
              return (
                <>
                  {typeof err === "string" ? (
                    <li className="error-message" key={i}>{`${err}`}</li>
                  ) :
                  (
                    <li className="error-message" key={i}>Make sure all required documents are uploaded</li>
                  )
                  }
                </>
              );
            })
          }
          </ul>
        </div>
        }
      </>  
    );
  };
  return (
    <>
      <div>
        <Loader loading={loader} />
      </div>
      <div
        className="container-fluid pt-3"
        ref={ref}
        style={{ backgroundColor: "#FFFF", overflow: "hidden" }}
      >
        {screenShort.active ? (
          <>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 ">
                <div>
                  <p className="page-heading">{candidateData?.pageTitle ? candidateData?.pageTitle : "Onboarding"}</p>
                  <div className="d-flex">
                    <Button
                      onClick={() => {

                        // bell 
                        // formstatus = "draft";
                        checkTimeline = "";
                        updateValudatesetFormstatus("draft");
                        const prsForm: any = updateFormData.filter((ress: any) => ress.active);
                        document.getElementById(`submitBtn_${prsForm[0]["index"]}`)?.click();

                        // setCountControl((prs) => {
                        //   return {
                        //     ...prs,
                        //     saveModel: true,
                        //     saveDraftTest: false,
                        //   };
                        // })
                      }
                      }
                      icon="pi pi-clock"
                      position="left"
                      label={candidateData?.draftButtonText ? candidateData?.draftButtonText : "Save & Exit"}
                      clasName="btn btn-primary"
                    />
                    <div className="d-block ps-3">
                      <div className="completion-progress">
                        <div
                          className="barbg"
                          style={{ width: candidateData?.completionPercentage }}
                        ></div>
                      </div>
                      <div className="completion pt-1">
                        Completion:{" "}
                        <span className="fw-bold">
                          {candidateData?.completionPercentage}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 d-flex 
              justify-content-xl-end ">
                <div className="compliance-timeline compliance-timeline-new">
                  <div key={getOrientationChange.timeStamp} className={`slick-main-parent ${updateFormData?.length >= getScreenWidth.showCount ? "remove-start-line" : ""}`}>
                    <Slider {...settings} ref={sliderRef}>
                      {updateFormData?.map((ress: any, i: number) => {
                        return (
                          <>
                            <div
                              role={
                                Number(candidateData?.finalStep) >= i + 1
                                  ? "button"
                                  : "none"
                              }
                              onClick={() =>
                                candidateData?.finalStep !== null &&
                                  Number(candidateData?.finalStep) >= i + 1
                                  ? getCurrentPage(ress, i + 1)
                                  : ""
                              }
                              className={`slick-cont ${updateFormData?.length === 1 ? "remove-all-index-line" : ""} ${i === 0 ? "first-list" : ""} ${i + 1 === updateFormData?.length ? "last-index" : ""}
                               ${ress?.completedPage && ress.currentPage
                                  ? "slick-cont-dot-active slick-cont-line-active"
                                  : ""
                                }
                                  ${ress?.completedPage &&
                                  !ress?.currentPage
                                  ? "slick-cont slick-cont-dot-active slick-cont-line-active slick-cont-completed"
                                  : ""
                                }
                                  ${ress?.currentPage
                                  ? "completed slick-cont-dot-active slick-cont-half-line-active"
                                  : ""
                                }
                                ${candidateData?.finalStep >= (i + 1) ? " slick-cont-half-line-active" : ""}

                             

                                ${slickStyleUpdate.currentPage === i ? "first-list" : slickStyleUpdate?.nextIndex === i ? "last-index" : "000"}
                               
                                
                                `}
                            >

                              <div className="slick-cont-inner">
                                <h3>{ress.label}</h3>
                              </div>
                            </div >
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <br />
          </>
        ) : (
          ""
        )}

        <div>
          <div className="row">
            <div className="pt-3">
              {updateFormData.length > 0 ? (
                <p className="form-title">
                  {updateFormData?.filter((y) => y.active)[0]?.label}
                </p>
              ) : (
                ""
              )}
            </div>
            <div>
              {updateFormData.map((ress: any, index: number) => {
                return (
                  <>
                    {/* {ress.active ? */}
                    <div
                      key={ress?.formId}
                      className={ress.active ? "d-block" : "d-none"}
                    >
                      <Formik
                        initialValues={ress.indata}
                        // validationSchema={validateSchema}
                        enableReinitialize
                        innerRef={(el) => (formRef.current[index] = el)}
                        validateOnBlur={false}
                        onSubmit={(values) => {

                          checkTimeline = "";
                          if (valudatesetformstatus === "draft") {
                            if (countControl?.timelineUpdate) {
                              saveAndEdit();
                            } else {
                              setCountControl((prs) => {
                                return {
                                  ...prs,
                                  saveModel: true,
                                  saveDraftTest: false,
                                };
                              });
                            }

                          } else {
                            setCountControl((prs) => {
                              return {
                                ...prs,
                                timelineUpdate: false
                              };
                            });
                            formstatus = "";
                            formstatusTwo = false;
                            fileUploadData();
                          }

                        }}
                      >
                        {(formik) => (
                          (
                            <Form>
                              <div className="row form-space">
                                {ress.fields &&
                                  ress.fields.map((data: any, i: number) => {
                                    return (
                                      <>
                                        {data.type === "iframe" ? (
                                          <div className="mb-2">
                                            {!data.value ? (
                                              <>
                                                <Iframe url={data.iframe_url} />
                                                <div>
                                                  <FormikControl
                                                    control="input"
                                                    type={data.type}
                                                    name={data.name}
                                                    className={
                                                      "form-control d-none "
                                                    }
                                                    allData={data}
                                                    formstatus={formstatus}
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div
                                                  className="alert alert-primary"
                                                  role="alert"
                                                >
                                                  {data.successMessage}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        {data.type !== "iframe" ? (
                                          <>
                                            {data.parentValue ===
                                              formik?.values[data.parentName] ? (
                                              <div
                                                key={data.name}
                                                className={`${data.className} child`}
                                              >
                                                <div className="mb-2 first-index">
                                                  <div className="row g-2 align-items-center">
                                                    {/* ${data.label ? "" : "d-none"} */}
                                                    {data.name !==
                                                      "subForm-1" &&
                                                      data.name !==
                                                      "subForm-2" ? (
                                                      <div
                                                        className={`${data.lableClass}`}
                                                      >
                                                        <Label
                                                          name={data.label}
                                                          className="form-label mb-0" //
                                                          label={data.label}
                                                          subLabel={
                                                            data.subLabel
                                                          }
                                                          required={
                                                            data.required
                                                          }
                                                        />
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {data.description ? (
                                                      <small className="form-description">
                                                        {data.description}
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}

                                                    <div
                                                      className={
                                                        data.inputClass
                                                      }
                                                    >
                                                      {data.type === "html" ? (
                                                        <>
                                                          <div
                                                            className="com-html-text"
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                data.value,
                                                            }}
                                                          ></div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {data.type ===
                                                        "select" ? (
                                                        <>
                                                          <FormikControl
                                                            control="select"
                                                            type={data.type}
                                                            name={data.name}
                                                            className={
                                                              "form-select"
                                                            }
                                                            allData={data}
                                                            formik={formik}
                                                            completedPage={ress.completedPage}
                                                          />
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {data.type === "date" ? (
                                                        <>
                                                          <FormikControl
                                                            control="date"
                                                            type={data.type}
                                                            name={data.name}
                                                            className={
                                                              "form-control"
                                                            }
                                                            allData={data}
                                                            dateFormat={
                                                              data.dateFormat
                                                            }
                                                            completedPage={ress.completedPage}
                                                          />
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {data.type === "text" ||
                                                        data.type === "number" ? (
                                                        <>
                                                          <FormikControl
                                                            control="input"
                                                            type={data.type}
                                                            name={data.name}
                                                            className={
                                                              "form-control"
                                                            }
                                                            completedPage={ress.completedPage}
                                                            // allData={data}
                                                            allData={
                                                              checkValidationElement.filter(
                                                                (item: any) =>
                                                                  item.child_name ===
                                                                  data.name
                                                              )[0]
                                                                ?.childValidation
                                                                ? {
                                                                  ...data,
                                                                  validate:
                                                                    true,
                                                                }
                                                                : data
                                                            }
                                                          />
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {data.type ===
                                                        "textarea" ? (
                                                        <>
                                                          <FormikControl
                                                            control="textarea"
                                                            type={data.type}
                                                            name={data.name}
                                                            className={
                                                              "form-control"
                                                            }
                                                            allData={data}
                                                          />
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {data.type ===
                                                        "radio-group" ? (
                                                        <>
                                                          <FormikControl
                                                            control="radio"
                                                            name={data.name}
                                                            className={
                                                              "form-control"
                                                            }
                                                            option={data.values}
                                                            allData={data}
                                                            formik={formik}
                                                          />
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {data.type ===
                                                        "checkbox-group" ||
                                                        data.type ===
                                                        "checkbox" ? (
                                                        <>
                                                          <FormikControl
                                                            control="checkbox"
                                                            name={data.name}
                                                            className={
                                                              "form-control"
                                                            }
                                                            option={data.values}
                                                            allData={data}
                                                          />
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {data.type === "file" ? (
                                                        <>
                                                          <>
                                                            {inputTypeFile(
                                                              formik,
                                                              data,
                                                              i
                                                            )}
                                                          </>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  </div>
                                                  {data.type === "hr" ? (
                                                    <hr />
                                                  ) : (
                                                    ""
                                                  )}
                                                  {data.status &&
                                                    data.name === "subForm-1" ? (
                                                    <>
                                                      {subForm_1(
                                                        formik,
                                                        ress.fields,
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {data.status &&
                                                    data.name === "subForm-2" ? (
                                                    <>
                                                      {subForm_2(
                                                        formik,
                                                        ress.fields
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                            ) : (
                                              <>{errorControl(data, formik)}</>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    );
                                  })}
                                <div></div>
                              </div>
                              {
                                formik?.errors && <ErrorSummary errors={formik.errors}/>
                              } 
                              {screenShort.active ? (
                                <div className="text-end">
                                  {/* onClick={() => { formstatus = ""; fileUploadData(); }} */}
                                  <Button
                                    onClick={() => {
                                      updateValudatesetFormstatus("");
                                      const prsForm: any = updateFormData.filter((ress: any) => ress.active);
                                      document.getElementById(`submitBtn_${prsForm[0]["index"]}`)?.click();
                                    }}
                                    type="button"
                                    icon="pi pi-arrow-right"
                                    position="right"
                                    label={candidateData?.continueButtonText ? candidateData?.continueButtonText : "Continue To Next Step"}
                                    clasName="btn btn-primary"
                                  />
                                  <Button
                                    // onClick={() => updateValudatesetFormstatus("")}
                                    id={`submitBtn_${ress.index}`}
                                    type="submit"
                                    icon="pi pi-arrow-right"
                                    position="right"
                                    label=""
                                    clasName=" d-none btn btn-primary"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </Form>
                          )
                        )}
                      </Formik>
                      <br />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div >
      <Model
        show={saveModel}
        modelHeader={modelHeader()}
        modelBody={modelBody()}
        modelClose={() => closeModel()}
      />
      <Model
        size="lg"
        show={countControl.saveModel}
        modelBody={saveModelBody()}
      />
      <Model size="lg" show={saveAndExitModel} modelBody={confirmMass()} />

      {/* <iframe id="pdfPreview" width="100%" height="400"></iframe> */}
    </>
  );
};

export default OnBoarding;
