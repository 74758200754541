import { ErrorMessage, Field } from "formik";
import React, { useEffect, useRef, useState } from "react";
import TextError from "./TextError";
import Button from "../button/Button";
import { oldFile } from "../../config/onboarding/onBoarding";
import Image from "../image/Image";
import CloudUploadOutlined from "../../assets/images/cloud_upload_outlined.png";
import Upload from "../../assets/images/upload.png";
import { childDisplay, valudatesetformstatus } from "../../pages/onboarding/Onboarding";

const FileUpload = (props: any) => {
    const { name, label, value, className, allData, formikData, ...rest } = props;

    const forRef = useRef();
    // const [selectedFile, setSelecteFile] = useState<any>({});
    const selectedFile: any = {};
    // console.log(selectedFile);

    // useEffect(() => {
    //     console.log(formikData);
    //     for (let i = 0; i < childDisplay.length; i++) {
    //         const element = childDisplay[i];
    //         for (let h = 0; h < element.child_display.length; h++) {
    //             const element_1 = element.child_display[h];
    //             if (element_1) {

    //             }

    //         }

    //     }

    // }, [name]);
    const sleep = (ms: number): Promise<void> => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(); // Resolve without a value to indicate completion
            }, ms);
        });
    };

    const fieldValidation = (value: any) => {
        let error: any[] = [];
        let errorFileData: any[] = [];
        if (allData?.validate) {
            if (allData?.type === "file") {
                allData?.validations.forEach((element: any) => {
                    if (value?.length === 0 && valudatesetformstatus !== "draft") {
                        if (element.type === "required") {
                            error = element.params[0];
                        }
                    }
                    // if (element.type === "required" && valudatesetformstatus !== "draft") {
                    //     if (value?.length === 0) {
                    //         error = element.params[0];
                    //     }
                    // }
                    else {
                        if (element.type === "file_count") {
                            if (value && Array.isArray(value)) {
                                if (!(element.count >= value.length)) {
                                    error = element.params[0];
                                }
                            }
                        }
                        // else 
                        if (element.type === "file_type") {
                            const errorFile: any[] = [];
                            if (value && Array.isArray(value) && value.length > 0) {
                                value.forEach((file: any) => {
                                    if (file instanceof File) {
                                        const singleFile = file as File;
                                        if (
                                            !element?.allowed_types?.includes(
                                                singleFile.type?.toString()
                                            )
                                        ) {
                                            errorFile.push(
                                                singleFile?.name + "_+_" + element.params[0]
                                            );
                                            // error = errorFile;
                                            error.push(singleFile?.name + "_+_" + element.params[0]);

                                            // errorFileData.push(singleFile?.name + "_+_" + element.params[0]);
                                            // console.log(error);
                                        }
                                    }
                                });
                            }
                        }
                        // else 
                        if (element.type === "file_size") {
                            if (value && Array.isArray(value) && value.length > 0) {
                                const errorFileSize: any[] = [];

                                value.forEach((file: any) => {
                                    if (file instanceof File) {
                                        const fileListData = file as File;
                                        const fileSizeKbToMb = (
                                            fileListData.size /
                                            (1024 * 1024)
                                        ).toFixed(2);
                                        if (Number(fileSizeKbToMb) >= element.size) {
                                            // error = element.params[0];
                                            errorFileSize.push(file.name + "_+_" + element.params[0]);
                                            // error = errorFileSize;
                                            error.push(file?.name + "_+_" + element.params[0]);
                                        }
                                    }
                                });
                            }
                        }

                    }
                });
            }
        }
        return error?.length > 0 ? error : undefined;
    };

    const getAndOldFile = (name: any, value: any) => {

        const fileList: any[] = [];
        Array.from(value).forEach((element: any) => {
            fileList.push(element);
        });
        // oldFile[name] = oldFile[name]?.length > 0 ? oldFile[name]?.concat(fileList) : fileList;
        oldFile[name] =
            oldFile[name]?.length > 0 ? oldFile[name]?.concat(fileList) : fileList;

        return oldFile[name];
    };

    return (
        <>
            <div className="class-name position-relative ">
                <Field
                    name={name}
                    validate={fieldValidation}
                    onBlur={(e: any) => e.preventDefault()}
                    {...rest}
                >
                    {({ field, form: { setFieldValue, values } }: any) => {
                        // document.getElementById(`${name}1`)?.value = null
                        // if (field.value.length === 0) {
                        const fileInput: any = document.getElementById(`${name}1`);
                        if (fileInput) {
                            fileInput.value = null;
                        }
                        // }

                        return (
                            <>
                                <div
                                    className={`${className} text-center btn-sm custom-file-upload`}
                                >
                                    {/* <div className="border-end pe-2">
                                        Choose Files
                                    </div>
                                    <div className="ps-2">

                                    </div> */}
                                    <div className="">
                                        <Image src={CloudUploadOutlined} />
                                        <div>Drag and drop here</div>
                                    </div>
                                    <div>or</div>
                                    <Button
                                        type="button"
                                        label={
                                            <div>
                                                <Image src={Upload} clasName="upload-icon" />
                                                <span className="ms-1 upload-file-text">
                                                    Select Files
                                                </span>
                                            </div>
                                        }
                                        clasName="btn btn-primary"
                                    />

                                    {/* <div className="d-flex">
                                        <div>
                                            <Image src={Upload} />
                                        </div>
                                        <div>
                                            Select Files
                                        </div>

                                    </div> */}
                                </div>
                                <input
                                    id={`${name}1`}
                                    className={`${className} hide-input-file custom-file-upload`}
                                    onBlur={(e: any) => e.preventDefault()}
                                    type="file"
                                    onChange={(e) => {
                                        setFieldValue(
                                            field.name,
                                            getAndOldFile(field.name, e.target.files)
                                        );
                                    }}
                                    // getOldFile(e.target.files)
                                    multiple={true}
                                // onClick={() => {
                                //     // document.getElementById(`${name}1`)?.value = null
                                //     if (field.value.length === 0) {
                                //         const fileInput: any = document.getElementById(`${name}1`);
                                //         if (fileInput) {
                                //             fileInput.value = null;
                                //         }
                                //     }
                                // }
                                // }

                                // value={}
                                // {...field}
                                />
                            </>
                        );
                    }}
                </Field>
                <ErrorMessage name={name} className="mb-2" component={TextError} />
            </div>
        </>
    );
};

export default FileUpload;
