export const htmlTostring = /<\/?[^>]+(>|$)/g;
export const characters = /^(?=[^A-Za-z]*[A-Za-z])[a-zA-Z0-9'\-_. ]+(?:\s[a-zA-Z0-9'_\-. ]+)*$/;
export const pinFormat = /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/;
export const customEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i;
export const format = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
// export const posportVisa = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;
// export const posportVisa = /^(N|C|V|A|F|P|M){1}\A{1}\d{7}$/;
// export const posportVisa = /^(?=.*[A-Z])(?=.*\d)[A-Z\d]{8,13}$/;
export const posportVisa = /^[A-Z0-9]{8,13}$/;
export const linkedinProfileUrl = /^((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
export const firstnameVar = / *\{[firstname]*\} */g;
export const lastnameVar = / *\{[lastname]*\} */g;

//allow alphanumeric and .#!@$
export const keywordRuleset =/^[ A-Za-z0-9.#!@$]*$/;

export const supportFormat = [
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/pdf", // .pdf
    // "application/vnd.oasis.opendocument.text", // .odt
    // "text/html", // .html
    // "text/htm", // .htm
    "text/plain", // .txt
    "application/rtf", // .rtf
    // "image/vnd.ms-modi", //.mdi - type not noticed
    "mdi", //mdi - working
];

export const FileSize = 2097152;



