export const loginErrorLabel = {
    user_name_required: "Username is required",
    password_required: "Password is required",
};

export const userErrorLabel = {
    user_name_required: "Username is required",
    email_required: "Email is required",
    phone_number_required: "Phone number is required",
    location_required: "Location is required",
    email_invalid: "Email is invalid"
};

export const jopApplicationError = {
    title_required: "Title is required",
    first_name_required: "First name is required",
    first_name_invalid: "First name is invalid",
    last_name_required: "Last name is required",
    last_name_invalid: "Last name is invalid",
    preferred_name_required: "Preferred name is required",
    mobile_required: "Mobile number is required",
    mobile_invalid: " Mobile number is invalid",
    email_required: "Email is required",
    email_invalid: "Email is invalid",
    address_1_required: "Address 1 is required",
    address_2_required: "Address 2 is required",
    suburb_city_required: "Suburb/City is required",
    state_required: "State is required",
    post_code_required: "Post code is required",
    post_code_invalid: "Post code is invalid",
    country_required: "Country is required",
    resume_required: "Resume is required",
    file_size_invalid: "Allowed maximum size is 2MB",
    file_invalid: "Invalid file format",
    linkedin_profile_invalid: "Invalid linkedin Profile",

};

export const JobAlertErrors = {
    email_required: "Email is required",
    email_invalid: "Email is invalid",
    alertfrequency_required: "Alert Frequency is required",
    keyword_minimum: "Please select atleast 1 keyword",
    alert_category_required: "Please select an alert category"
};

export const defaultFieldError = "This field is required";