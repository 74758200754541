import React, { useEffect, useState } from "react";
import "../../../styles/client/joblistingclient.scss";
import PaginationClient from "../../../component/pagination/PaginationClient";
import JobFilterClient from "./JobFilterClient";
import JobView from "./jobView/JobView";
import SingleSelect from "../../../component/singleSelect/SingleSelect";
import { clientIds } from "../../../config/clients/clients";
import { useSelector } from "react-redux";

const JobListingsClient = (props: any) => {
  
  const { location, clientId, checkListControl, jobsList, setTableControl, tableControl, sortList, 
    displayPage, filterView, moveToJobViewPage, pageChange, filterListData, searchControlSubmit, 
    pageSizeChange, withinDisabled, withinDisabledLocClass } = props;

  const clientConfig = useSelector((state: any) => state.careers.cliendConfig);
  const [noJobFoundStyle, setNoJobFoundStyle] = useState("");
  const [showPagination, setShowPagination] = useState(false);

  // const selectOption = (keyword: string, e: any) => {
  //   let value = e.target.value;
  //   let result = "";

  //   if(keyword === "sort") {
  //     result = sortList?.find((sort: any) => (sort?.value === value));
  //   } else if(keyword === "size") {
  //     result = displayPage?.find((size: any) => (size?.value === Number(value)));
  //   } 
    
  //   return result;
  // };

  const checkJobFound = () => {
    if(tableControl.totalCount <= 0){
      setNoJobFoundStyle("no-data-found");
    }
  };

  useEffect(() => {
    checkJobFound();
  }, [tableControl.totalCount]);

  useEffect(() => {    
    if((jobsList?.data?.data?.jobs?.perPage === 5 && jobsList?.data?.data?.jobs?.totalRecord > 5) || 
      (jobsList?.data?.data?.jobs?.perPage === 10 && jobsList?.data?.data?.jobs?.totalRecord > 10) || 
      (jobsList?.data?.data?.jobs?.perPage === 20 && jobsList?.data?.data?.jobs?.totalRecord > 20) || 
      (jobsList?.data?.data?.jobs?.perPage === 25 && jobsList?.data?.data?.jobs?.totalRecord > 25)) {
        setShowPagination(!false);
      } else {
        setShowPagination(false);
      }
  }, [jobsList?.data?.data?.jobs?.perPage, jobsList?.data?.data?.jobs?.totalRecord]);

  //Use resolveLogo for customizing client based default business logo image
  const resolveLogo = () => {    
    if(clientId === clientIds.hvtc) {
      return clientConfig?.data?.data?.clientSettings?.logo;
    }
    else {
      return "https://stag-compliance-careers.s3.ap-southeast-2.amazonaws.com/career-doc-settings/uploads/no_image.png";
    }
  };
  
  return (
    <>
      {location.pathname === "/" ?
        <div className="container">
          <JobFilterClient 
            tableControl = {tableControl}
            setTableControl = {setTableControl}
            filterListData = {filterListData}
            searchControlSubmit = {searchControlSubmit}
            withinDisabled = {withinDisabled}
            withinDisabledLocClass = {withinDisabledLocClass}
          />
          <div className="module-careers-listings">
            <div className="listings-top">
              { tableControl.totalCount > 0 ?
              <h5 className="h5-client">Showing<span className="primary-color ms-2">{ 
                (checkListControl && jobsList?.data?.data?.jobs?.totalRecord > 10) ? 
                  jobsList?.data?.data?.jobs?.totalJobCountPerPage :  
                    checkListControl ? 
                      jobsList?.data?.data?.jobs?.totalRecord : 
                        (jobsList?.data?.data?.jobs?.totalRecord > jobsList?.data?.data?.jobs?.totalJobCountPerPage) ? 
                          jobsList?.data?.data?.jobs?.totalJobCountPerPage : 
                            jobsList?.data?.data?.jobs?.totalRecord} </span>
                of<span className="primary-color ms-2">{jobsList?.data?.data?.jobs?.totalJobCount}</span>
              </h5> : <h5 className="h5-client"></h5> }
              <div className="flex box-gap cust-common-select cust-common-select-dropdown">
                <form className="sort">
                  <div className="form-group dropdown-field">
                    <label>Sort by</label>
                    <SingleSelect className="sort-control"
                      placeholder="" onChange={(e: any) => {
                          setTableControl((prs: any) => {
                              return {
                                  ...prs,
                                  sort: e
                              };
                          });
                      }}
                      value={tableControl.sort}
                      options={sortList}
                      isSearchable={false}
                    />
                    {/* <select className="form-control normal-text-input mq-normal-text-input"
                      placeholder="" onChange={(e: any) => {
                        setTableControl((prs: any) => {
                            return {
                              ...prs,
                              sort: selectOption("sort", e)
                            };
                        });
                      }} value={tableControl.sort.value}> */}
                        {/* {sortList?.map((ress: any, id: number) => {
                            return (
                                <option key={id} value={ress.value}>{ress.label}</option>
                            );
                        })} */}
                    {/* </select> */}
                  </div>
                </form>
                <form className="sort">
                  <div className="form-group dropdown-field">
                    <label>Display</label>
                    <SingleSelect className="sort-control"
                        placeholder="" onChange={(e: any) => {
                            pageSizeChange(e);
                        }}
                        value={tableControl.pageSize}
                        options={displayPage}
                        isSearchable={false}
                    />
                    {/* <select className="form-control normal-text-input mq-normal-text-input" 
                      value={tableControl.pageSize.value}
                      onChange={(e: any) =>
                        setTableControl((prs: any) => {
                            return {
                                ...prs,
                                pageSize: selectOption("size", e)
                            };
                        })
                      }
                    > */}
                    {/* {displayPage?.map((ress: any, id: number) => {
                      return <option key={id} value={ress.value}>{ress.label}</option>;
                    })} */}
                    {/* </select> */}
                  </div>
                </form>
              </div>
            </div>
            {tableControl.data.length > 0 ?
              <>
                <div  className="flex">
                {tableControl.data.map((ress: any, i: number) => {
                  return (
                    <div key={ress.id} className="careers-listing">
                      <div className="inner">
                        <div className="image">
                          <img src={ress.logo ? ress.logo : resolveLogo()} className="img-fluid" alt="logo" />
                        </div>
                        <div className="text">
                          <h5 className="h5-client" role="button" onClick={() => moveToJobViewPage(ress)}>{ress.title}</h5>
                          <h6 className="h6-client">{ress.location?.location}</h6>
                          <div className="copy" dangerouslySetInnerHTML={{ __html: ress.description }}></div>
                          <button className="butn" onClick={() => moveToJobViewPage(ress)}><span>Read More</span></button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                </div>
                {showPagination ? 
                  <div className="pagination">
                    <PaginationClient
                      currentPage={tableControl.currentPage}
                      totalCount={tableControl.totalCount}
                      pageSize={tableControl.pageSize?.value}
                      onPageChange={(page: any) => pageChange(page)}
                    />
                  </div>
                :
                  ""
                }
              </>
              :
              <>
                  <div>
                      <div className="job-list-parent">
                          <div className={`text-center ${noJobFoundStyle}`}>
                              {tableControl.massage}
                          </div>
                      </div>
                  </div>
              </>
            }
          </div>
        </div>
      :
        <>
          <JobView 
            jobDataView={filterView()}
            filterListData = {filterListData} 
            tableControl = {tableControl} 
            setTableControl = {setTableControl}
            searchControlSubmit = {searchControlSubmit}
            withinDisabled = {withinDisabled}
            withinDisabledLocClass = {withinDisabledLocClass}
          />
        </>
      }
    </>
  );
};

export default JobListingsClient;