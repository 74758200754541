import React from "react";
import Button from "../../../component/button/Button";
import { useSelector } from "react-redux";

const FilterPageTwo = (props: any) => {
    const questionId = useSelector((stete: any) => { return stete?.careers?.getQuestionId; });

    const { navigateToJob, navigateToJobAlerts } = props;

    // console.log(navigateToJob);
    

    // useEffect(() => {
    //     if (questionId?.status === 200) {
    //         dispatch<any>(getJobDetails(questionId?.data?.data));
    //     }
    // }, [questionId]);
    return (
        <>
            <div className={"job-search-box "}>
                <div className="btn-section mt-3">
                    <div className="text-center">
                        <h2 className="job-view-text">No position matches your interest?</h2>
                        <p className="infi-text">Submit your information below and
                            <br />
                            we will retain it for future consideration
                        </p>
                    </div>
                    {questionId?.status === 200 ?
                        <Button
                            clasName="btn btn-primary completion-btn-primary w-100 mb-3"
                            label={"Register Your Interest"}
                            onClick={navigateToJob} />
                        : ""}
                    <Button
                        clasName="btn btn-primary completion-btn-primary w-100"
                        label={"Job Alerts"}
                        onClick={navigateToJobAlerts}  />
                </div>
            </div>
        </>
    );
};

export default FilterPageTwo;