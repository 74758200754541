import React from "react";

const TextError = (props: any) => {
    // console.log(props);

    return (
        <>
            {!Array.isArray(props.children) ?
                <div className="error-message mb-1">
                    {props.children}
                </div>
                :
                ""
            }
        </>

    );
};

export default TextError;